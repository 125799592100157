<template>

  
<HeaderComponent />


<div class="side-menu">
    <MenuComponent class="side-menu" />
  </div> 


  <div class="informacion">

  <CardComponent>

    <template v-slot:title>
      <h2 class="title">Información de Contacto</h2>
    </template>

    <template v-slot:content>
      <!-- Contenido de tu anuncio -->
      <div class="card-anuncio">
        <div class="card-contentAnuncio">
          <div class=" anuncio">
            <p>Si tienes algún problema en tu procedimiento, no dudes en comunicarte con nosotros.</p>
            <p>Teléfono 1:+1 (123) 456-7890</p>
            <p>Teléfono 2:+1 (987) 654-3210</p>
            <p>Domicilio:123 Calle Principal, Ciudad</p>
            <p>Correo Electrónico:ejemplo@correo.com</p>
          </div>
        </div>
      </div>
    </template>

  </CardComponent>

  </div>

  <FooterComponent />


</template>





<!-- Estilos & Scripts -->
<style scoped src="../styles/views/InfoContacto.css"></style>

<script>
import MenuComponent      from    '../components/MenuComponent.vue';
import CardComponent      from    '../components/CardComponent.vue';
import FooterComponent    from    '../components/FooterComponent.vue';
import HeaderComponent    from    '../components/HeaderComponent.vue';

export default
  {
    name: 'InfoContacto',
    components:
    {
      CardComponent,
      FooterComponent,
      HeaderComponent,
      MenuComponent,

    },
    
  }
</script>