<template>
    <!-- main theme -->
    <div class="material-you light ">

        <!-- header -->
        <HeaderComponent />
        <!-- main menu -->
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>
        <!-- tooltips -->
        <article class="message">
            <MessageComponent title="Detalles" :message="descripcion" />
        </article>
        <!-- vacancie info -->
        <div class="card-perfil">
            <CardComponent>
                <template v-slot:content>
                    <div class="media-content-info">
                        <div class="content-postulados">
                            <p style="text-align: left;">Información de la Vacante</p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em;">
                                <span class="tag is-info-custom">Empresa Responsable</span> {{
                                vacancieDetails.company_name }}
                            </p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Nombre de la Vacante</span> {{ vacancieDetails.name }}
                            </p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Creada en </span>
                                {{ new Date(vacancieDetails.creation_log).toLocaleDateString('es-MX', {
                                    weekday: 'long',
                                    day: 'numeric', month: 'long', year: 'numeric'
                                }) }}
                            </p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                <span class="tag is-info-custom">Descripción</span> {{ vacancieDetails.description }}
                            </p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Dirección</span> {{ vacancieDetails.address }}
                            </p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Salario</span>
                                {{ vacancieDetails.salary !== null ? '$' + vacancieDetails.salary : 'No aplica' }}
                            </p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Notas Adicionales</span> {{ vacancieDetails.notes }}
                            </p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Estado</span> {{ vacancieDetails.state_name }}
                            </p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Municipio</span> {{ vacancieDetails.muni_name }}
                            </p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Sector</span> {{ vacancieDetails.sector_name }}
                            </p>
                        </div>
                    </div>
                </template>
                <!-- Botón Aceptar -->
                <template v-slot:buttons>
                    <md-elevated-button @click="handleClick">
                        {{ buttonText }}
                    </md-elevated-button>
                </template>
            </CardComponent>
        </div>
        <!-- footer -->
        <FooterComponent />
        <!-- modal for loading times -->
        <Transition name="bounce">
            <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
                <div class="modal-background"></div>
                <div class="modal-card">
                    <!-- Contenido del modal de carga (puede ser un spinner, mensaje, etc.) -->
                    <section class="modal-card-body">
                        <div> Validando vacante.. </div>
                        <md-circular-progress four-color indeterminate></md-circular-progress>
                    </section>
                </div>
                <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
            </div>
        </Transition>
        <!-- modal for responses -->
        <Transition name="bounce">
            <ModalComponent v-if="showModal" :title="modalTitle">
                <template v-slot:content>
                    <!-- Utiliza v-html para interpretar el contenido como HTML -->
                    <div v-html="modalContent"></div>
                </template>
                <template v-slot:buttons>
                    <md-elevated-button v-if="respuestaExitosa" @click="linkfor">
                        Aceptar
                    </md-elevated-button>
                    <md-elevated-button v-if="!respuestaExitosa" @click="linkfor">
                        Volver
                    </md-elevated-button>
                </template>
            </ModalComponent>
        </Transition>
    </div>
</template>



<style scoped src   =   "../styles/material/theme.light.css">       </style> 
<style scoped src   =   "../styles/material/theme.dark.css">        </style> 
<style scoped src   =   "../styles/views/MaterialTheming.css">      </style>
<style scoped src   =   "../styles/views/SolicitudesDetalles.css">  </style>

<script>
import CardComponent        from    '../components/CardComponent.vue';
import FooterComponent      from    '../components/FooterComponent.vue';
import HeaderComponent      from    '../components/HeaderComponent.vue';
import MenuComponent        from    '../components/MenuComponent.vue';
import MessageComponent     from    '../components/MessageComponent.vue';
import ModalComponent       from    '../components/ModalComponent.vue';

import { loadVacancie, joinRequest }     from    '../api/ApiService';

export default
{
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        ModalComponent
    },



    async created() 
    {
        try 
        {
            const data              =   await loadVacancie(this.$store);
            this.vacancieDetails    =   data.info_vacancie;
            console.log("DATOS DE LA API:", data);
        } 
        catch (error) 
        {
            console.error('Error loading vacancie:', error);
        }
    },



    computed: 
    {
        detalles() 
        {
            return this.$store.getters.getterVacancieId;
        },
    },



    data() 
    {
        return  {
                    isClicked           :   false,
                    isFetching          :   false,
                    respuestaExitosa    :   false,
                    showModal           :   false,
                    vacancieDetails     :   null,

                    actionOnSuccess     :   '',
                    buttonText          :   'Enviar Solicitud',
                    modalContent        :   '',
                    modalTitle          :   '',
                    motivoRechazo       :   '',


                    errorJoinApi        : `Desafortunadamente, en este momento no ha sido posible que te unas a la vacante seleccionada.`,
                    descripcion         : `Aquí encontrarás los detalles de la vacante disponible para que puedas decidir 
                                        si te gustaría unirte a nosotros. Por favor, tómate el tiempo necesario para leer detenidamente
                                        y asegúrate de cumplir con todos los requisitos antes de tomar una decisión.
                                        ¡Estamos emocionados de que consideres unirte a nuestro equipo!`
                };
    },



    methods:
    {
        handleClick() 
        {
            if (!this.isClicked) 
            {
                this.buttonText = 'Haz clic de nuevo para confirmar';
                this.isClicked = true;
            } 
            else 
            {
                this.handleAccept();
                this.isFetching = true;
                this.join_request(this.$store);
            }
        },
        
        
        async join_request(store) 
        {
            try 
            {
                this.isFetching  = true;
                await new Promise(resolve => setTimeout(resolve, 3000));

                const response          =   await joinRequest(store);

                this.isFetching         =   false;
                this.showModal          =   true;
                this.modalTitle         =   'Proceso Exitoso';
                this.modalContent       =   response.message;
                this.respuestaExitosa   =   true;
            } 
            catch (error) 
            {
                this.isFetching = false;
                console.error('Error en el catch general:', error);
                this.showModal = true;
                this.modalTitle = 'Ha Ocurrido un Error';
                this.modalContent = `ERROR DE SERVER: ${error.message}`;
            }
        },


        handleAccept() 
        {
            console.log('Botón aceptado');
            
            this.buttonText = 'Enviar Solicitud';
            this.isClicked = false;
            
        
        },


        linkfor()
        {
            this.$router.push({ name: 'solicitudes/nueva' });
        },


        closeModal()
        {
            this.showModal          =   false;
        },


        errorButton() 
        {

            this.showModal = false;
        },
    },

}
</script>