<template>
   <div :class="[mainTheme]">
  <div>

    <div class="float-menu">
      <md-filled-tonal-icon-button class=" menu-button" @click="toggleMenu">
        <span class="material-symbols-outlined">menu</span>
      </md-filled-tonal-icon-button>
    </div>

    <Transition name="bounce">
      <aside class="sidebar" :class="{ 'menu-visible': true }" v-if="isMenuVisible">
        <div class="tipo">&nbsp; Cuenta &nbsp;Alumno UMSNH</div>
        <router-link :to="{ name: 'estudiante-home' }" class="menu-logo">UMSNH</router-link>

        <ul class="menu-list">

          <li>
            <router-link :to="{ name: 'perfil-estudiante' }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">face</span> <!-- Outlined -->
                </div>
                <span>Perfil</span>
              </div>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'progreso' }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">clock_loader_40</span> <!-- Outlined -->
                </div>
                <span>Progreso</span>
              </div>
            </router-link>
          </li>

          <!-- submenus papus -->
          <li class="has-submenu">

            <div class="menu-item">
              <div class="icon-container">
                <span class="material-symbols-outlined">mark_email_unread</span> <!-- Outlined -->
              </div>
              <span>Solicitudes</span>
            </div>


            


            <ul class="submenu">
              <li>
                <div class="menu-item">
                  <router-link :to="{ name: 'solicitudes/nueva' }">
                    <span class="material-symbols-outlined">tab_new_right</span>
                    Nueva Solicitud
                  </router-link>
                </div>
              </li>

              <li>
                <div class="menu-item">
                  <router-link :to="{ name: 'estudiante-solicitudes-estado' }">
                    <span class="material-symbols-outlined">format_list_numbered</span>
                    Estado de mis Solicitudes
                  </router-link>
                </div>
              </li>
            </ul>
          </li>
          <!-- fin del submenu maquinola -->


          <li>
            <router-link :to="{ name: 'bolsa-trabajo-inicio' }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">shopping_bag</span>
                </div>
                <span>Bolsa de Trabajo</span>
              </div>
            </router-link>
          </li>



          <li>
            <router-link :to="{ name: 'lista-mensajes' }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">chat_bubble</span>
                </div>
                <span>Mensajes</span>
                <span class="unread-badge">{{ unreadMessages }}</span>
              </div>
            </router-link>
          </li>

          <!-- <li>
            <router-link :to="{ name: '' }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">card_membership</span> 
                </div>
                <span>Pase</span>
              </div>
            </router-link>
          </li> -->

          <li>
            <router-link :to="{ name: 'contacto' }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">phone_in_talk</span>
                </div>
                <span>Contacto</span>
              </div>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'empresa-solicitudes-listado' }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">page_info</span>
                </div>
                <span>Acerca de</span>
              </div>
            </router-link>
          </li>

          <li @click="cerrarSesion">
            <router-link :to="{  }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">exit_to_app</span>
                </div>
                <span>Salir</span>
              </div>
            </router-link>
          </li>
        </ul>

      </aside>
    </Transition>
  </div>
</div>
</template>






<style scoped src = "../styles/material/theme.light.css"    ></style> 
<style scoped src = "../styles/material/theme.dark.css"     ></style> 

<style scoped src="../styles/components/MenuComponent.css"></style>

<script>
export default 
{
  data() 
  {
    return  {
              unreadMessages  : 10,
              isMenuVisible   : true,
              mainTheme: 'light',
            };
  },



  beforeMount() 
  {
    window.addEventListener('resize', this.handleWindowResize);
    if (window.innerWidth <= 850) 
    {
        this.isMenuVisible = false;
    }
    document.addEventListener('click', this.handleOutsideClick);
  },



  mounted() 
  {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery);
  },



  beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
    document.removeEventListener('click', this.handleOutsideClick);
  },



  methods: 
  {
    handleOutsideClick(event) {
    if (this.isMenuVisible && window.innerWidth <= 850) {
      const menu = this.$el.querySelector('.sidebar');
      const button = this.$el.querySelector('.menu-button');
      if (!menu.contains(event.target) && !button.contains(event.target)) {
        this.isMenuVisible = false;
      }
    }
  },
    cerrarSesion() 
    {
      localStorage.clear();
      this.$router.push({ path: '/' });
    },


    toggleMenu() 
    {
      this.isMenuVisible = !this.isMenuVisible;
      if (this.isMenuVisible) 
      {
        document.body.classList.remove('menu-hidden');
      } 
      else 
      {
        document.body.classList.add('menu-hidden');
      }
    },


    cerrarSesion() 
    {
      localStorage.clear();
      this.$router.push({ path: '/' });
    },


    handleWindowResize() 
    {
      this.windowWidth = window.innerWidth;
      if(innerWidth >= 851)
      {
        this.isMenuVisible = true;
      }
    },



    handleThemeChange(e) 
    {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    }

  },

};
</script>
