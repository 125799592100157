<template>
  <div :class="[mainTheme]">
  <div class="b-table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
    <div class="table-wrapper has-mobile-cards">
      <table class="table is-fullwidth is-striped is-hoverable">

        <thead>
          <tr>
            <th v-for="column in columns" :key="column.field" @click="changeSort(column.field)"
              :class="{ 'is-sorted': sortColumn === column.field }">
              {{ column.label }}
              <span v-if="sortColumn === column.field"
                :class="sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'"></span>
            </th>
          </tr>
        </thead>

        <tbody>
          <!-- <tr v-for="item in items" :key="item.id"> -->
            <tr v-for="item in filteredItems" :key="item.id">
            <td v-for="column in columns" :key="column.field" :data-label="column.label">

              <!-- Comprueba si la columna es la de imagen -->
              <template v-if="column.field === 'img'">
                <img :src="item[column.field]" alt="Imagen de perfil" />
              </template>

              <template v-if="column.field === 'icon'">
                <span v-html="item[column.field]"></span>
              </template>

              <template v-else>

                <template v-if="column.field === 'verMensajes'">
                  <button class="button  is-rounded" @click="verMensajes(item)">Ver Mensajes</button>
                </template>

                <md-elevated-button v-if="column.field === 'VerProgreso' "   @click="VerProgreso(item)">
                  Ver Progreso
                </md-elevated-button>

                <md-elevated-button v-if="column.field === 'detalles' && item.detalles === ''"  @click="AprobarDetalles(item)">
                  Revisar
                </md-elevated-button>

                <template v-if="column.field === 'subirReporte'">
                  <button class="button  is-rounded" @click="openModal(item)">Subir</button>
                </template>

                <md-elevated-button v-if="column.field === 'Ver'" @click="SolicitudesDetalles(item)">
                  Revisar
                </md-elevated-button>

                <template v-else>
                  {{ item[column.field] }}
                </template>

              </template>

            </td>
          </tr>
        </tbody>

      </table>

    </div>
  </div>
  <div>
  </div>
</div>
</template>






<style scoped src = "../styles/material/theme.light.css"    ></style> 
<style scoped src = "../styles/material/theme.dark.css"     ></style> 


<style scoped src="../styles/components/TableComponent.css"></style>


<script>
import ModalComponent from './ModalComponent.vue';

export default 
{
  components: 
  {
    ModalComponent,
  },



  props: 
  {
    columns     :   Array, 
    items       :   Array, 
    searchQuery :   String,
  },


  emits: ['sort'],

  data() 
  {
    return  {
              isModalOpen :   false,
              selectedId      :   null,
              sortColumn      :   null, 
              sortDirection   :   'asc', 
              sortField       :   '',
              sortOrder       :   'asc', 
              mainTheme       :   'light'
            }
  },




  



  methods: 
  {
    handleThemeChange(e) 
    {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },


    verMensajes(item) 
    {
      this.$router.push({ name: 'estudiante-progreso-detalles-incidencias-mensajes' });
      console.log('id de la vacante lokita', item.incidentId);
      this.$store.commit('SET_INCIDENT_ID', item.incidentId);
    },


    VerProgreso(item) 
    {
      

      console.log('id de la vacante lokita', item.vacancieId);
      this.$store.commit('SET_VACANCIE_ID', item.vacancieId);

      console.log('id de la empresa lokita', item.idCompany);
      this.$store.commit('SET_COMPANY_ID', item.idCompany);

      this.$router.push({ name: 'detallesProgreso' });


    },









    verDetalles(item)
    {
      this.$router.push({ name: 'ListaMensajes' });
      console.log('Ver mensajes de:', item.id);



    },


    openModal(id) 
    {
      this.selectedId = id;
      this.isModalOpen = true;
    },


    closeModal() 
    {
      this.selectedId = null;
      this.isModalOpen = false;
    },


    AprobarDetalles(item) 
    {
      this.$store.dispatch('revisarStateVacancie', item.id);
      this.$router.push('/estudiante/solicitudes/estado/confirmacion');
      console.log('Ver mensajes de dds:', item.id);

      console.log('id de la company lokita',item.idCompany);
      this.$store.commit('SET_COMPANY_ID', item.idCompany);
    },



    //aqui 
    SolicitudesDetalles(item)
    {
      this.$router.push('/estudiante/solicitudes/detalles-vacante');

      console.log('id de la company lokita',item.idCompany);
      this.$store.commit('SET_COMPANY_ID', item.idCompany);

      console.log('id de la vacante lokitaXX', item.id);
      this.$store.commit('SET_VACANCIE_ID', item.id);
    },


    changeSort(field) 
    {
      if (this.sortField === field) 
      {
        // Si el campo de ordenamiento es el mismo que el anterior, cambia la dirección
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } 
      else 
      {
        // Si el campo de ordenamiento es diferente, establece el nuevo campo y la dirección ascendente
        this.sortField = field;
        this.sortOrder = 'asc';
      }
    },


    sortItems(items) 
    {
      if (!this.sortField) 
      {
        // Si no hay campo de ordenamiento, devuelve los elementos sin ordenar
        return items;
      }


      // Utiliza slice() para evitar mutar el array original
      const sortedItems = items.slice().sort((a, b) => {
        // Comparación basada en el campo de ordenamiento y la dirección
        const fieldA = a[this.sortField];
        const fieldB = b[this.sortField];

        if (fieldA < fieldB) 
        {
          return this.sortOrder === 'asc' ? -1 : 1;
        }
        if (fieldA > fieldB) 
        {
          return this.sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });

      return sortedItems;
    },




  },

  // TO DO: AGREGAR A LOS OTROS DOMINIOS OJO HAY NUEVA COMPROBACION
  computed: {



    filteredItems() {
  // Asegurarse de que 'items' está definido y es un array
  let items = Array.isArray(this.items) ? this.items : [];
  const searchLower = (this.searchQuery || '').toLowerCase();

  // Ordenar los elementos antes de filtrar
  let sortedItems = this.sortItems(items);

  // Filtrar los elementos basados en la consulta de búsqueda
  return sortedItems.filter(item => {
    // Concatenar los valores de varios campos en una sola cadena para la búsqueda
    // Asegurarse de que cada campo está definido antes de llamar a métodos como 'toLowerCase'
    const itemText = [
      item.empresa ?? '',
      item.nombre ?? '',
      item.perfil ?? '',
      item.actividad ?? '',
      item.estado ?? '',
      item.vacantes ? String(item.vacantes) : '', // Convertir números a cadena
      item.disponibles ? String(item.disponibles) : '', // Convertir números a cadena
      item.fecha ?? ''
    ].join(' '); // Unir los valores con un espacio

    // Buscar la consulta en el texto consolidado
    return itemText.toLowerCase().includes(searchLower);
  });
},






},

mounted() 
  {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery);
  },


  beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },


};
</script>
