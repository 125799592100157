<template>
    <div class="material-you light">
        <HeaderComponent />
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>
        <article class="message">
            <MessageComponent title="Detalles" :message="descripcion" />
        </article>
        <div class="card-perfil">
            <CardComponent>
                <!-- Slot para el título -->
                <template v-slot:title>
                </template>
                <template v-slot:content>
                    <div class="media-content-info">
                        <div class="content-postulados">
                            <p style="text-align: left;">Información de la Vacante</p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em;">
                                <span class="tag is-info-custom">Empresa Responsable</span> {{
                                    vacancieDetails.info_vacancie.company_name }}</p>

                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Nombre de la Vacante</span> {{
                                vacancieDetails.info_vacancie.name }}</p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Creada en </span>
                                {{ new Date(vacancieDetails.info_vacancie.creation_log).toLocaleDateString('es-MX', {
                                    weekday: 'long', day: 'numeric', month:'long', year:'numeric' }) }}
                            </p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                <span class="tag is-info-custom">Descripción</span> {{
                                    vacancieDetails.info_vacancie.description }}</p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Dirección</span> {{
                                vacancieDetails.info_vacancie.address }}</p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Salario</span>
                                {{ vacancieDetails.info_vacancie.salary !== null ? '$' +
                                    vacancieDetails.info_vacancie.salary : 'No aplica' }}
                            </p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Notas Adicionales</span> {{
                                vacancieDetails.info_vacancie.notes }}</p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Estado</span> {{
                                    vacancieDetails.info_vacancie.state_name }}</p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Municipio</span> {{
                                vacancieDetails.info_vacancie.muni_name }}</p>
                            <md-divider></md-divider>
                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Sector</span> {{
                                    vacancieDetails.info_vacancie.sector_name }}</p>
                        </div>
                    </div>
                </template>
                <template v-slot:buttons>
                    <!-- Botón Aceptar -->
                    <md-elevated-button @click="handleClick">
                        {{ buttonText }}
                    </md-elevated-button>
                    <!-- Botón Rechazar -->
                    <md-elevated-button @click="handleClickReject">
                        {{ buttonReject }}
                    </md-elevated-button>
                </template>
            </CardComponent>
        </div>
        <FooterComponent />
        <Transition name="bounce">
            <ModalComponent v-if="showModal" :title="modalTitle">
                <template v-slot:content>
                    <!-- Utiliza v-html para interpretar el contenido como HTML -->
                    <div v-html="modalContent"></div>
                </template>
                <template v-slot:buttons>
                    <md-elevated-button @click="returnHome">
                        Aceptar
                    </md-elevated-button>
                    <!-- <md-elevated-button  @click="closeModal">
          Volver
        </md-elevated-button> -->
                </template>
            </ModalComponent>
        </Transition>
        <Transition name="bounce">
            <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
                <!-- <div class="modal is-active" style="z-index: 1001;"> -->
                <div class="modal-background"></div>
                <div class="modal-card">
                    <!-- Contenido del modal de carga (puede ser un spinner, mensaje, etc.) -->
                    <section class="modal-card-body">

                        <div> Validando vacante.. </div>

                        <md-circular-progress four-color indeterminate></md-circular-progress>
                    </section>
                </div>
                <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
            </div>
        </Transition>
    </div>
</template>



<style scoped src   =   "../styles/material/theme.light.css">                   </style> 
<style scoped src   =   "../styles/material/theme.dark.css">                    </style> 
<style scoped src   =   "../styles/views/MaterialTheming.css">                  </style>
<style scoped src   =   "../styles/views/SolicitudesEstadoConfirmacion.css">    </style>

<script>
import CardComponent        from    '../components/CardComponent.vue';
import FooterComponent      from    '../components/FooterComponent.vue';
import HeaderComponent      from    '../components/HeaderComponent.vue';
import jwt_decode           from    'jsonwebtoken/decode';
import MenuComponent        from    '../components/MenuComponent.vue';
import MessageComponent     from    '../components/MessageComponent.vue';
import ModalComponent       from    '../components/ModalComponent.vue';

export default
{

    name: 'EmpresasMenuValidacionesSolicitud',
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        ModalComponent,
    },



    async created() 
    {
        console.log('mounted se ha llamado');
        await this.loadvacancie();
        console.log('hi lokita',this.$store.getters.obtenerStateVacancie);
    },



    computed: 
    {
        detalles() 
        {
            return this.$store.getters.obtenerStateVacancie;
        },
    },



    data() 
    {
        return  {
                    vacancieDetails     :   null,
                    isClicked           :   false,
                    isClickedReject     :   false,
                    isFetching          :   false,
                    respuestaExitosa    :   false,
                    showModal           :   false,

                    actionOnSuccess     :   '',
                    modalContent        :   '',
                    modalTitle          :   '',
                    motivoRechazo       :   '',

                    errorServer         :   `No fue posible enviar los datos al servidor, compruebe su conexión a Internet e inténtelo de nuevo
                                            , si el problema persiste contacte con su administrador.`,

                    buttonText          :   'Aceptar',

                    buttonReject        :   'Rechazar',

                    descripcion         :   `Nos complace informarte que has sido aceptado/a para la vacante solicitada.
                                            Te solicitamos revisar detenidamente los detalles proporcionados. Por favor,
                                            ten en cuenta que al confirmar tu participación,se procederá a la cancelación
                                            de otras postulaciones realizadas, conforme a su clasificación (servicio, social o prácticas profesionales).`,

                    errorApiConfirm     :   `Lamentablemente, se ha producido un error. Por favor,
                                            asegúrate de que la vacante a la que intentas unirte no haya alcanzado su cupo máximo o aún esté disponible.
                                            Te recomendamos intentarlo más tarde. Si el problema persiste,te sugerimos ponerte en contacto con tu administrador.`,

                    errorApiReject      :   `Lamentablemente, no se ha podido rechazar la vacante en este momento.
                                            Te sugerimos intentarlo nuevamente más tarde. Si el problema persiste, por favor consulta con tu administrador.`,

                    messageFine         :   `Se te informa que has confirmado tu participación en la vacante solicitada. 
                                            Tienes un plazo de 7 días naturales a partir de hoy para presentarte a trabajar. En caso de no hacerlo, 
                                            serás dado de baja automáticamente y no podrás volver a solicitar esta misma vacante. Es importante destacar 
                                            que la empresa se reserva el derecho de cancelar tu participación antes de tu primera asistencia.
                                            Asegúrate de que tu asesor registre tu primera asistencia, ya que a partir de ese momento comenzará 
                                            a contar el tiempo de tu participación. `,

                    messageFineReject   :   `Has rechazado tu participación en la vacante seleccionada. Te recordamos que ya no será posible
                                            volver a postularte para la misma en el futuro`,
                };
    },



    methods:
    {
        returnHome()
        {
            this.$router.push({ name: 'estudiante-home' });

        },


        async loadvacancie()
        {
            try
            {

                const vacancie = this.$store.getters.obtenerStateVacancie; //mover a created

                console.log('este es el id de la vcanate',vacancie);
                const token = localStorage.getItem('token');
                const decodedToken = jwt_decode(token);
                const id_staff = decodedToken.userId;
                const id_vacancie = vacancie;


                const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_INFOVACANCIE,{
                    method : 'POST',
                    headers: 
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ id_vacancie: id_vacancie }),
                });
                console.log(response);

                if(!response.ok)
                {
                    throw new Error('Error loading vacancie');
                }
                 const data = await response.json();
                this.vacancieDetails = data;
                console.log("hola", data);
            }
            catch(error)
            {
                console.log(error);

            }

        },


        handleClick() 
        {
            if (!this.isClicked) 
            {
                this.buttonText = 'Haz clic de nuevo para confirmar';
                this.isClicked = true;
            } 
            else 
            {
                this.handleAccept();
                this.isFetching = true;
                this.confirm_request();
                //aqui se envian los datos a la api para aceptar la vacante
            }
        },


        handleClickReject() 
        {
            if (!this.isClickedReject) 
            {
                this.buttonReject = 'Haz clic de nuevo para confirmar';
                this.isClickedReject = true;
            } 
            else 
            {
                this.handleReject();
                this.isFetching = true;
                this.rejectVacancieApi();
                //aqui se envian los datos a la api para aceptar la vacante
            }
        },


        handleAccept() 
        {
            console.log('Botón aceptado');
            this.buttonText = 'Aceptar';
            this.isClicked = false;
        },


        handleReject() 
        {
            console.log('Botón aceptado');
            this.buttonText = 'Aceptar';
            this.isClickedReject = false;
        },


        closeModal()
        {
            this.showModal          =   false;
        },


        async rejectVacancieApi()
        {
            try
            {
                const idVacancie = this.$store.getters.obtenerStateVacancie;
                const token = localStorage.getItem('token');
                const decodedToken = jwt_decode(token);
                const idStudent = decodedToken.id_student;

                await new Promise(resolve => setTimeout(resolve, 3000));

                const apiRejectRequest = process.env.VUE_APP_API_STUDENT_REJECT_REQUEST;

                const response = await fetch(apiRejectRequest, 
                {
                    method  : 'POST',
                    headers: 
                            {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            },
                            body: JSON.stringify({ idStudent:idStudent, idVacancie: idVacancie }),
                });

                console.log('Código de Estado:', response.status);
                this.isFetching = false;

                if (!response.ok) 
                {
                    this.showModal          =   true;
                    this.modalTitle         =   'Ha Ocurrido un Error';
                    this.modalContent       =    `${this.errorApiReject} \n \n MENSAJE DE ERROR: ${errorMessage}. `;
                    this.respuestaExitosa   =   true;
                }

                if (response.ok) 
                {
                    this.showModal          =   true;
                    this.modalTitle         =   'Proceso Exitoso';
                    this.modalContent       =   this.messageFineReject;
                    this.respuestaExitosa   =   true;
                } 

            }
            catch (error) 
            {
                this.isFetching = false;
                console.error('Error al enviar datos a la API:', error);
                console.error('Error en el catch general:', error);
                this.showModal = true;
                this.modalTitle = 'Ha ocurrido un error!!! ';
                this.modalContent = this.errorServer;
            }
        },


        async confirm_request()
        {
            try
            {
                const idVacancie = this.$store.getters.obtenerStateVacancie; //mover a created
                const token = localStorage.getItem('token');

                const idCompany     =   this.$store.getters.getterCompanyId;
                const decodedToken = jwt_decode(token);
                const idStudent = decodedToken.id_student;

                await new Promise(resolve => setTimeout(resolve, 3000));
                
                const apiConfirmRequest = process.env.VUE_APP_API_STUDENT_CONFIRM_REQUEST;

                const response = await fetch(apiConfirmRequest, 
                {
                    method  : 'POST',
                    headers: 
                            {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            },
                            body: JSON.stringify({ idStudent:idStudent, idVacancie: idVacancie, idCompany:idCompany }),
                });
                console.log('Código de Estado:', response.status);
                this.isFetching = false;

                if (!response.ok) 
                {
                    this.showModal          =   true;
                    this.modalTitle         =   'Ha Ocurrido un Error';
                    this.modalContent       =    `${this.errorApiConfirm} \n \n MENSAJE DE ERROR: ${errorMessage}. `;
                    this.respuestaExitosa   =   true;
                }

                if (response.ok) 
                {
                    this.showModal          =   true;
                    this.modalTitle         =   'Proceso Exitoso';
                    this.modalContent       =   this.messageFine;
                    this.respuestaExitosa   =   true;
                } 

            }

            catch (error) 
            {
                this.isFetching = false;
                console.error('Error al enviar datos a la API:', error);
                console.error('Error en el catch general:', error);
                this.showModal = true;
                this.modalTitle = 'Ha ocurrido un error!!! ';
                this.modalContent = this.errorServer;
            }

        },

    },
}
</script>