<template>
   <div :class="['material-you', mainTheme]">
        <HeaderComponent />
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>
        <article class="message">
            <MessageComponent title="Editar la Vacante" :message="descripcion" class="tooltip"/>
            <MessageComponent title="Motivo del Rechazo" :message="store.getters.getterVacancieReason" />
        </article>
        <div class="container">
            <div v-if="isDataLoaded">
                <form @submit.prevent="submitForm">
                    <FormComponent :formFields="formFields" :apiUrl="apiUrl" />
                </form>
            </div>
            <div v-else>
                Cargando...
            </div>
        </div>
        <FooterComponent />
    </div>
</template>


<style scoped src="../styles/material/theme.light.css"></style> 
<style scoped src="../styles/material/theme.dark.css"></style> 

<style scoped src="../styles/views/MaterialTheming.css"></style>
<style scoped src="../styles/views/VacantesListadoRechazada.css"></style>


<script>
import { ref, computed, watch, onMounted, onBeforeUnmount}      from    'vue';
import { useStore }                             from    'vuex';  
import CardComponent                            from    '../components/CardComponent.vue';
import FooterComponent                          from    '../components/FooterComponent.vue';
import FormComponent                            from    '../components/FormComponent.vue';
import HeaderComponent                          from    '../components/HeaderComponent.vue';
import jwt_decode                               from    'jsonwebtoken/decode';
import MenuComponent                            from    '../components/MenuComponent.vue';
import MessageComponent                         from    '../components/MessageComponent.vue';


export default 
{
    name: 'PerfilEditarperfil',


    components: 
    {
        CardComponent,
        FooterComponent,
        FormComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
    },


    setup() 
    {
        const handleThemeChange = (e) => {
        mainTheme.value = e.matches ? 'dark' : 'light';
        console.log(`El sistema está configurado para tema ${mainTheme.value}.`);
    };
        let mediaQuery;
        const mainTheme = ref('light');
        const apiUrl        =   process.env.VUE_APP_API_STUDENT_POST_EDITPROFILE;
        const descripcion   =   ref(`En este espacio, tienes la posibilidad de realizar ajustes en la vacante
                                    que fue rechazada, incluyendo la opción de agregar la razón específica del rechazo.
                                    Asegúrate de atender los detalles señalados en la vacante.
                                    Además, te brindamos la flexibilidad de eliminar la vacante, o simplemente dejarla sin enviar, según tus preferencias y necesidades.
                                    `);
                            
        const exp           =   ref(null);
        const formData      =   ref({});
        const idVacanieCC   =   ref(null);
        const isDataLoaded  =   ref(false);
        const loading       =   ref(true);
        const nombre        =   ref('hola');
        const store         =   useStore();


        onMounted(async () => 
        {
            try 
            {
                idVacanieCC.value = store.getters.getterVacancieId;
                if (idVacanieCC.value !== null) 
                {
                    localStorage.setItem('EDITVACANCIE', idVacanieCC.value);
                }

                await loadVacancie();
                isDataLoaded.value = true;
            } 
            catch (error) 
            {
                console.error('Error durante la carga de datos:', error.message);
            } 
            finally 
            {
                loading.value = false;
            }

            mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            mediaQuery.addEventListener('change', handleThemeChange);
            handleThemeChange(mediaQuery);
        });

        onBeforeUnmount(() => {
    if (mediaQuery) {
      mediaQuery.removeEventListener('change', handleThemeChange);
    }
  });


        const idVacancieCompany         = computed( ()  => store.getters.getterIdVacancie           );
        const vacancieName              = computed( ()  => store.getters.getterVacancieName         );
        const vacancieHiring            = computed( ()  => store.getters.getterVacancieHiring       );
        const vacancieAddress           = computed( ()  => store.getters.getterVacancieAddress      );
        const vacancieDescription       = computed( ()  => store.getters.getterVacancieDescription  );
        const vacancieNotes             = computed( ()  => store.getters.getterVacancieNotes        );
        const vacancieStateMex          = computed( ()  => store.getters.getterVacancieStateMex     );
        const vacancieStateMuni         = computed( ()  => store.getters.getterVacancieMuniMex      );
        const vacancieBSector           = computed( ()  => store.getters.getterVacancieBsector      );
        const vacancieBoard             = computed( ()  => store.getters.getterVacancieBoard        );
        const vacancieType             = computed( ()  => store.getters.getterVacancieType          );
        const vacanciePaid             = computed( ()  => store.getters.getterVacanciePaid          );
        const vacancieReason             = computed( ()  => store.getters.getterVacancieReason          );



        const formFields = computed(() => 
        [
            {
                label: "Tipo",
                type: "select",
                name: "type",
                options: 
                [
                    { value: store.getters.getterVacancieType, label: store.getters.getterVacancieType, selected: true }
                ]
            },




            { 
                label: "Nombre",
                type: "text",
                name: "name",
                maxlength: 100,
                value: store.getters.getterVacancieName
            },


            {
                label: 'Giro de la Empresa', type: 'select', name: 'business_sectors_id',
                options:
                    [
                        { value: store.getters.getterVacancieBsector, label: store.getters.getterVacancieBsector, selected: true }
                    ]
            },


            {
                label: "Seleccione el Estado",
                type: "select",
                name: "state_id",
                options:
                    [
                        { value: store.getters.getterVacancieStateMex, label: store.getters.getterVacancieStateMex , selected: true }
                        
                    ],
            },


            {
                label: "Seleccione el Municipio",
                type: "select",
                name: "municipality_id",
                options:
                    [
                        { value: store.getters.getterVacancieMuniMex, label: store.getters.getterVacancieMuniMex , selected: true }
                    ]
            },


            {
                label: "Número de vacantes",
                type: "number",
                name: "w_hiring_capacity",
                value: store.getters.getterVacancieHiring
            },


            {
  label: "¿Apoyo Económico?",
  type: "select",
  name: "ofrecerSueldo",
  options: [
    { value: "si", label: "Si", selected: store.getters.getterVacanciePaid > 0 },
    { value: "no", label: "No", selected: store.getters.getterVacanciePaid === null || store.getters.getterVacanciePaid <= 0}
  ]
},


            { label: "", type: "number", name: "salary",  supportingText: "*Monto Mensual", value:store.getters.getterVacanciePaid, readonly:false},


            {
                label: "Domicilio",
                type: "textarea",
                name: "address",
                maxlength: 250,
                value: store.getters.getterVacancieAddress
            },


            {
                label: "Descripcion",
                type: "textarea",
                name: "description",
                maxlength: 500,
                supportingText: "Incluye tus requisitos, como horario, experiencia, aptitudes etc",
                value: store.getters.getterVacancieDescription
            },


            {
                label: "Notas Adicionales", type: "textarea", name: "notes", maxlength: 500,
                supportingText: "Incluye cualquier información pertinente que no haya sido mencionada en los puntos anteriores.",
                value: store.getters.getterVacancieNotes
            },
            // Agregar otros campos del formulario...
            {
        
        type: "files",
        name: "type",

    },

        ]);

        watch(
            [
                vacancieName,
                vacancieHiring,
                vacancieAddress,
                vacancieDescription,
                vacancieNotes,
                vacancieStateMex,
                vacancieStateMuni,
                vacancieBSector,
                vacancieBoard,
                vacancieType,
            ],
            () => {
                // Actualizar el store cuando cambian las propiedades computadas
                store.commit('SET_VACANCIE_TYPE', vacancieType.value)
                store.commit('SET_VACANCIE_NAME', vacancieName.value);
                store.commit('SET_VACANCIE_HIRING', vacancieHiring.value);
                store.commit('SET_VACANCIE_ADDRESS', vacancieAddress.value);
                store.commit('SET_VACANCIE_DESCRIPTION', vacancieDescription.value);
                store.commit('SET_VACANCIE_NOTES', vacancieNotes.value);
                store.commit('SET_VACANCIE_STATE_MEX', vacancieStateMex.value);
                store.commit('SET_VACANCIE_MUNI_MEX', vacancieStateMuni.value);
                store.commit('SET_VACANCIE_B_SECTOR', vacancieBSector.value);
                store.commit('SET_VACANCIE_BOARD', vacancieBoard.value);
                store.commit('SET_VACANCIE_PAID', vacanciePaid.value);
                store.commit('SET_VACANCIE_REASON', vacancieReason.value);

            }
        );


        const loadVacancie = async () => {
            try {
                const idVacancie = localStorage.getItem('EDITVACANCIE');
                const token = localStorage.getItem('token');
                const decodedToken = jwt_decode(token);
                const id_company = decodedToken.userCompanyId;
                console.log('ID DE LA VACANTE', idVacancie);

                const response = await fetch(process.env.VUE_APP_COMPANY_POST_VACANCIE_INFO, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        id_vacancie: idVacancie,
                        id_company: id_company,
                    }),
                });



                if (!response.ok) {
                    throw new Error('Error loading profile');
                }

                if (response.ok) {
                    const data = await response.json();
                    console.log('estos son los datos de la api: ', data.vacancieInfo);

                    store.commit('SET_VACANCIE_TYPE', data.vacancieInfo.type);
                    store.commit('SET_VACANCIE_NAME', data.vacancieInfo.name);
                    store.commit('SET_VACANCIE_HIRING', data.vacancieInfo.w_hiring_capacity);
                    store.commit('SET_VACANCIE_ADDRESS', data.vacancieInfo.address);
                    store.commit('SET_VACANCIE_DESCRIPTION', data.vacancieInfo.description);
                    store.commit('SET_VACANCIE_NOTES', data.vacancieInfo.notes);
                    store.commit('SET_VACANCIE_STATE_MEX', data.vacancieInfo.state_name);
                    store.commit('SET_VACANCIE_MUNI_MEX', data.vacancieInfo.muni_name);
                    store.commit('SET_VACANCIE_BOARD', data.vacancieInfo.sector_name);
                    store.commit('SET_VACANCIE_B_SECTOR', data.vacancieInfo.sector_name);
                    store.commit('SET_VACANCIE_PAID', data.vacancieInfo.salary);
                    store.commit('SET_VACANCIE_REASON', data.vacancieInfo.rejected_reason);




                    
                }
            }
            catch (error) {
                console.error('Error during data loading:', error.message);
            }
            finally {
                isDataLoaded.value = true; // Actualiza la carga al finalizar, ya sea con éxito o con error
            }
        };

        return {
            store,
            loading,
            descripcion,
            formData,
            apiUrl,
            nombre,
            exp,
            idVacanieCC,
            idVacancieCompany,
            vacancieName,
            vacancieHiring,
            vacancieAddress,
            vacancieDescription,
            vacancieNotes,
            vacancieStateMex,
            vacancieStateMuni,
            vacancieBSector,
            vacancieBoard,
            vacancieType,
            formFields,
            loadVacancie,
            isDataLoaded,
            vacanciePaid,
            vacancieReason,
            mainTheme,
        };
    },
};
</script>
