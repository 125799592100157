<template>

    <!-- material theming -->
    <div :class="['material-you', mainTheme]">


        <!-- header -->
        <HeaderComponent />


        <!-- main menu -->
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>


        <!-- tooltips -->
        <article class="message">
            <MessageComponent :title="'Alta de Empresas en el Sistema'" :message="descripcion" />
        </article>



        <!-- new company form -->
        <div class="container">
            <form @submit.prevent="submitForm">
                <FormComponent :formFields="formFields" formId="newComp" :apiUrl="apiUrl" />
            </form>
        </div>


        <!-- footer -->
        <FooterComponent />
    </div>
</template>





<style scoped src="../styles/material/theme.light.css"></style>
<style scoped src="../styles/material/theme.dark.css"></style>

<style scoped src="../styles/views/EmpresasMenuAltas.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style>

<script>
import { ref, defineComponent, onMounted, onBeforeUnmount }   from   'vue';

import FooterComponent            from   '../components/FooterComponent.vue';
import FormComponent              from   '../components/FormComponent.vue';
import HeaderComponent            from   '../components/HeaderComponent.vue';
import MenuComponent              from   '../components/MenuComponent.vue';
import MessageComponent           from   '../components/MessageComponent.vue';



export default defineComponent
({
    components: 
    {
        FooterComponent,
        FormComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent
    },



    setup() 
    {
        const mainTheme = ref('light');
        const handleThemeChange = (e) => {
        mainTheme.value = e.matches ? 'dark' : 'light';
        console.log(`El sistema está configurado para tema ${mainTheme.value}.`);
    };
        const descripcion = ref (    `En este espacio, se encuentra la opción de registrar en el sistema a aquellas empresas
                                    que hayan cumplido con la presentación adecuada de su documentación. Para llevar a cabo este proceso,
                                    te solicitamos que ingreses el nombre y la dirección de correo electrónico de la empresa exactamente como 
                                    aparecen en los documentos recibidos. Agradecemos tu colaboración.`
                                );


        const formFields = ref([
            { label : "Nombre de la Empresa",   type    : "text",   name    : "name",   maxlength: 50   },
            { label : "Correo Electronico",     type    : "email",  name    : "email",  maxlength: 50   },
        ]);


        const formData = ref({
            name: '',
            email: '',
        });


        const apiUrl = ref(process.env.VUE_APP_API_STAFF_POST_REGISTERCOMPANY);


        const submitForm = () => 
        {
            console.log("Formulario enviado", formData.value);
        };

        let mediaQuery;
  
  onMounted(async () => {


    mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', handleThemeChange);
    handleThemeChange(mediaQuery);
  });

  onBeforeUnmount(() => {
    if (mediaQuery) {
      mediaQuery.removeEventListener('change', handleThemeChange);
    }
  });


        return  {
                    descripcion,
                    formFields,
                    formData,
                    apiUrl,
                    submitForm,
                    mainTheme
                };
    }
});
</script>