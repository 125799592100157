<template>
  <div class="notifications">

    <div v-if="showNotificationPrompt" class="notiButton">
      <p>Por favor, activa las notificaciones para una mejor experiencia.</p>
      <md-elevated-button @click="requestNotificationPermission">Activar notificaciones</md-elevated-button>
    </div>

    <router-view />
  </div>
</template>





<script>
import { getMessaging, getToken } from 'firebase/messaging';

export default 
{
  data() 
  {
    return  {
              isDarkMode: false,
              showNotificationPrompt: false,
            };
  },



  async mounted() 
  {
    if ('Notification' in window && Notification.permission !== 'granted') 
    {
      this.showNotificationPrompt = true;
    }
  },



  methods: 
  {
    async requestNotificationPermission() 
    {
      const messaging = getMessaging();
      try 
      {
        await getToken(messaging);
        this.showNotificationPrompt = false;
      } 
      catch (error) 
      {
        console.error('Error al obtener el token de notificación:', error);
      }
    },


    toggleTheme() 
    {
      this.isDarkMode = !this.isDarkMode;
    },
  },
};
</script>





<style>
:root
{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;

  .notifications
  {
    padding-top: 3em;
  }

  .notiButton
  {
    padding-top: 1em;
    background-color: #f4fbf8;
  }

}
</style>
