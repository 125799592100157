<template>
  <div class="material-you light ">
    <HeaderComponent />
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>
    <article class="message">
      <MessageComponent title="Mi Progreso" :message="descripcion" />
    </article>
    <div class="tabs is-boxed" style="width: 100%;">
      <md-tabs v-model="activeTab" :style="{ borderRadius: '15em' }">
        <md-primary-tab v-for="(tab, index) in tabs" :key="index" :name="tab.name" @click="changeTab(tab.name)">
          {{ tab.label }}
        </md-primary-tab>
      </md-tabs>
    </div>
    <div class="job-table ">
      <TableComponent :columns="tableColumns"
        :items="activeTab === 'tab1' ? tableItemsTab1 : (activeTab === 'tab2' ? tableItemsTab2 : tableItemsTab3)">
      </TableComponent>
    </div>
    <FooterComponent />
  </div>
</template>



<style scoped src = "../styles/material/theme.light.css">   </style>
<style scoped src = "../styles/material/theme.dark.css">    </style>
<style scoped src = "../styles/views/MaterialTheming.css">  </style>
<style scoped src = "../styles/views/MiProgreso.css">       </style>

<script>
import { loadEmployees }  from    '../api/ApiService';

import FooterComponent    from    '../components/FooterComponent.vue';
import HeaderComponent    from    '../components/HeaderComponent.vue';
import MenuComponent      from    '../components/MenuComponent.vue';
import MessageComponent   from    '../components/MessageComponent.vue';
import TableComponent     from    '../components/TableComponent.vue';

export default 
{
  name: 'MiProgreso',

  components: 
  {
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    MessageComponent,
    TableComponent,
  },



  data() 
  {
    return  {
              activeTab   :   'tab1',
              descripcion :   `Aquí puedes revisar todo tu historial de prácticas profesionales y servicio social,
                              además de acceder a los detalles y gestionar las vacantes activas. Sube tus informes, revisa registros
                              y, en caso de algún problema, inicia una incidencia.`,

              tabs: 
              [
                  { name: 'tab1', label: 'Servicio Social'            },
                  { name: 'tab2', label: 'Prácticas Profesionales'    },
              ],

              tableColumns: 
              [
                { label   :  'Empresa',                   field   :   'company_name'    },
                { label   :  'Actividad',                 field   :   'vacancie_name'   },
                { label   :  'Fecha de Ingreso',          field   :   'start_date'      },
                { label   :  'Fecha de Finalización ',    field   :   'finish_date'     },
                { label   :  'VerProgreso',               field   :   'VerProgreso'     },
              ],

              tableItemsTab1: [],
              tableItemsTab2: [],
            };
  },



  async created() 
  {
    try 
    {
      const data = await loadEmployees();
      this.tableItemsTab1 = data.tableItemsTab1;
      this.tableItemsTab2 = data.tableItemsTab2;
      console.log('datos', data);
    } 
    catch (error) 
    {
      console.error('Error loading employees:', error);
    }
  },



  methods: 
  {
    changeTab(tab) 
    {
      this.activeTab = tab;
    },

  },

};
</script>
