<template>
  <div :class="[mainTheme]">

    <!-- universal forms -->
    <form @submit.prevent="submitForm">
      <div class="inputs" v-for="(field, index) in formFields" :key="index">

        <div class="select" v-if="field.type === 'select'">
          <select :name="field.name" v-model="formData[field.name]" @change="handleSelectChange">
            <option v-for="option in field.options" :value="option.value" :key="option.value">{{ option.label }}
            </option>
          </select>
        </div>

        <div v-else>
          <Transition name="bounce">
            <md-filled-text-field
              v-if="field.name !== 'salary' || (field.name === 'salary' && formData.ofrecerSueldo === 'si')"
              :label="field.label" :type="field.type" :maxlength="field.maxlength"
              :supporting-text="field.supportingText" v-model="formData[field.name]" required>
            </md-filled-text-field>
          </Transition>
        </div>

      </div>

      <md-elevated-button type="submit">Enviar</md-elevated-button>
    </form>


<!-- modal options menu -->
<div class="afterApi">
  <Transition name="bounce">
    <ModalComponent v-if="showModal" :title="modalTitle" :modalContent="modalContent" class="move-right-modal">
      <template v-slot:buttons>
        <md-elevated-button v-if="respuestaExitosa" @click="handleAccept">
          Aceptar
        </md-elevated-button>
        <md-elevated-button v-if="!respuestaExitosa" @click="errorButton">
          Aceptar
        </md-elevated-button>
      </template>
    </ModalComponent>
  </Transition>
</div>


    <!-- modal loading -->
    <div class="loading">
      <Transition name="bounce">
        <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
          <div class="modal-background"></div>
          <div class="modal-card">
            <section class="modal-card-body">
              <div> Enviando los datos... </div>
              <md-circular-progress four-color indeterminate></md-circular-progress>
            </section>
          </div>
          <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
        </div>
      </Transition>
    </div>

  </div>
</template>



<style scoped src   =   "../styles/components/FormComponent.css">   </style>
<style scoped src   =   "../styles/material/theme.light.css"    >   </style> 
<style scoped src   =   "../styles/material/theme.dark.css"     >   </style> 

<script>
import ModalComponent       from        '../components/ModalComponent.vue';
import jwt_decode           from        'jsonwebtoken/decode';

import { sendDataToApi }    from        '../api/ApiService';

export default
{
  inheritAttrs: false,
  emits: ['estadoSelected'],



  components: 
  {
    ModalComponent,
  },



  props:
  {
    show        :   Boolean,
    formFields  :
    {
      type      :   Array,
      required  :   true,
      formId    :   String,
    },

    apiUrl      : 
    {
      type      :   String,
      required  :   true,
    },
  },



  data() 
  {
    const token         =   localStorage.getItem('token');
    const decodedToken  =   jwt_decode(token);
    const id_staff      =   decodedToken.userStaffId;
    
    return  {
              formData          :   {id_staff : id_staff,},
              isFetching        :   false,
              respuestaExitosa  :   false,
              showModal         :   false,

              actionOnSuccess   :   '',
              mainTheme         :   'light',
              modalContent      :   '',
              modalTitle        :   '',

              //messages Section
              errorServer               : `No fue posible enviar los datos al servidor, compruebe su conexión a Internet e inténtelo de nuevo
                                          , si el problema persiste contacte con su administrador.`,

              newCompanyAPI_success     : `El registro del usuario se ha completado exitosamente. Se ha enviado un correo electrónico que contiene
                                          la contraseña necesaria para acceder al portal correspondiente. No es necesario realizar ninguna otra acción en este momento.`,

              newcompanyAPI_error       : `No se pudo completar el registro de la compañía. Por favor, asegúrese de que el correo electrónico
                                          sea válido y no esté asociado con otra compañía registrada. Para más asistencia, póngase en contacto
                                          con nuestro equipo de soporte. Lamentamos cualquier inconveniente causado.`,
            };
  },



  mounted() 
  {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery);
  },



  beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },



  methods:
  {
    handleThemeChange(e) 
    {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },


    handleAccept() 
    {
      if (this.actionOnSuccess === 'action1') 
      {
        this.$router.push({ name: 'personal-inicio' });
      }
      
      else if (this.actionOnSuccess === 'action2') 
      {
        console.log('Realizando acciones para action2');
      }

      this.showModal = false;
    },


    errorButton() 
    {

      this.showModal = false;
    },


    handleSelectChange() 
    {
      this.$emit('estado-selected', this.formData.state_id);
      console.log('Evento handleSelectChange ejecutado');
    },


    submitForm() 
    {
      console.log('Datos del formulario:', this.formData);
      console.log(this.apiUrl	)
      this.sendDataToApi(); 
    },


    async sendDataToApi() 
    {
      if (this.apiUrl === process.env.VUE_APP_API_STAFF_POST_REGISTERCOMPANY) 
      {
        try 
        {
          const token = localStorage.getItem('token');
          this.isFetching = true;
          const result = await sendDataToApi(this.apiUrl, this.formData, token);
          this.isFetching = false;

          if (!result.success) 
          {
            this.showModal = true;
            this.modalTitle = 'Ha Ocurrido un Error';
            this.modalContent = `${this.newcompanyAPI_error} \n \n MENSAJE DE ERROR: ${result.errorMessage}. `;
            this.respuestaExitosa = true;
          } 
          else 
          {
            this.showModal = true;
            this.modalTitle = 'Proceso Exitoso';
            this.modalContent = this.newCompanyAPI_success;
            this.respuestaExitosa = true;
            this.actionOnSuccess = 'action1';
          }
        } 
        catch (error) 
        {
          this.isFetching = false;
          console.error('Error al enviar datos a la API:', error);
          this.showModal = true;
          this.modalTitle = 'ERROR';
          this.modalContent = this.errorServer;
        }
      }
    }
  },

};
</script>