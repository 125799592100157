<template>
    <div :class="['material-you', mainTheme]">
    <HeaderComponent />

    <div class="side-menu">
        <MenuComponent class="side-menu" />
    </div>

    <div class="card-perfil">
    <CardComponent>

      <!-- Slot para el título (dejado vacío) -->
      <template v-slot:title>
        
      </template>

      <!-- Slot para el contenido -->
      <template v-slot:content>
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <div class="media-content-image has-text-centered">
                <figure class="image custom-image">
                  <img src="https://xsgames.co/randomusers/avatar.php?g=female" alt="User Avatar" class="rounded-image">
                </figure>
              </div>
            </div>
            <div class="content-perfil">
              <p class="perfil">{{ studentName }}</p>
              <p class="perfil">{{ email }}</p>
              <!-- Puedes mostrar otros detalles del profesor aquí -->
            </div>
          </div>
        </div>
      </template>

      <!-- Slot para los botones (dejado vacío) -->
      <template v-slot:buttons>
        <!-- Deja este slot vacío para no mostrar botones en esta parte -->
      </template>

    </CardComponent>
  </div>


  <div class="card-perfil">
    <CardComponent>

      <!-- Slot para el título -->
      <template v-slot:title>
        <div class="media-content-info">
        <span class="texto tag is-info is-light">Tiempo Restante: {{ remainingHours }}</span>
        <br>
        <span class="texto tag is-info is-light">Reportes Por Entregar: {{ remainingReports }}</span>
        <br>
        <span class="texto tag is-info is-light">Departamento: {{ nameDepartment }}</span>
        <br>
        <span class="texto tag is-info is-light">Tipo de Pasantía: {{ intershipType }}</span>
        <br>
        <span class="tag is-info is-light">Tiempo Total: {{ totalHours }}</span>
        </div>
        <span class="tag is-warning">Progreso: {{ completedHoursPercentage }}%</span>
        <div class="progress-container">
          <progress class="progress is-material" :value="completedHoursPercentage" max="100">{{ completedHoursPercentage }}%</progress>
        </div>
      </template>

      <!-- Slot para el contenido (dejado vacío) -->
      <template v-slot:content>
        <!-- Deja este slot vacío para no mostrar contenido en esta parte -->
      </template>

      <!-- Slot para los botones -->
      <template v-slot:buttons>
        <div class="buttons is-centered is-grouped">
              
                  <!-- <button class="button is-link is-rounded">Enviar Mensaje</button> -->
                  <md-elevated-button>Enviar Mensaje</md-elevated-button>

                  <md-elevated-button >Revisar Reportes</md-elevated-button>
                  <!-- <button class="button is-warning is-rounded">Revisar Reportes</button> -->
              </div>
      </template>

    </CardComponent>
  </div>
    <FooterComponent />
  </div>
</template>




<style scoped src =  "../styles/material/theme.light.css"            ></style> 
<style scoped src =  "../styles/material/theme.dark.css"             ></style> 
<style scoped src = "../styles/views/MaterialTheming.css"   ></style>
<style scoped src="../styles/views/AlumnosMisalumnosDetalles.css"></style>

<script>
import CardComponent      from        '../components/CardComponent.vue';
import FooterComponent    from        '../components/FooterComponent.vue';
import HeaderComponent    from        '../components/HeaderComponent.vue';
import MenuComponent      from        '../components/MenuComponent.vue';
import { loadGlobalInfo } from '../api/ApiService';



export default
{
  name: 'InfoContacto',
  components:
  {
      CardComponent,
      FooterComponent,
      HeaderComponent,
      MenuComponent,
  },

  data()
  {
    return  {
              mainTheme: 'light',
              studentName : '',
              nameDepartment : '',
              intershipType : '',
              totalHours : null,
              email : '',
              remainingHours : null,
              remainingReports : null,
              completedHoursPercentage : null,
            }

  },


  async created() 
    {
      try {
      const studentInfo = await loadGlobalInfo(this.$store);
      this.studentName = studentInfo.studentFullName;
      this.nameDepartment = studentInfo.name_department;
      this.intershipType = studentInfo.type;
      this.totalHours = studentInfo.totalHours;
      this.email = studentInfo.email;
      this.remainingReports = studentInfo.remainingReports;
      this.remainingHours = studentInfo.remainingHours;
      this.completedHoursPercentage = studentInfo.completedHoursPercentage;
    } catch (error) {
      console.error('Failed to load student global info:', error);
    }
    },

  methods: 
    {
      handleThemeChange(e) {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },

    },

    mounted() {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },


  beforeUnmount() {
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },


}

</script>