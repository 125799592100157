<template>
    <div class="material-you light">
        <HeaderComponent />
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>
        <article class="message">
            <MessageComponent title="Mis Incidencias" :message="descripcion" />
        </article>
        <div class="soySeparador">
            <md-elevated-button @click="handleReject">
                Nueva Incidencia
            </md-elevated-button>
        </div>
        <div class="tabla">
            <TableComponent :columns="tableColumns"
                :items="activeTab === 'tab1' ? tableItemsTab1 : (activeTab === 'tab2' ? tableItemsTab2 : tableItemsTab3)">
            </TableComponent>
        </div>
        <FooterComponent />
        <!-- modal para inciiar nueva incidenmcia -->
        <Transition name="bounce">
            <ModalComponent v-if="showModalIncidence" :title="modalTitle" @close="closeModal">
                <template v-slot:content>
                    <md-filled-select label="Motivo" v-model="selectedReasonId">
                        <md-select-option v-for="reason in incidentReasons" :key="reason.id" :value="reason.id">
                            <div slot="headline">{{ reason.reason }}</div>
                        </md-select-option>
                    </md-filled-select>
                    <md-filled-text-field ref="motivoRechazoRef" type="textarea" label="Fundamento de la Incidencia"
                        v-model="motivoRechazo" rows="5" maxlength="500"
                        style="width: 100%; height: 100%; "></md-filled-text-field>
                </template>
                <template v-slot:buttons>
                    <md-elevated-button :disabled="!isFormValid" @click="handleAceptar">
                        {{ acceptButton }}
                    </md-elevated-button>
                    <md-elevated-button @click="closeModal">
                        Volver
                    </md-elevated-button>
                </template>
            </ModalComponent>
        </Transition>
        <!-- modal completo solo para motivos de una mejor UX -->
        <Transition name="bounce">
            <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
                <div class="modal-background"></div>
                <div class="modal-card">
                    <section class="modal-card-body">
                        <div> Validando Datos.. </div>
                        <md-circular-progress four-color indeterminate></md-circular-progress>
                    </section>
                </div>
                <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
            </div>
        </Transition>
        <!-- modal para el final de los procesos de aceptar o rechazar -->
        <Transition name="bounce">
            <ModalComponent v-if="showModal" :title="modalTitle" :modalContent="modalContent">
                <template v-slot:buttons>
                    <md-elevated-button v-if="respuestaExitosa" @click="handleAccept">
                        Aceptar
                    </md-elevated-button>
                    <md-elevated-button v-if="!respuestaExitosa" @click="handleError">
                        Volver
                    </md-elevated-button>
                </template>
            </ModalComponent>
        </Transition>
    </div>
</template>



<style scoped src   =   "../styles/material/theme.light.css">                   </style> 
<style scoped src   =   "../styles/material/theme.dark.css">                    </style> 
<style scoped src   =   "../styles/views/ProgresoDetalllesIncidencias.css">     </style>
<style scoped src   =   "../styles/views/MaterialTheming.css">                  </style>

<script>
import { loadHistoryIncidence, loadIncidencesReasons,startIncident } from '../api/ApiService';

import CardComponent            from        '../components/CardComponent.vue';
import FooterComponent          from        '../components/FooterComponent.vue';
import HeaderComponent          from        '../components/HeaderComponent.vue';
import jwt_decode               from        'jsonwebtoken/decode';
import MenuComponent            from        '../components/MenuComponent.vue';
import MessageComponent         from        '../components/MessageComponent.vue';
import ModalComponent           from        '../components/ModalComponent.vue';
import TableComponent           from        '../components/TableComponent.vue';

export default
{
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        ModalComponent,
        TableComponent,
    },



    async created() 
    {   
        try 
        {
            const incidentReasons = await loadIncidencesReasons();
            this.incidentReasons = incidentReasons;
            console.log("INCIDENCIAS LIST:", incidentReasons);
        } 
        catch (error) 
        {
            console.error('Error loading incident reasons:', error);
        }


        try 
        {
            const data = await loadHistoryIncidence(this.$store);
            this.tableItemsTab1 = data;
        } 
        catch (error) 
        {
            console.error('Error loading history incidence:', error);
        }
    },



    data() 
    {
        return  {
                    incidentReasons         :       [],
                    mostrarLista            :       false,
                    showModalIncidence      :       false,
                    studentDetails          :       null,
                    isFetching              :       false,
                    showModal               :       false,
                    vacancieDetails         :       null,
                    incidence               :       false,
                    selectedReasonId        :       null,

                    serverErrorMessage      :       `Se ha producido un error en el servidor.
                                                    Asegúrate de estar conectado a internet e inténtalo de nuevo en unos minutos.
                                                    Si el problema persiste, comunícate con tu administrador.`,

                    acceptButton            :       'Aceptar',

                    apiNewIncidence         :       `Tu incidencia ha sido enviada a nuestro personal universitario. Pronto la revisaremos.
                                                    Mantente atento a la sección de mensajes de la incidencia para estar informado.
                                                    Lamentamos que hayas experimentado este problema durante tu pasantía`,
                                                    
                    motivoRechazo           :       '',

                    descripcion             :       `Si has experimentado problemas como abuso sexual, maltrato, falta de respeto,
                                                    falta de registro de asistencias u otras situaciones similares, queremos que sepas que estamos aquí para ayudarte.
                                                    Puedes abrir una incidencia con nosotros de forma confidencial. La denuncia es estrictamente privada;
                                                    la empresa o institución donde estás realizando tu trabajo nunca será informada de este proceso.
                                                    Queremos que te sientas seguro al informarnos cualquier problema que enfrentes.
                                                    Recuerda que solo podrás tener una incidencia activa por proceso (ya sea una por servicio social
                                                    y una para prácticas profesionales) para garantizar que podamos atender tu problema y resolverlo de la mejor manera posible.`,

                    activeTab               :       'tab1',

                    apiErrorMessage         :       `Se ha producido un error. Por favor, inténtalo de nuevo más tarde.
                                                    Recuerda que no puedes tener más de una incidencia activa por unidad receptora.
                                                    Si el problema persiste, comunícate con tu administrador`,

                    tableColumns:
                    [
                        { label     :   'Actividad',            field:  'name'          },
                        { label     :   'Motivo',               field:  'reason'        },
                        { label     :   'Argumento',            field:  'message'       },
                        { label     :   'Fecha de Incidencia',  field:  'start_date'    },
                        { label     :   'Seguimiento',          field:  'verMensajes'   },
                    ],
                    tableItemsTab1: [],

                }
    },



    computed: 
    {
        isFormValid() 
        {
            return this.selectedReasonId !== null && this.motivoRechazo.trim() !== '';
        },
    },



    methods:
    {
        handleError() 
        {
            this.showModal = false;
            this.showModalIncidence = false;
        },


        handleAceptar() 
        {
            if (this.incidence) 
            {
                this.startIncident();
            } 
            else 
            {
                this.acceptButton = 'Toque de nuevo para confirmar';
                this.incidence = true;
            }
        },


        closeModal()
        {
            this.showModalIncidence = false;
        },


        toggleMenu()
        {
            this.mostrarLista = !this.mostrarLista;
        },


        //para ver si rechazamos o no la vacante
        handleReject() 
        {
            this.showModalIncidence = true;
            this.modalTitle = 'Reportar Incidencia';

            this.$nextTick(() => 
            {
                this.$refs.motivoRechazoRef.focus();
            });
        },


        handleAccept()
        {
            this.$router.push({ name: 'estudiante-home' });
        },



        async startIncident() 
        {
            this.isFetching = true;
            try 
            {
                const token = localStorage.getItem('token');
                const decodedToken = jwt_decode(token);
                const idStudent = decodedToken.id_student;
                const idVacancie = this.$store.getters.getterVacancieId;
                const idCompany = this.$store.getters.getterCompanyId;
                const idReason = this.selectedReasonId;
                const message = this.motivoRechazo;
                const result = await startIncident(idVacancie, idStudent, idCompany, idReason, message);

                this.isFetching = false;

                if (!result.ok) 
                {
                    const errorMessage      =   result.errorMessage;
                    this.showModal          =   true;
                    this.modalTitle         =   'Ha Ocurrido un Error';
                    this.modalContent       =   `${this.apiErrorMessage} \n\n MENSAJE DE ERROR: ${errorMessage}`;
                    this.respuestaExitosa   =   false;
                } 
                else 
                {
                    this.showModal          =   true;
                    this.modalTitle         =   'Proceso Exitoso';
                    this.modalContent       =   this.apiNewIncidence;
                    this.respuestaExitosa   =   true;
                    this.actionOnSuccess    =   'vacancieRejected';
                }
                console.log('datos', result.data);
            } 
            catch (error) 
            {
                this.isFetching         =   false;
                this.showModal          =   true;
                this.modalTitle         =   'ERROR';
                this.modalContent       =   `${this.serverErrorMessage} \n\n MENSAJE DE ERROR: ${error.message}. `;
                this.respuestaExitosa   =   false;
            }
        }
    }
    
}
</script>