<template>
<div class="material-you light ">
    <HeaderComponent />
    
    <div class="side-menu">
        <MenuComponent class="side-menu" />
    </div>
    
    <article class="message">
        <MessageComponent title="Información de la vacante" :message="descripcion" />
    </article>
    
    
        <div class="card-perfil">
                <CardComponent>
    
                    <!-- Slot para el título -->
                    <template v-slot:title>
                    </template>
    
    
                    <template v-slot:content>
                        <div class="media-content-info">
                            <div class="content-postulados">
                                <p style="text-align: left;">Información de la Vacante</p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em;"> 
                                    <span
                                        class="tag is-info-custom">Empresa Responsable
                                    </span>
                                    {{ vacancieDetails.vacancieInfo.company_name }}
                                </p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> 
                                    <span
                                        class="tag is-info-custom">Nombre de la Vacante
                                    </span> 
                                    {{ vacancieDetails.vacancieInfo.name }}
                                </p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> 
                                    <span
                                        class="tag is-info-custom">Creada en 
                                    </span>
                                    {{ new Date(vacancieDetails.vacancieInfo.creation_log).toLocaleDateString('es-MX', 
                                        {
                                            weekday: 'long',
                                            day: 'numeric', month: 'long', year: 'numeric'
                                        }) 
                                    }}
                                </p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                    <span class="tag is-info-custom">Descripción</span> 
                                    {{ vacancieDetails.vacancieInfo.description }}
                                </p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em">
                                    <span class="tag is-info-custom">Dirección</span> 
                                    {{ vacancieDetails.vacancieInfo.address }}
                                </p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                    <span class="tag is-info-custom">Salario</span>
                                    {{ vacancieDetails.salary !== null ? '$' + vacancieDetails.vacancieInfo.salary : 'No aplica' }}
                                </p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> 
                                    <span
                                        class="tag is-info-custom">Notas Adicionales
                                    </span> 
                                    {{ vacancieDetails.vacancieInfo.notes }}
                                </p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> 
                                    <span
                                        class="tag is-info-custom">Estado
                                    </span> 
                                    {{ vacancieDetails.vacancieInfo.state_name }}
                                </p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> 
                                    <span
                                        class="tag is-info-custom">Municipio
                                    </span> 
                                    {{ vacancieDetails.vacancieInfo.muni_name }}
                                </p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> 
                                    <span
                                        class="tag is-info-custom">Sector</span> 
                                        {{ vacancieDetails.vacancieInfo.sector_name }}
                                    </p>
                            </div>
                        </div>
                    </template>
    
                    <template v-slot:buttons>
                    </template>
    
                </CardComponent>
            </div>
            <div class="botones" style="display: flex; justify-content: space-between;">


        <md-elevated-button>
            Confirmaciones de ingreso 
        </md-elevated-button>

    </div>
        <FooterComponent />
    </div>
    </template>




<style scoped src="../styles/material/theme.light.css"></style> 
<style scoped src="../styles/material/theme.dark.css"></style> 
<style scoped src="../styles/views/VacantesListadoTerminada.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style>

<script>
import CardComponent            from        '../components/CardComponent.vue';
import FooterComponent          from        '../components/FooterComponent.vue';
import HeaderComponent          from        '../components/HeaderComponent.vue';
import jwt_decode               from        'jsonwebtoken/decode';
import MenuComponent            from        '../components/MenuComponent.vue';
import MessageComponent         from        '../components/MessageComponent.vue';
import TableComponent           from        '../components/TableComponent.vue';



export default
{
    name: 'InfoContacto',
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        TableComponent,
    },
    

    
    //cargamos la vacante al crear el componente
    async created() 
    {   
        await this.loadvacancie();  
    },



    data() 
    {
        return  {
                    activeTab: 'tab1',

                    descripcion: `Aquí puedes visualizar en detalle la información de tu vacante cerrada. Además,
                        tienes la posibilidad de acceder a las confirmaciones de asistencia de los candidatos que se postularon
                        para la misma. Es importante recordar que una vez que una vacante está cerrada, ya no se aceptan nuevos
                        postulantes. Sin embargo, el proceso de selección y contratación sigue su curso normal. Agradecemos
                        tu comprensión y cooperación durante este proceso.`,

                    tableColumns:
                    [
                        { label     :   'Nombre',       field   :   'nombre'                },
                        { label     :   'Actividad',    field   :   'actividad'             },
                        { label     :   'Estado',       field   :   'estado'                },
                        { label     :   'Detalles',     field   :   'misAlumnosDetalles'    },
                    ],

                    tableItemsTab1:
                    [
                        // { id: 1, img: '', nombre: 'Ana Sofía López Ramírez', actividad: 'Cerillo', estado: 'En Progreso' },
                    ],
                    vacancieDetails     :   null,
                    studentDetails      :   null,
                }
    },



    methods:
    {
        //cargamos la informacion de la vacante a rechazar o aceptar
        async loadvacancie()
        {
            try
            {
                const vacancie      =   this.$store.getters.getterVacancieId; //mover a created
                const token         =   localStorage.getItem('token');
                const id_vacancie   =   vacancie;
                const decodedToken  =   jwt_decode(token);
                const id_company    =   decodedToken.userCompanyId;

                const response      =   await fetch(process.env.VUE_APP_COMPANY_POST_VACANCIE_INFO,
                {
                    method : 'POST',
                    headers: 
                    {
                        'Content-Type'  : 'application/json',
                        'Authorization' : `Bearer ${token}`,
                    },
                    body: JSON.stringify({ id_vacancie: id_vacancie, id_company :id_company }),
                });

                if(!response.ok)
                {
                    throw new Error('Error al cargar la vacante');
                }

                const data              =   await response.json();
                this.vacancieDetails    =   data;
                console.log("DATOS DE LA API:",data)
            }
            catch(error)
            {
                console.log(error);
            }
        },
    }
    
}
</script>