<template>

    <!-- material theming -->
    <div :class="['material-you', mainTheme]">

        <!-- header -->
        <HeaderComponent />


        <!-- main menu -->
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>


        <!-- tooltips -->
        <article class="message">
            <MessageComponent title="Mis Pasantes" :message="descripcion" />
        </article>


        <!-- main tabs -->
        <div class="tabs " style="width: 100%;">
            <md-tabs v-model="activeTab" :style="{ borderRadius: '15em' }">
                <md-primary-tab v-for="(tab, index) in tabs" :key="index" :name="tab.name" @click="changeTab(tab.name)">
                    {{ tab.label }}
                </md-primary-tab>
            </md-tabs>
        </div>

        <!-- main table -->
        <div v-if="paginatedItems.length > 0" class="job-table">
            <div class="search">
                <md-outlined-text-field label="Buscar..." value="Value" v-model="searchQuery">
                </md-outlined-text-field>
            </div>
            <TableComponent :columns="tableColumns" :searchQuery="searchQuery" :items="paginatedItems">
            </TableComponent>

            <!-- pagination list -->
            <nav class="pagination" role="navigation" aria-label="pagination">
                <button class="pagination-previous" @click="prevPage" :disabled="currentPage === 1">Anterior</button>
                <button class="pagination-next" @click="nextPage"
                    :disabled="currentPage === totalPages">Siguiente</button>
                <ul class="pagination-list">
                    <li v-for="page in totalPages" :key="page">
                        <a class="pagination-link" :class="{ 'is-current': page === currentPage }"
                            @click="currentPage = page">{{
                                page
                            }}</a>
                    </li>
                </ul>
            </nav>
        </div>
            <!-- No data message -->
    <div v-else class="no-data-message">
      <p>Sin pasantes activos.</p>
    </div>


        <!-- footer -->
        <FooterComponent />
    </div>
</template>





<!-- Estilos & Scripts -->
<style scoped src="../styles/material/theme.light.css"></style> 
<style scoped src="../styles/material/theme.dark.css"></style> 

<style scoped src="../styles/views/AlumnosMisalumnos.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style>


<script>
import FooterComponent      from        '../components/FooterComponent.vue';
import HeaderComponent      from        '../components/HeaderComponent.vue';
import MenuComponent        from        '../components/MenuComponent.vue';
import MessageComponent     from        '../components/MessageComponent.vue';
import TableComponent       from        '../components/TableComponent.vue';
import { loadActualInternship } from '../api/ApiService';

export default
{
    name: 'AlumnosMisalumnos',

    components:
    {
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        TableComponent,
    },

    data() 
    {
        return  {
                    mainTheme               :   'light',
                    currentPage             :   1,
                    itemsPerPage            :   10,
                    searchQuery             :   '',
                    activeTab               :   'tab1',
                    descripcion             :   `En este espacio, tendrás acceso a la lista de alumnos pertenecientes a las 
                                                facultades que administras, con un estatus activo en sus pasantías. Esto te permitirá
                                                revisar sus reportes y cualquier incidencia que pueda surgir durante su periodo de pasantía.`,

                    tableColumns:
                    [
                        { label :   'Nombre',               field   :   'nombre'                },
                        { label :   'Actividad',            field   :   'actividad'             },
                        { label :   'Fecha De Ingreso',     field   :   'fecha'                 },
                        { label :   'Detalles',             field   :   'misAlumnosDetalles'    },
                    ],

                    tableItemsTab1: [],
                    tableItemsTab2: [],
                    tableItemsTab3: [],

                    tabs: 
                    [
                        { name  :   'tab1',     label   :   'Servicio Social'            },
                        { name  :   'tab2',     label   :   'Prácticas Profesionales'    },
                    ],
                }
    },


    async created() 
    {
        try {
        const token = localStorage.getItem('token');
        const internshipData = await loadActualInternship(token);
        this.tableItemsTab1 = internshipData.tab1;
        this.tableItemsTab2 = internshipData.tab2;
    } catch (error) {
        console.error('Failed to load internships:', error);
    }
    },



    computed: 
    {
        totalPages() 
        {
            const items = this.getCurrentTabItems();
            return Math.ceil(items.length / this.itemsPerPage);
        },


        paginatedItems() 
        {
            const items   =   this.getCurrentTabItems();
            const start   =   (this.currentPage - 1) * this.itemsPerPage;
            const end     =   start + this.itemsPerPage;

            return items.slice(start, end);
        },
    },


    methods: 
    {
        changeTab(tab) 
        {
            this.activeTab = tab;
        },


        getCurrentTabItems() 
        {
            switch(this.activeTab) 
            {
                case 'tab1': return this.tableItemsTab1;
                case 'tab2': return this.tableItemsTab2;
                case 'tab3': return this.tableItemsTab3;
                case 'tab4': return this.tableItemsTab4;
                case 'tab5': return this.tableItemsTab5;
                default: return [];
            }
        },


        nextPage() 
        {
            if (this.currentPage < this.totalPages) 
            {
                this.currentPage++;
            }
        },


        prevPage() 
        {
        if (this.currentPage > 1)
        {
            this.currentPage--;
        }
        },


        totalPages() 
        {
            const items = this.getCurrentTabItems();
            return Math.ceil(items.length / this.itemsPerPage);
        },
        handleThemeChange(e) {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    }

    },


    mounted() {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },


  beforeUnmount() {
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },




}
</script>