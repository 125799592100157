


//detalles es sobre cargar los detalles de la vancate seleccionada

export default
{
    state : 
    {
        detalles: null,
        vacancieId              :   null,
        studentId               :   null,   
        incidentId : null,
        companyId : null,
    },

    mutations: 
    {
        
        setDetalles(state,detalles)
        {
            state.detalles = detalles;
        },

        SET_VACANCIE_ID(state,vacancieId)   {   state.vacancieId            = vacancieId                },
        SET_STUDENT_ID(state,studentId)    {   state.studentId             = studentId   },
        SET_INCIDENT_ID(state,incidentId)                  {   state.incidentId     = incidentId;        },
        SET_COMPANY_ID(state,companyId)                  {   state.companyId     = companyId;        },
    },

    actions : 
    {
        actualizarDetalles({commit},detalles) 
        {
            commit('setDetalles',detalles);
        },


        async changeVacancieId({commit})
        {    
            async function fetchData()
            {
                const vacancieId = await fetchVacancieId();
                commit('SET_VACANCIE_ID',vacancieId);
            }
            fetchData();
        },


        async changeStudentId({commit})
        {    
            async function fetchData()
            {
                const studentId = await fetchStudentId();
                commit('SET_STUDENT_ID',studentId);
            }
            fetchData();
        },


        async changeIncidentId({commit})
        {    
            async function fetchData()
            {
                const incidentId = await fetchIncidentId();
                commit('SET_INCIDENT_ID',incidentId);
            }
            fetchData();
        },

        async changeCompanyId({commit})
        {    
            async function fetchData()
            {
                const companyId = await fetchCompanyId();
                commit('SET_COMPANY_ID',companyId);
            }
            fetchData();
        },


    },
    getters : {
        obtenerDetalles : (state) => state.detalles,
        getterVacancieId          :       (state) =>          state.vacancieId,
        getterStudentId             :       (state) =>          state.studentId,
        getterIncidentId :(state) => state.incidentId,
        getterCompanyId : (state) => state.companyId,
    },



}



