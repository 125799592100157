<template>
  <div :class="[mainTheme]">
  <div class="card gradient-background">
    <div class="card-title">
      <slot name="title"></slot>
    </div>
    <div class="separador"></div>
    <div class="card-content">
      <slot name="content"></slot>
    </div>
    <div class="card-buttons">
      <slot name="buttons"> </slot>
    </div>
  </div>
</div>
</template>





<style scoped src="../styles/components/CardComponent.css"> </style>

<style scoped src = "../styles/material/theme.light.css"    ></style> 
<style scoped src = "../styles/material/theme.dark.css"     ></style> 
<script>
export default 
{
  data() 
    {
      return  {

                mainTheme: 'light',
              };
    },


  mounted() 
  {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery);
  },


  methods: 
  {
    handleThemeChange(e) 
    {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    }
  },


  beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },
};
</script>




