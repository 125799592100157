<template>
<div :class="['material-you', mainTheme]">
<HeaderComponent />

<div class="side-menu">
    <MenuComponent class="side-menu" />
  </div> 

  <div class="informacion">
  <CardComponent>

    <template v-slot:title>
      <!-- Título del card, si lo deseas -->
      <h2 class="title">Información de Contacto</h2>
    </template>

    <template v-slot:content>
      <!-- Contenido de tu anuncio -->
      <div class="card-anuncio">
        <div class="card-contentAnuncio">
          <div class="anuncio">
            <p class="texto-destacado">Si tienes algún problema en tu procedimiento, no dudes en comunicarte con nosotros.</p>
            <p>Teléfono 1: +1 (123) 456-7890</p>
            <p>Teléfono 2: +1 (987) 654-3210</p>
            <p>Domicilio: 123 Calle Principal, Ciudad</p>
            <p>Correo Electrónico:ejemplo@correo.com</p>
          </div>
        </div>
      </div>
    </template>
    
  </CardComponent>
  </div>

  <FooterComponent />
</div>
</template>




<style scoped src="../styles/material/theme.light.css"></style> 
<style scoped src="../styles/material/theme.dark.css"></style> 
<style scoped src="../styles/views/MaterialTheming.css"></style>

<style scoped src="../styles/views/InfoContacto.css"></style>

<script>
import CardComponent    from    '../components/CardComponent.vue';
import FooterComponent  from    '../components/FooterComponent.vue';
import HeaderComponent  from    '../components/HeaderComponent.vue';
import MenuComponent    from    '../components/MenuComponent.vue';

export default
{
    name: 'InfoContacto',
    components:
    {
      CardComponent,
      FooterComponent,
      HeaderComponent,
      MenuComponent,
    },

    data()
    {
      return {
      mainTheme: 'light',
    }

    },

    methods:
    {
      handleThemeChange(e) {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    }

    },

    beforeUnmount() {
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },

    mounted() {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },



}
</script>