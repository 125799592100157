<template>
  <div :class="['material-you', mainTheme]">
    <HeaderComponent />
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>
    <div class="seprador"></div>
    <div class="inicio">
      <CardComponent>
        <template v-slot:title>
          <div class="columns is-vcentered is-centered">
            <div class="column is-7">
              <figure class="image is-16by9">
                <img src="https://picsum.photos/800/1200/?random" alt="Description">
              </figure>
            </div>
            <h6 class="texto">
              {{ mensaje }}
            </h6>
          </div>
        </template>
      </CardComponent>
    </div>
    <FooterComponent />
  </div>
</template>




<style scoped src="../styles/material/theme.light.css"></style> 
<style scoped src="../styles/material/theme.dark.css"></style> 


<style scoped src="../styles/views/PaginaInicio.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style>


<script>
import CardComponent    from    '../components/CardComponent.vue';
import FooterComponent  from    '../components/FooterComponent.vue';
import HeaderComponent  from    '../components/HeaderComponent.vue';
import MenuComponent    from    '../components/MenuComponent.vue';


export default
{
    name: 'PaginaInicio',
    components:
    {
      CardComponent,
      FooterComponent,
      HeaderComponent,
      MenuComponent,
    },



    data()
    {
      return  {
                    mainTheme: 'light',
                    mensaje : `Bienvenido al Portal de Servicio Social y Prácticas Profesionales, tu puerta de entrada a
                    experiencias enriquecedoras que complementarán tu educación y te prepararán para el mundo laboral.
                    Nuestra plataforma se ha creado pensando en ti, el estudiante, con el objetivo de facilitar la búsqueda
                    y el proceso de solicitud de oportunidades de servicio social y prácticas profesionales.`,
              }
    },



  mounted() 
  {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },



  methods :
  {
    handleThemeChange(e) {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    }
  },



  beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },

}
</script>