<template>
  <div :class="['material-you', mainTheme]">

    <HeaderComponent />

    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>

    <article class="message">
      <MessageComponent title="Nueva Solicitud" :message="descripcion" />
    </article>

    <div class="container">
      <form @submit.prevent="submitForm">
        <FormComponent :formFields="formFields" formId="formulario1" :apiUrl="apiUrl"
          @estado-selected="handleEstadoSelected" />
      </form>
    </div>

    <FooterComponent />

  </div>
</template>





<style scoped src="../styles/material/theme.dark.css"></style> 
<style scoped src="../styles/material/theme.light.css"></style> 

<style scoped src="../styles/views/MaterialTheming.css"></style>
<style scoped src="../styles/views/VacantesNuevaCrear.css"></style>
<script>

import FooterComponent      from        '../components/FooterComponent.vue';
import FormComponent        from        '../components/FormComponent.vue';
import HeaderComponent      from        '../components/HeaderComponent.vue';
import MenuComponent        from        '../components/MenuComponent.vue';
import MessageComponent     from        '../components/MessageComponent.vue';

import { loadEstados, loadSectors, loadBoards, loadMunicipios } from '../api/ApiService';



export default
{
  components:
  {
    FooterComponent,
    FormComponent,
    HeaderComponent,
    MenuComponent,
    MessageComponent,
  },



  data() 
  {
    return  {
              mainTheme               :   'light',
              estados                 :   [],
              estadoSeleccionado      :   null,

              descripcion             :     `Ingresa los detalles de tus vacantes de prácticas profesionales y servicio social en nuestro formulario exclusivo para empresas.
                                            Asegúrate de completar toda la información obligatoria en cada campo para conectar eficazmente con candidatos cualificados.
                                            ¡Tu próxima colaboración está a solo unos clics de distancia!`,

              apiUrl      : process.env.VUE_APP_API_COMPANY_POST_NEWVACANCIE,

              formFields  :
              [
                {
                  label   : "Tipo",
                  type    : "select", 
                  name    : "type",
                  options : []
                },

                { label   : "Nombre", type: "text", name: "name", maxlength: 200 },

                {
                  label   : "Giro de la Empresa", type: "select", name: "business_sectors_id",
                  options : []
                },

                {
                  label   : "Seleccione el Estado",
                  type    : "select",
                  name    : "state_id",
                  options : [],
                },

                {
                  label   : "Seleccione el Municipio", 
                  type    : "select", 
                  name    : "municipality_id",
                  options : []
                },

                { 
                  label   : "Número de vacantes", 
                  type    : "number", 
                  name    : "w_hiring_capacity" 
                },

                {
                  label: "Apoyo Económico", type: "select", name: "ofrecerSueldo",
                  options:
                  [
                    { value: "si", label: "Sí" },
                    { value: "no", label: "No" }
                  ]
                },

                { label: "", type: "number", name: "salary",  supportingText: "*Monto Mensual"},


                { label: "Domicilio", type: "textarea", name: "address", maxlength: 1000 },


                { label: "Descripcion", type: "textarea", name: "description", maxlength: 2000, supportingText: "Incluye tus requisitos, como horario, experiencia, aptitudes etc" },


                { label: "Notas Adicionales", type: "textarea", name: "notes", maxlength: 1000, supportingText: "Incluye cualquier información pertinente que no haya sido mencionada en los puntos anteriores." },

              ],

              formData:
              {
                business_sectors_id   :   null,
                description           :   '',
                municipality_id       :   null,
                nombre                :   '',
                notes                 :   '',
                ofrecerSueldo         :   null,
                salary                :   null,
                state_id              :   null,
                type                  :   null,
                w_hiring_capacity     :   null,
              },

            };
  },


  beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },


  async mounted() 
  {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial

    try 
    {
      const estados = await loadEstados();
      this.estados = estados;

      // Actualiza las opciones del campo de selección
      const stateField = this.formFields.find(field => field.name === 'state_id');
      if (stateField) 
      {
        stateField.options = this.estados.map(state => 
        ({
          value : state.id.toString(),
          label : state.nombre,
        }));
      }
    } 
    catch (error) 
    {
      console.error('Error al cargar estados:', error);
    }; 


    try 
    {
      const sectors = await loadSectors();
      this.sectors = sectors;

      const sectorField = this.formFields.find(field => field.name === 'business_sectors_id');
      if (sectorField) 
      {
          sectorField.options = this.sectors.map(sector => 
          ({
            value: sector.id.toString(),
            label: sector.name,
        }));
      }
    } 
    catch (error) 
    {
      console.error('Error al cargar sectores:', error);
    }


    try 
    {
      const boards = await loadBoards();
      this.types = boards;

      // Actualiza las opciones del campo de selección
      const typeField = this.formFields.find(field => field.name === 'type');
      if (typeField) 
      {
        typeField.options = this.types.map(type => 
        ({
          value : type.id.toString(),
          label : type.type,
        }));
      }
    } 
    catch (error) 
    {
      console.error('Error al cargar estados:', error);
    }
  },



  methods:
  {
    handleThemeChange(e) {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },
    
    submitForm() {},


    async handleEstadoSelected(valorSeleccionado) 
    {
      this.estadoSeleccionado = valorSeleccionado;

      if (this.estadoSeleccionado !== null) 
      {
        try 
        {
          console.log('Antes de la llamada a la API');
          const municipios = await loadMunicipios(this.estadoSeleccionado);
          console.log('data de los municipios', municipios);

          // Actualiza las opciones del campo de selección 'municipality_id'
          const municipalityField = this.formFields.find(field => field.name === 'municipality_id');
          if (municipalityField) 
          {
            municipalityField.options = municipios.map(municipio => 
            ({
              value : municipio.id.toString(),
              label : municipio.nombre,
            }));
          }
        } 
        catch (error) 
        {
          console.error('Error al enviar datos y obtener municipios:', error);
        }
      }
    },

  }

};
</script>
