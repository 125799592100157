<template>

    <!-- material theme -->
    <div class="material-you light">

        <!-- header -->
        <HeaderComponent />

        <!-- main menu -->
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>

        <!-- tooltips -->
        <article class="message">
            <MessageComponent title="Asistencias & Horas" :message="descripcion" />
        </article>

        <!-- student global info -->
        <div class="aboutMe">
            <CardComponent>
                <template v-slot:content>
                    <div class="card-content">
                        <div class="media">
                            <div class="media-left">
                                <div class="media-content-image has-text-centered">
                                    <figure class="image custom-image">
                                        <img src="https://xsgames.co/randomusers/avatar.php?g=female" alt="User Avatar"
                                            class="rounded-image">
                                    </figure>
                                </div>
                            </div>

                            <div class="content-perfil">
                                <p class="perfil">{{ studentName }}</p>
                                <p class="perfil">{{ email }}</p>
                            </div>

                            <div class="container">
                            <div class="totalTimeContainer">
                            <div class="info-container">
                                <span class="tag is-info is-light">Departamento: {{ nameDepartment }}</span>
                                <br>
                                <span class="tag is-info is-light">Tipo de Pasantía: {{ intershipType }}</span>
                                <br>
                                <span class="tag is-info is-light">Tiempo Total: {{ totalHours }}</span>
                                <!-- <span class="tag is-warning">Progreso: {{ progress }}%</span> -->
                            </div>
                            <!-- <div class="progress-container">
                                <progress class="progress is-material" :value="progress" max="100">{{ progress }}%</progress>
                            </div> -->
                            </div>
                        </div>
                        </div>
                    </div>
                </template>
            </CardComponent>
        </div>

        <!-- add assistance  -->
        <div class="soySeparador">
            <md-elevated-button @click="handleReject">
                Agregar Asistencias
            </md-elevated-button>
        </div>

        <!-- main table -->
        <div class="tabla">
            <TableComponent :columns="tableColumns"
                :items="activeTab === 'tab1' ? tableItemsTab1 : (activeTab === 'tab2' ? tableItemsTab2 : tableItemsTab3)">
            </TableComponent>
        </div>

        <!-- footer -->
        <FooterComponent />
    </div>


    <!-- MODAL add assistance -->
    <Transition name="bounce">
        <ModalComponent v-if="showModalReject" :title="modalTitle" @close="closeModal">
            <template v-slot:content>

                <div class="fondo">
                    <span class="tag is-danger is-light">{{ asistenciasDesc }}</span>
                </div>

                <p>Esta es la fecha en la que estás registrando la asistencia : {{ today }} </p>
                <p> Entrada</p>

                <div class="picker-container">
                    <div class="hello">
                        <div class="timepicker-ui" ref="tmEntrada">
                            <input v-model="inputEntrada" type="text" class="timepicker-ui-input" required />
                        </div>
                        {{ inputEntrada }}
                    </div>
                </div>

                <p>Salida</p>

                <div class="picker-container">
                    <div class="hello">
                        <div class="timepicker-ui" ref="tmSalida">
                            <input v-model="inputSalida" type="text" class="timepicker-ui-input" required />
                        </div>
                        {{ inputSalida }}
                    </div>
                </div>

                <md-elevated-button :disabled="!canSubmit" @click="registrarAsistencia">
                    Registrar Asistencia
                </md-elevated-button>

                <div style="margin-top: 10px;">
                    <md-elevated-button @click="handleDiaNoHabil">
                        {{ diaNoHabilText }}
                    </md-elevated-button>
                    <md-elevated-button @click="handleInasistencia">
                        {{ inasistenciaText }}
                    </md-elevated-button>
                </div>

            </template>

            <template v-slot:buttons>
                <md-elevated-button @click="closeModal">
                    Volver
                </md-elevated-button>
            </template>

        </ModalComponent>
    </Transition>

    <!-- MODAL loading UX -->
    <Transition name="bounce">
        <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
            <div class="modal-background"></div>
            <div class="modal-card">
                <section class="modal-card-body">
                    <div> Validando Datos.. </div>
                    <md-circular-progress four-color indeterminate></md-circular-progress>
                </section>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
        </div>
    </Transition>

    <!-- MODAL post actions -->
    <Transition name="bounce">
        <ModalComponent v-if="showModal" :title="modalTitle" :modalContent="modalContent">
            <template v-slot:buttons>
                <md-elevated-button v-if="respuestaExitosa" @click="handleAccept">
                    Aceptar
                </md-elevated-button>
                <md-elevated-button v-if="!respuestaExitosa" @click="handleError">
                    Volver
                </md-elevated-button>
            </template>
        </ModalComponent>
    </Transition>
</template>





<style scoped src   =   "../styles/material/theme.light.css"                        ></style>
<style scoped src   =   "../styles/material/theme.dark.css"                         ></style>
<style scoped src   =   "../styles/views/EmpleadosActualesDetallesAsistencias.css"  ></style>
<style scoped src   =   "../styles/views/MaterialTheming.css"                       ></style>

<script>
import '@vuepic/vue-datepicker/dist/main.css';

import { nextTick }             from     'vue';
import { TimepickerUI }         from     "timepicker-ui";
import CardComponent            from     '../components/CardComponent.vue';
import FooterComponent          from     '../components/FooterComponent.vue';
import HeaderComponent          from     '../components/HeaderComponent.vue';
import jwt_decode               from     'jsonwebtoken/decode';
import MenuComponent            from     '../components/MenuComponent.vue';
import MessageComponent         from     '../components/MessageComponent.vue';
import ModalComponent           from     '../components/ModalComponent.vue';
import TableComponent           from     '../components/TableComponent.vue';
import VueDatePicker            from     '@vuepic/vue-datepicker';

export default 
{
    name: 'EmpleadosActualesDetallesAsistencias',



    components: 
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        ModalComponent,
        TableComponent,
        VueDatePicker,
    },



    async created() 
    {
        await this.loadAssitances();
        await this.load_global_info();
        await this.load_today();
    },



    mounted() 
    {
        if (this.$refs.tm) 
        {
            const test = new TimepickerUI(this.$refs.tm, { theme: "crane-radius" });
            test.create();

            this.$refs.tm.addEventListener("accept", ({ detail }) => {
                this.inputValue = `${detail.hour}:${detail.minutes} ${detail.type}`;
            });
        }
    },



    updated() 
    {
        if (this.inputValue === "10:00 PM") 
        {
            alert("You selected 10:00 PM");
        }
    },



    computed: 
    {
        canSubmit() 
        {
            return this.inputEntrada !== '' && this.inputSalida !== '' && this.isEntradaMenorSalida;
        },


        isEntradaMenorSalida() 
        {
            const entrada = this.convertirATiempoCompleto(this.inputEntrada);
            const salida = this.convertirATiempoCompleto(this.inputSalida);
            return entrada < salida;
        }
    },



    watch: 
    {
        showModalReject(newValue) 
        {
            if (newValue) 
            {
                nextTick(() => {
                    // Inicializar el timepicker para la entrada
                    if (this.$refs.tmEntrada) 
                    {
                        const entradaPicker = new TimepickerUI(this.$refs.tmEntrada, { theme: 'crane-radius' });
                        entradaPicker.create();
                        this.$refs.tmEntrada.addEventListener('accept', ({ detail }) => {
                            this.inputEntrada = `${detail.hour}:${detail.minutes} ${detail.type}`;
                            console.log(`Hora de entrada seleccionada: ${this.inputEntrada}`);
                        });
                    }

                    // Inicializar el timepicker para la salida
                    if (this.$refs.tmSalida) 
                    {
                        const salidaPicker = new TimepickerUI(this.$refs.tmSalida, { theme: 'crane-radius' });
                        salidaPicker.create();
                        this.$refs.tmSalida.addEventListener('accept', ({ detail }) => {
                            this.inputSalida = `${detail.hour}:${detail.minutes} ${detail.type}`;
                            console.log(`Hora de salida seleccionada: ${this.inputSalida}`);
                        });
                    }
                });
            }
        },
    },



    data() 
    {
        return  {
                    date                :   null,
                    date2               :   null,
                    diaNoHabilConfirm   :   false,
                    inasistenciaConfirm :   false,
                    isFetching          :   false,
                    mostrarLista        :   false,
                    progress            :   13.89,
                    showModal           :   false,
                    showModalReject     :   false,
                    studentDetails      :   null,
                    totalHours          :   null,
                    vacancieDetails     :   null,

                    activeTab           :   'tab1',
                    diaNoHabilText      :   'Día No Hábil',
                    email               :   '',
                    entrada             :   '',
                    inasistenciaText    :   'Inasistencia',
                    inputEntrada        :   '',
                    inputSalida         :   '',
                    inputValue          :   "10:10 PM",
                    intershipType       :   '',
                    nameDepartment      :   '',
                    salida              :   '',
                    selectedTime        :   "12:00",
                    studentName         :   '',
                    today               :   '',

                    tableItemsTab1      :   [],
                    tableColumns        : 
                    [
                        { label :   'Fecha',            field   :   'day'           },
                        { label :   'Hora de Entrada',  field   :   'entranceTime'  },
                        { label :   'Hora de Salida',   field   :   'departureTime' },
                        { label :   'Tiempo Total',     field   :   'totalTime'     },
                    ],

                    asistenciasDesc :   `Recuerda que es obligatorio registrar las asistencias una por una, diariamente.
                     Si olvidas alguna, asegúrate de registrarla de todos modos. Para días festivos o no laborables,
                      selecciona el botón correspondiente. Si el pasante falta, también debes registrar la ausencia.`,

                    descripcion     :   `En este lugar, tendrás la oportunidad de llevar un seguimiento detallado tanto de las asistencias
                                        como de las horas trabajadas por el estudiante.`,
                };
    },



    methods: 
    {
        convertirATiempoCompleto(time) 
        {
            // Dividir la hora y el tipo (AM/PM)
            const [timePart, period] = time.split(' ');
            let [hour, minutes] = timePart.split(':');

            // Convertir a formato 24 horas
            hour = parseInt(hour, 10);
            if (period === 'PM' && hour !== 12) 
            {
                hour += 12;
            } 
            else if (period === 'AM' && hour === 12) 
            {
                hour = 0;
            }

            // Asegurar que las horas y minutos tengan siempre dos dígitos
            const formattedHour = String(hour).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');

            // Añadir segundos a "00"
            const formattedSeconds = '00';

            return `${formattedHour}:${formattedMinutes}:${formattedSeconds}`;
        },


        toggleMenu() 
        {
            this.mostrarLista = !this.mostrarLista;
        },


        handleReject() 
        {
            this.showModalReject = true;
            this.modalTitle = 'Añadir Asistencias';

        },


        closeModal() 
        {
            this.showModal = false;
            this.showModalReject = false;
        },


        handleError()
        {
            this.showModal = false;
        },


        irAIncidencias() 
        {
            console.log("hola");
        },


        irAsistencias() 
        {
            console.log("hola2");
        },

        async load_global_info()
        {
            try
            {
                const token = localStorage.getItem('token');

                // se obtiene el id del alumno de la store
                const idStudent = this.$store.getters.getterStudentId;

                // se obtiene el id de la vacante del store
                const idVacancie = this.$store.getters.getterVacancieId; // mover a created

                const response = await fetch(process.env.VUE_APP_API_COMPANY_POST_GLOBAL_INFO, 
                    {
                        method  : 'POST',
                        headers : 
                        {
                            'Content-Type'  : 'application/json',
                            'Authorization' : `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            idStudent   : idStudent,
                            idVacancie  : idVacancie,
                        }),
                    });

                    if (!response.ok) 
                    {
                        throw new Error('Error');
                    }
                    const data = await response.json();
                    console.log('global info', data);

                    this.studentName = data.studentFullName;
                    this.nameDepartment = data.name_department;
                     this.intershipType = data.type;
                     this.totalHours = data.totalHours;
                     this.email = data.email;

            }
            catch (error) 
            {
                console.error(error);
            }

        },


        

        async load_today()
        {
            const token = localStorage.getItem('token');

            // se obtiene el id del alumno de la store
            const idStudent = this.$store.getters.getterStudentId;

            // se obtiene el id de la vacante del store
            const idVacancie = this.$store.getters.getterVacancieId; // mover a created

            const response = await fetch(process.env.VUE_APP_API_COMPANY_POST_TODAY, 
                {
                    method  : 'POST',
                    headers : 
                    {
                        'Content-Type'  : 'application/json',
                        'Authorization' : `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        idStudent   : idStudent,
                        idVacancie  : idVacancie,
                    }),
                });
                const data = await response.json();
                console.log('today', data.firstEmployedDateFormatted);
                console.log('today OTRO', data);
                if(data.firstEmployedDateFormatted != null)
                {
                    this.today = this.formatDateToReadable(data.firstEmployedDateFormatted);

                }

                if(data.lastDayFormatted != null)
                {
                    this.today = this.formatDateToReadable(data.lastDayFormatted);

                }
                // this.today = data.firstEmployedDateFormatted;
                
        },

        formatDateToReadable(dateString) {
        let date;

        if (dateString.includes('T')) {
            // Si la fecha está en formato ISO con 'T'
            date = new Date(dateString);
        } else {
            // Si la fecha está en formato YYYY-MM-DD
            const [year, month, day] = dateString.split('-').map(Number);
            date = new Date(year, month - 1, day);
        }

        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('es-ES', options).format(date);

        // Personalizar el formato para que sea "09 de junio del 2024"
        const parts = formattedDate.split(' ');
        if (parts.length === 4) {
            return `${parts[0]} de ${parts[2]} del ${parts[3]}`;
        } else if (parts.length === 3) {
            return `${parts[0]} de ${parts[1]} del ${parts[2]}`;
        }
        return formattedDate;
        },


        async loadAssitances() 
        {
            try 
            {
                const token = localStorage.getItem('token');
                const decodedToken = jwt_decode(token);

                // se obtiene el id del alumno de la store
                const idStudent = this.$store.getters.getterStudentId;

                // se obtiene el id de la vacante del store
                const idVacancie = this.$store.getters.getterVacancieId; // mover a created

                const response = await fetch(process.env.VUE_APP_COMPANY_POST_ASSITANCE_HISTORY, 
                {
                    method  : 'POST',
                    headers : 
                    {
                        'Content-Type'  : 'application/json',
                        'Authorization' : `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        idStudent   : idStudent,
                        idVacancie  : idVacancie,
                    }),
                });

                if (!response.ok) 
                {
                    throw new Error('Error');
                }
                const data = await response.json();
                console.log('datos', data);

                this.tableItemsTab1 = data.listHoursDays.map(item => 
                ({
                    id              : item.id,
                    entranceTime    : item.entrance_time,
                    departureTime   : item.departure_time,
                    day             : item.day.split('T')[0],
                    totalTime       : item.total_time,
                    special         : item.special,
                }));
            } 
            catch (error) 
            {
                console.error(error);
            }
        },


        onTimeChange(type) 
        {
            return (event) => {
                const time = event.detail.value;
                if (type === 'entrada') 
                {
                    this.entrada = time;
                } 
                else if (type === 'salida') 
                {
                    this.salida = time;
                }
            };
        },


        async registrarAsistencia() 
        {
            this.isFetching         =   true;
            try
            { 
                const token             =   localStorage.getItem('token');
                const decodedToken      =   jwt_decode(token);           
                const entranceTime      =   this.convertirATiempoCompleto(this.inputEntrada);
                const departureTime     =   this.convertirATiempoCompleto(this.inputSalida);
                const idStudent         =   this.$store.getters.getterStudentId;
                const idVacancie        =   this.$store.getters.getterVacancieId;

                const data = 
                {
                    entrada: entranceTime,
                    salida: departureTime,
                };

                const apiIngresarAsistencia = process.env.VUE_APP_COMPANY_POST_NEW_ASSITANCE;

                console.log(`DATOSSSS`, data);

                await new Promise(resolve => setTimeout(resolve, 4000));
                const response = await fetch(apiIngresarAsistencia, 
                {
                    method  : 'POST',
                    headers: 
                            {
                                'Content-Type'  :   'application/json',
                                'Authorization' :   `Bearer ${token}`,
                            },
                            body: JSON.stringify({ idStudent: idStudent, idVacancie : idVacancie, entranceTime:entranceTime, departureTime:departureTime   }),
                });

                this.isFetching = false;

                //ha ocurrido algun tipo de error
                if (!response.ok) 
                {
                    const errorMessage = await response.text();
                    this.showModal          =   true;
                    this.modalTitle         =   'Ha Ocurrido un Error';
                    this.modalContent       =   `${this.apiErrorMessage}   \n \n MENSAJE DE ERROR:  ${errorMessage}`
                    this.respuestaExitosa   =   false;
                }

                //si todo salio bien
                if (response.ok) 
                {
                    this.showModal          =   true;
                    this.modalTitle         =   'Proceso Exitoso';
                    this.modalContent       =   this.apiVacancieRejected;
                    this.respuestaExitosa   =   true;
                    this.actionOnSuccess    =   'vacancieRejected'
                }
            } 
            catch (error) 
            {
                this.isFetching         =       false;
                this.showModal          =       true;
                this.modalTitle         =       'ERROR';
                this.modalContent       =       `${this.serverErrorMessage} \n \n MENSAJE DE ERROR: ${error.message}. `;
                this.respuestaExitosa   =       false;
            }
        },


        handleDiaNoHabil() 
        {
            if (this.diaNoHabilConfirm) 
            {
                this.marcarDiaNoHabil();
            } 
            else 
            {
                this.diaNoHabilText = 'Toque de nuevo para confirmar';
                this.diaNoHabilConfirm = true;
            }
        },


        async marcarDiaNoHabil() 
        {
            this.isFetching         =   true;
            try
            {
                
                const token = localStorage.getItem('token');
                const decodedToken = jwt_decode(token);

                // se obtiene el id del alumno de la store
                const idStudent = this.$store.getters.getterStudentId;

                // se obtiene el id de la vacante del store
                const idVacancie = this.$store.getters.getterVacancieId;

                await new Promise(resolve => setTimeout(resolve, 3000));

                const response = await fetch(process.env.VUE_APP_COMPANY_POST_HOLIDAY, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify
                    ({
                        idVacancie: idVacancie,
                        idStudent: idStudent,
                        
                    }),
                });

                this.isFetching = false;

                //ha ocurrido algun tipo de error
                if (!response.ok) 
                {
                    const errorMessage = await response.text();
                    this.showModal          =   true;
                    this.modalTitle         =   'Ha Ocurrido un Error';
                    this.modalContent       =   `${this.apiErrorMessage}   \n \n MENSAJE DE ERROR:  ${errorMessage}`
                    this.respuestaExitosa   =   false;
                }

                //si todo salio bien
                if (response.ok) 
                {
                    this.showModal          =   true;
                    this.modalTitle         =   'Proceso Exitoso';
                    this.modalContent       =   this.apiVacancieRejected;
                    this.respuestaExitosa   =   true;
                    this.actionOnSuccess    =   'vacancieRejected'
                }

            }
            catch (error) 
            {
                this.isFetching         =       false;
                this.showModal          =       true;
                this.modalTitle         =       'ERROR';
                this.modalContent       =       `${this.serverErrorMessage} \n \n MENSAJE DE ERROR: ${error.message}. `;
                this.respuestaExitosa   =       false;
            }
        },


        handleInasistencia() 
        {
            if (this.inasistenciaConfirm) 
            {
                this.marcarInasistencia();
            } 
            else 
            {
                this.inasistenciaText = 'Toque de nuevo para confirmar';
                this.inasistenciaConfirm = true;
            }
        },


        async marcarInasistencia() 
        {
            this.isFetching         =   true;
            try
            {
                const token = localStorage.getItem('token');
                const decodedToken = jwt_decode(token);

                // se obtiene el id del alumno de la store
                const idStudent = this.$store.getters.getterStudentId;

                // se obtiene el id de la vacante del store
                const idVacancie = this.$store.getters.getterVacancieId;

                await new Promise(resolve => setTimeout(resolve, 3000));

                const response = await fetch(process.env.VUE_APP_COMPANY_ABSCENSE_DAY, {
                    method: 'POST',
                    headers: 
                    {
                        'Content-Type'  : 'application/json',
                        'Authorization' : `Bearer ${token}`,
                    },
                    body: JSON.stringify
                    ({
                        idVacancie: idVacancie,
                        idStudent: idStudent,
                        
                    }),
                });

                this.isFetching = false;

                //ha ocurrido algun tipo de error
                if (!response.ok) 
                {
                    const errorMessage = await response.text();
                    this.showModal          =   true;
                    this.modalTitle         =   'Ha Ocurrido un Error';
                    this.modalContent       =   `${this.apiErrorMessage}   \n \n MENSAJE DE ERROR:  ${errorMessage}`
                    this.respuestaExitosa   =   false;
                }

                //si todo salio bien
                if (response.ok) 
                {
                    this.showModal          =   true;
                    this.modalTitle         =   'Proceso Exitoso';
                    this.modalContent       =   this.apiVacancieRejected;
                    this.respuestaExitosa   =   true;
                    this.actionOnSuccess    =   'vacancieRejected'
                }
            }
            catch (error) 
            {
                this.isFetching         =       false;
                this.showModal          =       true;
                this.modalTitle         =       'ERROR';
                this.modalContent       =       `${this.serverErrorMessage} \n \n MENSAJE DE ERROR: ${error.message}. `;
                this.respuestaExitosa   =       false;
            }
        },



        handleAccept()
        {
            this.$router.push({ name: 'empresa-inicio' });
        }

    },


    
};
</script>