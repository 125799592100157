<template>
    <!-- material theming -->
    <div :class="['material-you', mainTheme]">
        <HeaderComponent />


        <!-- main menu -->
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>


        <!-- tooltips -->
        <article class="message">
            <MessageComponent title="Información de la vacante" :message="descripcion" />
        </article>


        <!-- vacancie info -->
        <div class="card-perfil">
            <CardComponent>
                <template v-slot:content>
                    <div class="media-content-info">
                        <div class="content-postulados">
                            <p style="text-align: left;">Información de la Vacante</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em;">
                                <span class="tag is-info-custom">Empresa Responsable</span> {{ vacancieDetails.vacancieInfo.company_name }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Nombre de la Vacante</span> {{ vacancieDetails.vacancieInfo.name }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Creada en </span>
                                {{ new Date(vacancieDetails.vacancieInfo.creation_log).toLocaleDateString('es-MX', {
                                    weekday: 'long',
                                    day: 'numeric', month: 'long', year: 'numeric'
                                }) }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                <span class="tag is-info-custom">Descripción</span> {{ vacancieDetails.vacancieInfo.description }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                <span class="tag is-info-custom">Disponibilidad</span> {{
                                    vacancieDetails.vacancieInfo.filled_hiring }} /
                                {{ vacancieDetails.vacancieInfo.w_hiring_capacity }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Dirección</span> {{ vacancieDetails.vacancieInfo.address }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Salario o Apoyo Económico</span>
                                {{ vacancieDetails.vacancieInfo.salary !== null ? '$' +
                                    vacancieDetails.vacancieInfo.salary : 'No aplica' }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Notas Adicionales</span> {{ vacancieDetails.vacancieInfo.notes }}</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Estado</span> {{ vacancieDetails.vacancieInfo.state_name }}</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Municipio</span> {{ vacancieDetails.vacancieInfo.muni_name }}</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Sector</span> {{ vacancieDetails.vacancieInfo.sector_name }}</p>
                        </div>
                    </div>
                </template>
            </CardComponent>
        </div>


    <!-- footer -->
    <FooterComponent />
    </div>
</template>





<style scoped src   =  "../styles/material/theme.light.css"            ></style> 
<style scoped src   =  "../styles/material/theme.dark.css"             ></style> 
<style scoped src   =  "../styles/views/VacantesListadoRevision.css"   ></style>
<style scoped src   =  "../styles/views/MaterialTheming.css"           ></style>

<script>
import CardComponent            from        '../components/CardComponent.vue';
import FooterComponent          from        '../components/FooterComponent.vue';
import HeaderComponent          from        '../components/HeaderComponent.vue';
import MenuComponent            from        '../components/MenuComponent.vue';
import MessageComponent         from        '../components/MessageComponent.vue';
import TableComponent           from        '../components/TableComponent.vue';

import { loadVacancie }    from        '../api/ApiService';



export default
{
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        TableComponent,
    },



    async created() 
    {   
        try 
        {
            const data              =   await loadVacancie(this.$store);
            this.vacancieDetails    =   data;
            console.log("DATOS DE LA API:", data);
        } 
        catch (error) 
        {
            console.error('Error loading vacancie:', error);
        }
    },



    data() 
    {
        return  {
                    vacancieDetails     :       null,

                    activeTab           :       'tab1',
                    mainTheme           :       'light',

                    descripcion         :       `Aquí podrás visualizar detalladamente la información de la vacante que has publicado recientemente.
                                                Te pedimos paciencia mientras nuestro equipo de profesionales realiza una revisión exhaustiva de la
                                                información proporcionada.Nuestro objetivo es asegurar que la vacante cumple con todos los requisitos
                                                y estándares de nuestra respetada institución.Agradecemos tu comprensión y cooperación durante este proceso.
                                                Te mantendremos informado sobre cualquier actualización
                                                o cambio en el estado de tu publicación. Gracias por elegir nuestra plataforma para tus necesidades de contratación`,
                }
        },



    methods: 
    {
        handleThemeChange(e) 
        {
            this.mainTheme = e.matches ? 'dark' : 'light';
            console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
        }   
    },



    mounted() 
    {
        this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        this.mediaQuery.addEventListener('change', this.handleThemeChange);
        this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
    },



    beforeUnmount() 
    {
        if (this.mediaQuery) 
        {
            this.mediaQuery.removeEventListener('change', this.handleThemeChange);
        }
    },

}

</script>