import jwt_decode from 'jsonwebtoken/decode';

//INICCIO DE SESION
export async function loginApi(mail, password) 
{
  await new Promise(resolve => setTimeout(resolve, 3000)); // Simulando un retraso

  const response = await fetch(process.env.VUE_APP_API_COMPANY_POST_SIGNIN, 
  {
    method: 'POST',
    headers: 
    {
      'Content-Type'  : 'application/json',
    },
    body: JSON.stringify({ mail, password }),
  });

  if (!response.ok) 
  {
    const error   =   new Error('Error en la solicitud');
    error.status  =   response.status;
    throw error;
  }

  const responseData    =   await response.json();
  const token           =   responseData.token;
  const decodedToken    =   jwt_decode(token);
  const id_company      =   decodedToken.userCompanyId;
  const email_company   =   decodedToken.email;
  const rol_company     =   decodedToken.rol_company;

  return {
    token,
    id_company,
    email_company,
    rol_company,
    message: responseData.message,
  };
};



//HISTORIAL DE NOTIFICACIONES
export async function loadCompanyNotificationsApi() 
{
  const token         =   localStorage.getItem('token');
  const decodedToken  =   jwt_decode(token);
  const idCompany     =   decodedToken.userCompanyId;

  const response = await fetch(process.env.VUE_APP_API_COMPANY_POST_HISTORY, {
    method: 'POST',
    headers: 
    {
      'Content-Type'  : 'application/json',
      'Authorization' : `Bearer ${token}`,
    },
    body: JSON.stringify({ idCompany }),
  });

  if (!response.ok) 
  {
    throw new Error('Failed to fetch notifications');
  }

  const data = await response.json();
  return data.historyList.map(item => item.notification_message);
}



//ENVIA TOKEN DE NOTIFICATION
export async function sendNotiTokenApi(notiToken) 
{
  const apiUrl        =   process.env.VUE_APP_API_COMPANY_POST_UPDATENOTI;
  const token         =   localStorage.getItem('token');
  const decodedToken  =   jwt_decode(token);
  const id            =   decodedToken.userCompanyId;

  const response = await fetch(apiUrl, 
  {
    method: 'POST',
    headers: 
    {
      'Content-Type'  : 'application/json',
      'Authorization' : `Bearer ${token}`,
    },
    body: JSON.stringify({ token: notiToken, id: id }),
  });

  if (!response.ok) 
  {
    throw new Error('Error enviando el token de notificación');
  }

  console.log('ID DEL STAFF:', decodedToken.userStaffId);
};



//ESTADOS DE MEXICO
export async function loadEstados() 
{
  try 
  {
    const response = await fetch(process.env.VUE_APP_API_COMPANY_GET_LOADSTATES);

    if (!response.ok) 
    {
      throw new Error('Error al cargar estados');
    }

    const data = await response.json();
    return data.state_mexico;
  } 
  catch (error) 
  {
    console.error('Error al cargar estados:', error);
    throw error;
  }
};



//SECTORES DE NEGOCIO
export async function loadSectors() 
{
  try 
  {
    const response = await fetch(process.env.VUE_APP_API_COMPANY_GET_LOADSECTORS);

    if (!response.ok) 
    {
      throw new Error('Error al cargar sectores');
    }

    const data = await response.json();
    return data.sectors;
  } 
  catch (error) 
  {
    console.error('Error al cargar sectores:', error);
    throw error;
  }
};



//BOARDS TIPOS DE VACANTES
export async function loadBoards() 
{
  try 
  {
    const response = await fetch(process.env.VUE_APP_API_COMPANY_GET_LOADBOARDS);

    if (!response.ok) 
    {
      throw new Error('Error al cargar estados');
    }

    const data = await response.json();
    return data.boards;
  } 
  catch (error) 
  {
    console.error('Error al cargar estados:', error);
    throw error;
  }
};



//MUNICIPIOS DE ESTADOS DE MEXICO
export async function loadMunicipios(estadoSeleccionado) 
{
  try 
  {
    const response = await fetch(process.env.VUE_APP_API_COMPANY_POST_LOADMUNI, 
      {
        method: 'POST',
        headers: 
        {
          'Content-Type'  : 'application/json',
        },
        body: JSON.stringify
        ({
          states  : estadoSeleccionado,
        }),
      });

    if (!response.ok) 
    {
      throw new Error('Error al enviar datos y obtener municipios');
    }

    const data = await response.json();
    return data.muni_mexico;
  } 
  catch (error) 
  {
    console.error('Error al enviar datos y obtener municipios:', error);
    throw error;
  }
};



//INFORMACION DE UNA VACANTE
export async function loadVacancie(store) 
{
    try 
    {
        const vacancie = store.getters.getterVacancieId;
        const token = localStorage.getItem('token');
        const decodedToken = jwt_decode(token);
        const id_company = decodedToken.userCompanyId;

      const response = await fetch(process.env.VUE_APP_COMPANY_POST_VACANCIE_INFO, 
        {
          method: 'POST',
          headers: 
          {
            'Content-Type'  : 'application/json',
            'Authorization' : `Bearer ${token}`,
          },
          body: JSON.stringify({ id_vacancie: vacancie, id_company: id_company }),
        });

      if (!response.ok) 
      {
        throw new Error('Error al cargar la vacante');
      }

      const data = await response.json();
      return data;
    } 
    catch (error) 
    {
      console.log(error);
      throw error;
    }
};



//SOLICITUDES RECIBIDAS DE VACANTE
export async function loadRequests(store) 
{
    try 
    {
      const spaces        =   " ";
      const vacancie      =   store.getters.getterVacancieId;
      const token         =   localStorage.getItem('token');
      const idVacancie    =   vacancie;
      const decodedToken  =   jwt_decode(token);
      const idCompany     =   decodedToken.userCompanyId;
  
      const response = await fetch(process.env.VUE_APP_COMPANY_POST_REQUESTLISTBYVACANCIE, 
        {
          method: 'POST',
          headers: 
          {
            'Content-Type'  : 'application/json',
            'Authorization' : `Bearer ${token}`,
          },
          body: JSON.stringify({ idVacancie: idVacancie, idCompany: idCompany }),
        });
  
      if (!response.ok) 
      {
        throw new Error('Error al cargar la vacante');
      }
  
      const data = await response.json();
      return data.list.map(item => ({
        id: item.id,
        vacancieId: item.id_vacancie,
        nombre: item.last_name_A + spaces + item.last_name_B + spaces + item.name,
        actividad: item.vacancie_name,
        fecha: item.creation_log.split('T')[0],
        idStudent: item.id_student,

        //COLUMNA SOLICITUD 
        requestState: 
        item.request_state === 1 ? 'Sin revisar' :
        item.request_state === 2 ? 'Rechazaste la solicitud del alumno.' :
        item.request_state === 3 ? 'Aceptaste la solicitud del alumno.' : 'Desconocido',

        //COLUMNA RESPUESTA
        accepted_date: 
        //#1 el alumono fue aceptado y se espera que confirme
        item.accepted_date === null && item.request_state === 3 && item.presence !== 4 ? 'En espera de confirmación del alumno' :

        //#2 el alumno fue aceptado y este confirmo su asistencia
        item.accepted_date !== null && item.request_state === 3 && item.presence !== 4 ? 'El alumno confirmó su asistencia.' :

        //#3 el alumono fue aceptado y este rechazo ir
        item.presence === 4 && item.request_state === 3 ? 'El alumno rechazó su asistencia.' :

        //#4 Aun no se ha revisado la solicitud del alumno
        item.request_state === 1 ? 'No aplica' :

        //#5 Se rechazo la soliitud del alumno
        item.request_state === 2 ? 'No aplica' :

        item.presence === 1 && item.request_state === 2 ? 'No aplica' : '',

        //COLUMNA PRIMERA ASISTENCIA
        presenseState: 
        //#1 el alumono fue aceptado y se espera que confirme
        item.request_state === 3 && item.presence === 1 && item.accepted_date === null ? 'En espera' :

        //#2 el alumno fue aceptado y este confirmo su asistencia
        item.request_state === 3 && item.presence === 1 && item.accepted_date !== null ? 'En espera' :

        //#3 el alumono fue aceptado y este rechazo ir
        item.presence === 4  ? 'No aplica' :

        //#4 Aun no se ha revisado la solicitud del alumno
        item.request_state === 1 ? 'No aplica' :

        //#5 Se rechazo la soliitud del alumno
        item.request_state === 2 ? 'No aplica' :

        item.presence === 1 && item.accepted_date === null ? 'En espera' :

        item.presence === 2 ? 'No se Presento' :
        item.presence === 3 ? 'Primera Asistencia Registrada' : 'Desconocido',

        //DETALLES BOTONES DE ACCION
        detalles: item.request_state === 1 ? 'revisar' :

        //#1 el alumono fue aceptado y se espera que confirme
        item.request_state === 3 && item.presence === 1 && item.accepted_date === null ? '' :
         //#2 el alumno fue aceptado y este confirmo su asistencia
         //xx
        item.request_state === 3 && item.presence === 1 && item.accepted_date !== null ? 'x' : null
      }));
    } 
    catch (error) 
    {
      console.log(error);
      throw error;
    }
};



//6 INFO DEL ESTUDIANTE
export async function loadStudent(store) 
{
  try 
  {
    const idStudent   =   store.getters.getterStudentId;
    const token       =   localStorage.getItem('token');
    const response    =   await fetch(process.env.VUE_APP_COMPANY_POST_STUDENT_INFO, 
      {
        method: 'POST',
        headers: 
        {
          'Content-Type'  : 'application/json',
          'Authorization' : `Bearer ${token}`,
        },
        body: JSON.stringify
        ({
          idStudent: idStudent,
        }),
      });

    if (!response.ok) 
    {
      throw new Error('Error loading profile');
    }

    const data = await response.json();
    return data.info;
  } 
  catch (error) 
  {
    console.error('Server Error:', error);
    throw error;
  }
};



//LISTADO DE REQUEST
export async function loadRequestsList(token) 
{
  const decodedToken  =   jwt_decode(token);
  const id_company    =   decodedToken.userCompanyId;

  try 
  {
    const response = await fetch(process.env.VUE_APP_API_COMPANY_POST_LOADLISTINFO, 
    {
      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({ id_company }),
    });

    if (!response.ok) 
    {
      throw new Error('Error loading vacancies');
    }

    const data = await response.json();

    // Función para mapear el estado de las vacantes
    const mapVacancyState = (item) => ({
      id: item.id,
      nombre: item.name,
      tipo: item.vacancie_type,
      fecha: item.creation_log.split('T')[0],
      vState: (() => {
        switch (item.status_option) {
          case 2: return 'Rechazada';
          case 3: return 'Creada';
          case 4: return 'Abierta';
          case 5: return 'Cerrada';
          case 6: return 'Terminada';
          default: return 'Desconocido';
        }
      })(),
    });

    return  {
              tab1: data.list.filter(item => item.status_option === 3).map(mapVacancyState),
              tab2: data.list.filter(item => item.status_option === 2).map(mapVacancyState),
              tab3: data.list.filter(item => item.status_option === 4).map(mapVacancyState),
              tab4: data.list.filter(item => item.status_option === 5).map(mapVacancyState),
              tab5: data.list.filter(item => item.status_option === 6).map(mapVacancyState),
            };
  } 
  catch (error) 
  {
    console.error('Error in loadRequests:', error);
    throw error;
  }
};



//ACEPTAR REQUEST
export async function acceptRequest(store) 
{
  try 
  {
    const vacancie    =   store.getters.getterVacancieId;
    const token       =   localStorage.getItem('token');
    const idVacancie  =   vacancie;
    const idStudent   =   store.getters.getterStudentId;

    const decodedToken  =   jwt_decode(token);
    const id_company    =   decodedToken.userCompanyId;

    await new Promise(resolve => setTimeout(resolve, 4000));
    const response = await fetch(process.env.VUE_APP_API_COMPANY_POST_ACEEPT_REQUESTS, 
    {
      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({ idVacancie, idStudent }),
    });

    if (!response.ok) 
    {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }

    return { success: true };
  } 
  catch (error) 
  {
    throw error;
  }
}

