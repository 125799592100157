<template>

    <!-- material theming -->
    <div :class="['material-you', mainTheme]">


        <!-- header -->
        <HeaderComponent />


        <!-- main menu -->
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>


        <!-- tooltips -->
        <article class="message">
            <MessageComponent title="Detalles" :message="descripcion" />
        </article>


        <!-- vacancie info -->
        <div class="card-perfil">
            <CardComponent>
                <template v-slot:content>
                    <div class="media-content-info">
                        <div class="content-postulados">
                            <p style="text-align: left;">Información de la Vacante</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em;">
                                <span class="tag is-info-custom">Empresa Responsable</span> {{
                                vacancieDetails.company_name }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Nombre de la Vacante</span> {{ vacancieDetails.name }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Creada en </span>
                                {{ new Date(vacancieDetails.creation_log).toLocaleDateString('es-MX', {
                                weekday: 'long',
                                day: 'numeric', month: 'long', year: 'numeric'
                                }) }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                <span class="tag is-info-custom">Descripción</span> {{ vacancieDetails.description }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Dirección</span> {{ vacancieDetails.address }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Salario</span>
                                {{ vacancieDetails.salary !== null ? '$' + vacancieDetails.salary : 'No aplica' }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Notas Adicionales</span> {{ vacancieDetails.notes }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Estado</span> {{ vacancieDetails.state_name }}</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Municipio</span> {{ vacancieDetails.muni_name }}</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Sector</span> {{ vacancieDetails.sector_name }}</p>
                        </div>
                    </div>
                </template>

                <!-- actions buttons -->
                <template v-slot:buttons>
                    <!-- Botón Aceptar -->
                    <md-elevated-button @click="handleClick">
                        {{ buttonText }}
                    </md-elevated-button>

                    <!-- Botón Rechazar -->
                    <md-elevated-button @click="handleReject">
                        Rechazar
                    </md-elevated-button>
                </template>
            </CardComponent>
        </div>


        <!-- footer -->
        <FooterComponent />


        <!-- modal para el final de los procesos de aceptar o rechazar -->
        <Transition name="bounce">
            <ModalComponent v-if="showModal" :title="modalTitle" :modalContent="modalContent" class="modalR">
                <template v-slot:buttons>
                    <md-elevated-button v-if="respuestaExitosa" @click="handleAccept">
                        Aceptar
                    </md-elevated-button>
                    <md-elevated-button v-if="!respuestaExitosa" @click="handleError">
                        Volver
                    </md-elevated-button>
                </template>
            </ModalComponent>
        </Transition>


        <!-- modal para poder rechazar la vacante o nope -->
         <div class="modalRechazo">
        <Transition name="bounce">
            <ModalComponent v-if="showModalReject" :title="modalTitle" @close="closeModal" class="modalR">
                <template v-slot:content>
                    <md-filled-text-field ref="motivoRechazoRef" type="textarea" label="Motivo de Rechazo"
                        v-model="motivoRechazo" rows="5" maxlength="500"
                        style="width: 100%; height: 100%; "></md-filled-text-field>
                </template>
                <template v-slot:buttons>
                    <md-elevated-button @click="rejectVacancieApi" :disabled="!motivoRechazo.trim()">
                        Aceptar
                    </md-elevated-button>
                    <md-elevated-button @click="closeModal">
                        Volver
                    </md-elevated-button>
                </template>
            </ModalComponent>
        </Transition> 
    </div>

 
        <!-- modal completo solo para motivos de una mejor UX -->
        <Transition name="bounce">
            <div v-if="isFetching" class="modal is-active" style="z-index: 1001;" >
                <div class="modal-background"></div>
                <div class="modal-card">
                    <section class="modal-card-body">
                        <div> Validando Datos.. </div>
                        <md-circular-progress four-color indeterminate></md-circular-progress>
                    </section>
                </div>
                <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
            </div>
        </Transition>

    </div>
</template>




<style scoped src   =   "../styles/material/theme.light.css"></style> 
<style scoped src   =   "../styles/material/theme.dark.css"></style> 

<style scoped src   =   "../styles/views/EmpresasMenuValidacionesSolicitud.css"></style>
<style scoped src   =   "../styles/views/MaterialTheming.css"></style>

<script>
import CardComponent        from    '../components/CardComponent.vue';
import FooterComponent      from    '../components/FooterComponent.vue';
import HeaderComponent      from    '../components/HeaderComponent.vue';
import MenuComponent        from    '../components/MenuComponent.vue';
import MessageComponent     from    '../components/MessageComponent.vue';
import ModalComponent       from    '../components/ModalComponent.vue';

import { loadVacancie, rejectVacancieApi, sendAcceptApi }     from    '../api/ApiService';



export default
{
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        ModalComponent,
    },



    async created() 
    {
        try 
        {
            this.vacancieDetails = await loadVacancie(this.$store);
        } 
        catch (error) 
        {
            console.error('Server Error:', error);
        }
    },



    data() 
    {
        return  {
            mainTheme: 'light',
                    actionOnSuccess     :   '',
                    buttonText          :   'Aceptar',
                    modalContent        :   '',
                    modalTitle          :   '',
                    motivoRechazo       :   '',
                    showModalReject     :   '',

                    isClicked           :   false,
                    isFetching          :   false,
                    respuestaExitosa    :   false,
                    showModal           :   false,
                    vacancieDetails     :   null,

                    descripcion         :   `En este módulo, te instamos a realizar una revisión minuciosa de la Unidad Receptora  para asegurarte de que se ajuste a los protocolos
                                            establecidos por nuestra institución. Te recomendamos examinar cada detalle con atención. En el caso de que la vacante no cumpla con dichos
                                            protocolos, es necesario rechazarla y proporcionar una explicación clara que respalde esta decisión. Ten en cuenta que una vez que se tome
                                            la acción de aceptar o rechazar, dicha decisión será final y no podrá revertirse. Agradecemos tu dedicación y atención a este proceso.`,

                    serverErrorMessage  :   `No ha sido posible establecer conexión con el servidor. Por favor, verifique su conexión a Internet y asegúrese de que los datos ingresados sean correctos.
                                            Si el problema persiste, le recomendamos que consulte con su administrador.`,

                    apiErrorMessage     :   `Se ha producido un error. Por favor, revise cuidadosamente la información ingresada e intente nuevamente. En caso de que el problema persista,
                                            le recomendamos que se comunique con su administrador para obtener asistencia adicional.`,

                    apiVacancieRejected :   `La solicitud de la vacante ha sido rechazada de manera satisfactoria. Se ha notificado al responsable correspondiente para que pueda 
                                            realizar las ediciones necesarias. Agradecemos su colaboración.`,

                    apiVacancieAccepted :   `La solicitud de vacante ha sido aceptada de forma satisfactoria. No es necesario que realices ninguna acción adicional. Agradecemos tu colaboración.`
                };
    },



    methods:
    {
        handleThemeChange(e) {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },
        closeModal()
        {
            this.showModal          =       false;
            this.showModalReject    =       false;
        },


        handleClick() 
        {
            if (!this.isClicked) 
            {
                this.buttonText = 'Haz clic de nuevo para confirmar';
                this.isClicked = true;
            } 
            else 
            {
                this.handleAccept();
                this.isFetching = true;
                this.acceptVacancie();
            }
        },


        handleAccept() 
        {
            if (this.actionOnSuccess === 'vacancieAccepted') 
            {
                this.$router.push({ name: 'personal-inicio' });
            }

            else if (this.actionOnSuccess === 'vacancieRejected') 
            {
                this.$router.push({ name: 'personal-inicio' });
            }

            this.showModal = false;
        },


        handleReject() 
        {
            this.showModalReject = true;
            this.modalTitle = 'Rechazo de la vacante';

            this.$nextTick(() => 
            {
                this.$refs.motivoRechazoRef.focus();
            });
        },


        async acceptVacancie() 
        {
            this.isFetching = true;
            try 
            {
                const result        =   await sendAcceptApi(this.$store);
                this.isFetching     =   false;
                
                this.showModal          =   result.showModal;
                this.modalTitle         =   result.modalTitle;
                this.modalContent       =   result.modalContent;
                this.respuestaExitosa   =   result.respuestaExitosa;

                if (result.actionOnSuccess) 
                {
                this.actionOnSuccess = result.actionOnSuccess;
                }

            } catch (error) 
            {
                this.isFetching         =   false;
                this.showModal          =   true;
                this.modalTitle         =   'ERROR';
                this.modalContent       =   `Error del servidor: ${error.message}`;
                this.respuestaExitosa   =   false;
            }
        },


        async rejectVacancieApi() 
        {
            this.showModalReject    =   false;
            this.isFetching         =   true;

            try 
            {
                const result = await rejectVacancieApi(this.$store, this.motivoRechazo);
                this.isFetching = false;

                this.showModal          =   result.showModal;
                this.modalTitle         =   result.modalTitle;
                this.modalContent       =   result.modalContent;
                this.respuestaExitosa   =   result.respuestaExitosa;

                if (result.actionOnSuccess) 
                {
                    this.actionOnSuccess = result.actionOnSuccess;
                }
            } 
            catch (error) 
            {
                this.isFetching         =   false;
                this.showModal          =   true;
                this.modalTitle         =   'ERROR';
                this.modalContent       =   `Error del servidor: ${error.message}`;
                this.respuestaExitosa   =   false;
            }
        },


        async acceptVacancie() 
        {
            this.isFetching = true;
            try 
            {
                const result        =   await sendAcceptApi(this.$store, this.apiErrorMessage, this.apiVacancieAccepted);
                this.isFetching     =   false;
                
                this.showModal          =   result.showModal;
                this.modalTitle         =   result.modalTitle;
                this.modalContent       =   result.modalContent;
                this.respuestaExitosa   =   result.respuestaExitosa;

                if (result.actionOnSuccess) 
                {
                    this.actionOnSuccess = result.actionOnSuccess;
                }
            } 
            catch (error) 
            {
                this.isFetching         =   false;
                this.showModal          =   true;
                this.modalTitle         =   'ERROR';
                this.modalContent       =   `Error del servidor: ${error.message}`;
                this.respuestaExitosa   =   false;
            }
        },


        handleError()
        {
            this.showModal          =   false;
            this.showModalReject    =   false;
        },

    },

    mounted() {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },


  beforeUnmount() {
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },

}
</script>