<template>

  <form @submit.prevent="submitForm">
    <div class="inputs" v-for="(field, index) in formFields" :key="index">

      <div v-if="field.type === 'select'">
        <md-filled-select :label="field.label" v-model="formData[field.name]" required @change="handleSelectChange">
          <md-select-option v-for="option in field.options" :key="option.value" :value="option.value"
            :selected="option.selected">
            <div slot="headline">{{ option.label }}</div>
          </md-select-option>
        </md-filled-select>
      </div>

      <div v-else-if="field.type === 'files'" class="file is-centered is-boxed has-name">
        <label class="file-label">
          <input class="file-input" type="file" @change="handleFileChange" :name="field.name" required>
          <span class="file-cta is-blur">
            <span class="file-icon">
              <span class="material-symbols-outlined">attach_file</span>
            </span>
            <span class="file-label ">{{ selectedFileName || 'Subir archivo' }}
              <!-- Aquí mostraremos el nombre del archivo seleccionado -->
            </span>
          </span>
        </label>
      </div>

      <div v-else>
        <Transition name="bounce">
          <md-filled-text-field
            v-if="field.name !== 'salary' || (field.name === 'salary' && formData.ofrecerSueldo === 'si') || formData.salary > 0"
            :label="field.label" :type="field.type" :maxlength="field.maxlength" :supporting-text="field.supportingText"
            v-model="formData[field.name]" required></md-filled-text-field>
        </Transition>
      </div>

    </div>

    <md-elevated-button type="submit">Enviar</md-elevated-button>

  </form>

  <Transition name="bounce">
    <ModalComponent v-if="showModal" :title="modalTitle" :modalContent="modalContent">
      <template v-slot:buttons>
        <md-elevated-button v-if="respuestaExitosa" @click="handleAccept">
          Aceptar
        </md-elevated-button>
        <md-elevated-button v-if="!respuestaExitosa" @click="errorButton">
          Aceptar
        </md-elevated-button>
      </template>
    </ModalComponent>
  </Transition>

  <Transition name="bounce">
    <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-body">
          <div> Enviando los datos... </div>
          <md-circular-progress four-color indeterminate></md-circular-progress>
        </section>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
    </div>
  </Transition>

</template>







<style scoped src="../styles/components/FormComponent.css"></style>

<script>
import jwt_decode         from    'jsonwebtoken/decode';
import ModalComponent     from    '../components/ModalComponent.vue';

export default
{
  emits: ['data-loaded'],


  components: 
  {
    ModalComponent,
  },

  props:
  {
    show      : Boolean,
    formFields:
    {
        type      : Array,
        required  : true,
        formId    : String,
    },

    apiUrl: 
    {
        type      : String,
        required  : true,
    },
  },



    data()
    {
        const token         =   localStorage.getItem('token');
        const decodedToken  =   jwt_decode(token);
        //const id_student    =   decodedToken.id_student;
        
      return  {
                  actionOnSuccess     :   '',
                  formData            :   {},
                  id_student          : decodedToken.id_student,
                  isFetching          :   false,
                  modalContent        :   '',
                  modalTitle          :   '',
                  respuestaExitosa    :   false,
                  showModal           :   false,

                  // mensajes
                  errorEditProfile    :   `ha ocurrido un error asegurate de haber llenado los datos de forma correcta`,
                  successEditProfile  :   `Perfil modificado de forma correcta`,
                  errorServer         :   `No fue posible enviar los datos al servidor, compruebe su conexión a Internet e inténtelo de nuevo, 
                                          si el problema persiste contacte con su administrador.`,
              }
        
    },



  watch: 
  {
    formFields(newValue) 
    {
      this.formData = newValue.reduce((obj, field) => ({ ...obj, [field.name]: field.value || '' }), {id_student : this.id_student  });
    }
  },



  mounted() 
  {
    console.log('id_student:',this.id_student);

    // Verificar que formFields esté definido antes de utilizarlo
    if (this.formFields) 
    {
      this.formData = this.formFields.reduce((obj, field) => ({ ...obj, [field.name]: field.value || '' }), {id_student : this.id_student});
    } 
    else 
    {
        console.error('formFields is undefined');
    }
    console.log('formData antes de enviar:', this.formData);
  },



  methods :
  {
    async sendDataToApi()
    {
      if(this.apiUrl === process.env.VUE_APP_API_STUDENT_POST_EDITPROFILE)
      {
        try
        {
          const token         =   localStorage.getItem('token');
          this.isFetching     =   true;

          await new Promise(resolve => setTimeout(resolve, 3000));

          const response = await fetch(this.apiUrl, 
          {
              method  : 'POST',
              headers: 
              {
                  'Content-Type'  : 'application/json',
                  'Authorization' : `Bearer ${token}`,
              },
              body    : JSON.stringify(this.formData),
          });

          console.log('Código de Estado:', response.status);
          this.isFetching = false;

          if (!response.ok) 
          {
              this.showModal          =   true;
              this.modalTitle         =   'Ha Ocurrido un Error';
              this.modalContent       =   this.errorEditProfile;
              this.respuestaExitosa   =   false;
          }

          const data = await response.json();
          console.log('Respuesta de la API:', data);

          if (response.ok) 
          {
              this.showModal          =   true;
              this.modalTitle         =   'Proceso Exitoso';
              this.modalContent       =   this.successEditProfile;
              this.respuestaExitosa   =   true;
              this.actionOnSuccess    =   'action1';
          } 
        }
        catch (error) 
        {
          this.isFetching = false;
          console.error('Error al enviar datos a la API:', error);

          if (this.apiUrl === process.env.VUE_APP_API_STUDENT_POST_EDITPROFILE) 
          {
              console.error('Error en el catch general:', error);
              this.showModal = true;
              this.modalTitle = 'ERROR';
              this.modalContent = this.errorServer;
          } 

        }

      }

    },


    submitForm() 
    {
        console.log('Datos del formulario:', this.formData);
        console.log(this.apiUrl	)
        this.sendDataToApi(); 
    },


    errorButton() 
    {
      this.showModal = false;
    },


    handleAccept() 
    {
      if (this.actionOnSuccess === 'action1') 
      {
        this.$router.push({ name: 'perfil-estudiante' });
      }

      else if (this.actionOnSuccess === 'action2') 
      {
        console.log('Realizando acciones para action2');
      }
      this.showModal = false;
    },

  }

}
</script>