<template>
  <div class="modal is-active" style="z-index: 1001;">
    <div :class="[mainTheme]">
    <div class="modal-background"></div>
    <div class="modal-card">

      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>

      <section class="modal-card-body">
        <p style="text-align: start;">
          {{ modalContent }}
        </p>
        <slot name="content"></slot>
      </section>

      <footer class="modal-card-foot" style="justify-content: center;">
        <slot name="buttons"></slot>
      </footer>

    </div>
  </div>
</div>
</template>







<style scoped src="../styles/components/ModalComponent.css"></style>
<style scoped src = "../styles/material/theme.light.css"    ></style> 
<style scoped src = "../styles/material/theme.dark.css"     ></style> 
<script>
export default 
{
  props: 
  {
    show          :   Boolean,
    title         :   String,
    modalContent  :   String,
  },



  data() 
  {
    return  {
              isActive: this.show,
              mainTheme: 'light',
            };
  },


  mounted() 
  {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery);
  },



  methods: 
  {
    openPopup()
    {
      // Utiliza $nextTick para retrasar la apertura del modal hasta después de la actualización del DOM
      this.$nextTick(() => { this.isActive = true; });
    },


    closeModal() 
    {
      console.log("Closing modal");
      this.$emit("update:show", false); // Emitir evento para actualizar la propiedad show en FormComponent
    },

    handleThemeChange(e) 
    {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },


    beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },
    
  },

};
</script>