<template>
  <div class="material-you">
    <HeaderComponent />
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>
    <div class="informacion">
      <CardComponent>
        <template v-slot:title>
          <h2 class="title">Información de Contacto</h2>
        </template>
        <template v-slot:content>
          <div class="card-anuncio">
            <div class="card-contentAnuncio">
              <div class=" anuncio">
                <p><strong>Si tienes algún problema en tu procedimiento, no dudes en comunicarte con nosotros.</strong>
                </p>
                <p><strong>Teléfono 1:</strong> +1 (123) 456-7890</p>
                <p><strong>Teléfono 2:</strong> +1 (987) 654-3210</p>
                <p><strong>Domicilio:</strong> 123 Calle Principal, Ciudad</p>
                <p><strong>Correo Electrónico:</strong> ejemplo@correo.com</p>
              </div>
            </div>
          </div>
        </template>
      </CardComponent>
    </div>
    <FooterComponent />
  </div>
</template>



<style scoped src="../styles/views/MaterialTheming.css"></style>
<style scoped src="../styles/views/InfoContacto.css"></style>

<script>
import CardComponent        from    '../components/CardComponent.vue';
import FooterComponent      from    '../components/FooterComponent.vue';
import HeaderComponent      from    '../components/HeaderComponent.vue';
import MenuComponent        from    '../components/MenuComponent.vue';

export default
  {
    name: 'InfoContacto',
    components:
    {
      CardComponent,
      FooterComponent,
      HeaderComponent,
      MenuComponent,
    },
  }
</script>