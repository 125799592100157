<template>
  <div class="card gradient-background">
    <div class="card-title">
      <slot name="title"></slot>
    </div>
    <div class="separador"></div>
    <div class="card-content">
      <slot name="content"></slot>
    </div>
    <div class="card-buttons">
      <slot name="buttons"> </slot>
    </div>
  </div>
</template>







<style scoped src="../styles/components/CardComponent.css"></style>
<script>
export default {};
</script>




