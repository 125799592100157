<template>
    <div :class="['material-you', mainTheme]">
        <HeaderComponent />
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>
        <article class="message">
            <MessageComponent title="Solicitud" :message="descripcion" />
        </article>
        <div class="navbar-center">
            <div class="navbar-item">
                <div class="notiBotton">
                    <md-elevated-button class="menu-button" @click="toggleMenu">
                        Información de la Vacante
                    </md-elevated-button>
                </div>
            </div>
        </div>
        <Transition :duration="550" name="nested">
            <div class="detailsVacancie" v-if="mostrarLista">
                <div class="card-perfil">
                    <CardComponent>
                        <!-- Slot para el título -->
                        <template v-slot:title>
                        </template>
                        <template v-slot:content>
                            <div class="media-content-info">
                                <div class="content-postulados">
                                    <p style="text-align: left;">Información de la Vacante</p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em;"> <span
                                            class="tag is-info-custom">Empresa Responsable</span> {{
                                        vacancieDetails.vacancieInfo.company_name }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Nombre de la Vacante</span> {{
                                        vacancieDetails.vacancieInfo.name }}</p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Creada en </span>
                                        {{ new
                                            Date(vacancieDetails.vacancieInfo.creation_log).toLocaleDateString('es-MX', {
                                                weekday: 'long',
                                                day: 'numeric', month: 'long', year: 'numeric'
                                        }) }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                        <span class="tag is-info-custom">Descripción</span> {{
                                        vacancieDetails.vacancieInfo.description }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: justify; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Dirección</span> {{
                                        vacancieDetails.vacancieInfo.address }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Salario</span>
                                        {{ vacancieDetails.salary !== null ? '$' + vacancieDetails.vacancieInfo.salary :
                                        'No aplica' }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Notas Adicionales</span> {{
                                        vacancieDetails.vacancieInfo.notes }}</p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Estado</span> {{
                                        vacancieDetails.vacancieInfo.state_name }}</p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Municipio</span> {{
                                        vacancieDetails.vacancieInfo.muni_name }}</p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Sector</span> {{
                                        vacancieDetails.vacancieInfo.sector_name }}</p>
                                </div>
                            </div>
                        </template>
                        <template v-slot:buttons>
                        </template>
                    </CardComponent>
                </div>
            </div>
        </Transition>
        <div class="aboutMe">
            <CardComponent>
                <!-- Slot para el título (dejado vacío) -->
                <template v-slot:title>
                </template>
                <!-- Slot para el contenido -->
                <template v-slot:content>
                    <div class="card-content">
                        <div class="media">
                            <div class="media-left">
                                <div class="media-content-image has-text-centered">
                                    <figure class="image custom-image">
                                        <img src="https://xsgames.co/randomusers/avatar.php?g=female" alt="User Avatar"
                                            class="rounded-image">
                                    </figure>
                                </div>
                            </div>
                            <div class="content-perfil">
                                <p class="perfil">{{ studentName }} {{ studentLastNameB }} {{ studentName }}</p>
                                <p class="perfil">{{ email }}</p>
                                <!-- Puedes mostrar otros detalles del profesor aquí -->
                            </div>
                        </div>
                    </div>
                </template>
                <!-- Slot para los botones (dejado vacío) -->
                <template v-slot:buttons>
                    <!-- Deja este slot vacío para no mostrar botones en esta parte -->
                </template>
            </CardComponent>
        </div>
        <div class="soySeparador">
        </div>
        <div class="card-perfil">
            <CardComponent>
                <!-- Slot para el título -->
                <template v-slot:title>
                    <div class="media-content-info">
                        <p class="  has-text"> Nombre del Departamento: {{ name_department }}</p>
                        <p class="  has-text">Carrera: {{ career }}</p>
                        <p class="  has-text">Habilidades: {{ skills }}</p>
                        <p class="  has-text">Experiencia: {{ exp }}</p>
                        <p class="  has-text">Acerca de mi: {{ aboutMe }}</p>
                    </div>
                </template>
                <!-- Slot para el contenido (dejado vacío) -->
                <template v-slot:content>
                    <!-- Deja este slot vacío para no mostrar contenido en esta parte -->
                </template>
                <!-- Slot para los botones -->
                <template v-slot:buttons>
                    <div class="buttons is-centered is-grouped">
                        <md-elevated-button @click="handleClick" style="margin-right: 20px;">
                            {{ buttonText }}
                        </md-elevated-button>
                        <md-elevated-button>
                            Rechazar
                        </md-elevated-button>
                    </div>
                </template>
            </CardComponent>
        </div>
        <FooterComponent />
    </div>
    <Transition name="bounce">
        <ModalComponent v-if="showModal" :title="modalTitle" :modalContent="modalContent">
            <template v-slot:buttons>
                <md-elevated-button v-if="respuestaExitosa" @click="handleAccept">
                    Aceptar
                </md-elevated-button>
                <md-elevated-button v-if="!respuestaExitosa" @click="handleError">
                    Volver
                </md-elevated-button>
            </template>
        </ModalComponent>
    </Transition>
    <!-- modal completo solo para motivos de una mejor UX -->
    <Transition name="bounce">
        <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
            <div class="modal-background"></div>
            <div class="modal-card">
                <section class="modal-card-body">
                    <div> Validando Datos.. </div>
                    <md-circular-progress four-color indeterminate></md-circular-progress>
                </section>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
        </div>
    </Transition>
</template>





<style scoped src="../styles/views/MaterialTheming.css"></style>
<style scoped src="../styles/material/theme.light.css"></style> 
<style scoped src="../styles/material/theme.dark.css"></style>

<style scoped src="../styles/views/SolicitudesListadoUnirse.css"></style>

<script>
import CardComponent            from        '../components/CardComponent.vue';
import FooterComponent          from        '../components/FooterComponent.vue';
import HeaderComponent          from        '../components/HeaderComponent.vue';
import MenuComponent            from        '../components/MenuComponent.vue';
import MessageComponent         from        '../components/MessageComponent.vue';
import TableComponent           from        '../components/TableComponent.vue';
import ModalComponent           from        '../components/ModalComponent.vue';

import {  loadStudent, loadVacancie, acceptRequest }    from        '../api/ApiService'

export default
{
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        TableComponent,
        ModalComponent,
    },



    data() 
    {
        return  {
                    serverErrorMessage : `Parece que no se ha podido conectar al servidor.
                    Por favor, verifica tu conexión a internet e inténtalo de nuevo.
                    Si el problema persiste, no dudes en ponerte en contacto con tu administrador para obtener ayuda`,

                    acceptedRequest : `Has aceptado la solicitud del alumno. Recuerda mantener un seguimiento de su estado, 
                    ya que él tiene la última palabra y aún podría decidir rechazar su participación en la unidad receptora`,
                    apiErrorMessage: `No ha sido posible unirse a la vacante en este momento. Por favor, inténtelo nuevamente más tarde. 
                    Si el problema persiste, le sugerimos que se ponga en contacto con su administrador.`,

                    activeTab: 'tab1', 

                    descripcion: `En esta sección, tienes la opción de decidir si aceptas la solicitud del estudiante para unirse a tu vacante.
                    Te recomendamos leer detenidamente la información proporcionada por el estudiante para tomar una decisión informada.
                    Ten en cuenta que, incluso si aceptas la solicitud, el estudiante todavía tiene la opción de rechazar la oferta al final.
                    Por lo tanto, te sugerimos que estés al pendiente en el apartado ‘Ingreso’ para verificar si el estudiante finalmente decidió unirse a tu equipo.
                    Agradecemos tu atención y cooperación en este proceso.`,

                    mainTheme           :   'light',
                    exp                 :   '',
                    studentName         :   '',
                    studentLastNameA    :   '',
                    studentLastNameB    :   '',
                    enrollment          :   '',
                    email               :   '',
                    name_department     :   '',
                    career              :   '',
                    skills              :   '',
                    buttonText          :   'Aceptar',

                    isClicked           :   false,
                    isFetching          :   false,
                    showModal           :   false,
                    vacancieDetails     :   null,
                    studentDetails      :   null,
                    mostrarLista        :   false,
                    aboutMe : '',
                }
    },



    async created() 
    {   
        try 
        {
            const data              =   await loadVacancie(this.$store);
            this.vacancieDetails    =   data;
            console.log("DATOS DE LA API:", data);
        } 
        catch (error) 
        {
            console.error('Error loading vacancie:', error);
        }


        try 
        {
            const studentInfo = await loadStudent(this.$store);
            console.log('estos son los datos de la api: ', studentInfo);

            this.studentName        =   studentInfo.name;
            this.studentLastNameA   =   studentInfo.last_name_A;
            this.studentLastNameB   =   studentInfo.last_name_B;
            this.email              =   studentInfo.email;
            this.enrollment         =   studentInfo.enrollment;
            this.name_department    =   studentInfo.name_department;
            this.career             =   studentInfo.career;
            this.skills             =   studentInfo.skills;
            this.exp                =   studentInfo.exp;
            this.aboutMe            =   studentInfo.about_me;

        } 
        catch (error) 
        {
            console.error('Server Error:', error);
        }
    
    },




    methods:
    {
        handleThemeChange(e) 
        {
            this.mainTheme = e.matches ? 'dark' : 'light';
            console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
        },


        toggleMenu()
        {
            this.mostrarLista = !this.mostrarLista;
        },


        //determina los clicks que se hacen aka double step
        handleClick() 
        {
            if (!this.isClicked) 
            {
                this.buttonText = 'Haz clic de nuevo para confirmar';
                this.isClicked = true;
            } 
            else 
            {
                this.handleAccept();
                this.isFetching = true;
                this.accept_request();
            }
        },


        //acciones a tomar segun la accion que se realiza
        handleAccept() 
        {
            if (this.actionOnSuccess === 'vacancieAccepted') 
            {
                this.$router.push({ name: 'empresa-inicio' });
            }

            else if (this.actionOnSuccess === 'vacancieRejected') 
            {
                this.$router.push({ name: 'empresa-inicio' });
            }

            this.showModal = false;
        },


        async accept_request() 
        {
            this.isFetching = true; // Muestra el modal de fetching antes de la llamada
            this.showModal = false; // Oculta el modal anterior si está visible

            try 
            {
                const result = await acceptRequest(this.$store);
                this.isFetching = false;

                if (result.success) 
                {
                    this.showModal = true;
                    this.modalTitle = 'Proceso Exitoso';
                    this.modalContent = this.acceptedRequest;
                    this.respuestaExitosa = true;
                    this.actionOnSuccess = 'vacancieAccepted';
                }
            } 
            catch (error) 
            {
                this.isFetching = false;
                this.showModal = true;
                this.modalTitle = 'Ha Ocurrido un Error';
                this.modalContent = `${this.apiErrorMessage} \n \n MENSAJE DE ERROR: ${error.message}`;
                this.respuestaExitosa = false;
            }
        },


        handleError()
        {
            this.showModal          =   false;
            this.showModalReject    =   false;
        },
    },



    beforeUnmount() 
    {
        if (this.mediaQuery) 
        {
            this.mediaQuery.removeEventListener('change', this.handleThemeChange);
        }
    },



    mounted() 
    {
        this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        this.mediaQuery.addEventListener('change', this.handleThemeChange);
        this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
    },

}

</script>