
import jwt_decode from 'jsonwebtoken/decode';


//INICIO DE SESION
export async function signInWithGoogle(requestBody) 
{
  const apiUrl = process.env.VUE_APP_API_STAFF_POST_SIGNIN;
  await new Promise(resolve => setTimeout(resolve, 3000));

  const response = await fetch(apiUrl, 
  {
    method: 'POST',
    headers: { 'Content-Type' : 'application/json' },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) 
  {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const data = await response.json();
  return data;
};



//ENVIA TOKEN DE NOTIFICATION
export async function sendNotiTokenApi(notiToken) 
{
  const apiUrl        =   process.env.VUE_APP_API_STAFF_POST_SENDNOTIFYTOKEN;
  const token         =   localStorage.getItem('token');
  const decodedToken  =   jwt_decode(token);
  const idStaff       =   decodedToken.userStaffId;

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ token: notiToken, id_staff: idStaff }),
  });

  if (!response.ok) 
  {
    throw new Error('Error enviando el token de notificación');
  }
};



//CARGA LAS NOTIFICACIONES
export async function loadNotificationsApi() 
{
  const token         =   localStorage.getItem('token');
  const decodedToken  =   jwt_decode(token);
  const idStaff       =   decodedToken.userStaffId;

  const response = await fetch(process.env.VUE_APP_API_STAFF_POST_RECENT_ACTIVITY, {
    method: 'POST',
    headers : 
    {
      'Content-Type'  : 'application/json',
      'Authorization' : `Bearer ${token}`,
    },
    body: JSON.stringify({ idStaff }),
  });

  if (!response.ok) 
  {
    throw new Error('Failed to fetch notifications');
  }

  const data = await response.json();
   // Mapea y retorna un array de objetos que contengan name y url
   return data.historyList.map(item => ({
    name: item.name,
    url: item.url
  }));
}




//CARGA LA INFORMACION DEL STAFF SOBRE QUE FACULTADES ADMINSITRA
export async function loadManagementByStaff(token) 
{
    try 
    {
        const decodedToken  =   jwt_decode(token);
        const idStaff       =   decodedToken.userStaffId;
        const response      =   await fetch(process.env.VUE_APP_API_STAFF_POST_LOADMYDEPAS, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ id_staff: idStaff }),
            });

        if (!response.ok) 
        {
            throw new Error('Error loading departments');
        }

        const data = await response.json();
        return data.map(item => item.description);
    } 
    catch (error) 
    {
        console.error('Server Error:', error);
        throw error;
    }
};



//LISTADO DE LAS VACANTES A REVISAR
export async function loadVacancies(token) 
{
  try 
  {
    const decodedToken  =   jwt_decode(token);
    const idStaff       =   decodedToken.userStaffId;

    const response = await fetch(process.env.VUE_APP_API_STAFF_POST_LISTFORAPROBE, 
    {
      method: 'POST',
      headers: 
      {
        'Content-Type'  :   'application/json',
        'Authorization' :   `Bearer ${token}`,
      },
      body: JSON.stringify({ idStaff: idStaff }),
    });

    if (!response.ok) 
    {
      throw new Error('Error loading vacancies');
    }

    const data = await response.json();
    return data;
  } 
  catch (error) 
  {
    console.error('Server Error:', error);
    throw error;
  }
};



//CARGA LA INFORMACION DE UNA VACANTE
export async function loadVacancie(store) 
{
  try 
  {
    const vacancie  = store.getters.obtenerDetalles;
    const token     = localStorage.getItem('token');

    const response = await fetch(process.env.VUE_APP_API_STAFF_POST_APPROVEVACANCIE, {
      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({ id_vacancie: vacancie }),
    });

    if (!response.ok) 
    {
      throw new Error('Error al cargar la vacante');
    }

    const data = await response.json();
    return data[0];  // Asumiendo que la respuesta es un array y necesitas el primer elemento
  } 
  catch (error) 
  {
    console.error('Server Error:', error);
    throw error;
  }
};



//RECHAZA UNA VACANTE
export async function rejectVacancieApi(store, motivoRechazo) 
{
  const token = localStorage.getItem('token');
  const vacancie = store.getters.obtenerDetalles;
  
  await new Promise(resolve => setTimeout(resolve, 4000));

  const rejectApi = process.env.VUE_APP_API_STAFF_POST_REJECTVACANCIE;
  const response = await fetch(rejectApi, 
  {
    method: 'POST',
    headers: 
    {
      'Content-Type'  : 'application/json',
      'Authorization' : `Bearer ${token}`,
    },
    body: JSON.stringify({ idVacancie: vacancie, reason: motivoRechazo }),
  });

  if (!response.ok) 
  {
    const errorMessage = await response.text();
    return {
      showModal: true,
      modalTitle: 'Ha Ocurrido un Error',
      modalContent: `${this.apiErrorMessage}   \n \n MENSAJE DE ERROR:  ${errorMessage}`,
      respuestaExitosa: false,
    };
  }

  return {
    showModal: true,
    modalTitle: 'Proceso Exitoso',
    modalContent: 'La vacante ha sido rechazada exitosamente.',
    respuestaExitosa: true,
    actionOnSuccess: 'vacancieRejected',
  };
};



//ACEPTAR VACANTE 
export async function sendAcceptApi(store, apiErrorMessage, apiVacancieAccepted) 
{
  const vacancie    =   store.getters.obtenerDetalles;
  const idCompany   =   store.getters.getterCompanyId;
  const token       =   localStorage.getItem('token');
  
  await new Promise(resolve => setTimeout(resolve, 3000));
  const api_accept_vacancie = process.env.VUE_APP_API_STAFF_POST_ACCEPTVACANCIE;
  
  const response = await fetch(api_accept_vacancie, 
    {
      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({ id_vacancie: vacancie, idCompany : idCompany }),
  });

  if (!response.ok) 
  {
    const errorMessage = await response.text();
    return {
      showModal: true,
      modalTitle: 'Ha Ocurrido un Error',
      modalContent: `${apiErrorMessage} \n \n MENSAJE DE ERROR: ${errorMessage}`,
      respuestaExitosa: false,
    };
  }

  return {
    showModal         : true,
    modalTitle        : 'Proceso Exitoso',
    modalContent      : apiVacancieAccepted,
    respuestaExitosa  : true,
    actionOnSuccess   : 'vacancieAccepted',
  };
};



//CARGAR INTERNADO 
export async function loadActualInternship(token) 
{
    try 
    {
      const decodedToken = jwt_decode(token);
      const idStaff = decodedToken.userStaffId;

      const response = await fetch(process.env.VUE_APP_API_STAFF_POST_INTERNSHIP_LIST, 
      {
          method: 'POST',
          headers: 
          {
              'Content-Type'  : 'application/json',
              'Authorization' : `Bearer ${token}`,
          },
          body: JSON.stringify({ idStaff: idStaff }),
      });

      if (!response.ok) 
      {
          throw new Error('Error loading vacancies');
      }

      const data = await response.json();
      console.log('datos', data);

      const spaces = " ";
      const formattedData = {
            tab1: data.internshipList
                .filter(item => item.id_job_board === 1)
                .map(item => ({
                    id          :   item.employee_id,
                    nombre      :   `${item.last_name_A} ${spaces} ${item.last_name_B} ${spaces} ${item.name}`,
                    actividad   :   item.vacancie_name,
                    fecha       :   item.start_date.split('T')[0],
                    idStudent   :   item.id_student,
                    vacancieId  :   item.id_vacancie,
                })),
            tab2: data.internshipList
                .filter(item => item.id_job_board === 2)
                .map(item => ({
                    id          :   item.employee_id,
                    nombre      :   `${item.last_name_A} ${spaces} ${item.last_name_B} ${spaces} ${item.name}`,
                    actividad   :   item.vacancie_name,
                    fecha       :   item.start_date.split('T')[0],
                    idStudent   :   item.id_student,
                    vacancieId  :   item.id_vacancie,
                }))
        };

      return formattedData;
    } 
    catch (error) 
    {
        console.error('Error:', error);
        throw error;
    }
};



//GLOBAL INFO STUDENT
export async function loadGlobalInfo(store) 
{
  const token       = localStorage.getItem('token');
  const idStudent   = store.getters.getterStudentId;
  const idVacancie  = store.getters.getterVacancieId;

  try 
  {
    const response = await fetch(process.env.VUE_APP_API_STAFF_POST_STUDENT_GLOBAL_INFO, 
      {
      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({ idStudent, idVacancie })
    });

    if (!response.ok) 
    {
      throw new Error('Error loading global student info');
    }

    return await response.json();
  } 
  catch (error) 
  {
    console.error('Error in loadGlobalInfo:', error);
    throw error;
  }
};



//DAR DE ALTA UNA EMPRESA
export async function sendDataToApi(apiUrl, formData, token) 
{
  try 
  {
    await new Promise(resolve => setTimeout(resolve, 3000));

    const response = await fetch(apiUrl, 
      {
        method  : 'POST',
        headers : 
        {
          'Content-Type'  : 'application/json',
          'Authorization' : `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

    if (!response.ok) 
      {
      const errorMessage = await response.text();
      return {
        success: false,
        status: response.status,
        errorMessage: errorMessage,
      };
    }

    return {
      success: true,
      status: response.status,
      successMessage: 'Proceso Exitoso',
    };
  } 
  catch (error) 
  {
    console.error('Error al enviar datos a la API:', error);
    return {
      success: false,
      errorMessage: 'Error en el servidor',
    };
  }
};

