<template>
  <div class="material-you light ">
    <HeaderComponent />
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>
    <article class="message">
      <MessageComponent title="Estado Solicitudes" :message="descripcion" />
    </article>
    <div class="tabs" style="width: 100%;">
      <md-tabs v-model="activeTab" :style="{ borderRadius: '15em' }">
        <md-primary-tab v-for="(tab, index) in tabs" :key="index" :name="tab.name" @click="changeTab(tab.name)">
          {{ tab.label }}
        </md-primary-tab>
      </md-tabs>
    </div>
    <div v-if="paginatedItems.length > 0" class="job-table">
      <div class="search">
        <!-- <input type="text" v-model="searchQuery" placeholder="Buscar..."> -->
        <md-outlined-text-field label="Buscar..." value="Value" v-model="searchQuery">
        </md-outlined-text-field>
      </div>
      <TableComponent :columns="tableColumns" :searchQuery="searchQuery" :items="paginatedItems">
      </TableComponent>
      <nav class="pagination" role="navigation" aria-label="pagination">
        <md-filled-tonal-button @click="prevPage" :disabled="currentPage === 1">Anterior</md-filled-tonal-button>
        <md-filled-tonal-button @click="nextPage"
          :disabled="currentPage === totalPages">Siguiente</md-filled-tonal-button>
        <ul class="pagination-list">
          <li v-for="page in totalPages" :key="page">
            <a class="pagination-link" :class="{ 'is-current': page === currentPage }" @click="currentPage = page">{{
              page }}</a>
          </li>
        </ul>
      </nav>
    </div>
        <!-- No data message -->
        <div v-else class="no-data-message">
      <p>No has enviado ninguna solicitud.</p>
    </div>
    <FooterComponent />
  </div>
</template>



<style scoped src = "../styles/material/theme.light.css">     </style>
<style scoped src = "../styles/material/theme.dark.css">      </style>
<style scoped src = "../styles/views/MaterialTheming.css">    </style>
<style scoped src = "../styles/views/SolicitudesEstado.css">  </style>

<script>
import FooterComponent      from    '../components/FooterComponent.vue';
import HeaderComponent      from    '../components/HeaderComponent.vue';
import MenuComponent        from    '../components/MenuComponent.vue';
import MessageComponent     from    '../components/MessageComponent.vue';
import TableComponent       from    '../components/TableComponent.vue';

import { loadStudentRequests } from '../api/ApiService';


export default
{
  components:
  {
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    MessageComponent,
    TableComponent,
  },



  data()
  {

    return  {
              currentPage           :   1,
              isLoading             :   true,
              itemsPerPage          :   5,
              sortColumn            :   null,


              activeTab             :   'tab1', 
              searchQuery           :   '',
              sortDirection         :   'asc',

              tabs: 
              [
                  { name: 'tab1', label: 'Servicio Social'            },
                  { name: 'tab2', label: 'Prácticas Profesionales'    },
              ],

              descripcion: `Verifica el estado de tu solicitud para las Prácticas Profesionales en esta sección. Descubre si has sido aceptado o si tu solicitud está en proceso.
                                  Obtén la información necesaria para planificar tu experiencia profesional.`,
                    
              tableItemsTab1  :   [],
              tableItemsTab2  :   [],
              tableItemsTab3  :   [],

              tableColumns:
                [  
                  { label   :   'Empresa',    field   :   'empresa'       },
                  { label   :   'Actividad',  field   :   'actividad'     },
                  { label   :   'Estado',     field   :   'estado'        },
                  { label   :   'Detalles',   field   : 'detalles'        },
                ],
            };
  },



  async created() 
    {
      try 
      {
        const data = await loadStudentRequests();
        this.tableItemsTab1 = data.tableItemsTab1;
        this.tableItemsTab2 = data.tableItemsTab2;
        console.log('LISTADO DE REQUEST:', data);
      } 
      catch (error) 
      {
        console.error('Error loading requests:', error);
      }
    },



  computed: 
  {
    totalPages() 
    {
      const items = this.getCurrentTabItems();
      return Math.ceil(items.length / this.itemsPerPage);
    },


    paginatedItems() 
    {
      const items   =   this.getCurrentTabItems();
      const start   =   (this.currentPage - 1) * this.itemsPerPage;
      const end     =   start + this.itemsPerPage;

      return items.slice(start, end);
    },


    filteredItems() 
    {
      if (this.selectedEstado === '' && this.selectedMunicipio === '' && this.selectedPerfil === '') {
        return this.activeTab === 'tab1' ? this.tableItemsTab1 : this.tableItemsTab2;
      } 
      else 
      {
        const items = this.activeTab === 'tab1' ? this.tableItemsTab1 : this.tableItemsTab2;
        return items.filter(item => {
          const matchEstado = this.selectedEstado === '' || item.estado === this.selectedEstado;
          const matchMunicipio = this.selectedMunicipio === '' || item.municipio === this.selectedMunicipio;
          const matchPerfil = this.selectedPerfil === '' || item.perfil === this.selectedPerfil;
          return matchEstado && matchMunicipio && matchPerfil;
        });
      }
    },

  },



  methods:
  {
    changeTab(tab) 
    {
      this.activeTab = tab;
      this.currentPage = 1;
    },


    nextPage() 
    {
      if (this.currentPage < this.totalPages) 
      {
        this.currentPage++;
      }
    },


    prevPage() 
    {
      if (this.currentPage > 1)
      {
        this.currentPage--;
      }
    },


    getCurrentTabItems() 
    {
      switch(this.activeTab) 
      {
        case 'tab1': return this.tableItemsTab1;
        case 'tab2': return this.tableItemsTab2;
        case 'tab3': return this.tableItemsTab3;
        case 'tab4': return this.tableItemsTab4;
        case 'tab5': return this.tableItemsTab5;
        default: return [];
      }
    },

  },

};
</script>
