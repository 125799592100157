<template>
  <div :class="['material-you', mainTheme]">

     <HeaderComponent />


   <div class="columns">
     <div class="interactive-bg">
       <div class="welcome-message">
         <div class="card">
           <div class="logo-container">
             <img class="login-logo" src="../../assets/umsnh.png">
           </div>
           <header class="card-header"></header>
           <div class="card-content">
             <div class="content">
               <p class="card-header-title">
                 DIRECCIÓN DE VINCULACIÓN Y SERVICIO SOCIAL UMSNH
               </p>
               <p class="centered-list-item" v-if="$route.query.error"></p>
               <div class="selectYou">
                 <md-filled-select label="Seleccione su tipo de usuario" v-model="selectedOption" style="width: 300px">
                   <md-select-option value="Student" style="width: 300px">
                     <div slot="headline">Estudiante</div>
                   </md-select-option>
                   <md-select-option value="Company">
                     <div slot="headline">Empresa</div>
                   </md-select-option>
                   <md-select-option value="PersonalServicioSocial">
                     <div slot="headline">Staff UMNSH</div>
                   </md-select-option>
                 </md-filled-select>
               </div>
             </div>
           </div>
           <footer class="card-footer" style="display: flex; justify-content: flex-end;">
             <a href="#" class="card-footer-item button">
               <span class="tag" style="white-space: normal;">{{ $route.query.error }}</span>
             </a>
           </footer>
           <div class="botones">
             <Transition name="bounce">
               <component :is="getButtonComponent()" :key="selectedOption" />
             </Transition>
           </div>
         </div>
       </div>
     </div>
   </div>
   <FooterComponent />
 </div>
</template>






<style scoped src="../styles/views/MaterialTheming.css"></style> 
<style scoped src="../styles/views/InicioSesion.css"></style> 

<style scoped src="../styles/material/theme.light.css"></style> 
<style scoped src="../styles/material/theme.dark.css"></style> 


<script>
import FooterComponent  from    '../components/FooterComponent.vue';
import GButtonStaff     from    '../../STAFF/components/GoogleComponent.vue'
import GButtonStudent   from    '../../STUDENT/components/GoogleComponent.vue'
import HeaderComponent  from    '../components/HeaderComponent.vue';
import LoginCompany     from    '../../COMPANY/components/LoginComponent.vue';


export default
  {
    name: 'InicioSesion',
    components:
    {
      FooterComponent,
      GButtonStaff,
      GButtonStudent,
      HeaderComponent,
      LoginCompany,
    },


    data() {
      return {
        selectedOption: 'Seleccione su tipo de usuario',
        showGoogleLogin: false,
        showFormLogin: false,
        mediaQuery: null,
         mainTheme: 'light',
      };
    },
    


    mounted() {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },

    methods: 
    {
      getButtonComponent() 
      {
        switch (this.selectedOption) 
        {
          case 'Company':
            return 'LoginCompany';
          case 'Student':
            return 'GButtonStudent';
          case 'PersonalServicioSocial':
            return 'GButtonStaff';
          default:
            return null;
        }
      },

      handleThemeChange(e) {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    }


  },

  beforeUnmount() {
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },


  };
</script>
    