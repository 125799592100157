// En tu archivo ./storePrincipal.js
import { createStore } from 'vuex'
import staff from '../../STAFF/store/store' // Asegúrate de que la ruta es correcta
import student from '../../STUDENT/store/store' // Asegúrate de que la ruta es correcta
import company from '../../COMPANY/store/store' // Asegúrate de que la ruta es correcta
const mainStore = createStore({
  modules: {
    staff,
    student, 
    company
  }
})

export default mainStore;
