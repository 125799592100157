<template>
    <div class="material-you">
        <HeaderComponent />
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>
        <div class="contenedor">
            <CardComponent>
                <!-- Ranura para el título -->
                <template v-slot:title>
                    <h3>{{ remitente }}</h3>
                </template>
                <!-- Ranura para el contenido -->
                <template v-slot:content>
                    <div class="chat-container">
                        <div class="chat-messages">
                            <div v-for="(message, index) in messages" :key="index" class="chat-message"
                                :class="{ 'is-mine': message.isMine, 'is-not-mine': !message.isMine }">
                                <div class="message-content">{{ message.text }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-input">
                        <div class="input-button-container">
                            <textarea maxlength="1000" class="textarea" v-model="newMessage" @keyup.enter="sendMessage"
                                placeholder="Escribe tu mensaje"></textarea>
                            <div class="separador"></div>
                            <button class="button is-rounded" @click="sendMessage"></button>
                        </div>
                    </div>
                </template>
            </CardComponent>
        </div>
        <FooterComponent />
    </div>
</template>





<style scoped src="../styles/views/MaterialTheming.css"></style>
<style scoped src="../styles/views/EmpleadosActualesDetallesIncidenciasMensajes.css"></style> 

<script>
import CardComponent from '../components/CardComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';
import HeaderComponent from '../components/HeaderComponent.vue';
import MenuComponent from '../components/MenuComponent.vue';



export default 
{
    components: 
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
    },



    data() 
    {
        return  {
                    messages: [],
                    newMessage: '',
                    remitente: 'Nombre de la otra persona',
                    intervalId: null
                };
    },



    mounted() 
    {
        this.loadMessages();
        this.intervalId = setInterval(this.loadMessages, 5000); // Carga mensajes cada 5 segundos
    },



    beforeUnmount() 
    {
        if (this.intervalId) 
        {
            clearInterval(this.intervalId); // Limpia el intervalo cuando el componente se destruya
        }
    },



    methods: 
    {
        async loadMessages() 
        {
            const token = localStorage.getItem('token');
            // const idIncident = this.$store.getters.getterIncidentId;
            const idIncident = 18;
            try 
            {
                const response = await fetch(process.env.VUE_APP_API_COMPANY_POST_CONVERSATION_INCIDENT, 
                {
                    method: 'POST',
                    headers: 
                    {
                        'Content-Type'  : 'application/json',
                        'Authorization' : `Bearer ${token}`,
                    },
                    body: JSON.stringify({ idIncident: idIncident }),
                });
        
                if (!response.ok) 
                {
                    const errorResponse = await response.json();
                    console.error('Error loading messages:', errorResponse);
                    return;
                }
    
                const data = await response.json();
                this.messages = data.conversation.map(msg => 
                ({
                    text    :   msg.message,
                    isMine  :   msg.sender !== 'Staff',
                }));
            
            } 
            catch (error) 
            {
                console.error('Error fetching messages:', error);
            }
        },


        async sendMessage() 
        {
            if (this.newMessage.trim() !== '') 
            {
                const token = localStorage.getItem('token');
                const idCompany = 92; // Asegúrate de obtener este valor adecuadamente
                const idIncident = 18; // Asegúrate de tener este valor
    
                const messageData = 
                {
                    idCompany   : idCompany,
                    idIncident  : idIncident,
                    message     : this.newMessage
                };
        
                try 
                {
                    const response = await fetch(process.env.VUE_APP_API_COMPANY_POST_NEW_INCIDENT_MESSAGE, 
                    {
                        method: 'POST',
                        headers: 
                        {
                            'Content-Type'  : 'application/json',
                            'Authorization' : `Bearer ${token}`,
                        },
                        body: JSON.stringify(messageData)
                    });
        
                    if (!response.ok) 
                    {
                    throw new Error('Failed to send message');
                    }
        
                    // Agrega el mensaje enviado a la vista localmente
                    this.messages.push({ text: this.newMessage, isMine: true });
                    this.newMessage = ''; // Limpia el campo de texto
                    this.loadMessages(); // Recarga mensajes para ver cambios
                } 
                catch (error) 
                {
                    console.error('Error sending message:', error);
                }
            }
        },

    },
};
</script>
