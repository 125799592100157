<template>

  <!-- material theming -->
  <div :class="['material-you', mainTheme]">

    <!-- header -->
    <HeaderComponent />

    <!-- main menu -->
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>

    <!-- personal info -->
    <div class="card-perfil">
      <CardComponent>
        <template v-slot:title>
        </template>
        <template v-slot:content>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <div class="media-content-image has-text-centered">
                  <figure class="image custom-image">
                    <img :src="user_picture" alt="User Avatar" class="rounded-image">
                  </figure>
                </div>
              </div>
              <div class="content-perfil">
                <p class="perfil">{{ nombre }} {{ apellido }}</p>
                <p class="perfil">{{ correo }}</p>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:buttons>
        </template>
      </CardComponent>
    </div>

    <!-- management by staff -->
    <div class="card-profile-details">
      <CardComponent>
        <template v-slot:title>
          <div class="media-content-info">
            <p class="">Facultades que Administras:</p>
            <ul >
              <!-- <li v-for="(description, index) in descriptions" :key="index">{{ description }}</li> -->
              <span  v-for="(description, index) in descriptions" :key="index" class="tag is-rounded facultades" >{{ description }}</span>
            </ul>
           
          </div>
        </template>
        <template v-slot:content>
        </template>
        <template v-slot:buttons>
          <div class="buttons is-centered">
          </div>
        </template>
      </CardComponent>
    </div>
    <div class="separador"></div>

    <!-- footer -->
    <FooterComponent />
  </div>
</template>



<style scoped src = "../styles/material/theme.light.css"    ></style> 
<style scoped src = "../styles/material/theme.dark.css"     ></style> 
<style scoped src = "../styles/views/MaterialTheming.css"   ></style>

<style scoped src = "../styles/views/PerfilStaff.css"       ></style>

<script>
import CardComponent              from      '../components/CardComponent.vue';
import FooterComponent            from      '../components/FooterComponent.vue';
import HeaderComponent            from      '../components/HeaderComponent.vue';
import MenuComponent              from      '../components/MenuComponent.vue';

import { loadManagementByStaff }  from      '../api/ApiService';



export default
{
    components: 
    {
      CardComponent,
      FooterComponent,
      HeaderComponent,
      MenuComponent,
    },



    data() 
    {
      return  {
                descriptions      :   [],
                correo            :   localStorage.getItem('userEmail'),
                nombre            :   localStorage.getItem('userName'),
                apellido          :   localStorage.getItem('userLastName'),
                user_picture      :   localStorage.getItem('avatar'),
                mainTheme: 'light',
              };
    },



  mounted() 
  {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },



    async created() 
    {
      try 
      {
        const token = localStorage.getItem('token');
        const descriptions = await loadManagementByStaff(token);
        this.descriptions = descriptions;
        console.log(this.descriptions);
      } 
      catch (error) 
      {
        console.error('Server Error:', error);
      }
    },



    methods: 
    {
      handleThemeChange(e) 
      {
        this.mainTheme = e.matches ? 'dark' : 'light';
        console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
      }
    },



  beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },
}
</script>
