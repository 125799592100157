<template>
  <div :class="[mainTheme]">
  <article class="message">
    <div class="message-header">
      <p>{{ title }}</p>
      <span class="material-symbols-outlined">info</span> 
    </div>
    <div class="message-body">
      {{ message }}
    </div>
  </article>
</div>
</template>




<style scoped src="../styles/components/MessageComponent.css"></style>
<style scoped src = "../styles/material/theme.light.css"    ></style> 
<style scoped src = "../styles/material/theme.dark.css"     ></style> 

<script>
export default 
{
  props: 
  {
    title   : String,
    message : String,
  },

  data() 
  {
      return  {

                mainTheme: 'light',
              };
    },


  methods: 
  {
    closeMessage() {},
    handleThemeChange(e) 
    {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },
  },


  mounted() 
  {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery);
  },


  beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },

};
  </script>
