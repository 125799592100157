<template>
  <div class="material-you">
    <HeaderComponent />
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>
    <div class="card">
      <CardComponent>
        <template v-slot:content>
          <div class="tabla">
            <TableComponent :columns="tableColumns" :items="messagesData">
              <template v-slot:cell="{ item, column }">
                <span v-if="column.field === 'entidad'">{{ item.entidad }}</span>
                <span v-else-if="column.field === 'lastMessage'">{{ item.lastMessage }}</span>
                <span v-else-if="column.field === 'img'">{{ item.img }}</span>
                <button v-else-if="column.field === 'verMensajes'" @click="verMensajes(item)">Ver Mensajes</button>
              </template>
            </TableComponent>
          </div>
        </template>
      </CardComponent>
    </div>
    <FooterComponent />
  </div>
</template>



<style scoped src = "../styles/views/MaterialTheming.css">    </style>
<style scoped src = "../styles/views/MisConversaciones.css">  </style>

<script>
import CardComponent    from      '../components/CardComponent.vue';
import FooterComponent  from    '../components/FooterComponent.vue';
import HeaderComponent  from    '../components/HeaderComponent.vue';
import MenuComponent    from    '../components/MenuComponent.vue';
import TableComponent   from    '../components/TableComponent.vue';

export default 
{
  name: 'MisConversaciones',

  components: 
  {
    CardComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    TableComponent,
  },



  data() 
  {
    return  {
              activeTab: 'tab1',

              tableColumns: 
              [
                { label   :   'id',               field:      'id'            },
                { label   :   'Nombre',           field:      'entidad'       },
                { label   :   'Ultimo mensaje',   field:      'lastMessage'   },
                { label   :   'img',              field:      'img'           },
                { label   :   'Ver Mensajes',     field:      'verMensajes'   },
              ],

              messagesData: 
              [
                {
                  id            :   1,
                  entidad       :   'Staff Servicio Social',
                  lastMessage   :   '10-10-2022 15:45:09',
                  img           :   'https://xsgames.co/randomusers/avatar.php?g=female',
                },

                {
                  id            :   2,
                  entidad       :   'Walmart',
                  lastMessage   :   '10-07-2022 15:45:09',
                  img           :   'https://xsgames.co/randomusers/avatar.php?g=female',
                },
              ],

            };
  },



  methods: 
  {
    changeTab(tab) 
    {
      this.activeTab = tab;
    },

    verMensajes() 
    {
      this.$router.push({ name: 'ListaMensajes' });
    },

  },
  
};
</script>
