<template>

<div :class="[mainTheme]">

    <div class="card">
      <div class="hero-body has-text-centered">
        <div class="login">
          <form @submit.prevent="login">
            <div class="field">
              <div class="control">
                <md-filled-text-field label="Email" type="email" v-model="mail" required>
                </md-filled-text-field>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <md-filled-text-field label="Contraseña" type="password" v-model="password" required>
                </md-filled-text-field>
              </div>
            </div>
            <md-elevated-button>
              Iniciar sesión
            </md-elevated-button>
          </form>
        </div>
      </div>
    </div>

    <Transition name="bounce">
      <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
        <div class="modal-background"></div>
        <div class="modal-card">
          <!-- Contenido del modal de carga (puede ser un spinner, mensaje, etc.) -->
          <section class="modal-card-body">
            <div> Iniciando la Sesión... </div>
            <md-circular-progress indeterminate four-color></md-circular-progress>
          </section>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
      </div>
    </Transition>

  </div>

</template>



<style scoped src = "../styles/material/theme.light.css"    ></style> 
<style scoped src = "../styles/material/theme.dark.css"     ></style> 
<style scoped src="../styles/components/LoginComponent.css"></style> 

<script>
import { ref, onMounted, onBeforeUnmount}   from  'vue'
import { useRouter }  from  'vue-router';
import { loginApi }   from  '../api/ApiService';

export default 
{
  setup() 
  {
    const mainTheme = ref('light');

    const handleThemeChange = (e) => 
    {
        mainTheme.value = e.matches ? 'dark' : 'light';
        console.log(`El sistema está configurado para tema ${mainTheme.value}.`);
    };

    let mediaQuery;
    const mail = ref('');
    const password = ref('');
    const router = useRouter();
    const isFetching = ref(false);

    const login = async () => 
    {
      if (!mail.value || !password.value) 
      {
        console.error('Correo electrónico y contraseña son obligatorios');
        return;
      }

      isFetching.value = true;
      try 
      {
        const responseData = await loginApi(mail.value, password.value);

        isFetching.value = false;
        if (responseData.message === 'Login Success!') 
        {
          await new Promise(resolve => setTimeout(resolve, 650));

          localStorage.setItem('token', responseData.token);
          // localStorage.setItem('level', responseData.id_company);
          localStorage.setItem('user', responseData.email_company);
          // localStorage.setItem('rol_company', responseData.rol_company);
          console.log('Token Guardado Security:', responseData.token);
          router.push({ name: 'empresa-inicio' });
        } 
        else 
        {
          console.error('Inicio de sesión fallido');
          router.push({ path: '/', query: { error: 'Usuario no registrado, consulta a tu administrador' } });
        }
      }
      catch (error) 
      {
        isFetching.value = false;
        if (error.status === 401) 
        {
          console.error('Error en la solicitud:', error.status);
          router.push({ path: '/', query: { error: 'ERROR: Contraseña/Usuario no válidos' } });
        } 
        else 
        {
          console.error('Error en la llamada a la API:', error);
          router.push({ path: '/', query: { error: 'No es posible comunicarse con el servidor, consulta a tu administrador' } });
        }
      }
    };

    onMounted(async () => {
    mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', handleThemeChange);
    handleThemeChange(mediaQuery);
    });

    onBeforeUnmount(() => {
    if (mediaQuery) {
      mediaQuery.removeEventListener('change', handleThemeChange);
    }
    });

    return {
      mail,
      password,
      isFetching,
      login,
      mainTheme,
    };
  },
};
</script>
