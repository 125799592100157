<template>
 <!-- ESTA ES LA QUE TIENE EL LOADING -->

  <!-- material theming -->
  <div :class="['material-you', mainTheme]">


    <!-- header -->
    <HeaderComponent />


    <!-- main menu -->
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>


    <!-- tooltips -->
    <article class="message">
      <MessageComponent title="Estado Solicitudes" :message="descripcion" class="mensaje"/>
      <MessageComponent v-if="activeTab === 'tab1'" title="Unidad Receptora En Revisión" :message="enRevision" />
      <MessageComponent v-if="activeTab === 'tab2'" title="Unidad Receptora Rechazada" :message="enRechazadas" />
      <MessageComponent v-if="activeTab === 'tab3'" title="Unidad Receptora Abierta" :message="enAbiertas" />
      <MessageComponent v-if="activeTab === 'tab4'" title="Unidad Receptora Cerrada" :message="enCerradas" />
      <MessageComponent v-if="activeTab === 'tab5'" title="Unidad Receptora Terminada" :message="enTerminadas" />
    </article>


    <!-- main tabs -->
    <div class="tabs" style="width: 100%;">
      <md-tabs v-model="activeTab" :style="{ borderRadius: '15em' }">
        <md-primary-tab v-for="(tab, index) in tabs" :key="index" :name="tab.name" @click="changeTab(tab.name)">
          {{ tab.label }}
        </md-primary-tab>
      </md-tabs>
    </div>

    <!-- main table -->
    <div v-if="paginatedItems.length > 0" class="job-table">
      <div class="search">
        <md-outlined-text-field label="Buscar..." value="Value" v-model="searchQuery">
        </md-outlined-text-field>
      </div>
      <div v-if="isLoading" class="loading-message">
        <img src="../../assets/loading.gif" alt="Cargando..." class="loading-image">
      </div>
      <TableComponent v-else :columns="tableColumns" :searchQuery="searchQuery" :items="paginatedItems">
      </TableComponent>


      <nav class="pagination" role="navigation" aria-label="pagination">
        <!-- <button class="pagination-previous" @click="prevPage" :disabled="currentPage === 1">Anterior</button> -->

        <md-filled-tonal-icon-button @click="prevPage" :disabled="currentPage === 1">
          <span class="material-symbols-outlined">arrow_back</span>
        </md-filled-tonal-icon-button>

        <md-filled-tonal-icon-button @click="nextPage" :disabled="currentPage === totalPages">
          <span class="material-symbols-outlined">arrow_forward</span>
        </md-filled-tonal-icon-button>

        <!-- <button class="pagination-next" @click="nextPage" :disabled="currentPage === totalPages">Siguiente</button> -->
        <ul class="pagination-list">
          <li v-for="page in totalPages" :key="page">
            <a class="pagination-link" :class="{ 'is-current': page === currentPage }"
                            @click="currentPage = page">{{
                                page
                            }}</a>
          </li>
        </ul>
      </nav>


    </div>
    <!-- No data message -->
    <div v-else class="no-data-message">
      <p>No hay unidades receptoras con este estatus.</p>
    </div>

    <!-- footer -->
    <FooterComponent />
  </div>
</template>





<style scoped src="../styles/material/theme.light.css"></style> 
<style scoped src="../styles/material/theme.dark.css"></style> 

<style scoped src="../styles/views/MaterialTheming.css"></style>
<style scoped src="../styles/views/VacantesListadoEstado.css"></style>

<script>
// ESTA ES LA QUE TRAE EL LOADING
import FooterComponent    from    '../components/FooterComponent.vue';
import HeaderComponent    from    '../components/HeaderComponent.vue';
import MenuComponent      from    '../components/MenuComponent.vue';
import MessageComponent   from    '../components/MessageComponent.vue';
import TableComponent     from    '../components/TableComponent.vue';

import { loadRequestsList } from '../api/ApiService';
  
export default 
{
  components: 
  {
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    MessageComponent,
    TableComponent,
  },


  data() 
  {
    return  {
              mainTheme       :   'light',
              activeTab       :   'tab1',
              searchQuery     :   '',
              sortDirection   :   'asc',

              currentPage     :   1,
              isLoading       :   true,
              itemsPerPage    :   10,
              sortColumn      :   null,

              tabs: 
              [
                { name  : 'tab1',   label : 'En revisión' },
                { name  : 'tab2',   label : 'Rechazadas'  },
                { name  : 'tab3',   label : 'Abiertas'    },
                { name  : 'tab4',   label : 'Cerradas'    },
                { name  : 'tab5',   label : 'Terminadas'  },
              ],

              tableItemsTab1: [],
              tableItemsTab2: [],
              tableItemsTab3: [],
              tableItemsTab4: [],
              tableItemsTab5: [],

              tableColumns: 
              [
                { label : 'Nombre',     field : 'nombre'          },
                { label : 'Fecha',      field : 'fecha'           },
                { label : 'Tipo',       field : 'tipo'            },
                { label : 'Detalles',   field : 'AprobarDetalles' },
              ],

              descripcion   :   `Este módulo te permite examinar todas tus Unidades Receptoras publicadas,
                                proporcionándote detalles completos sobre cada una, incluyendo su estado actual.
                                Con esta herramienta, puedes acceder a información detallada sobre el estado
                                actual de tus Unidades Receptoras y realizar un seguimiento eficiente de su evolución.`,

              enRevision    :   `Cuando una Unidad Receptora se encuentra en revisión, significa que estamos
                                dedicando tiempo a examinar cuidadosamente tu publicación para garantizar que cumpla con
                                los estándares de calidad de nuestra institución. Este proceso nos permite revisar
                                minuciosamente cada aspecto de la publicación y proporcionarte comentarios significativos
                                para asegurar que se ajuste a nuestros criterios`,

              enRechazadas  :   `En esta sección encontrarás las unidades receptoras que han sido rechazadas.
                                Tendrás la oportunidad de editarlas para corregir los detalles incorrectos,
                                así como también la opción de eliminarlas si lo prefieres.
                                Estamos aquí para ayudarte en el proceso y asegurarnos de que tus publicaciones
                                cumplan con nuestros estándares de calidad.`,

              enAbiertas    :   `En esta sección, encontrarás el listado de las unidades receptoras abiertas.
                                Estas unidades tienen la capacidad de recibir solicitudes de ingreso y también pueden tener 
                                empleados en activo. Es un espacio dinámico donde puedes gestionar las interacciones relacionadas
                                con el personal y los procesos de admisión`,

              enCerradas    :   `Esta sección presenta un listado de las unidades receptoras cerradas,
                                lo que indica que no aceptan solicitudes de ingreso en este momento.
                                Sin embargo, estas unidades aún cuentan con empleados en activo.`,

              enTerminadas  :   `Este listado de unidades receptoras terminadas incluye aquellas que han
                                llegado al final de su ciclo. Aquí podrás consultar las personas que participaron
                                en dicha unidad, así como revisar detalles como el tiempo que estuvieron involucradas en ella.`,

            };
    },

    mounted() 
  {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },

    beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },



  computed: 
  {
    totalPages() 
    {
      const items = this.getCurrentTabItems();
      return Math.ceil(items.length / this.itemsPerPage);
    },


    paginatedItems() 
    {
      const items = this.getCurrentTabItems();
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return items.slice(start, end);
    },

    sortedItems() 
    {
      const items = this.getCurrentTabItems();
      if (!this.sortColumn) 
      {
        return items;
      }

      // Realiza una copia superficial del array para evitar mutaciones
      const sortedItems = [...items].sort((a, b) => {
        const fieldA = a[this.sortColumn];
        const fieldB = b[this.sortColumn];
        let comparison = 0;
        
        // Compara los campos dependiendo del tipo de dato
        if (typeof fieldA === 'string') 
        {
          comparison = fieldA.localeCompare(fieldB);
        } 
        else 
        {
          comparison = fieldA - fieldB;
        }

        return this.sortDirection === 'asc' ? comparison : -comparison;
      });

        return sortedItems;
    },
  },



  async created() 
  {
    this.isLoading = true;
    try 
    {
      const token = localStorage.getItem('token');
      const { tab1, tab2, tab3, tab4, tab5 } = await loadRequestsList(token);
      this.tableItemsTab1 = tab1;
      this.tableItemsTab2 = tab2;
      this.tableItemsTab3 = tab3;
      this.tableItemsTab4 = tab4;
      this.tableItemsTab5 = tab5;
    } 
    catch (error) 
    {
      console.error('Failed to load requests:', error);
    } 
    finally 
    {
      this.isLoading = false;
    }
  },



  methods: 
  {
    handleThemeChange(e) 
    {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },


    changeTab(tab) 
    {
      this.activeTab = tab;
      this.currentPage = 1; // Reset to first page when tab changes
    },


    changeSort(column) 
    {
      if (this.sortColumn === column) 
      {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } 
      else 
      {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
    },


    getCurrentTabItems() 
    {
      switch(this.activeTab) 
      {
        case 'tab1': return this.tableItemsTab1;
        case 'tab2': return this.tableItemsTab2;
        case 'tab3': return this.tableItemsTab3;
        case 'tab4': return this.tableItemsTab4;
        case 'tab5': return this.tableItemsTab5;
        default: return [];
      }
    },


    nextPage() 
    {
      if (this.currentPage < this.totalPages) 
      {
        this.currentPage++;
      }
    },


    prevPage() 
    {
      if (this.currentPage > 1) 
      {
        this.currentPage--;
      }
    },

  },

};
</script>


