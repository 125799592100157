<template>
    <!-- Tema material -->
    <div :class="['material-you', mainTheme]">

        <!-- Encabezado -->
        <HeaderComponent />

        <!-- Menú lateral -->
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>

        <!-- Menú de tarjetas -->
        <div class="group-cards">

            <!-- Tarjeta de alta de empresas -->
            <div class="card-alta">
                <router-link :to="{ name: 'personal-empresas-menu-altas' }">
                    <CardComponent class="card">
                        <template v-slot:title>
                            <div class="media-content-info">
                                <img src="../../assets/alta.jpg" alt="Descripción de la imagen">
                                <p>Alta de Empresas en sistema</p>
                            </div>
                        </template>
                        <template v-slot:content>
                            <p>{{  }}</p>
                        </template>
                        <template v-slot:buttons>
                            <div class="buttons is-centered">
                                <!-- Botones u otros elementos -->
                            </div>
                        </template>
                    </CardComponent>
                </router-link>
            </div>

            <!-- Tarjeta para aprobar postulaciones -->
            <div class="card-aprobar">
                <router-link :to="{ name: 'personal-empresas-menu-validaciones' }">
                    <CardComponent class="card">
                        <template v-slot:title>
                            <div class="media-content-info">
                                <img src="../../assets/revision.jpeg" alt="Descripción de la imagen">
                                <p>Aprobar Postulaciones</p>
                            </div>
                        </template>
                        <template v-slot:content>
                            <!-- Contenido dinámico si es necesario -->
                        </template>
                        <template v-slot:buttons>
                            <div class="buttons is-centered">
                                <!-- Botones u otros elementos -->
                            </div>
                        </template>
                    </CardComponent>
                </router-link>
            </div>

        </div>

        <!-- Pie de página -->
        <FooterComponent />

    </div>
</template>




<style scoped src="../styles/material/theme.light.css"></style> 
<style scoped src="../styles/material/theme.dark.css"></style> 

<style scoped src="../styles/views/EmpresasMenu.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style>



<script>
import CardComponent        from    '../components/CardComponent.vue';
import FooterComponent      from    '../components/FooterComponent.vue';
import HeaderComponent      from    '../components/HeaderComponent.vue';
import MenuComponent        from    '../components/MenuComponent.vue';


export default
{
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
    },

    data()
    {
      return  {
                    mainTheme: 'light',
              }
    },


    mounted() {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },


  methods :
  {
    handleThemeChange(e) {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    }
  },



  beforeUnmount() {
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },




}

</script>