<template>
  <div :class="[mainTheme]">
  <div class="table-responsive b-table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-rounded has-pagination table-mobile-sort">
    <div class="table-wrapper has-mobile-cards">
      <table class="table is-fullwidth is-striped is-hoverable is-rounded">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column.field" @click="changeSort(column.field)"
              :class="{ 'is-sorted': sortColumn === column.field }">
              {{ column.label }}
              <span v-if="sortColumn === column.field"
                :class="sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filteredItems" :key="item.id">
            <td v-for="column in columns" :key="column.field" :data-label="column.label">
              <!-- Comprueba si la columna es la de imagen -->
              <template v-if="column.field === 'img'">
                <img :src="item[column.field]" alt="Imagen de perfil" />
              </template>

              <!-- Renderiza los botones específicos si el campo coincide -->
              <template v-else-if="column.field === 'verMensajes'">
                <button class="button is-rounded" @click="verMensajes(item)">Ver Mensajes</button>
              </template>

              <template v-else-if="column.field === 'AprobarDetalles'">
                <md-elevated-button @click="AprobarDetalles(item)">
                  Revisar
                </md-elevated-button>
              </template>

              <template v-else-if="column.field === 'misAlumnosDetalles'">
                <button class="button is-rounded" @click="misAlumnosDetalles(item)">Detalles</button>
              </template>

              <template v-else-if="column.field === 'Candidatos'">
                <md-elevated-button @click="candidatos(item)">
                  Ver Detalles
                </md-elevated-button>
              </template>

              <template v-else-if="column.field === 'CandidatosDetalles'">
                <md-elevated-button @click="candidatos_detalles(item)">
                  Ver Detallesx
                </md-elevated-button>
              </template>

              <template v-else-if="column.field === 'Confirmacion'">
                <md-elevated-button @click="confirmacion(item)">
                  Ver Detalles
                </md-elevated-button>
              </template>

              <template v-else-if="column.field === 'detalles' && item.detalles === 'revisar'">
                <md-elevated-button @click="revisarSolicitud(item)">
                  Revisar Solicitud
                </md-elevated-button>
              </template>

              <template v-else-if="column.field === 'detalles' && item.detalles === 'x'">
                <md-elevated-button @click="confirmacion(item)">
                  Confirma su Asistencia
                </md-elevated-button>
              </template>

              <!-- Renderiza el texto si ninguna condición anterior se cumple -->
              <template v-else>
                {{ item[column.field] }}
              </template>
            </td>
          </tr>

         
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<style scoped src = "../styles/material/theme.light.css"    ></style> 
<style scoped src = "../styles/material/theme.dark.css"     ></style> 

<style scoped src="../styles/components/TableComponent.css"></style>





<script>

import ModalComponent from './ModalComponent.vue';

export default 
{
  components: 
  {
    ModalComponent,
  },



  props: 
  {
    columns     :   Array, 
    items       :   Array, 
    searchQuery :   String,
  },



  emits: ['sort'],



  data() 
  {
    return  {
              isModalOpen     :   false,
              selectedId      :   null,
              sortColumn      :   null, 
              sortDirection   :   'asc', 
              sortField       :   '',
              sortOrder       :   'asc', 
              mainTheme       :   'light',
            }
  },



  methods: 
  {
    handleThemeChange(e) 
    {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },


    prevPage() 
    {
      if (this.currentPage > 1) { this.currentPage--; }
    },


    nextPage() 
    {
      if (this.currentPage < this.totalPages) 
      {
        this.currentPage++;
      }
    },


    verMensajes(item) 
    {
      this.$router.push({ name: 'empresa-empleados-actuales-detalles-incidencias-messages' });
      console.log('Ver mensajes de:', item.id);
    },


    misAlumnosDetalles(item) 
    {
      this.$router.push({ name: 'empresa-detalles-alumno' });
      console.log('Ver mensajes de:', item.id);
    },


    //http://localhost:8080/empresa/solicitudes/listado
    candidatos(item) 
    {
      this.$router.push({ name: 'empresa-solicitudes-listado-unirse' });

      console.log('id de la vacante', item.vacancieId);
      this.$store.commit('SET_VACANCIE_ID', item.vacancieId);

      console.log('id del student lokit', item.idStudent);
      this.$store.commit('SET_STUDENT_ID', item.idStudent);
    },


    candidatos_detalles(item) 
    {
      this.$router.push({ name: 'empresa-empleados-actuales-detalles' });

      console.log('id de la vacante', item.vacancieId);
      this.$store.commit('SET_VACANCIE_ID', item.vacancieId);

      console.log('id del student lokit', item.idStudent);
      this.$store.commit('SET_STUDENT_ID', item.idStudent);
    },


    confirmacion(item) 
    {
      this.$router.push({ name: 'empresa-ingreso-listado-confirmacion' });
      console.log('id de la vacante', item.vacancieId);
      this.$store.commit('SET_VACANCIE_ID', item.vacancieId);

      console.log('id del student lokit', item.idStudent);
      this.$store.commit('SET_STUDENT_ID', item.idStudent);
    },


    // NUEVO FORMATO
    AprobarDetalles(item) 
    {
      console.log('Ver mensajes de dds:', item.vState);

      if(item.vState === 'Rechazada')
      {
        this.$store.commit('SET_VACANCIE_ID', item.id);
        this.$router.push('/empresa/vacantes/listado/estado/rechazada');
      }

      if(item.vState === 'Creada')
      {
        this.$store.commit('SET_VACANCIE_ID', item.id);
        this.$router.push('/empresa/vacantes/listado/estado/revision');
      }

      if(item.vState === 'Abierta')
      {
        this.$store.commit('SET_VACANCIE_ID', item.id);
        this.$router.push('/empresa/vacantes/listado/estado/abierta');
      }

      if(item.vState === 'Cerrada')
      {
        this.$store.commit('SET_VACANCIE_ID', item.id);
        this.$router.push('/empresa/vacantes/listado/estado/cerrada');
      }

      if(item.vState === 'Terminada')
      {
        this.$store.commit('SET_VACANCIE_ID', item.id);
        this.$router.push('/empresa/vacantes/listado/estado/terminada');
      }

    },


    changeSort(field) 
    {
      console.log('headeder tocado');
      if (this.sortField === field) 
      {
        // Si el campo de ordenamiento es el mismo que el anterior, cambia la dirección
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } 
      else 
      {
        // Si el campo de ordenamiento es diferente, establece el nuevo campo y la dirección ascendente
        this.sortField = field;
        this.sortOrder = 'asc';
      }
    },


    sortItems(items) 
    {
      if (!this.sortField) 
      {
        // Si no hay campo de ordenamiento, devuelve los elementos sin ordenar
        return items;
      }


      // Utiliza slice() para evitar mutar el array original
      const sortedItems = items.slice().sort((a, b) => {
        // Comparación basada en el campo de ordenamiento y la dirección
        const fieldA = a[this.sortField];
        const fieldB = b[this.sortField];

        if (fieldA < fieldB) 
        {
          return this.sortOrder === 'asc' ? -1 : 1;
        }
        if (fieldA > fieldB) 
        {
          return this.sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });

      return sortedItems;
    },


    revisarSolicitud(item) 
    {
      this.$router.push({ name: 'empresa-solicitudes-listado-unirse' });
      console.log('id de la vacante', item.vacancieId);
      console.log('id del student lokit', item.idStudent);
      this.$store.commit('SET_VACANCIE_ID', item.vacancieId);
      this.$store.commit('SET_STUDENT_ID', item.idStudent);
    },

  },



  computed: 
  {


  filteredItems() {
  let items = this.items; // Asegúrate de que this.items tenga los objetos correctos
  const searchLower = this.searchQuery?.toLowerCase() ?? '';

  // Ordenar los elementos antes de filtrar
  let sortedItems = this.sortItems(items);

  // Filtrar los elementos basados en la consulta de búsqueda
  return sortedItems.filter(item => {
    // Concatenar los valores de varios campos en una sola cadena para la búsqueda
    const itemText = [
      item.nombre,
      item.tipo,
      item.actividad,
      item.requestState,
      item.accepted_date,
      item.presenseState
    ].map(text => text ?? '') // Usar map para aplicar un valor predeterminado a cada campo
     .join(' '); // Unir los valores con un espacio

    // Buscar la consulta en el texto consolidado
    return itemText.toLowerCase().includes(searchLower);
  });
},

  },

  mounted() 
  {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery);
  },
  

  beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },



};
</script>
