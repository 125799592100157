<template>
    <!-- tema material -->
    <div class="material-you light ">
        <!-- header -->
        <HeaderComponent />
        <!-- menu lateral -->
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>
        <!-- tooltips -->
        <article class="message">
            <MessageComponent title="Bolsa de trabajo" :message="descripcion" />
        </article>
        <!-- options cards -->
        <div class="group-cards">
            <div class="card-alta">
                <router-link :to="{ name: 'empresa-bolsa-trabajo-nueva' }">
                    <CardComponent>
                        <template v-slot:title>
                            <div class="media-content-info">
                                <img src="../../assets/new-job.jpg" alt="Descripción de la imagen">
                                <p class="">Crear Nueva Vacante</p>
                            </div>
                        </template>
                        <template v-slot:content>
                            <p class="">{{ }}</p>
                        </template>
                        <template v-slot:buttons>
                            <div class="buttons is-centered">
                            </div>
                        </template>
                    </CardComponent>
                </router-link>
            </div>
            <div class="card-aprobar">
                <router-link :to="{ name: 'empresa-bolsa-trabajo-listado' }">
                    <CardComponent>
                        <template v-slot:title>
                            <div class="media-content-info">
                                <img src="../../assets/job-applications.jpg" alt="Descripción de la imagen">
                                <p class="">Ver Solicitudes Recibidas</p>
                            </div>
                        </template>
                        <template v-slot:content>
                            <p class="">{{ }}</p>
                        </template>
                        <template v-slot:buttons>
                            <div class="buttons is-centered">
                            </div>
                        </template>
                    </CardComponent>
                </router-link>
            </div>
            <div class="card-empresas">
                <router-link :to="{ name: 'personal-inicio' }">
                    <CardComponent>
                        <template v-slot:title>
                            <div class="media-content-info">
                                <img src="../../assets/survey.jpg" alt="Descripción de la imagen">
                                <p class="">Encuestas</p>
                            </div>
                        </template>
                        <template v-slot:content>
                            <p class="content-info">{{ }} </p>
                        </template>
                        <template v-slot:buttons>
                            <div class="buttons is-centered">
                            </div>
                        </template>
                    </CardComponent>
                </router-link>
            </div>
        </div>
        <!-- footer -->
        <FooterComponent />
    </div>
</template>



<style scoped src   =   "../styles/material/theme.light.css">   </style> 
<style scoped src   =   "../styles/material/theme.dark.css">    </style> 
<style scoped src   =   "../styles/views/BolsaTrabajo.css">     </style>
<style scoped src   =   "../styles/views/MaterialTheming.css">  </style>

<script>
import CardComponent        from        '../components/CardComponent.vue';
import FooterComponent      from        '../components/FooterComponent.vue';
import HeaderComponent      from        '../components/HeaderComponent.vue';
import MenuComponent        from        '../components/MenuComponent.vue';
import MessageComponent     from        '../components/MessageComponent.vue';

export default 
{
    components: 
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
    },



    data()
    {
        return  {        
                    descripcion : `En este espacio, tendrás la capacidad de administrar las vacantes de empleo disponibles,
                                    así como de publicar nuevas oportunidades laborales y gestionar las solicitudes de candidatos
                                    interesados en unirse a tu equipo y demás aspectos relacionados,`,
                }

    }

}
</script>