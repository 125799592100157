<template>
  <!-- tema material -->
  <div class="material-you light">

    <!-- header -->
    <HeaderComponent />

    <!-- menu lateral -->
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>

    <!-- tooltips -->
    <article class="message">
      <MessageComponent title="Estado Solicitudes" :message="descripcion" />
    </article>

    <!-- options tabs -->
    <div class="tabs" style="width: 100%;">
      <md-tabs v-model="activeTab" :style="{ borderRadius: '15em' }">
        <md-primary-tab v-for="(tab, index) in tabs" :key="index" :name="tab.name">
          {{ tab.label }}
        </md-primary-tab>
      </md-tabs>
    </div>

    <!-- tabla -->
    <div class="job-table">

      <!-- search bar -->
      <div class="search">
        <md-outlined-text-field label="Buscar..." value="Value" v-model="searchQuery">
        </md-outlined-text-field>
      </div>

      <!-- loading  -->
      <div v-if="isLoading" class="loading-message">
        <img src="../../assets/loading.gif" alt="Cargando..." class="loading-image">
      </div>

      <!-- table component -->
      <TableComponent v-else :columns="tableColumns" :searchQuery="searchQuery" :items="paginatedItems">
      </TableComponent>

      <!-- pagination -->
      <nav class="pagination" role="navigation" aria-label="pagination">
        <button class="pagination-previous" @click="prevPage" :disabled="currentPage === 1">Anterior</button>
        <button class="pagination-next" @click="nextPage" :disabled="currentPage === totalPages">Siguiente</button>
        <ul class="pagination-list">
          <li v-for="page in totalPages" :key="page">
            <a class="pagination-link" :class="{ 'is-current': page === currentPage }" @click="currentPage = page">{{ page }}</a>
          </li>
        </ul>
      </nav>

    </div>

    <!-- footer -->
    <FooterComponent />
  </div>
</template>



<style scoped src = "../styles/material/theme.light.css">         </style> 
<style scoped src = "../styles/material/theme.dark.css">          </style> 
<style scoped src = "../styles/views/MaterialTheming.css">        </style>
<style scoped src = "../styles/views/VacantesListadoEstado.css">  </style>

<script>
import FooterComponent      from    '../components/FooterComponent.vue';
import HeaderComponent      from    '../components/HeaderComponent.vue';
import jwt_decode           from    'jsonwebtoken/decode';
import MenuComponent        from    '../components/MenuComponent.vue';
import MessageComponent     from    '../components/MessageComponent.vue';
import TableComponent       from    '../components/TableComponent.vue';
  
export default 
{
  components: 
  {
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    MessageComponent,
    TableComponent,
  },



  data() 
  {
    return  {
              activeTab       :   'tab1',
              sortDirection   :   'asc',
              searchQuery     :   '',

              currentPage     :   1,
              isLoading       :   true,
              itemsPerPage    :   10,
              sortColumn      :   null,

              tabs: 
              [
                { name: 'tab3', label: 'Vacantes' },
              ],
              tableItemsTab1  : [],
              tableColumns: 
              [
                { label   :   'Nombre',     field   :   'nombre'            },
                { label   :   'Fecha',      field   :   'fecha'             },
                { label   :   'Tipo',       field   :   'tipo'              },
                { label   :   'Detalles',   field   :   'AprobarDetalles'   },
              ],

              descripcion   :   `En este espacio, tendrás acceso a un registro detallado de las vacantes actualmente publicadas en tu bolsa de trabajo
                                corporativa. Además, podrás revisar minuciosamente las solicitudes recibidas de nuestros colaboradores afiliados a nuestra institución.
                                Esta herramienta te brinda la capacidad de gestionar eficientemente el proceso de reclutamiento interno,
                                facilitando la identificación de talento y el seguimiento de las solicitudes de empleo dentro de nuestra comunidad empresarial.`,
            };
    },



  computed: 
  {
    totalPages() 
    {
      const items = this.getCurrentTabItems();
      return Math.ceil(items.length / this.itemsPerPage);
    },


    paginatedItems() 
    {
      const items   =   this.getCurrentTabItems();
      const start   =   (this.currentPage - 1) * this.itemsPerPage;
      const end     =   start + this.itemsPerPage;
      return items.slice(start, end);
    },


    sortedItems() 
    {
      const items = this.getCurrentTabItems();
      if (!this.sortColumn) 
      {
        return items;
      }

      // Realiza una copia superficial del array para evitar mutaciones
      const sortedItems = [...items].sort((a, b) => 
      {
        const fieldA = a[this.sortColumn];
        const fieldB = b[this.sortColumn];
        let comparison = 0;
        
        // Compara los campos dependiendo del tipo de dato
        if (typeof fieldA === 'string') 
        {
          comparison = fieldA.localeCompare(fieldB);
        } 
        else 
        {
          comparison = fieldA - fieldB;
        }
        
        // Aplica la dirección de ordenamiento
        return this.sortDirection === 'asc' ? comparison : -comparison;
      });

      return sortedItems;
    },
  },



  created() 
  {
    this.loadRequests();
  },



  methods: 
  {
    changeSort(column) 
    {
        if (this.sortColumn === column) 
        {
          this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        } 
        else 
        {
          this.sortColumn = column;
          this.sortDirection = 'asc';
        }
    },


    getCurrentTabItems() 
    {
      switch(this.activeTab) 
      {
        case 'tab1': return this.tableItemsTab1;
        case 'tab2': return this.tableItemsTab2;
        case 'tab3': return this.tableItemsTab3;
        case 'tab4': return this.tableItemsTab4;
        case 'tab5': return this.tableItemsTab5;
        default: return [];
      }
    },


    nextPage() 
    {
      if (this.currentPage < this.totalPages) 
      {
        this.currentPage++;
      }
    },


    prevPage() 
    {
      if (this.currentPage > 1) 
      {
        this.currentPage--;
      }
    },


    async loadRequests() 
    {
      this.isLoading = true;
      try 
      {
        const token         =   localStorage.getItem('token');
        const decodedToken  =   jwt_decode(token);
        const id_company    =   decodedToken.userCompanyId;

        const response = await fetch(process.env.VUE_APP_API_COMPANY_POST_LOADLISTINFO, 
        {
          method: 'POST',
          headers: 
          {
            'Content-Type'  : 'application/json',
            'Authorization' : `Bearer ${token}`,
          },
          body: JSON.stringify({ id_company: id_company }),
        });

        if (!response.ok) 
        {
          throw new Error('Error loading requets');
        }

        const data = await response.json();

        this.tableItemsTab1 = data.list.filter(item => item.status_option === 3)
        .map(item => 
        ({
            id      :   item.id,
            nombre  :   item.name,
            tipo    :   item.vacancie_type,
            fecha   :   item.creation_log.split('T')[0],
            vState  : (() => 
            {
              switch (item.status_option) 
              {
                case 2: return 'Rechazada';
                case 3: return 'Creada';
                case 4: return 'Abierta';
                case 5: return 'Cerrada';
                case 6: return 'Terminada';
                default: return 'Desconocido';
              }
            })(),
          }));
      } 
      catch (error) 
      {
        console.error(error);
      } 
      finally 
      {
        this.isLoading = false;
      }
    },

  },

};
</script>


