<template>
    <div class="material-you light ">

    <HeaderComponent />


    <div class="side-menu">
        <MenuComponent class="side-menu" />
    </div>

    <div class="side-menu">
        <MenuComponent class="side-menu" />
    </div>

    <article class="message">
        <MessageComponent title="Confirmación de Ingreso " :message="descripcion" />
    </article>

    <div class="tabs " style="width: 100%;">
        <md-tabs v-model="activeTab" :style="{ borderRadius: '15em' }">
            <md-primary-tab v-for="(tab, index) in tabs" :key="index" :name="tab.name" @click="changeTab(tab.name)">
                {{ tab.label }}
            </md-primary-tab>
        </md-tabs>
    </div>


    <div class="job-table">

        <div class="search">
          <!-- <input type="text" v-model="searchQuery" placeholder="Buscar..."> -->
          <md-outlined-text-field label="Buscar..." value="Value" v-model="searchQuery">
          </md-outlined-text-field>
        </div>

        <TableComponent :columns="tableColumns" :searchQuery="searchQuery"
            :items="activeTab === 'tab1' ? tableItemsTab1 : (activeTab === 'tab2' ? tableItemsTab2 : tableItemsTab3)">
            <template v-slot:cell="{ item, column }">
                <!-- Personaliza el contenido de la celda según la columna -->
                <span v-if="column.field === 'Nombre'">{{ item.nombre }}</span>
                <span v-else-if="column.field === 'Actividad'">{{ item.actividad }}</span>
                <span v-else-if="column.field === 'Disponibilidad'">{{ item.disponibilidad }}</span>
                <span v-else-if="column.field === 'Aceptados'">{{ item.aceptados }}</span>
                <span v-else-if="column.field === 'Fecha'">{{ item.fecha }}</span>
                <button v-else-if="column.field === 'Confirmacion'" @click="candidatos(item)">Ver Detalles</button>

            </template>
        </TableComponent>


        <nav class="pagination" role="navigation" aria-label="pagination">
          <button class="pagination-previous" @click="prevPage" :disabled="currentPage === 1">Anterior</button>
          <button class="pagination-next" @click="nextPage" :disabled="currentPage === totalPages">Siguiente</button>
          <ul class="pagination-list">
            <li v-for="page in totalPages" :key="page">
              <a class="pagination-link" :class="{ 'is-current': page === currentPage }" @click="currentPage = page">{{ page }}</a>
            </li>
          </ul>
        </nav>

    </div>


    <FooterComponent />
</div>
</template>



<style scoped src="../styles/material/theme.light.css"></style>
<style scoped src="../styles/material/theme.dark.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style>
<style scoped src="../styles/views/IngresoListado.css"></style>

<script>
import FooterComponent      from        '../components/FooterComponent.vue';
import HeaderComponent      from        '../components/HeaderComponent.vue';
import jwt_decode           from        'jsonwebtoken/decode';
import MenuComponent        from        '../components/MenuComponent.vue';
import MessageComponent     from        '../components/MessageComponent.vue';
import TableComponent       from        '../components/TableComponent.vue';



export default
    {
        name: 'IngresoListado',
        components:
        {
            FooterComponent,
            HeaderComponent,
            MenuComponent,
            MessageComponent,
            TableComponent,
        },



        data() 
        {
            return  {
                searchQuery: '',

sortColumn: null,
sortDirection: 'asc',
currentPage: 1,
itemsPerPage: 10,
isLoading: true,
                        descripcion: `En esta sección, podrás ver el listado de estudiantes que han confirmado su participación en tus vacantes.
                         Es importante que verifiques su asistencia para el trabajo. Revisa cuidadosamente cada caso para una gestión efectiva de tus vacantes.
                        
                        `,
                        activeTab: 'tab1',

                        tabs: 
                            [
                                { name: 'tab1', label: 'Servicio Social'            },
                                { name: 'tab2', label: 'Prácticas Profesionales'    },
                                { name: 'tab3', label: 'Bolsa de Trabajo'           },
                            ],

                            tableItemsTab1: [],
                            tableItemsTab2: [],
                            tableItemsTab3: [],


                        tableColumns:
                            [
                                { label: 'Nombre', field: 'nombre' },
                                { label: 'Actividad', field: 'actividad' },
                                { label: 'Fecha', field: 'fecha' },
                                { label: 'Confirmacion', field: 'Confirmacion' },
                            ],


                    }
        },



        created() 
        {
            this.loadVacancies();
        },

        computed: {
  totalPages() {
    const items = this.getCurrentTabItems();
    return Math.ceil(items.length / this.itemsPerPage);
  },
  paginatedItems() {
    const items = this.getCurrentTabItems();
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return items.slice(start, end);
  },

  sortedItems() {
      const items = this.getCurrentTabItems();
      if (!this.sortColumn) {
        return items;
      }

      // Realiza una copia superficial del array para evitar mutaciones
      const sortedItems = [...items].sort((a, b) => {
        const fieldA = a[this.sortColumn];
        const fieldB = b[this.sortColumn];
        let comparison = 0;
        
        // Compara los campos dependiendo del tipo de dato
        if (typeof fieldA === 'string') {
          comparison = fieldA.localeCompare(fieldB);
        } else {
          comparison = fieldA - fieldB;
        }
        
        // Aplica la dirección de ordenamiento
        return this.sortDirection === 'asc' ? comparison : -comparison;
      });

      return sortedItems;
    },
},


        methods:
        {
            changeTab(tab) 
            {
                this.activeTab = tab;
            },

            async loadVacancies()
        {
            
            try
            {
                const token         =   localStorage.getItem('token');
                const decodedToken  =   jwt_decode(token);
                const userCompanyId      =   decodedToken.userCompanyId;
                const spaces = " ";

                console.log(userCompanyId);

                const response = await fetch(process.env.VUE_APP_COMPANY_POST_VACANCIE_REQUEST_LIST,
                {
                    method : 'POST',
                    headers: 
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ idCompany: userCompanyId }),
                });


                if (!response.ok) 
                {
                    throw new Error('Error loading vacancies');
                }


                const data = await response.json();
                console.log('datos',data.requeet_list);

                // Asignar los datos según el tipo de vacante
                this.tableItemsTab1 = data.requeet_list
                    .filter(item => item.type === 'Servicio Social')
                    .map(item => 
                    ({
                        id      :   item.id,
                        vacancieId : item.id_vacancie,
                        // studentId : item.id_student,
                        nombre  :   item.last_name_A + spaces+ item.last_name_B + spaces+ item.name,
                        actividad : item.vacancie_name,
                        fecha   :   item.creation_log.split('T')[0],
                    }));

                this.tableItemsTab2 = data
                    .filter(item => item.type === 'Prácticas Profesionales')
                    .map(item => 
                    ({
                        id      :   item.id,
                        vacancieId : item.id_vacancie,
                        // studentId : item.id_student,
                        nombre  :   item.last_name_A + spaces+ item.last_name_B + spaces+ item.name,
                        actividad : item.vacancie_name,
                        fecha   :   item.creation_log.split('T')[0],
                    }));

                this.tableItemsTab3 = data
                    .filter(item => item.type === 'Bolsa de Trabajo')
                    .map(item => 
                    ({
                        id      :   item.id,
                        vacancieId : item.id_vacancie,
                        // studentId : item.id_student,
                        nombre  :   item.last_name_A + spaces+ item.last_name_B + spaces+ item.name,
                        actividad : item.vacancie_name,
                        fecha   :   item.creation_log.split('T')[0],
                    }));

            }
            catch(error)
            {

            }

        },

        changeSort(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
    },

  getCurrentTabItems() {
    switch(this.activeTab) {
      case 'tab1': return this.tableItemsTab1;
      case 'tab2': return this.tableItemsTab2;
      case 'tab3': return this.tableItemsTab3;
      case 'tab4': return this.tableItemsTab4;
      case 'tab5': return this.tableItemsTab5;
      default: return [];
    }
  },

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  },
  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  },
        },

    }

</script>