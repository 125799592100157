<template>
  <div :class="[mainTheme]">
  <div>
    <div class="float-menu">
      <md-filled-tonal-icon-button class=" menu-button" @click="toggleMenu">
        <span class="material-symbols-outlined">menu</span>
      </md-filled-tonal-icon-button>
    </div>
    <Transition name="bounce">
      <aside class="sidebar" :class="{ 'menu-visible': true }" v-if="isMenuVisible">
        <div class="tipo">Personal Universitario</div>
        <router-link :to="{ name: 'personal-inicio' }" class="menu-logo">UMSNH</router-link>
        <ul class="menu-list">
          <li>
            <router-link :to="{ name: 'personal-perfil-mi-perfil' }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">face</span>
                </div>
                <span>Perfil</span>
              </div>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'personal-empresas-menu' }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">work</span>
                </div>
                <span> Empresas</span>
              </div>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'personal-alumnos-mis-alumnos' }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">school</span>
                </div>
                <span> Pasantes</span>
              </div>
            </router-link>
          </li>

          <li class="has-submenu">

            <div class="menu-item">
              <div class="icon-container">
                <span class="material-symbols-outlined">sentiment_stressed</span> <!-- Outlined -->
              </div>
              <span>Incidencias</span>
            </div>

            <ul class="submenu">
              <li>
                <div class="menu-item">
                  <router-link :to="{ name: 'personal-incidencias' }">
                    <span class="material-symbols-outlined">diversity_4</span>
                    Pasantes
                  </router-link>
                </div>
              </li>

              <li>
                <div class="menu-item">
                  <router-link :to="{ name: 'personal-incidencias-listado-empresas' }">
                    <span class="material-symbols-outlined">work</span>
                    Empresas
                  </router-link>
                </div>
              </li>
            </ul>
          </li>
          <!-- fin del submenu maquinola -->

          <li>
            <router-link :to="{ name: 'personal-lista-personas' }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">chat_bubble</span>
                </div>
                <span>Mensajes</span>
                <span class="unread-badge">{{ unreadMessages }}</span>
              </div>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'personal-contacto' }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">phone_in_talk</span>
                </div>
                <span>Contacto</span>
              </div>
            </router-link>
          </li>

          <li>
            <router-link :to="{ }">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">page_info</span>
                </div>
                <span>Acerca de</span>
              </div>
            </router-link>
          </li>

          <li @click="cerrarSesion">
            <router-link :to="{}">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">exit_to_app</span>
                </div>
                <span>Salir</span>
              </div>
            </router-link>
          </li>

        </ul>

      </aside>
    </Transition>

    <div class="overlay" v-if="isMenuVisible" @click="closeMenu"></div>
  </div>
</div>
</template>





<style scoped src="../styles/components/MenuComponent.css"></style>
<style scoped src = "../styles/material/theme.light.css"    ></style> 
<style scoped src = "../styles/material/theme.dark.css"     ></style> 

<script>
export default 
{
  data() 
  {
    return  {
              unreadMessages  : 10,
              isMenuVisible   : true,
              mainTheme: 'light',
            };
  },



  beforeMount() 
  {
    window.addEventListener('resize', this.handleWindowResize);
    if (window.innerWidth <= 850) 
    {
        this.isMenuVisible = false;
    }
    document.addEventListener('click', this.handleOutsideClick);
  },



  mounted() 
  {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery);
  },



  beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
    document.removeEventListener('click', this.handleOutsideClick);
  },



  methods: 
  {
    handleOutsideClick(event) {
    if (this.isMenuVisible && window.innerWidth <= 850) {
      const menu = this.$el.querySelector('.sidebar');
      const button = this.$el.querySelector('.menu-button');
      if (!menu.contains(event.target) && !button.contains(event.target)) {
        this.isMenuVisible = false;
      }
    }
  },
    cerrarSesion() 
    {
      localStorage.clear();
      this.$router.push({ path: '/' });
    },


    toggleMenu() 
    {
      this.isMenuVisible = !this.isMenuVisible;
      if (this.isMenuVisible) 
      {
        document.body.classList.remove('menu-hidden');
      } 
      else 
      {
        document.body.classList.add('menu-hidden');
      }
    },


    cerrarSesion() 
    {
      localStorage.clear();
      this.$router.push({ path: '/' });
    },


    handleWindowResize() 
    {
      this.windowWidth = window.innerWidth;
      if(innerWidth >= 851)
      {
        this.isMenuVisible = true;
      }
    },



    handleThemeChange(e) 
    {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    }

  },

};
</script>
