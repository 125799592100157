<template>

  <HeaderComponent />

  <div class="side-menu">
    <MenuComponent class="side-menu" />
  </div>


  <div class="card-perfil">
    <CardComponent>

      <!-- Slot para el título (dejado vacío) -->
      <template v-slot:title>
      </template>

      <!-- Slot para el contenido -->
      <template v-slot:content>
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <div class="media-content-image has-text-centered">
                <figure class="image custom-image">
                  <img src="https://xsgames.co/randomusers/avatar.php?g=female" alt="User Avatar" class="rounded-image">
                </figure>
              </div>
            </div>
            <div class="content-perfil">
              <p class="perfil">Ana Sofía López Ramírez</p>
              <p class="perfil">6948065y@umich.mx</p>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:buttons>
      </template>

    </CardComponent>
  </div>


  <div class="card-perfil">
    <CardComponent>

      <template v-slot:title>
        <div class="media-content-info">

          <div class="seguimiento">
            <p class="  has-text-black">Actividad: Mesera</p>
            <p class="  has-text-black">Estado: En Progreso</p>
            <p class="  has-text-black">120 Horas cumplidas</p>
          </div>

          <p class="  has-text-black">Facultad de Filosofia</p>
          <p class="  has-text-black">Octavo Semestre</p>
          <p class="  has-text-black">La filosofia del siglo xiv</p>
          <p class="  has-text-black">Student de filosofía con habilidades en reflexión
            crítica, análisis de argumentos y expresión escrita.</p>
          <p class="  has-text-black">Sin Experiencia Laboral</p>
        </div>
      </template>

      <template v-slot:content>
      </template>

      <template v-slot:buttons>
        <div class="buttons is-centered is-grouped">

          <router-link :to="{ name: '' }">
            <button class="button is-link is-rounded">Enviar Mensaje</button>
          </router-link>

          <router-link :to="{ name: '' }">
            <button class="button is-warning is-rounded">Reportar</button>
          </router-link>

          <router-link :to="{ name: '' }">
            <button class="button is-danger is-rounded">Expulsar</button>
          </router-link>

        </div>
      </template>

    </CardComponent>
  </div>

  <FooterComponent />

</template>







<style scoped src="../styles/views/DetallesAlumno.css"></style>

<script>
import CardComponent    from        '../components/CardComponent.vue';
import FooterComponent  from        '../components/FooterComponent.vue';
import HeaderComponent  from        '../components/HeaderComponent.vue';
import MenuComponent    from        '../components/MenuComponent.vue';


export default
{
  name: 'InfoContacto',
  components:
  {
    CardComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
  }

}

</script>