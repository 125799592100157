<template>
  <!-- material theming -->
  <div :class="['material-you', mainTheme]">
    <HeaderComponent />
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>
    <!-- tooltips -->
    <article class="message ">
      <div class="tooltip">
      <MessageComponent title="Nueva Solicitud" :message="descripcion" />
    </div>
      <MessageComponent v-if="activeTab === 'tab1'" title="Servicio Social" :message="servicioSocial" />
      <MessageComponent v-if="activeTab === 'tab2'" title="Prácticas Profesionales" :message="practicas" />
      <MessageComponent v-if="activeTab === 'tab3'" title="Bolsa de Trabajo" :message="bolsaTrabajo" />
    </article>
    <!-- clean filters -->
    <div class="cleanFilters">
      <md-filled-tonal-button @click="clearFilters">
        Limpiar filtros
      </md-filled-tonal-button>
    </div>
    <!-- main tabs -->
    <div class="tabs " style="width: 100%;">
      <md-tabs v-model="activeTab" :style="{ borderRadius: '15em' }">
        <md-primary-tab v-for="(tab, index) in tabs" :key="index" :name="tab.name" @click="changeTab(tab.name)">
          {{ tab.label }}
        </md-primary-tab>
      </md-tabs>
    </div>
    <!-- selectors -->
    <div class="select-container">
      <div class="select-you SolicitudesNueva">
        <md-filled-select label="Estado" v-model="selectedEstado" @change="onEstadoChange" :disabled="allSelectsLocked">
          <md-select-option selected value="">
            <div slot="headline"></div>
          </md-select-option>
          <md-select-option v-for="estado in uniqueEstados" :value="estado" :key="estado">
            <div slot="headline">{{ estado }}</div>
          </md-select-option>
        </md-filled-select>
      </div>
      <div class="select-you SolicitudesNueva">
        <md-filled-select label="Municipio" v-model="selectedMunicipio" @change="onMunicipioChange"
          :disabled="!selectedEstado || allSelectsLocked">
          <md-select-option selected value="">
            <div slot="headline"></div>
          </md-select-option>
          <md-select-option v-for="municipio in filteredMunicipios" :value="municipio" :key="municipio">
            <div slot="headline">{{ municipio }}</div>
          </md-select-option>
        </md-filled-select>
      </div>
      <div class="select-you SolicitudesNueva">
        <md-filled-select label="Sector" v-model="selectedPerfil" @change="onPerfilChange"
          :disabled="!selectedMunicipio || allSelectsLocked">
          <md-select-option selected value="">
            <div slot="headline"></div>
          </md-select-option>
          <md-select-option v-for="perfil in filteredPerfiles" :value="perfil" :key="perfil">
            <div slot="headline">{{ perfil }}</div>
          </md-select-option>
        </md-filled-select>
      </div>
    </div>
    <!-- main table -->
    <div v-if="paginatedItems.length > 0" class="job-table">
      <TableComponent :columns="tableColumns" :items="paginatedItems">
      </TableComponent>
      <!-- pagination list -->
      <nav class="pagination" role="navigation" aria-label="pagination">
        <md-filled-tonal-button @click="prevPage" :disabled="currentPage === 1">Anterior</md-filled-tonal-button>
        <md-filled-tonal-button @click="nextPage"
          :disabled="currentPage === totalPages">Siguiente</md-filled-tonal-button>
        <ul class="pagination-list">
          <li v-for="page in totalPages" :key="page">
            <a class="pagination-link" :class="{ 'is-current': page === currentPage }" @click="currentPage = page">{{
              page }}</a>
          </li>
        </ul>
      </nav>
    </div>
    <!-- No data message -->
    <div v-else class="no-data-message">
      <p>No hay vacantes disponibles.</p>
    </div>
    <!-- footer -->
    <FooterComponent />
  </div>
</template>



<style scoped src   =   "../styles/material/theme.light.css"      ></style> 
<style scoped src   =   "../styles/material/theme.dark.css"       ></style> 
<style scoped src   =   "../styles/views/MaterialTheming.css"     ></style>

<style scoped src   =   "../styles/views/SolicitudesNueva.css"    ></style>

// ESTE ES EL BUENO
<script>
import FooterComponent        from    '../components/FooterComponent.vue';
import HeaderComponent        from    '../components/HeaderComponent.vue';
import MenuComponent          from    '../components/MenuComponent.vue';
import MessageComponent       from    '../components/MessageComponent.vue';
import TableComponent         from    '../components/TableComponent.vue';

import { loadVacancies }      from    '../api/ApiService';

export default 
{
  components: 
  {
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    MessageComponent,
    TableComponent,
  },



  data() 
  {
    return  {
              mainTheme             :   'light',
              allSelectsAreDefined  :   false,
              allSelectsLocked      :   false,
              currentPage           :   1,
              isLoading             :   true,
              itemsPerPage          :   5,
              sortColumn            :   null,

              activeTab             :   'tab1',
              searchQuery           :   '',
              selectedEstado        :   '',
              selectedMunicipio     :   '',
              selectedPerfil        :   '',
              sortDirection         :   'asc',



              filteredMunicipios    :   [],
              filteredPerfiles      :   [],
              tabs                  : 
              [
                { name  : 'tab1', label :   'Servicio Social'         },
                { name  : 'tab2', label :   'Prácticas Profesionales' },
              ],

              tableItemsTab1        :   [],
              tableItemsTab2        :   [],
              tableItemsTab3        :   [],

              tableColumns: 
              [
                { label :   'Empresa',        field   : 'empresa'       },
                { label :   'Nombre',         field   : 'nombre'        },
                { label :   'Estado',         field   : 'estado'        },
                { label :   'Municipio',      field   : 'municipio'     },
                { label :   'Vacantes',       field   : 'vacantes'      },
                { label :   'Disponibles ',   field   : 'disponibles'   },
                { label :   'Perfil',         field   : 'perfil'        },
                { label :   'Fecha',          field   : 'fecha'         },
                { label :   'Ver',            field   : 'Ver'           },
              ],

              descripcion           :   `¡Bienvenido! En esta sección, encontrarás las vacantes que se ajustan al perfil de tu carrera,
                                        publicadas por empresas e instituciones asociadas a la UMNSH. Las vacantes se dividen en categorías como Servicio Social,
                                        Prácticas Profesionales y Bolsa de Trabajo.`,

              servicioSocial        :   `Descubre estas oportunidades especialmente diseñadas para estudiantes que buscan adquirir experiencia práctica
                                        en diversos ámbitos profesionales, al mismo tiempo que ofrecen su tiempo para servir a la comunidad.
                                        Estas experiencias proporcionan una valiosa combinación de aprendizaje en el mundo real
                                        y contribuciones significativas a la sociedad, permitiendo a los estudiantes expandir sus habilidades
                                        mientras impactan positivamente en su entorno`,

              practicas             :   `Explora estas oportunidades diseñadas para estudiantes que desean sumergirse en el mundo laboral y
                                        aplicar sus conocimientos en situaciones reales. Las ofertas de Prácticas Profesionales te permiten colaborar
                                        con empresas e instituciones, brindándote la experiencia práctica necesaria para desarrollarte en tu área de estudio.
                                        Es una forma única de adquirir habilidades prácticas, establecer contactos profesionales y prepararte para tu futura carrera.`,
            };
  },



  async created() 
  {
    try 
    {
      const data = await loadVacancies();
      this.tableItemsTab1 = data.tableItemsTab1;
      this.tableItemsTab2 = data.tableItemsTab2;
      console.log('DATOS DE LA API:', data);
    } 
    catch (error) 
    {
      console.error('Error loading vacancies:', error);
    }
  },



  computed: 
  {
    totalPages() 
    {
      const items = this.getCurrentTabItems();

      return Math.ceil(items.length / this.itemsPerPage);
    },


    paginatedItems() 
    {
      let items   =   this.filteredItems;
      const start =   (this.currentPage - 1) * this.itemsPerPage;
      const end   =   start + this.itemsPerPage;

      return items.slice(start, end);
    },


    filteredItems() 
    {
      // Comienza con todos los items relevantes para la pestaña activa
      let items = this.getCurrentTabItems();

      // Filtra por estado si hay un estado seleccionado
      if (this.selectedEstado) 
      {
        items = items.filter(item => item.estado === this.selectedEstado);
      }

      // Filtra por municipio si hay un municipio seleccionado
      if (this.selectedMunicipio) 
      {
        items = items.filter(item => item.municipio === this.selectedMunicipio);
      }

      // Filtra por perfil si hay un perfil seleccionado
      if (this.selectedPerfil) 
      {
        items = items.filter(item => item.perfil === this.selectedPerfil);
      }

      // Retorna los items filtrados
      return items;
    },


    uniqueEstados() 
    {
      const items = this.activeTab === 'tab1' ? this.tableItemsTab1 : this.tableItemsTab2;
      return [...new Set(items.map(item => item.estado))];
    },
  },



  methods: 
  {
    handleThemeChange(e) {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },


    changeTab(tab) 
    {
      this.activeTab    =   tab;
      this.currentPage  =   1;
    },


    clearFilters() 
    {
      // Reiniciar los selects a su estado inicial sin selección
      this.selectedEstado     = null;
      this.selectedMunicipio  = null;
      this.selectedPerfil     = null;

      // Vaciar las listas filtradas
      this.filteredMunicipios   =   [];
      this.filteredPerfiles     =   [];

      // Desbloquear los selects (opcional)
      this.allSelectsLocked = false;

      // Actualizar las listas filtradas (opcional)
      this.getFilteredMunicipios();
      this.getFilteredPerfiles();
    },


    nextPage() 
    {
      if (this.currentPage < this.totalPages) 
      {
        this.currentPage++;
      }
    },


    prevPage() 
    {
      if (this.currentPage > 1)
      {
        this.currentPage--;
      }
    },


    getCurrentTabItems() 
    {
      switch(this.activeTab) 
      {
        case 'tab1': return this.tableItemsTab1;
        case 'tab2': return this.tableItemsTab2;
        case 'tab3': return this.tableItemsTab3;
        case 'tab4': return this.tableItemsTab4;
        case 'tab5': return this.tableItemsTab5;
        default: return [];
      }
    },


    getFilteredMunicipios() 
    {
      if (this.selectedEstado) 
      {
        const items = this.getCurrentTabItems();
        this.filteredMunicipios = [...new Set(items.filter(item => item.estado === this.selectedEstado).map(item => item.municipio))];
      } 
      else 
      {
        this.filteredMunicipios = []; // Asegura que no hay municipios listados si no hay estado seleccionado
      }
    },


    getFilteredPerfiles() 
    {
      if (this.selectedMunicipio) 
      {
        const items = this.getCurrentTabItems();
        this.filteredPerfiles = [...new Set(items.filter(item => item.municipio === this.selectedMunicipio).map(item => item.perfil))];
      } else 
      {
        this.filteredPerfiles = [];
      }
    },


    onEstadoChange() 
    {
      this.selectedMunicipio  =   '';
      this.selectedPerfil     =   '';
      this.getFilteredMunicipios();
      this.filteredPerfiles = [];
    },


    onMunicipioChange() 
    {
      this.selectedPerfil = '';
      this.getFilteredPerfiles();
    },


    onPerfilChange() 
    {
      if (this.selectedPerfil) {
        this.allSelectsLocked = true;
      }
    },


    clearOtherSelects() 
    {
      this.selectedMunicipio  =   '';
      this.selectedPerfil     =   '';
    },

  },



  beforeUnmount() {
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },

  mounted() {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },

};
</script>
