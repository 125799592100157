export default {
    state: 
    {
        detalles            :       null,
        state_vacancie      :       null,
        skills              :       "",
        exp                 :       "",
        vacancie_id         :       null,
        vacancieId              :   null,
        companyId : null,
        incidentId : null,
    },


    mutations: 
    {
        setDetalles(state, detalles) 
        {
            state.detalles = detalles;
        },


        setStateVacancie(state, state_vacancie) 
        {
            state.state_vacancie = state_vacancie;
        },


        setSkills(state, skills) 
        {
            state.skills = skills;
        },


        setExp(state, exp) 
        {
            state.exp = exp;
        },


         //PARA VACANTES POR ID
        set_id_vacancie(state,vacancie_id)
        {
            state.vacancie_id = vacancie_id;
        },

        SET_VACANCIE_ID(state,vacancieId)                       {   state.vacancieId            = vacancieId                },
        SET_COMPANY_ID(state,companyId)                       {   state.companyId            = companyId                },
        SET_INCIDENT_ID(state,incidentId)                       {   state.incidentId            = incidentId                },
    },

    actions: 
    {
        revisarRequests({ commit }, detalles) 
        {
            commit('setDetalles', detalles);
        },
        revisarStateVacancie({ commit }, state_vacancie) 
        {
            commit('setStateVacancie', state_vacancie);
        },

        update_vacancie_id({commit}, vacancie_id)
        {
            commit('set_id_vacancie', vacancie_id);
        },

        async loadSkills({ commit }) 
        {
            async function fetchData() 
            {
                const skills = await fetchSkills(); // Obtiene los datos
                commit('setSkills', skills);
            }
            fetchData();
        },

        async loadExp({ commit }) 
        {
            async function fetchData() 
            {
                const exp = await fetchExp(); // Obtiene los datos
                commit('setExp', exp);
            }
            fetchData();
        },


        async changeVacancieId({commit})
        {    
            async function fetchData()
            {
                const vacancieId = await fetchVacancieId();
                commit('SET_VACANCIE_ID',vacancieId);
            }
            fetchData();
        },



        async changeCompanyId({commit})
        {    
            async function fetchData()
            {
                const companyId = await fetchCompanyId();
                commit('SET_COMPANY_ID',companyId);
            }
            fetchData();
        },


        async changeIncidentId({commit})
        {    
            async function fetchData()
            {
                const incidentId = await fetchIncidentId();
                commit('SET_INCIDENT_ID',incidentId);
            }
            fetchData();
        },

    },


    getters: 
    {
        obtenerDetallesStudent      :       (state) =>      state.detalles,
        obtenerStateVacancie        :       (state) =>      state.state_vacancie,
        obtenerSkills               :       (state) =>      state.skills,
        obtenerExp                  :       (state) =>      state.exp,
        obtenerIDvacancie           :       (state) =>      state.vacancie_id,
        getterVacancieId            :       (state) =>      state.vacancieId,
        getterCompanyId            :        (state) =>      state.companyId,
        getterIncidentId            :       (state) =>      state.incidentId,
    },
};
