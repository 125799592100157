<template>
<div :class="[mainTheme]">
  <form @submit.prevent="submitForm">
    <div class="inputs" v-for="(field, index) in formFields" :key="index">

      <div v-if="field.type === 'select'" class="selects">
        <md-filled-select :label="field.label" v-model="formData[field.name]" required @change="handleSelectChange">

          
          <md-select-option v-for="option in field.options" :key="option.value" :value="option.value" :selected="option.selected">
            <div slot="headline" class="options-select">{{ option.label }}</div>
          </md-select-option>
        </md-filled-select>
      </div>

      <div v-else-if="field.type === 'files'" class="file is-centered is-boxed has-name">
        <label class="file-label">
          <input class="file-input" type="file" @change="handleFileChange" :name="field.name" required>
          <span class="file-cta is-blur">
            <span class="file-icon">
              <span class="material-symbols-outlined">attach_file</span>
            </span>
            <span class="file-label ">{{ selectedFileName || 'Subir archivo' }}
            </span>
          </span>
        </label>
      </div>

      <div v-else>
        <Transition name="bounce">
          <md-filled-text-field
            v-if="field.name !== 'salary' || (field.name === 'salary' && formData.ofrecerSueldo === 'si') || formData.salary > 0"
            :label="field.label" :type="field.type" :maxlength="field.maxlength" :supporting-text="field.supportingText" 
            v-model="formData[field.name]" required></md-filled-text-field>
        </Transition>
      </div>

    </div>

    <md-elevated-button type="submit">Enviar</md-elevated-button>

  </form>

  <Transition name="bounce">
    <ModalComponent v-if="showModal" :title="modalTitle" :modalContent="modalContent">
      <template v-slot:buttons>
        <md-elevated-button v-if="respuestaExitosa" @click="handleAccept">
          Aceptar
        </md-elevated-button>
        <md-elevated-button v-if="!respuestaExitosa" @click="errorButton">
          Aceptar
        </md-elevated-button>
      </template>
    </ModalComponent>
  </Transition>

  <Transition name="bounce">
    <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-body">
          <div> Enviando los datos... </div>
          <md-circular-progress four-color indeterminate></md-circular-progress>
        </section>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
    </div>
  </Transition>
</div>
</template>





<style scoped src="../styles/components/FormComponent.css"></style>
<script>
import jwt_decode           from        'jsonwebtoken/decode';
import ModalComponent       from        '../components/ModalComponent.vue';

export default
{
  inheritAttrs  :   false,
  emits         :   ['estadoSelected'],



  components: 
  {
    ModalComponent,
  },



  props:
  {
    show        : Boolean,
    formFields  :
    {
      type      : Array,
      required  : true,
      formId    : String,
    },

    apiUrl      : 
    {
      type      : String,
      required  : true,
    },
  },



  data() 
  {
    const token         =   localStorage.getItem('token');
    const decodedToken  =   jwt_decode(token);
    const id_company      =   decodedToken.userCompanyId;
    return  {
              isFetching          :   false,
              actionOnSuccess     :   '',
              formData            :   {  },
              modalContent        :   '',
              modalTitle          :   '',
              respuestaExitosa    :   false,
              showModal           :   false,
              id_company          :   id_company,
              selectedFileName    :   null,


              // MENSAJES PARA EL MODAL CUANDO LA API ES EXITOSA
              messageNewVacancieSuccess  :    `¡Buenas noticias! Tu Unidad Receptora  se ha registrado correctamente. 
                                              Todos los detalles están correctos. Pronto revisaremos la vacante.
                                              Para ver el estado de tu solicitud, ve a Unidad Receptora  > Listado’. Ahí puedes ver el estado de tus vacantes.`,
              
              //MENSAJES PARA EL MODAL CUANDO EXISTE UN ERROR EN LA API 
              messageNewVacancieError    :    `Lamentamos informar que no ha sido posible completar el registro de la vacante. Por favor, 
                                              asegúrese de que los datos ingresados sean válidos y no contengan caracteres inusuales. 
                                              Si el problema persiste, le recomendamos ponerse en contacto con su administrador para obtener asistencia adicional. 
                                              Agradecemos su comprensión`,
              
              // como son siempre formularios en este componente el mesanje de error del server siempre sera el mismo
              messageServerError          :   `No fue posible enviar los datos al servidor, compruebe su conexión a Internet e inténtelo de nuevo,
                                              si el problema persiste contacte con su administrador.`
            };
  },



  watch: 
  {
    formFields(newValue) 
    {
      this.formData = newValue.reduce((obj, field) => ({ ...obj, [field.name]: field.value || '' }), {id_company : this.id_company  });
    },

    'formData.ofrecerSueldo': function(newOfrecerSueldo, oldOfrecerSueldo) 
    {
      if (newOfrecerSueldo === 'no') { this.formData.salary = 0; }
    }
  },



  mounted() 
  {
    // Verificar que formFields esté definido antes de utilizarlo
    if (this.formFields) 
    {
      this.formData = this.formFields.reduce((obj, field) => ({ ...obj, [field.name]: field.value || '' }), {id_company : this.id_company});
    } 
    else 
    {
        console.error('formFields is undefined');
    }
    console.log('formData antes de enviar:', this.formData);
  },



  methods:
  {
    handleFileChange(event) 
    {
      const file            =   event.target.files[0];
      this.selectedFileName =   file ? file.name : null;
    },


    handleAccept() 
    {
      if (this.actionOnSuccess === 'action1') 
      {
        this.$router.push({ name: 'empresa-inicio' });
      }
      
      else if (this.actionOnSuccess === 'action2') 
      {
        console.log('Realizando acciones para action2');
      }

      this.showModal = false;
    },


    errorButton() 
    {
      this.showModal = false;
    },


    handleSelectChange() 
    {
      this.$emit('estado-selected', this.formData.state_id);
      console.log('Evento handleSelectChange ejecutado');
    },


    submitForm() 
    {
      console.log('Datos del formulario:', this.formData);
      console.log('Datos del apiURL:',  process.env.VUE_APP_API_COMPANY_POST_NEWVACANCIE);
      this.sendDataToApi(); 
    },


    async sendDataToApi() 
    {
      if(this.apiUrl === process.env.VUE_APP_API_COMPANY_POST_NEWVACANCIE)
      {
        try 
        {
          const token         =   localStorage.getItem('token');
          const decodedToken  =   jwt_decode(token);
          const idCompany     =   decodedToken.userCompanyId;


          this.isFetching = true;
          await new Promise(resolve => setTimeout(resolve, 3000));

          // aqui se envian los datos del formulario, es valido para cualquiera por que somos unos cracks
          const response = await fetch(this.apiUrl, 
          {
            method  : 'POST',
            headers : 
            { 
                'Content-Type'  :   'application/json',
                'Authorization' :   `Bearer ${token}`,
            },
            body    : JSON.stringify(this.formData),
          });


          this.isFetching = false;
          // primero el caso de que hubo en error en la api, ojo wachin no confundir con error 500
          if (!response.ok) 
          {
            if (this.apiUrl === process.env.VUE_APP_API_COMPANY_POST_NEWVACANCIE)
            {
              const errorMessage      =   await response.text();
              this.showModal          =   true;
              this.modalTitle         =   'Ha Ocurrido un Error :( ';
              this.modalContent       =   `${this.messageNewVacancieError}   \n \n MENSAJE DE ERROR:  ${errorMessage}`
              this.respuestaExitosa   =   true;
            }
          }


          if (this.apiUrl === process.env.VUE_APP_API_COMPANY_POST_NEWVACANCIE && response.ok) 
          {
            this.showModal          =   true;
            this.modalTitle         =   'Proceso Exitoso';
            this.modalContent       =   this.messageNewVacancieSuccess
            this.respuestaExitosa   =   true;
            this.actionOnSuccess    =   'action1';
          } 
          else if (this.apiUrl === 'URL_DE_LA_SEGUNDA_API') 
          {
            this.showModal = true;
            this.modalTitle = 'Proceso Exitoso (API 2)';
            this.modalContent = 'Mensaje específico para la API 2';
            this.respuestaExitosa = true;
          }

        }
        catch (error) 
        {
          this.isFetching = false;
          console.error('Error al enviar datos a la API:', error);

          if (this.apiUrl === process.env.VUE_APP_API_COMPANY_POST_NEWVACANCIE) 
          {
            console.error('Error en el catch general:', error);
            this.showModal      =     true;
            this.modalTitle     =     'ERROR';
            this.modalContent   =     `${this.messageServerError} \n \n MENSAJE DE ERROR: ${error.message}. `;
          } 
          else if (this.apiUrl === 'URL_DE_LA_SEGUNDA_API') 
          {
            this.showModal      =   true;
            this.modalTitle     =   'ERROR (API 2)';
            this.modalContent   =   'Mensaje específico para el error en la API 2';
          }
        }
      }


      if(this.apiUrl === process.env.VUE_APP_API_COMPANY_POST_NEWVACANCIE_WORK)
      {
        try 
        {
          const token         =   localStorage.getItem('token');
          const decodedToken  =   jwt_decode(token);
          const idCompany     =   decodedToken.userCompanyId;
          this.formData.type = 3;
          this.formData.salary = this.formData.salaryWork;


          this.isFetching = true;
          await new Promise(resolve => setTimeout(resolve, 3000));

          // aqui se envian los datos del formulario, es valido para cualquiera por que somos unos cracks
          const response = await fetch(this.apiUrl, 
          {
            method  : 'POST',
            headers : 
            { 
                'Content-Type'  :   'application/json',
                'Authorization' :   `Bearer ${token}`,
            },
            body    : JSON.stringify(this.formData),
          });


          this.isFetching = false;
          // primero el caso de que hubo en error en la api, ojo wachin no confundir con error 500
          if (!response.ok) 
          {
            if (this.apiUrl === process.env.VUE_APP_API_COMPANY_POST_NEWVACANCIE_WORK)
            {
              const errorMessage      =   await response.text();
              this.showModal          =   true;
              this.modalTitle         =   'Ha Ocurrido un Error :( ';
              this.modalContent       =   `${this.messageNewVacancieError}   \n \n MENSAJE DE ERROR:  ${errorMessage}`
              this.respuestaExitosa   =   true;
            }
          }


          if (this.apiUrl === process.env.VUE_APP_API_COMPANY_POST_NEWVACANCIE_WORK && response.ok) 
          {
            this.showModal          =   true;
            this.modalTitle         =   'Proceso Exitoso';
            this.modalContent       =   this.messageNewVacancieSuccess
            this.respuestaExitosa   =   true;
            this.actionOnSuccess    =   'action1';
          } 
          else if (this.apiUrl === 'URL_DE_LA_SEGUNDA_API') 
          {
            this.showModal = true;
            this.modalTitle = 'Proceso Exitoso (API 2)';
            this.modalContent = 'Mensaje específico para la API 2';
            this.respuestaExitosa = true;
          }

        }
        catch (error) 
        {
          this.isFetching = false;
          console.error('Error al enviar datos a la API:', error);

          if (this.apiUrl === process.env.VUE_APP_API_COMPANY_POST_NEWVACANCIE_WORK) 
          {
            console.error('Error en el catch general:', error);
            this.showModal      =     true;
            this.modalTitle     =     'ERROR';
            this.modalContent   =     `${this.messageServerError} \n \n MENSAJE DE ERROR: ${error.message}. `;
          } 
          else if (this.apiUrl === 'URL_DE_LA_SEGUNDA_API') 
          {
            this.showModal      =   true;
            this.modalTitle     =   'ERROR (API 2)';
            this.modalContent   =   'Mensaje específico para el error en la API 2';
          }
        }
      }
    },

  },

};
</script>