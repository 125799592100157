<template>
    <div class="material-you light">
        <HeaderComponent />

        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>

        <article class="message">
            <MessageComponent title="Información desss la vacante" :message="descripcion" />
        </article>


        <div class="navbar-center">
            <div class="navbar-item">

                <div class="notiBotton">
                    <md-elevated-button class="menu-button" @click="toggleMenu">
                        Información de la Vacante
                    </md-elevated-button>
                </div>

            </div>
        </div>

        <Transition>
            <div class="detailsVacancie" v-if="mostrarLista">
                <div class="card-perfil">
                    <CardComponent>

                        <!-- Slot para el título -->
                        <template v-slot:title>
                        </template>


                        <template v-slot:content>
                            <div class="media-content-info">
                                <div class="content-postulados">
                                    <p style="text-align: left;">Información de la Vacante</p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em;"> <span
                                            class="tag is-info-custom">Empresa Responsable</span> {{ vacancieDetails.vacancieInfo.company_name }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Nombre de la Vacante</span> {{ vacancieDetails.vacancieInfo.name }}</p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Creada en </span>
                                        {{ new
                                            Date(vacancieDetails.vacancieInfo.creation_log).
                                            toLocaleDateString('es-MX', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'}) 
                                        }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                        <span class="tag is-info-custom">Descripción</span> {{ vacancieDetails.vacancieInfo.description }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: justify; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Dirección</span> {{ vacancieDetails.vacancieInfo.address }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Salario</span>
                                        {{ vacancieDetails.salary !== null ? '$' + vacancieDetails.vacancieInfo.salary : 'No aplica' }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Notas Adicionales</span> {{ vacancieDetails.vacancieInfo.notes }}</p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Estado</span> {{ vacancieDetails.vacancieInfo.state_name }}</p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Municipio</span> {{ vacancieDetails.vacancieInfo.muni_name }}</p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Sector</span> {{ vacancieDetails.vacancieInfo.sector_name }}</p>
                                </div>
                            </div>
                        </template>

                        <template v-slot:buttons>

                        </template>

                    </CardComponent>
                </div>

            </div>
        </Transition>


        <div class="tabs " style="width: 100%;">
            <md-tabs v-model="activeTab" :style="{ borderRadius: '15em' }">
                <md-primary-tab v-for="(tab, index) in tabs" :key="index" :name="tab.name" @click="changeTab(tab.name)">
                    {{ tab.label }}
                </md-primary-tab>
            </md-tabs>
        </div>

        <div class="job-table">
            <TableComponent :columns="tableColumns"
                :items="activeTab === 'tab1' ? tableItemsTab1 : (activeTab === 'tab2' ? tableItemsTab2 : (activeTab === 'tab3' ? tableItemsTab3 : (activeTab === 'tab4' ? tableItemsTab4 : (activeTab === 'tab5' ? tableItemsTab5 : []))))">

                <template v-slot:cell="{ item, column }">
                    <span v-if="column.field === 'Nombre'">{{ item.nombre }}</span>
                    <span v-else-if="column.field === 'Company'">{{ item.empresa }}</span>
                    <span v-else-if="column.field === 'Fecha'">{{ item.fecha }}</span>
                </template>

            </TableComponent>
        </div>

        <FooterComponent />

    </div>
</template>




<style scoped src="../styles/material/theme.light.css"></style>
<style scoped src="../styles/material/theme.dark.css"></style>
<style scoped src="../styles/views/VacantesListadoCerrada.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style>

<script>
import CardComponent        from    '../components/CardComponent.vue';
import FooterComponent      from    '../components/FooterComponent.vue';
import HeaderComponent      from    '../components/HeaderComponent.vue';
import jwt_decode           from    'jsonwebtoken/decode';
import MenuComponent        from    '../components/MenuComponent.vue';
import MessageComponent     from    '../components/MessageComponent.vue';
import TableComponent       from    '../components/TableComponent.vue';


export default
{
    name: 'InfoContacto',
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        TableComponent,
    },


    //cargamos la vacante al crear el componente
    async created() 
    {
        await this.loadvacancie();
        await this.load_requests();
        await this.load_assistance();
    },


    data() 
    {
        return  {
                    activeTab   :   'tab1',
                    descripcion :   `Aquí puedes visualizar en detalle tu vacante abierta. Además,
                                    tienes la posibilidad de acceder a las solicitudes recibidas para dicha vacante.
                                    Esto incluye la revisión de los perfiles de los candidatos, sus credenciales
                                    y la posibilidad de interactuar con ellos. También puedes ver las confirmaciones de primera asistencia,
                                    lo que te permite tener un control y seguimiento de los candidatos que han mostrado un interés inicial
                                    y han confirmado su disponibilidad. Nuestra plataforma está diseñada para hacer el proceso de contratación
                                    más eficiente y efectivo. Gracias por utilizar nuestro servicio`,
                    tabs:
                    [
                        { name  : 'tab1', label : 'Solicitudes de Ingreso'      },
                        { name  : 'tab2', label : 'Confirmación de Asistencia'  },
                    ],

                    tableItemsTab1:
                    [
                        // { id: 1, img: '', nombre: 'Ana Sofía López Ramírez', actividad: 'Cerillo', estado: 'En Progreso' },
                    ],

                    tableItemsTab2: [],

                    tableColumns:
                    [
                        { label :   'Nombre',       field   :  'nombre'        },
                        { label :   'Actividad',    field   :  'actividad'     },
                        { label :   'Acciones',     field   :  'Candidatos'    },
                    ],

                    vacancieDetails :   null,
                    studentDetails  :   null,
                    requestsList    :   null,
                    assistance      :   null,
                    mostrarLista    :   false,
                
                }
    },


    methods:
    {
        changeTab(tab) 
        {
            this.activeTab = tab;
        },


        toggleMenu() 
        {
            this.mostrarLista = !this.mostrarLista;
        },


        //cargamos la informacion de la vacante a rechazar o aceptar
        async loadvacancie() 
        {
            try 
            {
                const vacancie      =   this.$store.getters.getterVacancieId; //mover a created
                const token         =   localStorage.getItem('token');
                const id_vacancie   =   vacancie;
                const decodedToken  =   jwt_decode(token);
                const id_company    =   decodedToken.userCompanyId;

                const response      =   await fetch(process.env.VUE_APP_COMPANY_POST_VACANCIE_INFO, 
                {
                    method: 'POST',
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ id_vacancie: id_vacancie, id_company: id_company }),
                });

                if (!response.ok) 
                {
                    throw new Error('Error al cargar la vacante');
                }

                const data = await response.json();
                this.vacancieDetails = data;
                console.log("DATOS DE LA API:", data)
            }
            catch (error) 
            {
                console.log(error);
            }
        },


        //Cargamos las solicitudes para unirse que tiene esta vacante
        async load_requests() 
        {
            try 
            {                
                const spaces        =   " ";
                const vacancie      =   this.$store.getters.getterVacancieId;
                const token         =   localStorage.getItem('token');
                const idVacancie    =   vacancie;
                const decodedToken  =   jwt_decode(token);
                const idCompany     =   decodedToken.userCompanyId;

                const response = await fetch(process.env.VUE_APP_COMPANY_POST_REQUESTLISTBYVACANCIE, 
                {
                    method: 'POST',
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ idVacancie: idVacancie, idCompany: idCompany }),
                })

                if (!response.ok) 
                {
                    throw new Error('Error al cargar la vacante');
                }

                const data = await response.json();
                this.requestsList = data.list;
                console.log("LISTADO DE REQUEST:", data.list)

                this.tableItemsTab1 = data.list.map(item =>
                ({
                    id: item.id,
                    vacancieId: item.id_vacancie,
                    nombre: item.last_name_A + spaces + item.last_name_B + spaces + item.name,
                    actividad: item.vacancie_name,
                    fecha: item.creation_log.split('T')[0],
                }));
            }
            catch (error) 
            {
                console.log(error);
            }
        },

        
        //cargamos el listado de las conformaciones para la primera asistencia
        async load_assistance() 
        {
            try 
            {
                const spaces        =   " ";
                const vacancie      =   this.$store.getters.getterVacancieId;
                const token         =   localStorage.getItem('token');
                const idVacancie    =   vacancie;
                const decodedToken  =   jwt_decode(token);
                const idCompany     =   decodedToken.userCompanyId;

                const response      =   await fetch(process.env.VUE_APP_COMPANY_POST_CONFIRM_ASSIST_LIST, 
                {
                        method: 'POST',
                        headers:
                        {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({ idVacancie: idVacancie, idCompany: idCompany }),
                })

                if (!response.ok) 
                {
                    throw new Error('Error al cargar la vacante');
                }

                const data          = await response.json();
                this.assistance     = data.firstAssistance;
                console.log("LISTADO DE CONFIRMACIONES:", data.firstAssistance)

                this.tableItemsTab2 = data.firstAssistance.map(item =>
                    ({
                        id: item.id,
                        vacancieId: item.id_vacancie,
                        // studentId : item.id_student,
                        nombre: item.last_name_A + spaces + item.last_name_B + spaces + item.name,
                        actividad: item.vacancie_name,
                    }));
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    }
}
</script>