<template>

  <!-- material theme -->
  <div class="material-you light ">

    <!-- header -->
    <HeaderComponent />

    <!-- main menu -->
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>

    <!-- tooltips -->
    <article class="message">
      <MessageComponent title="Nueva Solicitud" :message="descripcion" />
    </article>

    <!-- forms -->
    <div class="container">
      <form @submit.prevent="submitForm">
        <FormComponent :formFields="formFields" formId="formulario1" :apiUrl="apiUrl" @estado-selected="handleEstadoSelected" />
      </form>
    </div>

    <!-- footer -->
    <FooterComponent />

  </div>
</template>
  
  
  
  
  
  
<style scoped src = "../styles/material/theme.dark.css"       ></style> 
<style scoped src = "../styles/material/theme.light.css"      ></style> 
<style scoped src = "../styles/views/MaterialTheming.css"     ></style>
<style scoped src = "../styles/views/BolsaTrabajoNueva.css"   ></style>

<script>
import FooterComponent      from        '../components/FooterComponent.vue';
import FormComponent        from        '../components/FormComponent.vue';
import HeaderComponent      from        '../components/HeaderComponent.vue';
import MenuComponent        from        '../components/MenuComponent.vue';
import MessageComponent     from        '../components/MessageComponent.vue';
  
  
export default
{
  components:
  {
    FooterComponent,
    FormComponent,
    HeaderComponent,
    MenuComponent,
    MessageComponent,
  },
  
  
  
  data() 
  {
    return  {
              estados                 :   [],
              estadoSeleccionado      :   null,
              apiUrl                  :   process.env.VUE_APP_API_COMPANY_POST_NEWVACANCIE_WORK,
              descripcion             :   `Ingresa los detalles de tus ofertas de trabajo en nuestro formulario exclusivo para empresas.
                                          Cada campo es esencial; asegúrate de completar toda la información obligatoria para conectarte eficazmente
                                          con candidatos cualificados. Tu próxima contratación está a solo unos clics de distancia."`,

              formFields  :
              [
                { label   : "Nombre", type: "text", name: "name", maxlength: 200 },
  
                {
                  label   : "Giro de la Empresa", type: "select", name: "business_sectors_id",
                  options : []
                },
  
                {
                  label   : "Seleccione el Estado",
                  type    : "select",
                  name    : "state_id",
                  options : [],
                },
  
                {
                  label   : "Seleccione el Municipio", 
                  type    : "select", 
                  name    : "municipality_id",
                  options : []
                },
  
                { 
                  label   : "Número de vacantes", 
                  type    : "number", 
                  name    : "w_hiring_capacity" 
                },
                
                { label: "Salario", type: "number", name: "salaryWork",  supportingText: "*Monto Mensual Neto"},
                
                { label: "Domicilio", type: "textarea", name: "address", maxlength: 1000 },
                
                { label: "Descripcion", type: "textarea", name: "description", maxlength: 1000, supportingText: "Incluye tus requisitos, como horario, experiencia, aptitudes etc" },
                
                { 
                  label: "Notas Adicionales", type: "textarea", name: "notes", maxlength: 1000, 
                  supportingText: "Incluye cualquier información pertinente que no haya sido mencionada en los puntos anteriores." 
                },
              ],
            };
  },
  
  
  mounted() 
  {
    this.loadEstados();
    this.loadSectors();
    this.loadBoards();
  },
  
  
  
  methods:
  {
    async loadEstados() 
    {
      try 
      {
        const response = await fetch(process.env.VUE_APP_API_COMPANY_GET_LOADSTATES);
          
        if (!response.ok) 
        {
              throw new Error('Error al cargar estados');
        }
  
        const data    =   await response.json();
        this.estados  =   data.state_mexico;
        console.log('respuesta lokita',data);
  
        // Actualiza las opciones del campo de selección
        this.formFields.find(field => field.name === 'state_id').options = this.estados.map(state_id =>
        ({
            value: state_id.id.toString(),
            label: state_id.nombre,
        }));

      }
      catch (error) 
      {
        console.error('Error al cargar estados:', error);
      }
  
    },


    async loadSectors() 
    {
      try 
      {
        const response = await fetch(process.env.VUE_APP_API_COMPANY_GET_LOADSECTORS);
  
        if (!response.ok) 
        {
          throw new Error('Error al cargar estados');
        }
  
        const data = await response.json();
  
        this.sectors = data.sectors;
  
        // Actualiza las opciones del campo de selección
        this.formFields.find(field => field.name === 'business_sectors_id').options = this.sectors.map(sector =>
        ({
          value: sector.id.toString(),
          label: sector.name,
        }));
      }
      catch (error) 
      {
        console.error('Error al cargar estados:', error);
      }
  
    },


    async loadBoards() 
    {
      try 
      {
        const response = await fetch(process.env.VUE_APP_API_COMPANY_GET_LOADBOARDS);
  
        if (!response.ok) 
        {
          throw new Error('Error al cargar estados');
        }
  
        const data = await response.json();
        this.types = data.boards;
  
        // Actualiza las opciones del campo de selección
        this.formFields.find(field => field.name === 'type').options = this.types.map(type =>
        ({
          value: type.id.toString(),
          label: type.type,
        }));
  
      }
      catch (error) 
      {
        console.error('Error al cargar estados:', error);
      }
    },
    
    
    async handleEstadoSelected(valorSeleccionado) 
    {
      this.estadoSeleccionado = valorSeleccionado;
  
      // Verifica si el estado seleccionado es válido antes de hacer la llamada a la API
      if (this.estadoSeleccionado !== null) 
      {
        // Realiza la llamada a la API para enviar datos y obtener los municipios
        try 
        {
          console.log('Antes de la llamada a la API');
          const response = await fetch(process.env.VUE_APP_API_COMPANY_POST_LOADMUNI, 
          {
              method    :   'POST',
              headers   :   { 'Content-Type': 'application/json', },
              body      :   JSON.stringify({
              states    :   this.estadoSeleccionado, }),
          });

          if (!response.ok) 
          {
            throw new Error('Error al enviar datos y obtener municipios');
          }

          const data = await response.json();
          console.log('data de los municipios',data);

          // Actualiza las opciones del campo de selección 'municipality_id'
          this.formFields.find(field => field.name === 'municipality_id').options = data.muni_mexico.map(municipality_id => ({
          value: municipality_id.id.toString(),
          label: municipality_id.nombre, }));

        } 
        catch (error) 
        {
            console.error('Error al enviar datos y obtener municipios:', error);
        }
      }
    },
  
  }
  
};
</script>
