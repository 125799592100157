<template>
  <div :class="[mainTheme]">
    <div class="custom-container">
      <footer class="page-footer">
        <div class="content has-text-right">
          <p class="custom-paragraph">Dirección de vinculación y servicio social UMSNH 2024</p>
        </div>
      </footer>
    </div>
  </div>
</template>







<style scoped src="../styles/components/FooterComponent.css"></style>

<style scoped src = "../styles/material/theme.light.css"    ></style> 
<style scoped src = "../styles/material/theme.dark.css"     ></style> 
<script>
export default 
{
data() 
 {
   return  {

             mainTheme: 'light',
           };
 },


mounted() 
{
 this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
 this.mediaQuery.addEventListener('change', this.handleThemeChange);
 this.handleThemeChange(this.mediaQuery);
},


methods: 
{
 handleThemeChange(e) 
 {
   this.mainTheme = e.matches ? 'dark' : 'light';
   console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
 }
},


beforeUnmount() 
{
 if (this.mediaQuery) 
 {
   this.mediaQuery.removeEventListener('change', this.handleThemeChange);
 }
},
};
</script>