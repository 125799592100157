<template>
  <div class="custom-container">
    <footer class="page-footer">
      <div class="content has-text-right">
        <p class="custom-paragraph">Dirección de vinculación y servicio social UMSNH 2024</p>
      </div>
    </footer>
  </div>
</template>







<style scoped src="../styles/components/FooterComponent.css"></style>
