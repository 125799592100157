<template>

    <!-- material theme -->
    <div :class="['material-you', mainTheme]">

        <!-- header -->
        <HeaderComponent />

        <!-- main menu -->
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>

        <!-- tooltips -->
        <article class="message">
            <MessageComponent title="Detalles del Empleado" :message="descripcion" />
        </article>

        <!-- vacancie info button -->
        <div class="navbar-center">
            <div class="navbar-item">
                <div class="notiBotton">
                    <md-elevated-button class="menu-button" @click="toggleMenu">
                        Información de la Vacante
                    </md-elevated-button>
                </div>
            </div>
        </div>

        <!-- vacancie info slide -->
        <Transition>
            <div class="detailsVacancie" v-if="mostrarLista">
                <div class="card-perfil">

                    <CardComponent>

                        <template v-slot:title>
                        </template>

                        <template v-slot:content>
                            <div class="media-content-info">
                                <div class="content-postulados">
                                    <p style="text-align: left;">Información de la Vacante</p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em;">
                                        <span class="tag is-info-custom">Empresa Responsable
                                        </span>
                                        {{ vacancieDetails.vacancieInfo.company_name }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Nombre de la Vacante
                                        </span>
                                        {{ vacancieDetails.vacancieInfo.name }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Creada en
                                        </span>
                                        {{ 
                                            new Date(vacancieDetails.vacancieInfo.creation_log).toLocaleDateString('es-MX', {
                                            weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })
                                        }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                        <span class="tag is-info-custom">Descripción</span>
                                        {{ vacancieDetails.vacancieInfo.description }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: justify; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Dirección</span>
                                        {{ vacancieDetails.vacancieInfo.address }}
                                    </p>

                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Salario</span>
                                        {{ vacancieDetails.salary !== null ? '$' + vacancieDetails.vacancieInfo.salary : 'No aplica' }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Notas Adicionales
                                        </span>
                                        {{ vacancieDetails.vacancieInfo.notes }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Estado
                                        </span> {{ vacancieDetails.vacancieInfo.state_name }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Municipio
                                        </span>
                                        {{ vacancieDetails.vacancieInfo.muni_name }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Sector
                                        </span>
                                        {{ vacancieDetails.vacancieInfo.sector_name }}
                                    </p>

                                </div>
                            </div>

                        </template>
                    </CardComponent>
                </div>
            </div>
        </Transition>

        <!-- student deatils -->
        <div class="aboutMe">
            <CardComponent>
                <template v-slot:content>
                    <div class="card-content">
                        <div class="media">
                            <div class="media-left">
                                <div class="media-content-image has-text-centered">
                                    <figure class="image custom-image">
                                        <img src="https://xsgames.co/randomusers/avatar.php?g=female" alt="User Avatar"
                                            class="rounded-image">
                                    </figure>
                                </div>
                            </div>
                            <div class="content-perfil">
                                <p class="perfil">{{ studentName }} {{ studentLastNameB }} {{ studentName }}</p>
                                <p class="perfil">{{ email }}</p>
                            </div>
                        </div>
                    </div>
                </template>
            </CardComponent>
        </div>
        <div class="soySeparador"></div>

        <div class="card-perfil">
            <CardComponent>
                <template v-slot:title>
                    <div class="media-content-info">
                        <p class="has-text"> Nombre del Departamento: {{ name_department }}</p>
                        <p class="has-text">Carrera: {{ career }}</p>
                        <p class="has-text">Habilidades: {{ skills }}</p>
                        <p class="has-text">Experiencia: {{ exp }}</p>
                        <p class="has-text">Acerca de mi: {{ aboutMe }}</p>
                    </div>
                </template>
                <template v-slot:buttons>
                    <div class="buttons is-centered is-grouped">
                        <md-elevated-button style="margin-right: 20px;" @click="irAsistencias">
                            Asistencias & Horas
                        </md-elevated-button>
                        <md-elevated-button @click="irAIncidencias">
                            Incidencias
                        </md-elevated-button>
                    </div>
                </template>
            </CardComponent>
        </div>

        <!-- footer -->
        <FooterComponent />
    </div>
</template>





<style scoped src   =   "../styles/material/theme.light.css"            ></style> 
<style scoped src   =   "../styles/material/theme.dark.css"             ></style> 
<style scoped src   =   "../styles/views/EmpleadosActualesDetalles.css" ></style>
<style scoped src   =   "../styles/views/MaterialTheming.css"           ></style>

<script>
import CardComponent                    from        '../components/CardComponent.vue';
import FooterComponent                  from        '../components/FooterComponent.vue';
import HeaderComponent                  from        '../components/HeaderComponent.vue';
import jwt_decode                       from        'jsonwebtoken/decode';
import MenuComponent                    from        '../components/MenuComponent.vue';
import MessageComponent                 from        '../components/MessageComponent.vue';
import TableComponent                   from        '../components/TableComponent.vue';

import { loadStudent, loadVacancie }    from        '../api/ApiService';



export default
{
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        TableComponent,
    },



    async created() 
    {   
        try 
        {
            const data              =   await loadVacancie(this.$store);
            this.vacancieDetails    =   data;
            console.log("DATOS DE LA API:", data);
        } 
        catch (error) 
        {
            console.error('Error loading vacancie:', error);
        }


        try 
        {
            // const data              =   await loadVacancie(this.$store);
            const studentInfo = await loadStudent(this.$store);
            console.log('estos son los datos de la api: ', studentInfo);

            this.studentName = studentInfo.name;
            this.studentLastNameA = studentInfo.last_name_A;
            this.studentLastNameB = studentInfo.last_name_B;
            this.email = studentInfo.email;
            this.enrollment = studentInfo.enrollment;
            this.name_department = studentInfo.name_department;
            this.career = studentInfo.career;
            this.skills = studentInfo.skills;
            this.exp = studentInfo.exp;
        } 
        catch (error) 
        {
            console.error('Server Error:', error);
        }
    },



    data() 
    {
        return  {
                    mostrarLista        :       false,
                    studentDetails      :       null,
                    vacancieDetails     :       null,

                    activeTab           :       'tab1',
                    mainTheme           :       'light',
                    career              :       '',
                    email               :       '',
                    enrollment          :       '',
                    name_department     :       '',
                    skills              :       '',
                    studentLastNameA    :       '',
                    studentLastNameB    :       '',

                    descripcion         :       `En este espacio, te ofrecemos la posibilidad de explorar con detalle
                                                la información de tu empleado. Desde sus datos personales hasta la gestión
                                                de sus asistencias, aquí encontrarás todo lo necesario para administrar
                                                eficientemente su participación en la empresa. Además, en caso de cualquier
                                                problema o incidencia, puedes iniciar o dar seguimiento para garantizar una rápida
                                                resolución y un ambiente laboral óptimo`,
                    tableColumns    :
                    [
                        { label     :   'Nombre',       field   :   'nombre'                },
                        { label     :   'Actividad',    field   :   'actividad'             },
                        { label     :   'Estado',       field   :   'estado'                },
                        { label     :   'Detalles',     field   :   'misAlumnosDetalles'    },
                    ],

                    tableItemsTab1      :       [],

                }
    },



    methods:
    {
        handleThemeChange(e) 
        {
            this.mainTheme = e.matches ? 'dark' : 'light';
            console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
        },


        toggleMenu()
        {
            this.mostrarLista = !this.mostrarLista;
        },


        irAIncidencias()
        {
            this.$router.push('/empresa/empleados/actuales/detalles/incidencias');

            console.log('id de la vacante', this.$store.getters.getterVacancieId);
            this.$store.commit('SET_VACANCIE_ID', this.$store.getters.getterVacancieId);

            console.log('id del student lokit', this.$store.getters.getterStudentId);
            this.$store.commit('SET_STUDENT_ID', this.$store.getters.getterStudentId);
        },


        irAsistencias()
        {
            this.$router.push('/empresa/empleados/actuales/detalles/asistencia-horas');

            console.log('id de la vacante', this.$store.getters.getterVacancieId);
            this.$store.commit('SET_VACANCIE_ID', this.$store.getters.getterVacancieId);

            console.log('id del student lokit', this.$store.getters.getterStudentId);
            this.$store.commit('SET_STUDENT_ID', this.$store.getters.getterStudentId);
        },
    },


    mounted() 
    {
        this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        this.mediaQuery.addEventListener('change', this.handleThemeChange);
        this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
    },



    beforeUnmount() 
    {
        if (this.mediaQuery) 
        {
            this.mediaQuery.removeEventListener('change', this.handleThemeChange);
        }
    },
}


</script>