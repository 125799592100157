<template>
  <div :class="['material-you', mainTheme]">
    <HeaderComponent />
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>
    <div class="card-perfil">
      <CardComponent>
        <template v-slot:content>
          <div class="card-content">
            <div class="media">
              <div class="media-content-image">
                <figure class="image">
                  <img :src="user_picture" alt="User Avatar" class="rounded-image" />
                </figure>
              </div>
              <div class="content-perfil">
                <p class="perfil nombre">{{ studentLastNameA }} {{ studentLastNameB }} {{ studentName }}</p>
                <p class="perfil email">{{ email }}</p>
                <p class="perfil matricula">Matricula: {{ enrollment }}</p>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:buttons>
          <div class="subirCV">
  <md-elevated-button @click="handleCV">
    {{ cvStudent ? 'Actualizar Curriculum Vitae' : 'Subir Curriculum Vitae' }}
  </md-elevated-button>
  <div class="separa"></div>
  <md-elevated-button v-if="cvStudent" @click="viewCV">
    Visualizar Curriculum vitae
  </md-elevated-button>
</div>

        </template>
      </CardComponent>
    </div>
    <div class="card-perfil">
      <CardComponent>
        <template v-slot:title>
          <div class="media-content-info">
            <br>
            <p class="has-text">Acerca de ti : {{ aboutMe }}</p>
            <br>
            <p class="has-text">Institución : {{ name_department }}</p>
            <br>
            <p class="has-text">Carrera: {{ career }}</p>
            <br>
            <p class="has-text">Habilidades: {{ skills }}</p>
            <br>
            <p class="has-text">Experiencia: {{ exp }}</p>
            <br>
          </div>
        </template>
        <template v-slot:buttons>
          <div class="buttons is-centered">
            <router-link :to="{ name: 'estudiante-perfil-editar-perfil' }">
              <md-elevated-button>Editar Perfil</md-elevated-button>
            </router-link>
          </div>
        </template>
      </CardComponent>
    </div>
    <div class="separador"></div>
    <FooterComponent />

    <!-- modal para SUBIR EL CV  -->
    <Transition name="bounce">
      <ModalComponent v-if="showModalCV" :title="modalTitle" @close="closeModal" class="modal">
        <template v-slot:content>
          <div class="fondo">
            <span class="tag is-danger is-light">{{ subirCVinfo }}</span>
          </div>
          <div class="upload">
            <div class="field">
            <label class="label">Subir CV</label>
            <div class="file has-name">
              <label class="file-label">
                <input class="file-input" type="file" name="resume" @change="uploadFile">
                <span class="file-cta">
                  <span class="file-label">
                    <span class="material-symbols-outlined">attach_file</span> <!-- Outlined -->
                  </span>
                </span>
                <span class="file-name" v-if="file">
                  {{ file.name }}
                </span>
              </label>
            </div>
            <p class="help is-danger" v-if="fileErrorMessage">{{ fileErrorMessage }}</p> <!-- Mensaje de error -->
          </div>
            <div class="control">
              <button class="button is-link" @click="submitFile" :disabled="!file">Subir Archivo</button>
            </div>
          </div>
        </template>
        <template v-slot:buttons>
          <md-elevated-button @click="closeModal">
            Volver
          </md-elevated-button>
        </template>
      </ModalComponent>
    </Transition>

    <!-- modal para mostrar mensajes -->
    <Transition name="bounce">
      <ModalComponent v-if="showMessageModal" :title="isError ? 'Error' : 'Éxito'" @close="closeMessageModal">
        <template v-slot:content>
          <div class="message-content">
            <span :class="{'tag': true, 'is-danger': isError, 'is-success': !isError}">{{ modalMessage }}</span>
          </div>
        </template>
        <template v-slot:buttons>
          <md-elevated-button @click="closeMessageModal">
            Cerrar
          </md-elevated-button>
        </template>
      </ModalComponent>
    </Transition>

    <!-- modal loading -->
    <div class="loading">
      <Transition name="bounce">
        <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
          <div class="modal-background"></div>
          <div class="modal-card">
            <section class="modal-card-body">
              <div> Subiendo tu CV... {{ uploadProgress }}%</div>
              <md-circular-progress four-color indeterminate></md-circular-progress>
            </section>
          </div>
          <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
        </div>
      </Transition>
    </div>
  </div>
</template>

<style scoped src="../styles/material/theme.light.css"></style>
<style scoped src="../styles/material/theme.dark.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style>
<style scoped src="../styles/views/MiPerfil.css"></style>

<script>
import CardComponent from '../components/CardComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';
import HeaderComponent from '../components/HeaderComponent.vue';
import MenuComponent from '../components/MenuComponent.vue';
import ModalComponent from '../components/ModalComponent.vue';
import jwt_decode from 'jsonwebtoken/decode';
import { loadProfileApi, loadCV } from '../api/ApiService';

export default {
  name: 'PerfilStaff',

  components: {
    CardComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    ModalComponent,
  },

  data() {
    return {
      mainTheme: 'light',
      file: null,
      showModalCV: false,
      showMessageModal: false,
      modalMessage: '',
      isError: false,
      user_picture: localStorage.getItem('avatar'),

      isFetching: false,
      uploadProgress: 0,
      studentName: '',
      studentLastNameA: '',
      studentLastNameB: '',
      enrollment: '',
      email: '',
      name_department: '',
      career: '',
      aboutMe: '',
      skills: '',
      exp: '',
      fileErrorMessage: '',
      cvStudent : false,

      subirCVinfo: `Sube un archivo PDF con tu currículum vitae. Te recomendamos buscar en línea las mejores estrategias para crear un CV efectivo antes de proceder.`,
    };
  },

  methods: {
    handleThemeChange(e) {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },

    async check_cv()
    {
      try
      {
        const cv = await loadCV();
        console.log("XXX", cv.hasCV);
        this.cvStudent = cv.hasCV;
        console.log("CV",  this.cvStudent);

      }
      catch (error) 
      {
        console.error('Server Error: ', error);
      }


    },

    async viewCV() {
    try {
      const token = localStorage.getItem('token');
  const decodedToken = jwt_decode(token);
  const idStudent = decodedToken.id_student;
      const response = await fetch('http://localhost:3000/api/student/profile/download-cv', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ idStudent: idStudent }) // Asegúrate de que `this.studentId` esté definido en el estado
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      window.open(url); // Abrir el PDF en una nueva pestaña
    } catch (error) {
      console.error('Error al visualizar el CV:', error);
      this.modalMessage = `Hubo un error al visualizar el CV. Por favor, intenta nuevamente. Error: ${error.message}`;
      this.isError = true;
      this.showMessageModal = true;
    }
  },
    uploadFile(event) {
    const file = event.target.files[0];

    if (file.type !== 'application/pdf') {
      this.fileErrorMessage = 'Solo se permiten archivos PDF.';
      this.file = null;
    } else if (file.size > 20 * 1024 * 1024) {
      this.fileErrorMessage = 'El archivo no debe exceder los 20 MB.';
      this.file = null;
    } else {
      this.fileErrorMessage = '';
      this.file = file; // Almacena el archivo seleccionado
    }
  },
    handleCV() {
      this.showModalCV = true;
      this.modalTitle = 'Añadir currículum vitae';
    },
    closeModal() {
      this.showModalCV = false;
    },
    closeMessageModal() {
      this.showMessageModal = false;
      this.$router.push({ name: 'estudiante-home' });
    },
    async submitFile() {
      if (!this.file) return;

      const formData = new FormData();
      formData.append('idStudent', 1); // Asegúrate de usar el ID del estudiante correcto
      formData.append('fileDocument', this.file);

      const token = localStorage.getItem('token'); // Obtén el token de autenticación desde el almacenamiento local

      this.showModalCV = false;

      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          this.uploadProgress = Math.round((event.loaded / event.total) * 100);
        }
      });

      xhr.addEventListener('load', () => {
        this.isFetching = false;
        if (xhr.status >= 200 && xhr.status < 300) {
          this.modalMessage = 'CV subido exitosamente';
          this.isError = false;
        } else {
          this.modalMessage = `Hubo un error al subir el CV. Por favor, intenta nuevamente. Error: ${xhr.statusText}`;
          this.isError = true;
        }
        this.showMessageModal = true;
      });

      xhr.addEventListener('error', () => {
        this.isFetching = false;
        this.modalMessage = `Hubo un error al subir el CV. Por favor, intenta nuevamente. Error: ${xhr.statusText}`;
        this.isError = true;
        this.showMessageModal = true;
      });

      xhr.open('POST', 'http://localhost:3000/api/student/vacancie/upload-cv');
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);

      this.isFetching = true;
      this.uploadProgress = 0;

      xhr.send(formData);
    },
    async load_profile() {
      try {
        const profile = await loadProfileApi();
        console.log('estos son los datos de la api: ', profile);
        this.studentName = profile.name;
        this.studentLastNameA = profile.last_name_A;
        this.studentLastNameB = profile.last_name_B;
        this.email = profile.email;
        this.enrollment = profile.enrollment;
        this.name_department = profile.name_department;
        this.career = profile.career;
        this.skills = profile.skills;
        this.exp = profile.exp;
        this.aboutMe = profile.about_me;
      } catch (error) {
        console.error('Server Error: ', error);
      }
    },
  },

  beforeUnmount() {
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },

  mounted() {
    this.load_profile();
    this.check_cv();
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },
};
</script>
