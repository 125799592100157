


//TO DO:  11/17 APIs
import jwt_decode from 'jsonwebtoken/decode';


//PERFIL DEL ESTUDIANTE
export async function loadProfileApi() 
{
  const token = localStorage.getItem('token');
  const decodedToken = jwt_decode(token);
  const id_student = decodedToken.id_student;

  const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_LOADPROFILE, 
  {
    method: 'POST',
    headers: 
    {
      'Content-Type'  : 'application/json',
      'Authorization' : `Bearer ${token}`,
    },
    body: JSON.stringify({ id_student }),
  });

  if (!response.ok) 
    {
    throw new Error('Error loading profile');
  }

  const data = await response.json();
  return data.profile;
}


//ENVIA TOKEN DE NOTIFICATION
export async function sendNotiTokenApi(notiToken) 
{
  const apiUrl        =   process.env.VUE_APP_API_STUDENT_POST_UPDATETOKENNOTI;
  const token         =   localStorage.getItem('token');
  const decodedToken  =   jwt_decode(token);
  const id       =   decodedToken.id_student;

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ token: notiToken, id: id }),
  });

  if (!response.ok) 
  {
    throw new Error('Error enviando el token de notificación');
  }
};


//CARGA LAS NOTIFICACIONES
export async function loadNotificationsApi() 
{
  const token         =   localStorage.getItem('token');
  const decodedToken  =   jwt_decode(token);
  const idStudent       =   decodedToken.id_student;

  const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_LOADHISTORYNOTI, {
    method: 'POST',
    headers : 
    {
      'Content-Type'  : 'application/json',
      'Authorization' : `Bearer ${token}`,
    },
    body: JSON.stringify({ idStudent }),
  });

  if (!response.ok) 
  {
    throw new Error('Failed to fetch notifications');
  }

  const data = await response.json();
  return data.historyList.map(item => item.notification_message);
}



//REVISA SI TIENE UN CV GUARDADO
export async function loadCV()
{
  const token = localStorage.getItem('token');
  const decodedToken = jwt_decode(token);
  const idStudent = decodedToken.id_student;

  const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_CHECKCV,
    {
      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({ idStudent }),
    }
    

  );
  const data = await response.json();
  return data;
}



//LISTADO DE INCIDENCIAS ACTIVAS
export async function loadHistoryIncidence(store) 
{
  try 
  {
    const token         =   localStorage.getItem('token');
    const decodedToken  =   jwt_decode(token);
    const idStudent     =   decodedToken.id_student;
    const idVacancie    =   store.getters.getterVacancieId;
    const idCompany     =   store.getters.getterCompanyId;

    const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_INCIDENT_LIST, 
    {
      method: 'POST',
      headers: 
      {
          'Content-Type'  : 'application/json',
          'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify
      ({
          idCompany   :   idCompany,
          idVacancie  :   idVacancie,
          idStudent   :   idStudent,
      }),
    });

    if (!response.ok) 
    {
        throw new Error('Error');
    }

    const data = await response.json();

    return data.incidentList.map(item => 
    ({
        id          :   item.id,
        name        :   item.name,
        reason      :   item.reason,
        start_date  :   item.start_date.split('T')[0],
        message     :   item.message,
        incidentId  : item.incident_id,
    }));
  } 
  catch (error) 
  {
      console.error(error);
      throw error;
  }
};



//LISTADO DE REQUESTS ENVIADAS
export async function loadStudentRequests() 
{
  try 
  {
    const token = localStorage.getItem('token');
    const decodedToken = jwt_decode(token);
    const id_student = decodedToken.id_student;

    console.log('ID ESTUDIANTE', id_student);

    const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_LOADREQUESTS, 
    {
        method: 'POST',
        headers: 
        {
            'Content-Type'    : 'application/json',
            'Authorization'   : `Bearer ${token}`,
        },
        body: JSON.stringify({ id_student: id_student }),
    });

    if (!response.ok) 
    {
        throw new Error('Error loading vacancies');
    }

    const data = await response.json();
    console.log('datos', data);

    return  {
              tableItemsTab1: data.requests
              .filter(item => item.typevacancie === 'Servicio Social')
              .map(item => ({
                    id: item.id,
                    idCompany : item.company_id,
                    empresa: item.company_name,
                    actividad: item.vacancie_name,

                    estado: item.status_option === 0 ? 'Otra cosa para 0' :
                    item.status_option === 4 ? 'Ya cuentas con una vacante activa de este tipo.' :
                    item.status_option === 1 ? 'En espera' :
                    item.status_option === 2 ? 'Rechazado' :
                    item.status_option === 3 ? 'Aceptado' : 'Desconocido',

                    detalles: item.status_option === 3 && item.accepted_date !== null && item.presence !== 3 ? 'A la espera de tu Asistencia' :
                    item.presence === 3 ? 'Activa o previamente activa' :
                    (item.status_option === 3 ? '' : null)
                  })),
            
              tableItemsTab2: data.requests
              .filter(item => item.typevacancie === 'Prácticas Profesionales')
              .map(item => ({
                        id: item.id,
                        idCompany : item.company_id,
                        empresa: item.company_name,
                        actividad: item.vacancie_name,

                        estado: item.status_option === 1 ? 'En espera' :
                                item.status_option === 4 ? 'Ya cuentas con una vacante activa de este tipo.' :
                                item.status_option === 2 ? 'Rechazado' :
                                item.status_option === 3 ? 'Aceptado' : 'Desconocido',
                                
                        detalles: item.status_option === 3 && item.accepted_date !== null && item.presence !== 3 ? 'A la espera de tu Asistencia' :
                                item.presence === 3 ? 'Activa o previamente activa' :
                                (item.status_option === 3 ? '' : null)
                    }))
            };
    } 
    catch (error) 
    {
        console.log(error);
        throw error;
    }
};



//LISTADO DE VACANTES PARA UNIRSE
export async function loadVacancies() 
{
  try 
  {
    const token = localStorage.getItem('token');
    const decodedToken = jwt_decode(token);
    const id_student = decodedToken.id_student;

    const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_LOADVACANCIES, 
    {
      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({ id_student: id_student }),
    });


    if (!response.ok) 
    {
      throw new Error('Error loading vacancies');
    }

    const data = await response.json();

    // Asignar los datos según el tipo de vacante
    const tableItemsTab1 = data.vacancies
      .filter(item => item.vacancie_type === 'Servicio Social')
      .map(item => ({
        id          : item.vacancie_id,
        idCompany  : item.id_company,
        nombre      : item.vacancie_name,
        empresa     : item.company_name,
        estado      : item.state_name,
        municipio   : item.muni_name,
        perfil      : item.business_name,
        actividad   : item.description,
        vacantes    : item.w_hiring_capacity,
        disponibles : item.w_hiring_capacity - item.filled_hiring,
        fecha       : new Date(item.creation_log).toLocaleDateString('es-MX', 
        {
          weekday :   'long',
          day     :   'numeric',
          month   :   'long',
          year    :   'numeric',
        }),
      }));
  
    const tableItemsTab2 = data.vacancies
      .filter(item => item.vacancie_type === 'Prácticas Profesionales')
      .map(item => ({
        id          : item.vacancie_id,
        idCompany  : item.id_company,
        nombre      : item.vacancie_name,
        empresa     : item.company_name,
        estado      : item.state_name,
        municipio   : item.muni_name,
        perfil      : item.business_name,
        actividad   : item.description,
        vacantes    : item.w_hiring_capacity,
        disponibles : item.w_hiring_capacity - item.filled_hiring,
        fecha: new Date(item.creation_log).toLocaleDateString('es-MX', 
        {
          weekday   :   'long',
          day       :   'numeric',
          month     :   'long',
          year      :   'numeric',
        }),
      }));
  
    return { tableItemsTab1, tableItemsTab2 };
  } 
  catch (error) 
  {
    console.error(error);
    throw error;
  }
};



//INFO DE UNA VACANTE
export async function loadVacancie(store) 
{
  try 
  {
    const id_vacancie = store.getters.getterVacancieId;  // Obtén el ID de la vacante desde el store
    const token = localStorage.getItem('token');

    const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_INFOVACANCIE, 
      {
      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({ id_vacancie: id_vacancie}),
    });

    if (!response.ok) 
    {
      throw new Error('Error loading vacancie');
    }

    const data = await response.json();
    console.log("datos API:", data.info_vacancie);

    return data;
  } 
  catch (error) 
  {
    console.log(error);
    throw error;
  }
}



//ENVIAR REQUEST
export async function joinRequest(store) 
{
  try 
  {
    const id_vacancie   =   store.getters.getterVacancieId;  // Obtén el ID de la vacante desde el store
    const idCompany     =   store.getters.getterCompanyId;
    const token         =   localStorage.getItem('token');
    const decodedToken  =   jwt_decode(token);
    const id_student    =   decodedToken.id_student;

    const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_JOIN_REQUEST, 
    {
      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({ id_vacancie: id_vacancie, id_student: id_student, idCompany : idCompany }),
    });

    if (!response.ok) 
    {
      const errorMessageText = await response.text();
      let detailedErrorMessage = 'Unknown error';
      try 
      {
        const errorMessageObject = JSON.parse(errorMessageText);
        if (errorMessageObject.error && errorMessageObject.error.message) 
        {
          detailedErrorMessage = errorMessageObject.error.message.split('Error: ')[1];
        } 
        else 
        {
          detailedErrorMessage = errorMessageObject.message || errorMessageText;
        }
      } 
      catch (e) 
      {
        detailedErrorMessage = errorMessageText;
      }
      throw new Error(detailedErrorMessage);
    }

    return {
      success: true,
      message: 'Se ha enviado tu solicitud para unirte a la vacante seleccionada. Mantente atento al estado de tu postulación.'
    };
  } 
  catch (error) 
  {
    console.error('Error al enviar datos a la API:', error);
    throw new Error(`Error al enviar datos a la API: ${error.message}`);
  }
};



//LISTADO DE EMPLEOS
export async function loadEmployees() 
{
  try 
  {
    
    const token         =   localStorage.getItem('token');
    const decodedToken  =   jwt_decode(token);
    const idStudent     =   decodedToken.id_student;

    const response = await fetch(process.env.VUE_APP_API_STUDENT_EMPLOYEES, 
      {
        method: 'POST',
        headers: 
        {
          'Content-Type'  : 'application/json',
          'Authorization' : `Bearer ${token}`,
        },
        body: JSON.stringify({ idStudent: idStudent }),
    });

    if (!response.ok) 
    {
      throw new Error('Error');
    }

    const data = await response.json();
    console.log('datos', data);

    //SERVICIO SOCIAL
    const tableItemsTab1 = data.employeeList
      .filter(item => item.id_board === 1)
      .map(item => ({
        id: item.employeed_id,
        idCompany : item.id_company,
        vacancie_name: item.vacancie_name,
        vacancieId: item.id_vacancie,
        company_name: item.company_name,
        start_date: item.start_date.split('T')[0],
        totalTime: item.total_time,
        finish_date: item.finish_date,
      }));

      //PRACTICAS PROFESIONALES
    const tableItemsTab2 = data.employeeList
      .filter(item => item.id_board === 2)
      .map(item => ({
        id: item.employeed_id,
        idCompany : item.id_company,
        vacancie_name: item.vacancie_name,
        vacancieId: item.id_vacancie,
        company_name: item.company_name,
        start_date: item.start_date.split('T')[0],
        totalTime: item.total_time,
        finish_date: item.finish_date,
      }));

    return { tableItemsTab1, tableItemsTab2 };
  } 
  catch (error) 
  {
    console.error(error);
    throw error;
  }
};



//INFORMACION GLOBAL
export async function loadGlobalInfo(idStudent, idVacancie) 
{
  try 
  {
    const token = localStorage.getItem('token');

    const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_GLOBAL_INFO_VACANCIE, 
      {
      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({
        idStudent: idStudent,
        idVacancie: idVacancie,
      }),
    });

    if (!response.ok) 
    {
      throw new Error('Error');
    }

    const data = await response.json();
    return data;
  } 
  catch (error) 
  {
    console.error(error);
    throw error;
  }
};



//DETERMINA VARIAS COSILLAS SOBRE LA SUBIDA DE REPORTES
export async function reportsOverview(idVacancie)
{
  try
  {

    const token = localStorage.getItem('token');
    const decodedToken  =   jwt_decode(token);
    const idStudent     =   decodedToken.id_student;

    const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_REPORTSOVERVIEW,{

      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({
        idStudent: idStudent,
        idVacancie: idVacancie,
      }),
    });

    if (!response.ok) 
      {
        throw new Error('Error');
      }
  
      const data = await response.json();
      return data;
  }
  catch (error) 
  {
    console.error(error);
    throw error;
  }
}



//LISTADO DE ASISTENCIAS
export async function loadAssitances(idStudent, idVacancie) 
{
  try {
    const token = localStorage.getItem('token');

    const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_ASSISTANCE_LIST, 
      {
      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({
        idStudent: idStudent,
        idVacancie: idVacancie,
      }),
    });

    if (!response.ok) 
    {
      throw new Error('Error');
    }

    const data = await response.json();
    return data.assistanceList.map(item => ({
      id: item.id,
      entranceTime: item.entrance_time,
      departureTime: item.departure_time,
      day: item.day.split('T')[0],
      totalTime: item.total_time,
      special: item.special,
    }));
  } 
  catch (error) 
  {
    throw error;
  }
};



//MOTIVOS DE INCIDENCIAS
export async function loadIncidencesReasons() 
{
  try 
  {
    const token = localStorage.getItem('token');

    const response = await fetch(process.env.VUE_APP_API_STUDENT_GET_INCIDENCE_REASONS, 
      {
      method: 'GET',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
    });

    if (!response.ok) 
    {
      throw new Error('Error al cargar los datos');
    }

    const data = await response.json();
    return data.incidentReasons;
  } 
  catch (error) 
  {
    console.error(error);
    throw error;
  }
};



//INICIAR UNA INCIDENCIA
export async function startIncident(idVacancie, idStudent, idCompany, idReason, message) 
{
  try 
  {
    const token = localStorage.getItem('token');

    await new Promise(resolve => setTimeout(resolve, 3000));

    const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_NEW_INCIDENCE, 
      {
      method: 'POST',
      headers: 
      {
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body: JSON.stringify({
        idVacancie: idVacancie,
        idStudent: idStudent,
        idCompany: idCompany,
        message: message,
        idReason: idReason,
      }),
    });

    if (!response.ok) 
    {
      const errorMessage = await response.text();
      return { ok: false, errorMessage };
    }

    const responseData = await response.json();
    return { ok: true, data: responseData };
  } 
  catch (error) 
  {
    console.error(error);
    throw error;
  }
};












