<template>
<div class="material-you light ">
<HeaderComponent />
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>


  <div class="tabla">
    <TableComponent :columns="tableColumns" :items="messagesData">
      <template v-slot:cell="{ item, column }">
        <!-- Personaliza el contenido de la celda según la columna -->
        <span v-if="column.field === 'entidad'">{{ item.entidad }}</span>
        <span v-else-if="column.field === 'lastMessage'">{{ item.lastMessage }}</span>
        <span v-else-if="column.field === 'img'">{{ item.img }}</span>
        <!-- Agrega un botón "Ver Mensajes" -->
        <button v-else-if="column.field === 'verMensajes'" @click="verMensajes(item)">Ver Mensajes</button>
        <!-- Agrega más personalizaciones aquí según las columnas -->
      </template>
    </TableComponent>
  </div>

  <FooterComponent />
</div>
</template>



<style scoped src="../styles/views/MaterialTheming.css"></style>
<style scoped src="../styles/material/theme.light.css"></style> 
<style scoped src="../styles/material/theme.dark.css"></style> 
<style scoped src="../styles/views/MisConversaciones.css"></style>
  
<script>
import FooterComponent    from    '../components/FooterComponent.vue';
import HeaderComponent    from    '../components/HeaderComponent.vue';
import MenuComponent      from    '../components/MenuComponent.vue';
import TableComponent     from    '../components/TableComponent.vue';

export default {
  name: 'MisConversaciones',

  components: 
  {
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    TableComponent,
  },

  data() 
  {
    return  {
              activeTab: 'tab1',

              tableColumns: 
              [
                { label: 'id', field: 'id' },
                { label: 'Nombre', field: 'entidad' },
                { label: 'Ultimo mensaje', field: 'lastMessage' },
                { label: 'img', field: 'img' },
                { label: 'Ver Mensajes', field: 'verMensajes' }, // Nueva columna para el botón
                // Agrega más columnas según tus necesidades
              ],

              messagesData: 
              [
                {
                  id: 1,
                  entidad: 'Staff Servicio Social',
                  lastMessage: '10-10-2022 15:45:09',
                  img: 'https://xsgames.co/randomusers/avatar.php?g=female',
                },

                {
                  id: 2,
                  entidad: 'Walmart',
                  lastMessage: '10-07-2022 15:45:09',
                  img: 'https://xsgames.co/randomusers/avatar.php?g=female',
                },
                // Agrega más datos aquí según tus necesidades
              ],

            };
  },



  methods: 
  {
    changeTab(tab) 
    {
      this.activeTab = tab;
    },

    verMensajes() 
    {
      this.$router.push({ name: 'ListaMensajes' });
      // Lógica para ver los mensajes según el elemento seleccionado
      // Puedes abrir un modal u otra acción personalizada aquí
      //console.log('Ver mensajes de:', item.entidad);
    },
  },
  
};
</script>
