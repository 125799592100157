<template>

  <!-- Material Theming -->
  <div :class="['material-you', mainTheme]">


    <!-- header -->
    <HeaderComponent />


    <!-- main menu -->
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>


    <!-- tooltips -->
    <article class="message">
      <MessageComponent title="Aprobar Solicitudes" :message="descripcion" />
    </article>


    <!-- option tabs -->
    <div class="tabs " style="width: 100%;">
      <md-tabs v-model="activeTab" :style="{ borderRadius: '15em' }">
        <md-primary-tab v-for="(tab, index) in tabs" :key="index" :name="tab.name" @click="changeTab(tab.name)">
          {{ tab.label }}
        </md-primary-tab>
      </md-tabs>
    </div>


    <!-- main table -->
    <div class="job-table">
      <div class="search">
        <md-outlined-text-field label="Buscar..." value="Value" v-model="searchQuery">
        </md-outlined-text-field>
      </div>

      <TableComponent :columns="tableColumns" :searchQuery="searchQuery" :items="paginatedItems">
            </TableComponent>

            <nav class="pagination" role="navigation" aria-label="pagination">
        <!-- <button class="pagination-previous" @click="prevPage" :disabled="currentPage === 1">Anterior</button> -->

        <md-filled-tonal-icon-button @click="prevPage" :disabled="currentPage === 1">
          <span class="material-symbols-outlined">arrow_back</span>
        </md-filled-tonal-icon-button>

        <md-filled-tonal-icon-button @click="nextPage" :disabled="currentPage === totalPages">
          <span class="material-symbols-outlined">arrow_forward</span>
        </md-filled-tonal-icon-button>

        <!-- <button class="pagination-next" @click="nextPage" :disabled="currentPage === totalPages">Siguiente</button> -->
        <ul class="pagination-list">
          <li v-for="page in totalPages" :key="page">
            <a class="pagination-link" :class="{ 'is-current': page === currentPage }"
                            @click="currentPage = page">{{
                                page
                            }}</a>
          </li>
        </ul>
      </nav>
    </div>


    <!-- footer -->
    <FooterComponent />
  </div>
</template>



<style scoped src =  "../styles/material/theme.light.css"            ></style> 
<style scoped src =  "../styles/material/theme.dark.css"             ></style> 

<style scoped src =  "../styles/views/EmpresasMenuValidaciones.css"  ></style>
<style scoped src =  "../styles/views/MaterialTheming.css"           ></style>



<script>
import FooterComponent      from        '../components/FooterComponent.vue';
import HeaderComponent      from        '../components/HeaderComponent.vue';
import MenuComponent        from        '../components/MenuComponent.vue';
import MessageComponent     from        '../components/MessageComponent.vue';
import TableComponent       from        '../components/TableComponent.vue';

import { loadVacancies }    from        '../api/ApiService';



export default
{
    components:
    {
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        TableComponent,
    },


    data() 
    {
        return  {
          mainTheme: 'light',
                  currentPage   :   1,
                  isLoading     :   true,
                  itemsPerPage  :   10,
                  sortColumn    :   null,

                  activeTab     :   'tab1',
                  sortDirection :   'asc',
                  searchQuery    :   '',

                  tabs: 
                  [
                      { name  : 'tab1', label :   'Servicio Social'            },
                      { name  : 'tab2', label :   'Prácticas Profesionales'    },
                  ],

                  tableItemsTab1: [],
                  tableItemsTab2: [],
                  tableItemsTab3: [],

                  tableColumns:
                  [
                      { label   :   'Unidad Receptora',   field   :   'nombre'            },
                      { label   :   'Empresa',            field   :   'empresa'           },
                      { label   :   'Fecha',              field   :   'fecha'             },
                      { label   :   'Detalles',           field   :   'AprobarDetalles'   },
                  ],

                  descripcion:    `En esta sección, tu rol crucial consiste en revisar y aprobar cuidadosamente las propuestas de 
                                  empresas antes de que se publiquen. Tu labor contribuye significativamente a mantener la calidad 
                                  y relevancia de nuestras ofertas laborales, asegurando que cada oportunidad cumpla con los estándares
                                  antes de llegar a nuestro público.`,
                }
    },



    async created() 
    {
      try 
      {
        const token = localStorage.getItem('token');  // Asegúrate de que el token está disponible
        const data = await loadVacancies(token);
        console.log('datos', data);

        // Asignar los datos según el tipo de vacante
        this.tableItemsTab1 = data
            .filter(item => item.typevacancie === 'Servicio Social')
            .map(item => ({
                idCompany :   item.id_company,
                id        :   item.id,
                nombre    :   item.name,
                empresa   :   item.campany_name,
                fecha     :   item.creation_log.split('T')[0],
            }));

        this.tableItemsTab2 = data
            .filter(item => item.typevacancie === 'Prácticas Profesionales')
            .map(item => ({
                idCompany : item.id_company,
                id        :   item.id,
                nombre    :   item.name,
                empresa   :   item.campany_name,
                fecha     :   item.creation_log.split('T')[0],
            }));

        // this.tableItemsTab3 = data
        //     .filter(item => item.typevacancie === 'Bolsa de Trabajo')
        //     .map(item => ({
        //         id      :   item.id,
        //         nombre  :   item.name,
        //         empresa :   item.campany_name,
        //         fecha   :   item.creation_log.split('T')[0],
        //     }));

      } 
      catch (error) 
      {
        console.error('Server Error:', error);
      }
    },



  computed: 
  {
    totalPages() 
    {
      const items = this.getCurrentTabItems();
      return Math.ceil(items.length / this.itemsPerPage);
    },


    paginatedItems() 
    {
      const items = this.getCurrentTabItems();
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return items.slice(start, end);
    },


    sortedItems() 
    {
      const items = this.getCurrentTabItems();
      if (!this.sortColumn) 
      {
        return items;
      }

      // Realiza una copia superficial del array para evitar mutaciones
      const sortedItems = [...items].sort((a, b) => {
        const fieldA = a[this.sortColumn];
        const fieldB = b[this.sortColumn];
        let comparison = 0;
        
        // Compara los campos dependiendo del tipo de dato
        if (typeof fieldA === 'string') 
        {
          comparison = fieldA.localeCompare(fieldB);
        } 
        else 
        {
          comparison = fieldA - fieldB;
        }
        
        return this.sortDirection === 'asc' ? comparison : -comparison;
      });

      return sortedItems;
    },
  },



  methods: 
  {
    changeTab(tabName) 
    {
        this.activeTab = tabName;
        this.currentPage = 1; // Reset to first page when tab changes
    },


    changeSort(column) 
    {
      if (this.sortColumn === column) 
      {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } 
      else 
      {
        this.sortColumn     =   column;
        this.sortDirection  =   'asc';
      }
    },


    nextPage() 
    {
      if (this.currentPage < this.totalPages) 
      {
        this.currentPage++;
      }
    },


    prevPage() 
    {
      if (this.currentPage > 1) 
      {
        this.currentPage--;
      }
    },


    getCurrentTabItems() 
    {
      switch(this.activeTab) 
      {
        case 'tab1': return this.tableItemsTab1;
        case 'tab2': return this.tableItemsTab2;
        case 'tab3': return this.tableItemsTab3;
        case 'tab4': return this.tableItemsTab4;
        case 'tab5': return this.tableItemsTab5;
        default: return [];
      }
    },

    handleThemeChange(e) {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },

  },


  mounted() {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },


  beforeUnmount() {
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },

}
</script>