<template>

<HeaderComponent />

  <div class="side-menu">
    <MenuComponent class="side-menu" />
  </div>

  <div class="contenedor">
    <CardComponent>

      <!-- Ranura para el título -->
      <template v-slot:title>
        <h3>AQUI VA A MOSTRAR EL NOMBRE DE CON QUIEN ESTAMOS HABLANDO</h3>
      </template>

      <!-- Ranura para el contenido -->
      <template v-slot:content>
        <div class="chat-container">
          <div class="chat-messages">
            <div v-for="(message, index) in messages" :key="index" class="chat-message" :class="{ 'is-mine': message.isMine, 'is-not-mine': !message.isMine }">
              <div class="message-content">{{ message.text }}</div>
            </div>
          </div>
        </div>
        <div class="chat-input">
          <div class="input-button-container">
            <textarea maxlength ="1000" class="textarea"  v-model="newMessage" @keyup.enter="sendMessage" placeholder="Escribe tu mensaje"></textarea>
            <div class="separador"></div>
            <button class="button is-rounded " @click="sendMessage"><font-awesome-icon :icon="['fas', 'paper-plane']" /></button>
          </div>
        </div>
      </template>

    </CardComponent>
  </div>

  <FooterComponent />

</template>





<style scoped src="../styles/views/MisMensajes.css"></style>

<script>
import CardComponent      from    '../components/CardComponent.vue';
import FooterComponent    from    '../components/FooterComponent.vue';
import HeaderComponent    from    '../components/HeaderComponent.vue';
import MenuComponent      from    '../components/MenuComponent.vue';

export default 
{
    components: 
    {
      CardComponent,
      FooterComponent,
      HeaderComponent,
      MenuComponent,
    },

    data() 
    {
        return  {
                  messages: 
                  [
                      { text: 'Hola, ¿cómo estás?', isMine: true },
                      { text: '¡Hola! Estoy bien, ¿y tú?', isMine: false },
                  ],

                  newMessage: '',
                };
    },



    methods: 
    {
      sendMessage() 
      {
        if (this.newMessage.trim() !== '') 
        {
          this.messages.push({ text: this.newMessage, isMine: true });
          this.newMessage = '';
          setTimeout(() => { this.messages.push({ text: '¡Respuesta automática!', isMine: false }); }, 1000);
        }
      },
    },


};

</script>
