<template>
    <div :class="['material-you', mainTheme]">
        <HeaderComponent />

        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>

        <article class="message">
            <MessageComponent title="Mis Incidencias" :message="descripcion" />
        </article>

        <div class="soySeparador">

        </div>

        <div class="tabla">
            <TableComponent :columns="tableColumns" :searchQuery="searchQuery" 
            :items="activeTab === 'tab1' ? tableItemsTab1 : (activeTab === 'tab2' ? tableItemsTab2 : tableItemsTab3)">
            <template v-slot:cell="{ item, column }">
            </template>
        </TableComponent>
        </div>

        <FooterComponent />

</div>
</template>
    
    
    
    
<style scoped src="../styles/material/theme.light.css"></style> 
<style scoped src="../styles/material/theme.dark.css"></style> 

<style scoped src="../styles/views/IncidenciasListadoEmpresas.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style>
    
<script>
// import { loadHistoryIncidence, loadIncidencesReasons,startIncident } from '../api/ApiService';
import CardComponent            from        '../components/CardComponent.vue';
import FooterComponent          from        '../components/FooterComponent.vue';
import HeaderComponent          from        '../components/HeaderComponent.vue';
import MenuComponent            from        '../components/MenuComponent.vue';
import MessageComponent         from        '../components/MessageComponent.vue';
import TableComponent           from        '../components/TableComponent.vue';
import ModalComponent       from    '../components/ModalComponent.vue';
import jwt_decode from 'jsonwebtoken/decode';
    
export default
{
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        ModalComponent,
        TableComponent,
    },




    async created() 
    {   
        this.load_incident_company();


        // try 
        // {
        //     const data = await loadHistoryIncidence(this.$store);
        //     this.tableItemsTab1 = data;
        // } 
        // catch (error) 
        // {
        //     console.error('Error loading history incidence:', error);
        // }
    },



    data() 
    {
        return  {

                    descripcion             :       `En este apartado podrás visualizar las incidencias registradas por las empresas,
                     indicando el nombre del pasante responsable, así como el motivo y la descripción de cada incidencia.
                      Esto te permitirá llegar a una solución de manera oportuna y sencilla.`,

                    activeTab               :       'tab1',



                    tableColumns:
                    [
                        { label     :   'Nombre Pasante',            field:  'nameIntern'          }, 
                        { label     :   'Departamento',            field:  'department'          }, 
                        { label     :   'Empresa',            field:  'companyName'          }, 
                        { label     :   'Fecha de Incidencia',  field:  'startDate'    },
                        { label     :   'Fecha de Finalización',  field:  'finishDate'    },
                        { label     :   'Motivo',               field:  'reason'        },
                        { label     :   'Actividad',            field:  'activity'          },
                        
                        { label     :   'Seguimiento',          field:  'verMensajesEmpresa'   },
                    ],

                    tableItemsTab1: [],
                    mainTheme: 'light',

                }
    },


    computed: 
    {
    },
    
    
    
    methods:
    {

        async load_incident_company()
        {
            
            try
            {
                const token         =   localStorage.getItem('token');
                const decodedToken  =   jwt_decode(token);
                const idStaff      =   decodedToken.userStaffId;

                console.log(idStaff);

                const response = await fetch(process.env.VUE_APP_API_STAFF_POST_INCIDENT_CONVERSATION_COMPANY,
                {
                    method : 'POST',
                    headers: 
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ idStaff: idStaff }),
                });


                if (!response.ok) 
                {
                    throw new Error('Error loading vacancies');
                }


                const data = await response.json();
                console.log('datos',data.incidentListCompany);

                // Asignar los datos según el tipo de vacante
                this.tableItemsTab1 = data.incidentListCompany.map(item => ({
                    id: item.id_incident,
                    nameIntern: `${item.name_student} ${item.last_name_A} ${item.last_name_B}` ,
                    department: item.name_department,
                    companyName: item.name_company,
                    startDate: new Date(item.start_date).toLocaleDateString('es-MX', {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'}),
                    finishDate: item.finish_date ? new Date(item.finish_date).toLocaleDateString('es-MX', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                }) : 'N/A',
                    reason: item.reason,
                    activity: item.name_vacancie,
                    }));

            }
            catch(error)
            {

            }
        },

        handleThemeChange(e) {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    }



    },


    beforeUnmount() {
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },

    mounted() {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
  },
    
}
    
    </script>