<template>
  <div>
    <transition name="bounce">
      <div v-if="isActive" class="modal is-active" style="z-index: 1001;">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Cerrar Sesión</p>
            <button class="delete" aria-label="close" @click="closeModal"></button>
          </header>
          <section class="modal-card-body">
            <p>¿Estás seguro de que deseas cerrar sesión?</p>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-info is-rounded" @click="confirmLogout">Cerrar Sesión</button>
            <button class="button is-danger is-rounded" @click="closeModal">Cancelar</button>
          </footer>
        </div>
      </div>
    </transition>
  </div>
</template>




  
<script>
import { getMessaging, deleteToken } from 'firebase/messaging';

export default 
{
  data() 
  {
    return { 
      isActive: false, 
      messaging: null,
    };
  },

  async mounted() 
  {
    this.messaging = getMessaging();
  },

  methods: 
  {
    openPopup() 
    {
      // Utiliza $nextTick para retrasar la apertura del modal hasta después de la actualización del DOM
      this.$nextTick(() => { this.isActive = true; });
      
    },

    closeModal() { this.isActive = false; },

    async confirmLogout() 
    {
      this.closeModal();
      localStorage.clear();
      await deleteToken(this.messaging);
      this.closeModal();
      this.$emit('closePopup'); // Emite un evento para cerrar el modal después de cerrar la sesión
      this.$router.push('/'); // Redirige al usuario a la página raíz ("/")
    },

  },

};

</script>
  