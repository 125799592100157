<template>
    <!-- MEJOR VISTA MOVIL PARA PERFIL -->
    <div class="material-you light">
        <HeaderComponent />
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>
        <article class="message">
            <MessageComponent title="Asistencias & Horas" :message="descripcion" />
        </article>
        <div class="navbar-center">
            <div class="navbar-item">
                <div class="notiBotton">
                    <md-elevated-button class="menu-button" @click="toggleMenu">
                        Información de la Vacante
                    </md-elevated-button>
                </div>
            </div>
        </div>

        <Transition>
            <div class="detailsVacancie" v-if="mostrarLista">
                <div class="card-perfil">
                    <CardComponent>
                        <!-- Slot para el título -->
                        <template v-slot:title>
                        </template>
                        <template v-slot:content>
                            <div class="media-content-info">
                                <div class="content-postulados">
                                    <p style="text-align: left;">Información de la Vacante</p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em;"> <span
                                            class="tag is-info-custom">Empresa Responsable</span>
                                        {{ vacancieDetails.info_vacancie.company_name }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Nombre de la Vacante</span>
                                        {{ vacancieDetails.info_vacancie.name }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom"> Creada en </span>
                                        {{ new
                                            Date(vacancieDetails.info_vacancie.creation_log).toLocaleDateString('es-MX',
                                                {
                                                    weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
                                                }) }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                        <span class="tag is-info-custom"> Descripción </span>
                                        {{ vacancieDetails.info_vacancie.description }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: justify; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Dirección</span>
                                        {{ vacancieDetails.info_vacancie.address }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom"> Salario </span>
                                        {{ vacancieDetails.info_vacancie.salary !== null ? '$' +
                                            vacancieDetails.info_vacancie.salary : 'No aplica' }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom"> Notas Adicionales </span>
                                        {{ vacancieDetails.info_vacancie.notes }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">Estado</span>
                                        {{ vacancieDetails.info_vacancie.state_name }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom"> Municipio </span>
                                        {{ vacancieDetails.info_vacancie.muni_name }}
                                    </p>
                                    <md-divider></md-divider>
                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom"> Sector </span>
                                        {{ vacancieDetails.info_vacancie.sector_name }}
                                    </p>
                                </div>
                            </div>
                        </template>
                    </CardComponent>
                </div>
            </div>
        </Transition>

        <div class="aboutMe">
            <CardComponent>
                <!-- Slot para el título (dejado vacío) -->
                <template v-slot:title>
                </template>
                <!-- Slot para el contenido -->
                <template v-slot:content>
                    <div class="card-content">
                        <div class="media">
                            <div class="media-left">
                                <div class="media-content-image has-text-centered">
                                    <figure class="image custom-image">
                                        <img src="https://xsgames.co/randomusers/avatar.php?g=female" alt="User Avatar"
                                            class="rounded-image">
                                    </figure>
                                </div>
                            </div>
                            <div class="content-perfil">
                                <p class="perfil">{{ studentName }}</p>
                                <p class="perfil">{{ email }}</p>
                            </div>
                            <div class="container">
                                <div class="totalTimeContainer">
                                    <div class="info-container">
                                        <p class="tag material-tag separe">Departamento: {{ nameDepartment }}</p>
                                        <br>
                                        <p class="tag material-tag separe">Tipo de Pasantía: {{ intershipType }}</p>
                                        <br>
                                        <p class="tag material-tag separe">Tiempo Total en Horas: {{ totalHours }}</p>
                                        <!-- <p>Progreso: {{ progress }}%</p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="reportesContainer">
                        <p v-if="uploadReport === false">Aun no cuentas con las horas requeridas para subir tu reporte
                        </p>
                        <p v-else>Con tu avance actual puedes subir el Reporte #{{ canUpload }}</p>
                        <div class="soySeparador">
                            <md-elevated-button v-if="uploadReport" @click="handleReject">
                                Subir Reporte #{{ canUpload }}
                            </md-elevated-button>
                        </div>
                        <md-elevated-button v-if="canUpload > 0" @click="handleReport">
                            Estado de tus Reportes
                        </md-elevated-button>
                    </div>
                </template>
                <template v-slot:buttons>
                </template>
            </CardComponent>
        </div>

        <div class="incidencias">
            <span class="tag tag2 is-danger is-light">¿Estás enfrentando situaciones difíciles? No estás solo.
                Si estás pasando por problemas como acoso, maltrato u otras dificultades, estamos aquí para ayudarte.
                Comunícate con el personal de la Universidad, es un proceso sencillo y privado.
                <span class="material-symbols-outlined">sentiment_stressed</span>
            </span>
            <md-filled-tonal-button @click="irAIncidencias" class="boton-incidencias">
                Reportar Incidencia
            </md-filled-tonal-button>
        </div>

        <div class="">
            <p>Tus asistencias</p>
        </div>

        <div class="tabla">
            <TableComponent :columns="tableColumns"
                :items="activeTab === 'tab1' ? tableItemsTab1 : (activeTab === 'tab2' ? tableItemsTab2 : tableItemsTab3)">
            </TableComponent>
        </div>

        <FooterComponent />
    </div>

    <Transition name="bounce">
        <ModalComponent v-if="showModalUpload" :title="modalTitle" @close="closeModal" class="modal">
            <template v-slot:content>
                <div class="fondo">
                    <span class="tag is-danger is-light">{{ subirCVinfo }}</span>
                </div>
                <div class="upload">
                    <div class="field">
                        <label class="label">Haz click en el icono para adjuntar tu reporte</label>
                        <div class="file has-name">
                            <label class="file-label">
                                <input class="file-input" type="file" name="resume" @change="uploadFile">
                                <span class="file-cta">
                                    <span class="file-label">
                                        <span class="material-symbols-outlined">attach_file</span> <!-- Outlined -->
                                    </span>
                                </span>
                                <span class="file-name" v-if="file">
                                    {{ file.name }}
                                </span>
                            </label>
                        </div>
                        <p class="help is-danger" v-if="fileErrorMessage">{{ fileErrorMessage }}</p>
                        <!-- Mensaje de error -->
                    </div>
                    <div class="control">
                        <button class="button is-link" @click="submitFile" :disabled="!file">Subir Archivo</button>
                    </div>
                </div>
            </template>
            <template v-slot:buttons>
                <md-elevated-button @click="closeModal">
                    Volver
                </md-elevated-button>
            </template>
        </ModalComponent>
    </Transition>

    <!-- modal para ver reportes -->
    <Transition name="bounce">
        <ModalComponent v-if="showModalReports" :title="modalTitle" @close="closeModal">
            <template v-slot:content>
                <p>Fecha actual : 23/04/2024 </p>
                <div class="fondo">
                    <span class="tag is-danger is-light">{{ reporteEstadoDesc }}</span>
                </div>
                <div class="form-container">
                    <TableComponent :columns="tableColumns2" :items="messagesData2">
                        <template v-slot:cell="{ item, column }">
                            <span v-if="column.field === 'entidad'">{{ item.entidad }}</span>
                            <span v-else-if="column.field === 'fechaSubida'">{{ item.fechaSubida }}</span>
                            <span v-else-if="column.field === 'fechaRevision'">{{ item.fechaSubida }}</span>
                            <span v-else-if="column.field === 'img'">{{ item.img }}</span>
                            <span v-else-if="column.field === 'icon'">{{ item.icon }}</span>
                            <button v-else-if="column.field === 'verMensajes'" @click="verMensajes(item)">Ver
                                Mensajes</button>
                        </template>
                    </TableComponent>
                </div>
                <div class="picker-container">
                </div>
            </template>
            <template v-slot:buttons>
            </template>
        </ModalComponent>
    </Transition>


    <!-- modal loading -->
    <div class="loading">
        <Transition name="bounce">
            <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
                <div class="modal-background"></div>
                <div class="modal-card">
                    <section class="modal-card-body">
                        <div> Subiendo tu CV... {{ uploadProgress }}%</div>
                        <md-circular-progress four-color indeterminate></md-circular-progress>
                    </section>
                </div>
                <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
            </div>
        </Transition>
    </div>


    <!-- modal para mostrar mensajes -->
    <Transition name="bounce">
        <ModalComponent v-if="showMessageModal" :title="isError ? 'Error' : 'Éxito'" @close="closeMessageModal">
            <template v-slot:content>
                <div class="message-content">
                    <span :class="{ 'tag': true, 'is-danger': isError, 'is-success': !isError }">{{ modalMessage }}</span>
                </div>
            </template>
            <template v-slot:buttons>
                <md-elevated-button @click="closeMessageModal">
                    Cerrar
                </md-elevated-button>
            </template>
        </ModalComponent>
    </Transition>
</template>



<style scoped src="../styles/material/theme.light.css"></style>
<style scoped src="../styles/material/theme.dark.css"></style>
<style scoped src="../styles/views/ProgresoDetalles.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style>

<script>
import { loadVacancie, loadGlobalInfo, loadAssitances, reportsOverview } from '../api/ApiService';

import CardComponent        from    '../components/CardComponent.vue';
import FooterComponent      from    '../components/FooterComponent.vue';
import FormComponent        from    '../components/FormComponent.vue';
import HeaderComponent      from    '../components/HeaderComponent.vue';
import jwt_decode           from    'jsonwebtoken/decode';
import MenuComponent        from    '../components/MenuComponent.vue';
import MessageComponent     from    '../components/MessageComponent.vue';
import ModalComponent       from    '../components/ModalComponent.vue';
import TableComponent       from    '../components/TableComponent.vue';
import VueDatePicker        from    '@vuepic/vue-datepicker';



export default
{
    components:
    {
        CardComponent,
        FooterComponent,
        FormComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        ModalComponent,
        TableComponent,
        VueDatePicker,
    },



    data() 
    {
        return  {
                    idCompany: this.$store.getters.getterCompanyId,
                    idVacancie: this.$store.getters.getterVacancieId,

                    canUpload           :   0,
                    date                :   null,
                    date2               :   null,
                    file                :   null,
                    isError             :   false,
                    isFetching          :   false,
                    mostrarLista        :   false,
                    numeroReportes      :   1,
                    progress            :   13.89,
                    showMessageModal    :   false,
                    showModalUpload     :   false,
                    studentDetails      :   null,
                    totalHours          :   null,
                    uploadProgress      :   0,
                    uploadReport        :   false,
                    vacancieDetails     :   null,

                    activeTab           :   'tab1',
                    email               :   '',
                    fileErrorMessage    :   '',
                    nameDepartment      :   '',
                    selectedTime        :   "12:00",
                    showModalReports    :   '',
                    studentName         :   '',

                    tableItemsTab1: [],
                    tableColumns:
                        [
                            { label : 'Fecha',              field   :   'day'             },
                            { label : 'Hora de Entrada',    field   :   'entranceTime'    },
                            { label : 'Hora de Salida',     field   :   'departureTime'   },
                            { label : 'Tiempo Total',       field   :   'totalTime'       },
                        ],

                    descripcion         :   `En este lugar, tendrás la oportunidad de llevar un seguimiento detallado tanto de las asistencias
                                            como de las horas trabajadas por el estudiante.`,

                    asistenciasDesc     :   `Por favor, asegúrate de subir tu archivo PDF siguiendo las indicaciones proporcionadas por tu institución.
                                            Mantén todo en orden para agilizar el proceso y continuar con tu progreso.
                                            Recuerda que solo puedes subir un reporte a la vez.
                                            Sin embargo, si cumples con las horas requeridas, podrás subir los demás reportes, siempre y cuando sea uno a la vez.`,

                    reporteEstadoDesc   :   `Te proporcionamos la oportunidad de revisar el estado de sus reportes enviados y de 
                                            efectuar correcciones en caso de que sean rechazados`,
                }
        },



    async created() 
    {
        //LOAD VACANCIE INFO
        try 
        {
            const data              =   await loadVacancie(this.$store);
            this.vacancieDetails    =   data;
            console.log("DATOS DE LA API:", data);
        }
        catch (error) 
        {
            console.error('Error loading vacancie:', error);
        }


        //LOAD STUDENT INFO
        try 
        {
            const token         =   localStorage.getItem('token');
            const decodedToken  =   jwt_decode(token);
            const idStudent     =   decodedToken.id_student;
            const idVacancie    =   this.idVacancie;

            const data          = await loadGlobalInfo(idStudent, idVacancie);
            console.log('global info', data);

            this.studentName        =   data.studentFullName;
            this.nameDepartment     =   data.name_department;
            this.intershipType      =   data.type;
            this.totalHours         =   data.totalHours;
            this.email              =   data.email;
        }
        catch (error) 
        {
            console.error('Error loading global info:', error);
        }


        //REPORTS OVERVIEW
        try 
        {
            const idVacancie    =   this.idVacancie;
            const data          =   await reportsOverview(idVacancie);
            console.log('reports OVERVIEW', data);

            this.uploadReport   =   data.reportData.uploadReport;
            this.canUpload      =   data.reportData.numberCanUpload;
        }
        catch (error) 
        {
            console.error('Error loading global info:', error);
        }


        try 
        {
            const token         =   localStorage.getItem('token');
            const decodedToken  =   jwt_decode(token);
            const idStudent     =   decodedToken.id_student;
            const idVacancie    =   this.idVacancie;

            const assitances    =   await loadAssitances(idStudent, idVacancie);
            console.log('datos', assitances);
            this.tableItemsTab1 = assitances;
        }
        catch (error) 
        {
            console.error('Error loading assistances:', error);
        }

    },



    methods:
    {
        toggleMenu() 
        {
            this.mostrarLista = !this.mostrarLista;
        },


        handleReject() 
        {
            this.showModalUpload = true;
            this.modalTitle = 'Añadir Reporte';
        },


        handleReport() 
        {
            this.showModalReports = true;
            this.modalTitle = 'Estado de tus Reportes';
        },


        irAIncidencias() 
        {
            this.$store.commit('SET_VACANCIE_ID', this.idVacancie);
            console.log('id de la vacante lokita', this.idVacancie);
            this.$store.commit('SET_COMPANY_ID', this.idCompany);
            console.log('id de la empresa lokita', this.idCompany);
            this.$router.push('/estudiante/progreso/detalles/incidencias');
        },


        irAsistencias() 
        {
            console.log("hola2");
        },


        closeModal() 
        {
            this.showModalUpload = false;
        },


        closeMessageModal() 
        {
            this.showMessageModal = false;
            this.$router.push({ name: 'estudiante-home' });
        },


        uploadFile(event) 
        {
            const file = event.target.files[0];
            if (file.type !== 'application/pdf') 
            {
                this.fileErrorMessage = 'Solo se permiten archivos PDF.';
                this.file = null;
            } 
            else if (file.size > 20 * 1024 * 1024) 
            {
                this.fileErrorMessage = 'El archivo no debe exceder los 20 MB.';
                this.file = null;
            } 
            else 
            {
                this.fileErrorMessage = '';
                this.file = file; // Almacena el archivo seleccionado
            }
        },


        async submitFile() 
        {
            const id_vacancie = this.$store.getters.getterVacancieId;
            if (!this.file) return;

            const formData = new FormData();
            formData.append('idStudent', 1);
            formData.append('idVacancie', id_vacancie);
            formData.append('fileDocument', this.file);

            const token = localStorage.getItem('token');

            this.showModalCV = false;

            const xhr = new XMLHttpRequest();

            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    this.uploadProgress = Math.round((event.loaded / event.total) * 100);
                }
            });

            xhr.addEventListener('load', () => {
                this.isFetching = false;
                if (xhr.status >= 200 && xhr.status < 300) 
                {
                    this.modalMessage = 'CV subido exitosamente';
                    this.isError = false;
                } 
                else 
                {
                    try 
                    {
                        const response = JSON.parse(xhr.responseText);
                        this.modalMessage = response.message || `Hubo un error al subir el CV. Por favor, intenta nuevamente. Error: ${xhr.statusText}`;
                    } 
                    catch (e) 
                    {
                        this.modalMessage = `Hubo un error al subir el CV. Por favor, intenta nuevamente. Error: ${xhr.statusText}`;
                    }
                    this.isError = true;
                }
                this.showMessageModal = true;
            });

            xhr.addEventListener('error', () => {
                this.isFetching = false;
                this.modalMessage = `Hubo un error al subir el CV. Por favor, intenta nuevamente. Error: ${xhr.statusText}`;
                this.isError = true;
                this.showMessageModal = true;
            });

            xhr.open('POST', 'http://localhost:3000/api/student/vacancie/upload-pp');
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            this.isFetching         =   true;
            this.uploadProgress     =   0;
            xhr.send(formData);
        }   

    }

}
</script>