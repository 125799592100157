<template>
  <div :class="[mainTheme]">
    <div>

      <div class="float-menu">
      <md-filled-tonal-icon-button class=" menu-button" @click="toggleMenu">
        <span class="material-symbols-outlined">menu</span>
      </md-filled-tonal-icon-button>
    </div>

      <Transition name="bounce">
        <aside class="sidebar" :class="{ 'menu-visible': true }" v-if="isMenuVisible">
          <div class="tipo">&nbsp; Cuenta &nbsp; Empresarial</div>
          <router-link :to="{ name: 'empresa-inicio' }" class="menu-logo">UMSNH</router-link>
          <ul class="menu-list">
            <!-- submenu papus -->
            <li class="has-submenu">
              <div class="menu-item">
                <div class="icon-container">
                  <span class="material-symbols-outlined">source_environment</span>
                </div>
                <span>Unidad Receptora</span>
              </div>
              <ul class="submenu">
                <li>
                  <div class="menu-item">
                    <router-link :to="{ name: 'empresa-vacantes-nueva-crear' }">
                  
                      <span class="material-symbols-outlined">tab_new_right</span>
                      Nueva
                    </router-link>
              
                  </div>
                </li>
                <li>
                  <div class="menu-item">
                    <router-link :to="{ name: 'empresa-vacantes-listado-estado' }">
                      <span class="material-symbols-outlined">format_list_numbered</span>
                      Listado
                    </router-link>
                  </div>
                </li>
              </ul>
            </li>
            <!-- fin del submenu maquinola -->
            <li>
              <router-link :to="{ name: 'empresa-empleados-actuales' }">
                <div class="menu-item">
                  <div class="icon-container">
                    <span class="material-symbols-outlined">sprint</span>
                  </div>
                  <span> Pasantes</span>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'empresa-bolsa-trabajo' }">
                <div class="menu-item">
                  <div class="icon-container">
                    <span class="material-symbols-outlined">shopping_bag</span>
                  </div>
                  <span>Bolsa de Trabajo</span>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'empresa-lista-mensajes' }">
                <div class="menu-item">
                  <div class="icon-container">
                    <span class="material-symbols-outlined">chat_bubble</span>
                  </div>
                  <span>Mensajes</span>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'empresa-contacto' }">
                <div class="menu-item">
                  <div class="icon-container">
                    <span class="material-symbols-outlined">phone_in_talk</span>
                  </div>
                  <span>Contacto</span>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'empresa-solicitudes-listado' }">
                <div class="menu-item">
                  <div class="icon-container">
                    <span class="material-symbols-outlined">page_info</span>
                  </div>
                  <span>Acerca de</span>
                </div>
              </router-link>
            </li>
            <li @click="cerrarSesion">
              <router-link :to="{ name: 'personal-contacto' }">
                <div class="menu-item">
                  <div class="icon-container">
                    <span class="material-symbols-outlined">exit_to_app</span>
                  </div>
                  <span>Salir</span>
                </div>
              </router-link>
            </li>
          </ul>
        </aside>
      </Transition>
      <div class="overlay" v-if="isMenuVisible" @click="closeMenu"></div>
    </div>
  </div>
</template>



<style scoped src="../styles/components/MenuComponent.css"></style>
<style scoped src = "../styles/material/theme.light.css"    ></style> 
<style scoped src = "../styles/material/theme.dark.css"     ></style> 

<script>
export default 
{
  data() 
  {
    return  {
              unreadMessages  : 10,
              isMenuVisible   : true,
              mainTheme: 'light',
            };
  },



  beforeMount() 
  {
    window.addEventListener('resize', this.handleWindowResize);
    if (window.innerWidth <= 850) 
    {
        this.isMenuVisible = false;
    }
    document.addEventListener('click', this.handleOutsideClick);
  },



  mounted() 
  {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery);
  },



  beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
    document.removeEventListener('click', this.handleOutsideClick);
  },



  methods: 
  {
    handleOutsideClick(event) {
    if (this.isMenuVisible && window.innerWidth <= 850) {
      const menu = this.$el.querySelector('.sidebar');
      const button = this.$el.querySelector('.menu-button');
      if (!menu.contains(event.target) && !button.contains(event.target)) {
        this.isMenuVisible = false;
      }
    }
  },
    cerrarSesion() 
    {
      localStorage.clear();
      this.$router.push({ path: '/' });
    },


    toggleMenu() 
    {
      this.isMenuVisible = !this.isMenuVisible;
      if (this.isMenuVisible) 
      {
        document.body.classList.remove('menu-hidden');
      } 
      else 
      {
        document.body.classList.add('menu-hidden');
      }
    },


    cerrarSesion() 
    {
      localStorage.clear();
      this.$router.push({ path: '/' });
    },


    handleWindowResize() 
    {
      this.windowWidth = window.innerWidth;
      if(innerWidth >= 851)
      {
        this.isMenuVisible = true;
      }
    },



    handleThemeChange(e) 
    {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    }

  },

};
</script>
