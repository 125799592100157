<template>

  <!-- material theme -->
  <div :class="['material-you', mainTheme]">

    <!-- header -->
    <HeaderComponent />

    <!-- main menu -->
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>

    <!-- tooltips -->
    <article class="message">
      <MessageComponent title="Listado de Empleados Actuales" :message="descripcion" />
    </article>

    <!-- options tabs -->
    <div class="tabs " style="width: 100%;">
      <md-tabs v-model="activeTab" :style="{ borderRadius: '15em' }">
        <md-primary-tab v-for="(tab, index) in tabs" :key="index" :name="tab.name" @click="changeTab(tab.name)">
          {{ tab.label }}
        </md-primary-tab>
      </md-tabs>
    </div>

    <div v-if="paginatedItems.length > 0" class="job-table">

      <!-- search bar -->
      <div class="search">
        <md-outlined-text-field label="Buscar..." value="Value" v-model="searchQuery">
        </md-outlined-text-field>
      </div>

      <!-- table  -->
      <TableComponent :columns="tableColumns" :searchQuery="searchQuery"
        :items="activeTab === 'tab1' ? tableItemsTab1 : (activeTab === 'tab2' ? tableItemsTab2 : tableItemsTab3)">
      </TableComponent>
      
      <!-- pagination -->
      <nav class="pagination" role="navigation" aria-label="pagination">
        <!-- <button class="pagination-previous" @click="prevPage" :disabled="currentPage === 1">Anterior</button> -->

        <md-filled-tonal-icon-button @click="prevPage" :disabled="currentPage === 1">
          <span class="material-symbols-outlined">arrow_back</span>
        </md-filled-tonal-icon-button>

        <md-filled-tonal-icon-button @click="nextPage" :disabled="currentPage === totalPages">
          <span class="material-symbols-outlined">arrow_forward</span>
        </md-filled-tonal-icon-button>

        <!-- <button class="pagination-next" @click="nextPage" :disabled="currentPage === totalPages">Siguiente</button> -->
        <ul class="pagination-list">
          <li v-for="page in totalPages" :key="page">
            <a class="pagination-link" :class="{ 'is-current': page === currentPage }"
                            @click="currentPage = page">{{
                                page
                            }}</a>
          </li>
        </ul>
      </nav>

    </div>
    <!-- No data message -->
    <div v-else class="no-data-message">
      <p>Sin participantes.</p>
    </div>
    <!-- footer -->
    <FooterComponent />
  </div>
</template>






<style scoped src   =   "../styles/material/theme.light.css"      ></style> 
<style scoped src   =   "../styles/material/theme.dark.css"       ></style> 
<style scoped src   =   "../styles/views/MaterialTheming.css"     ></style>
<style scoped src   =   "../styles/views/EmpleadosActuales.css"   ></style>

<script>
import FooterComponent      from        '../components/FooterComponent.vue';
import HeaderComponent      from        '../components/HeaderComponent.vue';
import jwt_decode           from        'jsonwebtoken/decode';
import MenuComponent        from        '../components/MenuComponent.vue';
import MessageComponent     from        '../components/MessageComponent.vue';
import TableComponent       from        '../components/TableComponent.vue';



export default
{
    name: 'EmpleadosActuales',
    components:
    {
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        TableComponent,
    },



    data() 
    {
        return  {
            
                    searchQuery: '',
                    mainTheme           :       'light',
                    sortColumn: null,
                    sortDirection: 'asc',
                    currentPage: 1,
                    itemsPerPage: 10,
                    isLoading: true,
                    descripcion :       `¡Bienvenido! Aquí encontrarás información sobre los estudiantes que actualmente están realizando
                     pasantías en tu equipo. Explora sus actividades, desempeño y nivel de participación para obtener una visión detallada de su contribución en la empresa`,
                    activeTab: 'tab1',

                    tabs: 
                    [
                        { name  :   'tab1',     label   :   'Servicio Social'            },
                        { name  :   'tab2',     label   :   'Prácticas Profesionales'    },
                        // { name  :   'tab3',     label   :   'Bolsa de Trabajo'           },
                    ],

                    tableItemsTab1: [],
                    tableItemsTab2: [],
                    tableItemsTab3: [],

                    tableColumns:
                    [
                        { label :   'Nombre',               field   :   'nombre'                },
                        { label :   'Actividad',            field   :   'actividad'             },
                        { label :   'Fecha De Ingreso',                field   :   'fecha'                 },
                        { label :   'Acciones',   field   :   'CandidatosDetalles'    },
                    ],
                }
    },



    created() 
    {
        this.loadVacancies();
    },


    computed: {
  totalPages() {
    const items = this.getCurrentTabItems();
    return Math.ceil(items.length / this.itemsPerPage);
  },
  paginatedItems() {
    const items = this.getCurrentTabItems();
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return items.slice(start, end);
  },

  sortedItems() {
      const items = this.getCurrentTabItems();
      if (!this.sortColumn) {
        return items;
      }

      // Realiza una copia superficial del array para evitar mutaciones
      const sortedItems = [...items].sort((a, b) => {
        const fieldA = a[this.sortColumn];
        const fieldB = b[this.sortColumn];
        let comparison = 0;
        
        // Compara los campos dependiendo del tipo de dato
        if (typeof fieldA === 'string') {
          comparison = fieldA.localeCompare(fieldB);
        } else {
          comparison = fieldA - fieldB;
        }
        
        // Aplica la dirección de ordenamiento
        return this.sortDirection === 'asc' ? comparison : -comparison;
      });

      return sortedItems;
    },
},

    methods:
    {
      handleThemeChange(e) 
        {
            this.mainTheme = e.matches ? 'dark' : 'light';
            console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
        } ,


        changeTab(tab) 
        {
            this.activeTab = tab;
        },


        async loadVacancies()
        {
            try
            {
                const token             =   localStorage.getItem('token');
                const decodedToken      =   jwt_decode(token);
                const userCompanyId     =   decodedToken.userCompanyId;
                const spaces            =   " ";

                console.log(userCompanyId);

                const response = await fetch(process.env.VUE_APP_COMPANY_POST_ACTIVE_EMPLOYEES,
                {
                    method : 'POST',
                    headers: 
                    {
                        'Content-Type'  :   'application/json',
                        'Authorization' :   `Bearer ${token}`,
                    },
                    body: JSON.stringify({ idCompany: userCompanyId }),
                });

                if (!response.ok) 
                {
                    throw new Error('Error loading vacancies');
                }

                const data = await response.json();
                console.log('datos',data.activeEmployees);


                // Asignar los datos según el tipo de vacante
                this.tableItemsTab1 = data.activeEmployees
                    .filter(item => item.type === 1)
                    .map(item => 
                    ({
                        id              :       item.id,
                        vacancieId      :       item.id_vacancie,
                        nombre          :       item.last_name_A + spaces+ item.last_name_B + spaces+ item.name,
                        actividad       :       item.vacancie_name,
                        fecha           :       item.start_date.split('T')[0],
                        idStudent : item.id_student,
                    }));

                this.tableItemsTab2 = data.activeEmployees
                    .filter(item => item.type === 2)
                    .map(item => 
                    ({
                        id              :       item.id,
                        vacancieId      :       item.id_vacancie,
                        nombre          :       item.last_name_A + spaces+ item.last_name_B + spaces+ item.name,
                        actividad       :       item.vacancie_name,
                        fecha           :       item.start_date.split('T')[0],
                        idStudent : item.id_student,
                    }));

                this.tableItemsTab3 = data.activeEmployees
                    .filter(item => item.type === 3)
                    .map(item => 
                    ({
                        id              :       item.id,
                        vacancieId      :       item.id_vacancie,
                        nombre          :       item.last_name_A + spaces+ item.last_name_B + spaces+ item.name,
                        actividad       :       item.vacancie_name,
                        fecha           :       item.start_date.split('T')[0],
                        idStudent : item.id_student,
                    }));

            }
            catch(error)
            {
            }
        },

        changeSort(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
    },

  getCurrentTabItems() {
    switch(this.activeTab) {
      case 'tab1': return this.tableItemsTab1;
      case 'tab2': return this.tableItemsTab2;
      case 'tab3': return this.tableItemsTab3;
      case 'tab4': return this.tableItemsTab4;
      case 'tab5': return this.tableItemsTab5;
      default: return [];
    }
  },

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  },
  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  },
    },

    mounted() 
    {
        this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        this.mediaQuery.addEventListener('change', this.handleThemeChange);
        this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
    },



    beforeUnmount() 
    {
        if (this.mediaQuery) 
        {
            this.mediaQuery.removeEventListener('change', this.handleThemeChange);
        }
    },

}
</script>