
export default
{
    state : 
    {
        idVacancieCompany       :   null,
        vacancieName            :   "",
        vacancieHiring          :   null,
        vacancieAdresss         :   "",
        vacancieDescription     :   "",
        vacancieNotes           :   "",
        vacancieStateMex        :   "",
        vacancieMuniMex         :   "",
        vacancieBSector         :   "",
        vacancieBoard           :   "",
        vacancieType            :   "",
        vacanciePaid            :   "",
        vacancieId              :   null,
        vacancieReason          :   "",
        studentId               :   null,     
    },



    mutations: 
    {
        setIdVacancie(state,idVacancieCompany)                  {   state.idVacancieCompany     = idVacancieCompany;        },
        SET_VACANCIE_NAME(state,vacancieName)                   {   state.vacancieName          = vacancieName;             },
        SET_VACANCIE_HIRING(state,vacancieHiring)               {   state.vacancieHiring        = vacancieHiring            },
        SET_VACANCIE_ADDRESS(state,vacancieAdresss)             {   state.vacancieAdresss       = vacancieAdresss           },
        SET_VACANCIE_DESCRIPTION(state,vacancieDescription)     {   state.vacancieDescription   = vacancieDescription       },
        SET_VACANCIE_NOTES(state,vacancieNotes)                 {   state.vacancieNotes         = vacancieNotes             },
        SET_VACANCIE_STATE_MEX(state,vacancieStateMex)          {   state.vacancieStateMex      = vacancieStateMex          },
        SET_VACANCIE_MUNI_MEX(state,vacancieMuniMex)            {   state.vacancieMuniMex       = vacancieMuniMex           },
        SET_VACANCIE_B_SECTOR(state,vacancieBSector)            {   state.vacancieBSector       = vacancieBSector           },
        SET_VACANCIE_BOARD(state,vacancieBoard)                 {   state.vacancieBoard         = vacancieBoard             },
        SET_VACANCIE_TYPE(state,vacancieType)                   {   state.vacancieType          = vacancieType              },
        SET_VACANCIE_PAID(state,vacanciePaid)                   {   state.vacanciePaid          = vacanciePaid              },
        SET_VACANCIE_ID(state,vacancieId)                       {   state.vacancieId            = vacancieId                },
        SET_VACANCIE_REASON(state,vacancieReason)               {   state.vacancieReason        = vacancieReason            },
        SET_STUDENT_ID(state,studentId)                         {   state.studentId             = studentId                 },



        
    },



    actions : 
    {
        changeIdVacancie            (   {commit},   idVacancieCompany           )           {    commit('setIdVacancie',idVacancieCompany)                      },
        
        async changeVacancieName({commit})
        {    
            async function fetchData()
            {
                const vacancieName = await fetchVacancieName();
                commit('SET_VACANCIE_NAME',vacancieName);
            }
            fetchData();
        },


        async changeVacancieHiring({commit})
        {    
            async function fetchData()
            {
                const vacancieHiring = await fetchVacancieHiring();
                commit('SET_VACANCIE_HIRING',vacancieHiring);
            }
            fetchData();
        },

        async changeVacancieAddress({commit})
        {    
            async function fetchData()
            {
                const vacancieAdresss = await fetchVacancieAdresss();
                commit('SET_VACANCIE_ADDRESS',vacancieAdresss);
            }
            fetchData();
        },

        async changeVacancieDescription({commit})
        {    
            async function fetchData()
            {
                const vacancieDescription = await fetchVacancieDescription();
                commit('SET_VACANCIE_DESCRIPTION',vacancieDescription);
            }
            fetchData();
        },


        async changeVacancieNotes({commit})
        {    
            async function fetchData()
            {
                const vacancieNotes = await fetchVacancieNotes();
                commit('SET_VACANCIE_NOTES',vacancieNotes);
            }
            fetchData();
        },

        async changeVacancieStateMex({commit})
        {    
            async function fetchData()
            {
                const vacancieStateMex = await fetchVacancieStateMex();
                commit('SET_VACANCIE_STATE_MEX',vacancieStateMex);
            }
            fetchData();
        },


        async changeVacancieBoard({commit})
        {    
            async function fetchData()
            {
                const vacancieBoard = await fetchVacancieBoard();
                commit('SET_VACANCIE_BOARD',vacancieBoard);
            }
            fetchData();
        },

        async changeVacancieBSector({commit})
        {    
            async function fetchData()
            {
                const vacancieBSector = await fetchVacancieBSector();
                commit('SET_VACANCIE_B_SECTOR',vacancieBSector);
            }
            fetchData();
        },

        async changeVacancieType({commit})
        {    
            async function fetchData()
            {
                const vacancieType = await fetchVacancieType();
                commit('SET_VACANCIE_TYPE',vacancieType);
            }
            fetchData();
        },

        async changevacancieMuniMex({commit})
        {    
            async function fetchData()
            {
                const vacancieMuniMex = await fetchVacancieMuniMex();
                commit('SET_VACANCIE_MUNI_MEX',vacancieMuniMex);
            }
            fetchData();
        },

        async changeVacanciePaid({commit})
        {    
            async function fetchData()
            {
                const vacanciePaid = await fetchVacanciePaid();
                commit('SET_VACANCIE_PAID',vacanciePaid);
            }
            fetchData();
        },



        async changeVacancieId({commit})
        {    
            async function fetchData()
            {
                const vacancieId = await fetchVacancieId();
                commit('SET_VACANCIE_ID',vacancieId);
            }
            fetchData();
        },




        async changeVacancieReason({commit})
        {    
            async function fetchData()
            {
                const vacancieReason = await fetchVacancieReason();
                commit('SET_VACANCIE_REASON',vacancieReason);
            }
            fetchData();
        },



        async changeStudentId({commit})
        {    
            async function fetchData()
            {
                const studentId = await fetchStudentId();
                commit('SET_STUDENT_ID',studentId);
            }
            fetchData();
        },

    },



    getters : 
    {
        getterIdVacancie            :       (state) =>          state.idVacancieCompany,
        getterVacancieName          :       (state) =>          state.vacancieName,
        getterVacancieHiring        :       (state) =>          state.vacancieHiring,
        getterVacancieAddress       :       (state) =>          state.vacancieAdresss,
        getterVacancieDescription   :       (state) =>          state.vacancieDescription,
        getterVacancieNotes         :       (state) =>          state.vacancieNotes,
        getterVacancieStateMex      :       (state) =>          state.vacancieStateMex,
        getterVacancieMuniMex       :       (state) =>          state.vacancieMuniMex,
        getterVacancieBsector       :       (state) =>          state.vacancieBSector,
        getterVacancieBoard         :       (state) =>          state.vacancieBoard,
        getterVacancieType          :       (state) =>          state.vacancieType,
        getterVacanciePaid          :       (state) =>          state.vacanciePaid,
        getterVacancieId          :       (state) =>          state.vacancieId,
        getterVacancieReason        :       (state) =>          state.vacancieReason,
        getterStudentId             :       (state) =>          state.studentId,

    },



}