<template>
    <div class="material-you light ">
        <HeaderComponent />
    
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>
    
        <article class="message">
            <MessageComponent title="Solicitud" :message="descripcion" />
        </article>
    
    
        <CardComponent>
    
    <!-- Slot para el título (dejado vacío) -->
    <template v-slot:title>
      
    </template>
    
    <!-- Slot para el contenido -->
    <template v-slot:content>
      <div class="card-content">
        <div class="media">
          <div class="media-left">
            <div class="media-content-image has-text-centered">
              <figure class="image custom-image">
                <img src="https://xsgames.co/randomusers/avatar.php?g=female" alt="User Avatar" class="rounded-image">
              </figure>
            </div>
          </div>
          <div class="content-perfil">
            <p class="perfil">Ana Sofía López Ramírez</p>
            <p class="perfil">6948065y@umich.mx</p>
            <!-- Puedes mostrar otros detalles del profesor aquí -->
          </div>
        </div>
      </div>
    </template>
    
    <!-- Slot para los botones (dejado vacío) -->
    <template v-slot:buttons>
      <!-- Deja este slot vacío para no mostrar botones en esta parte -->
    </template>
    

    
    </CardComponent>
    
    <div class="buttons is-centered is-grouped">
            <md-elevated-button @click="handleClickConfirmarAsist" style="margin-right: 20px;">
            {{ botonConfirmarAsistencia }}
        </md-elevated-button>
        
        <md-elevated-button  @click="handleClickConfirmarAbandono">
            {{ botonInasistencia }}
        </md-elevated-button>
            </div>

    
    
        <div class="card-perfil">
                <CardComponent>
    
                    <!-- Slot para el título -->
                    <template v-slot:title>
                    </template>
    
    
                    <template v-slot:content>
                        <div class="media-content-info">
                            <div class="content-postulados">
                                <p style="text-align: left;">Información de la Vacante</p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em;"> <span
                                        class="tag is-info-custom">Empresa Responsable</span> {{ vacancieDetails.vacancieInfo.company_name }}
                                </p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                        class="tag is-info-custom">Nombre de la Vacante</span> {{ vacancieDetails.vacancieInfo.name }}</p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                        class="tag is-info-custom">Creada en </span>
                                    {{ new Date(vacancieDetails.vacancieInfo.creation_log).toLocaleDateString('es-MX', {
                                        weekday: 'long',
                                        day: 'numeric', month: 'long', year: 'numeric'
                                    }) }}
                                </p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                    <span class="tag is-info-custom">Descripción</span> {{ vacancieDetails.vacancieInfo.description }}</p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em">
                                    <span class="tag is-info-custom">Dirección</span> {{ vacancieDetails.vacancieInfo.address }}</p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                    <span class="tag is-info-custom">Salario</span>
                                    {{ vacancieDetails.salary !== null ? '$' + vacancieDetails.vacancieInfo.salary : 'No aplica' }}
                                </p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                        class="tag is-info-custom">Notas Adicionales</span> {{ vacancieDetails.vacancieInfo.notes }}</p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                        class="tag is-info-custom">Estado</span> {{ vacancieDetails.vacancieInfo.state_name }}</p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                        class="tag is-info-custom">Municipio</span> {{ vacancieDetails.vacancieInfo.muni_name }}</p>
    
                                <md-divider></md-divider>
    
                                <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                        class="tag is-info-custom">Sector</span> {{ vacancieDetails.vacancieInfo.sector_name }}</p>
                            </div>
                        </div>
                    </template>
    
                    <template v-slot:buttons>
    
    
                    </template>
    
                </CardComponent>
            </div>
    
    
    
    
        <FooterComponent />
    
    </div>


    <Transition name="bounce">
            <ModalComponent v-if="showModal" :title="modalTitle" :modalContent="modalContent">
                <template v-slot:buttons>
                    <md-elevated-button v-if="respuestaExitosa" @click="handleAccept">
                        Aceptar
                    </md-elevated-button>
                    <md-elevated-button v-if="!respuestaExitosa" @click="handleError">
                        Volver
                    </md-elevated-button>
                </template>
            </ModalComponent>
        </Transition>


        <!-- modal completo solo para motivos de una mejor UX -->
        <Transition name="bounce">
            <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
                <div class="modal-background"></div>
                <div class="modal-card">
                    <section class="modal-card-body">
                        <div> Validando Datos.. </div>
                        <md-circular-progress four-color indeterminate></md-circular-progress>
                    </section>
                </div>
                <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
            </div>
    </Transition>

    </template>
    
    
    
    
    <style scoped src="../styles/material/theme.light.css"></style>
<style scoped src="../styles/material/theme.dark.css"></style>
    <style scoped src="../styles/views/IngresoConfirmacion.css"></style>
    <style scoped src="../styles/views/MaterialTheming.css"></style>
    
    <script>
    import CardComponent            from        '../components/CardComponent.vue';
    import FooterComponent          from        '../components/FooterComponent.vue';
    import HeaderComponent          from        '../components/HeaderComponent.vue';
    import MenuComponent            from        '../components/MenuComponent.vue';
    import MessageComponent         from        '../components/MessageComponent.vue';
    import TableComponent           from        '../components/TableComponent.vue';
    import jwt_decode                               from    'jsonwebtoken/decode';
    import ModalComponent       from    '../components/ModalComponent.vue';
import { handleError } from 'vue';
    
    export default
        {
            name: 'InfoContacto',
            components:
            {
                CardComponent,
                FooterComponent,
                HeaderComponent,
                MenuComponent,
                MessageComponent,
                TableComponent,
                ModalComponent,
            },
    
        //cargamos la vacante al crear el componente
        async created() {   
            await this.loadvacancie();  
        
        },
    
            data() 
            {
                return  {
                    serverErrorMessage : `Parece que no se ha podido conectar al servidor.
                 Por favor, verifica tu conexión a internet e inténtalo de nuevo.
                  Si el problema persiste, no dudes en ponerte en contacto con tu administrador para obtener ayuda`,

                            botonConfirmarAsistencia : 'Confirmar Asistencia',
                            botonInasistencia : `No se presento`,

                            apiForbidden : `Lamentablemente, la acción solicitada no puede ser realizada en este momento.
                De acuerdo con el procedimiento establecido, el alumno dispone de un plazo de 7 días naturales
                para presentarse, el cual aún no ha expirado. Por favor, aguarde hasta que se cumpla dicho plazo
                para proceder con la acción requerida.`,

                            apiErrorMessage : `Lo sentimos, parece que ha habido un error al validar
                             la primera asistencia del alumno. Por favor, inténtalo de nuevo más tarde.
                              Si el problema persiste, no dudes en ponerte en contacto
                               con tu administrador para obtener ayuda.`,
                            messageSuccesAccepted : `¡Excelente! Has confirmado la primera asistencia del alumno de forma
                             satisfactoria. Ahora, además de registrar sus horas y asistencias en la sección de empleados,
                              también puedes agregar incidencias si surge algún problema.
                               ¡Estamos aquí para ayudarte en lo que necesites!`,
                            isFetching          :   false,
                            showModal           :   false,
                            isClickedConfAsist           :   false,
                            isClickedConfAbandono : false,
                            activeTab: 'tab1',
                            descripcion: `Aquí puedes confirmar la presencia del alumno en la vacante asignada.
                             Este paso es crucial, ya que las horas trabajadas se contabilizarán a partir de esta confirmación.
                             En el caso de que el alumno no se presente, tiene la opción de seleccionar 
                             'No se presentó' para liberar nuevamente esa vacante para otro candidato.`,
                            tableColumns:
                            [
                                // { label: 'img', field: 'img' },
                                { label: 'Nombre', field: 'nombre' },
                                { label: 'Actividad', field: 'actividad' },
                                { label: 'Estado', field: 'estado' },
                                { label: 'Detalles', field: 'misAlumnosDetalles' },
                            ],
    
                            tableItemsTab1:
                            [
                                // { id: 1, img: '', nombre: 'Ana Sofía López Ramírez', actividad: 'Cerillo', estado: 'En Progreso' },
                            ],
                            vacancieDetails     :   null,
                            studentDetails : null,
                        }
            },
    
    
            methods:
            {
                        //cargamos la informacion de la vacante a rechazar o aceptar
            async loadvacancie()
            {
                try
                {
                    const vacancie      =   this.$store.getters.getterVacancieId; //mover a created
                    const token         =   localStorage.getItem('token');
                    const id_vacancie   =   vacancie;
    
                    const decodedToken = jwt_decode(token);
                    const id_company = decodedToken.userCompanyId;
    
                    const response      =   await fetch(process.env.VUE_APP_COMPANY_POST_VACANCIE_INFO,{
                        method : 'POST',
                        headers: 
                        {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({ id_vacancie: id_vacancie, id_company :id_company }),
                    });
    
    
                    if(!response.ok)
                    {
                        throw new Error('Error al cargar la vacante');
                    }
    
                    const data              =   await response.json();
                    this.vacancieDetails    =   data;
                    console.log("DATOS DE LA API:",data)
                }
                catch(error)
                {
                    console.log(error);
                }
    
            },
            

            //determina los clicks que se hacen aka double step
            handleClickConfirmarAsist() 
            {
                if (!this.isClickedConfAsist) 
                {
                    this.botonConfirmarAsistencia = 'Haz clic de nuevo para confirmar';
                    this.isClickedConfAsist = true;
                } 
                else 
                {
                    this.handleAcceptConfAsist();
                    this.isFetching = true;
                    this.confirm_assitance();
                }
            },

            handleClickConfirmarAbandono() 
            {
                if (!this.isClickedConfAbandono) 
                {
                    this.botonInasistencia = 'Haz clic de nuevo para confirmar';
                    this.isClickedConfAbandono = true;
                } 
                else 
                {
                    this.handleAcceptConfAbandono();
                    this.isFetching = true;
                    this.left_work();
                }
            },


                            //acciones a tomar segun la accion que se realiza
                handleAcceptConfAbandono() 
                {
                    if (this.actionOnSuccess === 'vacancieAccepted') 
                    {
                        this.$router.push({ name: 'personal-inicio' });
                    }

                    else if (this.actionOnSuccess === 'vacancieRejected') 
                    {
                        this.$router.push({ name: 'personal-inicio' });
                    }

                    this.showModal = false;
                },




                //acciones a tomar segun la accion que se realiza
                handleAcceptConfAsist() 
                {
                    if (this.actionOnSuccess === 'vacancieAccepted') 
                    {
                        this.$router.push({ name: 'personal-inicio' });
                    }

                    else if (this.actionOnSuccess === 'vacancieRejected') 
                    {
                        this.$router.push({ name: 'personal-inicio' });
                    }

                    this.showModal = false;
                },

                async confirm_assitance()
                {
                    try 
                    {
                        const vacancie      =   this.$store.getters.getterVacancieId; //mover a created
                        const idVacancie   =   vacancie;
                        const confirmApì = process.env.VUE_APP_API_COMPANY_CONFIRM_ASSITANCE;
                        const token         =   localStorage.getItem('token');
                        const decodedToken  =   jwt_decode(token);
                        const idCompany     =   decodedToken.userCompanyId;
                        const idStudent = this.$store.getters.getterStudentId;

                        this.isFetching = true;
                        await new Promise(resolve => setTimeout(resolve, 3000));

                        const response = await fetch(confirmApì, 
                        {
                            method  : 'POST',
                            headers : 
                            { 
                                'Content-Type'  :   'application/json',
                                'Authorization' :   `Bearer ${token}`,
                            },
                            body: JSON.stringify({ idVacancie: idVacancie, idStudent :idStudent }),
                        });

                        this.isFetching = false;

                            if (!response.ok) 
                        {
                            const errorMessage      =   await response.text();
                            this.showModal          =   true;
                            this.modalTitle         =   'Ha Ocurrido un Error';
                            this.modalContent       =   `${this.apiErrorMessage}   \n \n MENSAJE DE ERROR:  ${errorMessage}`
                            this.respuestaExitosa   =   false;
                            
                        }

                                    //todo salio bien
                        if (response.ok) 
                        {
                            this.showModal          =   true;
                            this.modalTitle         =   'Proceso Exitoso';
                            this.modalContent       =   this.messageSuccesAccepted;
                            this.respuestaExitosa   =   true;
                            this.actionOnSuccess    =   'vacancieAccepted'
                        } 


                    }
                    catch (error) 
                {
                this.isFetching         =       false;
                this.showModal          =       true;
                this.modalTitle         =       'ERROR';
                this.modalContent       =       `${this.serverErrorMessage} \n \n MENSAJE DE ERROR: ${error.message}. `;
                }

                },

                async left_work()
                {
                    try 
                    {
                        const vacancie      =   this.$store.getters.getterVacancieId; //mover a created
                        const idVacancie   =   vacancie;
                        const confirmApì = process.env.VUE_APP_API_COMPANY_LEFT_WORK;
                        const token         =   localStorage.getItem('token');
                        const decodedToken  =   jwt_decode(token);
                        const idCompany     =   decodedToken.userCompanyId;
                        const idStudent = this.$store.getters.getterStudentId;

                        this.isFetching = true;
                        await new Promise(resolve => setTimeout(resolve, 3000));

                        const response = await fetch(confirmApì, 
                        {
                            method  : 'POST',
                            headers : 
                            { 
                                'Content-Type'  :   'application/json',
                                'Authorization' :   `Bearer ${token}`,
                            },
                            body: JSON.stringify({ idVacancie: idVacancie, idStudent :idStudent }),
                        });

                        this.isFetching = false;

                        if (response.status === 403) 
                        {
                            const errorMessage = await response.text();
                            this.showModal = true;
                            this.modalTitle = 'Acceso Denegado';
                            this.modalContent = `${this.apiForbidden} \n \n MENSAJE DE ERROR: ${errorMessage}`;
                            this.respuestaExitosa = false;
                        } 
 
                        if (!response.ok)  
                        {
                            const errorMessage      =   await response.text();
                            this.showModal          =   true;
                            this.modalTitle         =   'Ha Ocurrido un Error';
                            this.modalContent       =   `${this.apiErrorMessage}   \n \n MENSAJE DE ERROR:  ${errorMessage}`
                            this.respuestaExitosa   =   false;
                            
                        }

                                    //todo salio bien
                        if (response.ok) 
                        {
                            this.showModal          =   true;
                            this.modalTitle         =   'Proceso Exitoso';
                            this.modalContent       =   this.messageSuccesAccepted;
                            this.respuestaExitosa   =   true;
                            this.actionOnSuccess    =   'vacancieAccepted'
                        } 


                    }
                    catch (error) 
                {
                this.isFetching         =       false;
                this.showModal          =       true;
                this.modalTitle         =       'ERROR';
                this.modalContent       =       `${this.serverErrorMessage} \n \n MENSAJE DE ERROR: ${error.message}. `;
                }

                },


                handleAccept() 
                {
                    this.$router.push({ name: 'empresa-inicio' });
                },

                handleError()
                {
                    this.$router.push({ name: 'empresa-inicio' });

                }

            }
    
        }
    
    </script>