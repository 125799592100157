<template>
    <div class="material-you light">
        <HeaderComponent />
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>
        <article class="message">
            <MessageComponent title="Incidencias del Empleado" :message="descripcion" />
        </article>
        <div class="aboutMe">
            <CardComponent>
                <!-- Slot para el título (dejado vacío) -->
                <template v-slot:title>
                </template>
                <!-- Slot para el contenido -->
                <template v-slot:content>
                    <div class="card-content">
                        <div class="media">
                            <div class="media-left">
                                <div class="media-content-image has-text-centered">
                                    <figure class="image custom-image">
                                        <img src="https://xsgames.co/randomusers/avatar.php?g=female" alt="User Avatar"
                                            class="rounded-image">
                                    </figure>
                                </div>
                            </div>
                            <div class="content-perfil">
                                <p class="perfil">Ana Sofía López Ramírez</p>
                                <p class="perfil">6948065y@umich.mx</p>
                                <!-- Puedes mostrar otros detalles del profesor aquí -->
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:buttons>
                    <!-- Deja este slot vacío para no mostrar botones en esta parte -->
                </template>
            </CardComponent>
        </div>
        <div class="soySeparador">
            <md-elevated-button @click="handleReject">
                Nueva Incidencia
            </md-elevated-button>
        </div>
        <div class="tabla">
            <TableComponent :columns="tableColumns"
                :items="activeTab === 'tab1' ? tableItemsTab1 : (activeTab === 'tab2' ? tableItemsTab2 : tableItemsTab3)">
            </TableComponent>
        </div>
        <FooterComponent />
    </div>

    <!-- modal para inciiar nueva incidenmcia -->
    <Transition name="bounce">
        <ModalComponent v-if="showModalIndence" :title="modalTitle" @close="closeModal">
            <template v-slot:content>
                <md-filled-select label="Motivo" v-model="selectedReasonId">
                    <md-select-option v-for="reason in incidentReasons" :key="reason.id" :value="reason.id">
                        <div slot="headline">{{ reason.reason }}</div>
                    </md-select-option>
                </md-filled-select>
                <md-filled-text-field ref="motivoRechazoRef" type="textarea" label="Fundamento de la Incidencia"
                    v-model="motivoRechazo" rows="5" maxlength="500"
                    style="width: 100%; height: 100%; "></md-filled-text-field>
            </template>
            <template v-slot:buttons>
                <md-elevated-button :disabled="!isFormValid" @click="handleAceptar">
                    {{ acceptButton }}
                </md-elevated-button>
                <md-elevated-button @click="closeModal">
                    Volver
                </md-elevated-button>
            </template>
        </ModalComponent>
    </Transition>

    <!-- modal completo solo para motivos de una mejor UX -->
    <Transition name="bounce">
        <div v-if="isFetching" class="modal is-active" style="z-index: 1001;">
            <div class="modal-background"></div>
            <div class="modal-card">
                <section class="modal-card-body">
                    <div> Validando Datos.. </div>
                    <md-circular-progress four-color indeterminate></md-circular-progress>
                </section>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
        </div>
    </Transition>

    <!-- modal para el final de los procesos de aceptar o rechazar -->
    <Transition name="bounce">
        <ModalComponent v-if="showModal" :title="modalTitle" :modalContent="modalContent">
            <template v-slot:buttons>
                <md-elevated-button v-if="respuestaExitosa" @click="handleAccept">
                    Aceptar
                </md-elevated-button>
                <md-elevated-button v-if="!respuestaExitosa" @click="handleError">
                    Volver
                </md-elevated-button>
            </template>
        </ModalComponent>
    </Transition>
</template>



<style scoped src="../styles/material/theme.light.css"></style>
<style scoped src="../styles/material/theme.dark.css"></style>
<style scoped src="../styles/views/EmpleadosActualesDetallesIncidencias.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style>

<script>
import CardComponent from '../components/CardComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';
import HeaderComponent from '../components/HeaderComponent.vue';
import MenuComponent from '../components/MenuComponent.vue';
import MessageComponent from '../components/MessageComponent.vue';
import TableComponent from '../components/TableComponent.vue';
import jwt_decode from 'jsonwebtoken/decode';
import ModalComponent from '../components/ModalComponent.vue';

export default
    {
        name: 'InfoContacto',
        components:
        {
            CardComponent,
            FooterComponent,
            HeaderComponent,
            MenuComponent,
            MessageComponent,
            TableComponent,
            ModalComponent,
        },

        //cargamos la vacante al crear el componente
        async created() {
            await this.loadvacancie();
            await this.loadStudents();
            await this.load_history_incidence();
            await this.load_incidences_reasons();

        },

        data() {
            return {

                showModalIndence: false,
                descripcion: `Si como empresa encuentras algún inconveniente con nuestro alumno,
                                            te animamos a reportarlo. Nuestro equipo se encargará de abordar la situación. 
                                            Para iniciar este proceso, necesitarás abrir una incidencia a continuación.
                                            Es importante que seas claro y conciso al detallar el motivo del problema.
                                            Además, ten en cuenta que solo puede haber una incidencia activa por alumno en un momento dado.`,
                activeTab: 'tab1',

                tableColumns:
                    [
                        { label: 'Actividad', field: 'name' },
                        { label: 'Motivo', field: 'reason' },
                        { label: 'Argumento', field: 'message' },
                        { label: 'Fecha de Incidencia', field: 'start_date' },
                        { label     :   'Seguimiento',          field:  'verMensajes'   },
                    ],

                tableItemsTab1: [],
                vacancieDetails: null,
                studentDetails: null,
                mostrarLista: false,
                incidence: false,
                incidentReasons: [],
                selectedReasonId: null,
                acceptButton: 'Aceptar',
                motivoRechazo: '',
                isFetching: false,
                showModal: false,
            }
        },


        methods:
        {
            toggleMenu() {
                this.mostrarLista = !this.mostrarLista;
            },

            //para ver si rechazamos o no la vacante
            handleReject() {
                this.showModalIndence = true;
                this.modalTitle = 'Reportar Incidencia';

                this.$nextTick(() => {
                    this.$refs.motivoRechazoRef.focus();
                });
            },

            handleAceptar() {
                if (this.incidence) {
                    this.startIncident();
                } else {
                    this.acceptButton = 'Toque de nuevo para confirmar';
                    this.incidence = true;
                }

            },



            //cargamos la informacion de la vacante a rechazar o aceptar
            async loadvacancie() {
                try {
                    const vacancie = this.$store.getters.getterVacancieId; //mover a created
                    const token = localStorage.getItem('token');
                    const id_vacancie = vacancie;

                    const decodedToken = jwt_decode(token);
                    const id_company = decodedToken.userCompanyId;

                    const response = await fetch(process.env.VUE_APP_COMPANY_POST_VACANCIE_INFO, {
                        method: 'POST',
                        headers:
                        {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({ id_vacancie: id_vacancie, id_company: id_company }),
                    });


                    if (!response.ok) {
                        throw new Error('Error al cargar la vacante');
                    }

                    const data = await response.json();
                    this.vacancieDetails = data;
                    console.log("DATOS DE LA API:", data)
                }
                catch (error) {
                    console.log(error);
                }

            },

            async load_history_incidence() {
                try {
                    const token = localStorage.getItem('token');
                    const decodedToken = jwt_decode(token);

                    const idCompany = decodedToken.userCompanyId;

                    // se obtiene el id del alumno de la store
                    const idStudent = this.$store.getters.getterStudentId;

                    // se obtiene el id de la vacante del store
                    const idVacancie = this.$store.getters.getterVacancieId; // mover a created


                    const response = await fetch(process.env.VUE_APP_COMPANY_INCIDENT_LIST, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            idCompany: idCompany,
                            idVacancie: idVacancie,
                            idStudent: idStudent
                        }),
                    });

                    if (!response.ok) {
                        throw new Error('Error');
                    }

                    const data = await response.json();
                    console.log('datos', data);

                    this.tableItemsTab1 = data.incidentListActive.map(item =>
                    ({
                        id: item.id,
                        name: item.name,
                        reason: item.reason,
                        start_date: item.start_date.split('T')[0],
                        message: item.message,
                    }));
                }
                catch (error) {
                    console.error(error);
                }

            },

            async loadStudents() {
                try {
                    const token = localStorage.getItem('token');
                    const decodedToken = jwt_decode(token);
                    const idCompany = decodedToken.userCompanyId;

                    const response = await fetch(process.env.VUE_APP_COMPANY_POST_VACANCIE_REQUEST_LIST, {
                        method: 'POST',
                        headers:
                        {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({ idCompany: idCompany }),
                    });

                    if (!response.ok) {
                        throw new Error('Error al cargar la vacante');
                    }

                    const data = await response.json();
                    this.studentDetails = data;
                    console.log("DATOS DE LA API STUDENT LIST:", data)

                }
                catch (error) {
                    console.log(error);
                }
            },


            async load_incidences_reasons() {
                try {
                    const token = localStorage.getItem('token');


                    const response = await fetch(process.env.VUE_APP_API_COMPANY_GET_INCIDENCES_REASONS, {
                        method: 'GET',
                        headers:
                        {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (!response.ok) {
                        throw new Error('Error al cargar los datos');
                    }
                    const data = await response.json();
                    this.incidentReasons = data.incidentReasons;
                    console.log("INCIDENCIAS LIST:", data)
                }
                catch (error) {
                    console.log(error);
                }

            },


            async startIncident() {
                this.isFetching = true;
                try {
                    const token = localStorage.getItem('token');
                    const decodedToken = jwt_decode(token);
                    const idCompany = decodedToken.userCompanyId;


                    // se obtiene el id del alumno de la store
                    const idStudent = this.$store.getters.getterStudentId;

                    // se obtiene el id de la vacante del store
                    const idVacancie = this.$store.getters.getterVacancieId;

                    const idReason = this.selectedReasonId;
                    const message = this.motivoRechazo;

                    await new Promise(resolve => setTimeout(resolve, 3000));


                    const response = await fetch(process.env.VUE_APP_API_COMPANY_POST_NEW_INCIDENCE,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            },
                            body: JSON.stringify({
                                idStudent: idStudent,
                                idVacancie: idVacancie,
                                message: message,
                                idReason: idReason,
                                idCompany: idCompany,

                            }),

                        });

                    this.isFetching = false;

                    if (!response.ok) {
                        const errorMessage = await response.text();
                        this.showModal = true;
                        this.modalTitle = 'Ha Ocurrido un Error';
                        this.modalContent = `${this.apiErrorMessage}   \n \n MENSAJE DE ERROR:  ${errorMessage}`
                        this.respuestaExitosa = false;
                    }


                    //si todo salio bien
                    if (response.ok) {
                        this.showModal = true;
                        this.modalTitle = 'Proceso Exitoso';
                        this.modalContent = this.apiVacancieRejected;
                        this.respuestaExitosa = true;
                        this.actionOnSuccess = 'vacancieRejected'
                    }
                    const data = await response.json();
                    console.log('datos', data);
                }
                catch (error) {
                    this.isFetching = false;
                    this.showModal = true;
                    this.modalTitle = 'ERROR';
                    this.modalContent = `${this.serverErrorMessage} \n \n MENSAJE DE ERROR: ${error.message}. `;
                    this.respuestaExitosa = false;
                }

            },

            irAIncidencias() {
                console.log("hola");
            },

            irAsistencias() {
                console.log("hola2");
            },


            closeModal() {
                this.showModalIndence = false;
            },

            handleError() {
                this.showModal = false;
            }

        },

        computed: {
      isFormValid() {
        return this.selectedReasonId !== null && this.motivoRechazo.trim() !== '';
      },
    },

    }

</script>