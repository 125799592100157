<template>
  <div :class="[mainTheme]">
    <div class="b-table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <div class="table-wrapper has-mobile-cards">

        <table class="table is-fullwidth is-striped is-hoverable">

          <thead>
            <tr>
              <!-- <th v-for="column in columns" :key="column.field">{{ column.label }}</th> -->
              <th v-for="column in columns" :key="column.field" @click="changeSort(column.field)"
                :class="{ 'is-sorted': sortColumn === column.field }">
                {{ column.label }}
                <!-- Añade una flecha hacia arriba o hacia abajo según la dirección de ordenamiento -->
                <span v-if="sortColumn === column.field"
                  :class="sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'"></span>
              </th>

            </tr>
          </thead>

          <tbody>
            <tr v-for="item in filteredItems" :key="item.id">
              <td v-for="column in columns" :key="column.field" :data-label="column.label">

                <!-- Comprueba si la columna es la de imagen -->
                <template v-if="column.field === 'img'">
                  <img :src="item[column.field]" alt="Imagen de perfil" />
                </template>

                <template v-else>

                  <template v-if="column.field === 'verMensajes'">
                    <button class="button  is-rounded" @click="verMensajes(item)">Ver Mensajes</button>
                  </template>

                  <!-- <template v-if="column.field === 'verMensajesEmpresa'">
                  <button class="button  is-rounded" @click="verMensajesCompany(item)">Ver Mensajes</button> -->


                  <md-elevated-button v-if="column.field === 'verMensajesEmpresa'" @click="verMensajesCompany(item)">
                    Ver Mensajes
                  </md-elevated-button>
                  <!-- </template> -->


                  <template v-if="column.field === 'misAlumnosDetalles'">
                    <md-elevated-button v-if="column.field === 'misAlumnosDetalles'" @click="misAlumnosDetalles(item)">
                      Revisar
                    </md-elevated-button>
                  </template>

                  <template v-if="column.field === 'Candidatos'">
                    <button class="button  is-rounded" @click="candidatos(item)">Ver Detalles</button>
                  </template>

                  <md-elevated-button v-if="column.field === 'AprobarDetalles'" @click="AprobarDetalles(item)">
                    Revisar
                  </md-elevated-button>

                  <template v-else>
                    {{ item[column.field] }}
                  </template>

                </template>

              </td>

            </tr>

          </tbody>

        </table>

      </div>

    </div>


    <div>
      <ModalComponent v-if="showModal" :title="modalTitle" :modalContent="modalContent">
        <template #content>
          <p>Información basada en el ID: {{ selectedId }}</p>
        </template>
        <template #buttons>
          <button @click="closeModal">Cerrar</button>
        </template>
      </ModalComponent>
    </div>
  </div>
</template>







<style scoped src="../styles/components/TableComponent.css"></style>

<style scoped src = "../styles/material/theme.light.css"    ></style> 
<style scoped src = "../styles/material/theme.dark.css"     ></style> 

<script>
import ModalComponent from './ModalComponent.vue';

export default 
{
  components: 
  {
    ModalComponent,
  },



  props: 
  {
    columns   :   Array, // Configuración de columnas (etiqueta, campo de datos, etc.)
    items     :   Array, // Datos a mostrar en la tabla
    searchQuery: String,
  },



  data() 
  {
    return  {
              showModal   :   false,
              selectedId  :   null,


              isModalOpen :   false,
              sortField: '',
      sortOrder: 'asc', // Por defecto, orden ascendente
      sortColumn: null, // Define `sortColumn` como dato inicial
      sortDirection: 'asc', // Define `sortDirection` como dato inicial
      mainTheme: 'light',
            }
  },



  methods: 
  {
    handleThemeChange(e) 
    {
      this.mainTheme = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },


    changeSort(field) {
      if (this.sortField === field) {
        // Si el campo de ordenamiento es el mismo que el anterior, cambia la dirección
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        // Si el campo de ordenamiento es diferente, establece el nuevo campo y la dirección ascendente
        this.sortField = field;
        this.sortOrder = 'asc';
      }
    },



    sortItems(items) {
      if (!this.sortField) {
        // Si no hay campo de ordenamiento, devuelve los elementos sin ordenar
        return items;
      }

      // Utiliza slice() para evitar mutar el array original
      const sortedItems = items.slice().sort((a, b) => {
        // Comparación basada en el campo de ordenamiento y la dirección
        const fieldA = a[this.sortField];
        const fieldB = b[this.sortField];

        if (fieldA < fieldB) {
          return this.sortOrder === 'asc' ? -1 : 1;
        }
        if (fieldA > fieldB) {
          return this.sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });

      return sortedItems;
    },



    verMensajes(item) 
    {
      this.$store.commit('SET_INCIDENT_ID', item.id);
      console.log('inicidente:', item.id);
      this.$router.push({ name: 'personal-incidencias-mensajes' });
    },


    verMensajesCompany(item) 
    {
      this.$store.commit('SET_INCIDENT_ID', item.id);
      console.log('inicidente:', item.id);
      this.$router.push({ name: 'personal-incidencias-listado-empresas-mensajes' });
    },


    misAlumnosDetalles(item) 
    {
      this.$router.push({ name: 'personal-alumnos-mis-alumnos-detalles' });

      console.log('id de la vacante', item.vacancieId);
      this.$store.commit('SET_VACANCIE_ID', item.vacancieId);

      console.log('id del student lokit', item.idStudent);
      this.$store.commit('SET_STUDENT_ID', item.idStudent);
    },


    candidatos(item) 
    {
      this.$router.push({ name: 'empresa-solicitudes-listado-unirse' });
      console.log('x:', item.id);
    },


    AprobarDetalles(item) 
    {
      this.$store.dispatch('actualizarDetalles', item.id);
      this.$router.push('/personal/empresas/menu/validaciones/solicitud');
      console.log('Ver mensajes de dds:', item.id);

      console.log('id de la empresa', item.idCompany);
      this.$store.commit('SET_COMPANY_ID', item.idCompany);
    },

  },


  computed: {




  filteredItems() {
  let items = this.items; // Asegúrate de que this.items tenga los objetos correctos
  const searchLower = this.searchQuery?.toLowerCase() ?? '';

  // Ordenar los elementos antes de filtrar
  let sortedItems = this.sortItems(items);

  // Filtrar los elementos basados en la consulta de búsqueda
  return sortedItems.filter(item => {
    // Concatenar los valores de varios campos en una sola cadena para la búsqueda
    const itemText = [
      item.nombre,
      item.empresa,
      item.fecha,
      item.actividad
    ].map(text => text ?? '') // Usar map para aplicar un valor predeterminado a cada campo
     .join(' '); // Unir los valores con un espacio

    // Buscar la consulta en el texto consolidado
    return itemText.toLowerCase().includes(searchLower);
  });
},
},


mounted() 
  {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQuery.addEventListener('change', this.handleThemeChange);
    this.handleThemeChange(this.mediaQuery);
  },
  

  beforeUnmount() 
  {
    if (this.mediaQuery) 
    {
      this.mediaQuery.removeEventListener('change', this.handleThemeChange);
    }
  },
};
</script>
