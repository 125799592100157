<template>

    <HeaderComponent />

    <div class="side-menu">
        <MenuComponent class="side-menu" />
    </div>

    <article class="message is-info">
        <MessageComponent title="Mis Alumnos" :message="descripcion" />
    </article>

    <div class="tabs is-boxed" style="width: 100%;">
        <ul>
            <li :class="{ 'is-active': activeTab === 'tab1' }" @click="changeTab('tab1')">
                <a>Servicio Social</a>
            </li>
            <li :class="{ 'is-active': activeTab === 'tab2' }" @click="changeTab('tab2')">
                <a>Prácticas Profesionales</a>
            </li>
        </ul>
    </div>


    <div class="job-table">
        <TableComponent :columns="tableColumns" :items="activeTab === 'tab1' ? tableItemsTab1 : tableItemsTab2">
            <template v-slot:cell="{ item, column }">
                <!-- Personaliza el contenido de la celda según la columna -->
                <span v-if="column.field === 'img'">{{ item.img }}</span>
                <span v-else-if="column.field === 'Nombre'">{{ item.nombre }}</span>
                <span v-else-if="column.field === 'Actividad'">{{ item.actividad }}</span>
                <span v-else-if="column.field === 'Estado'">{{ item.estado }}</span>
                <button v-else-if="column.field === 'Detalles'" @click="misAlumnosDetalles(item)">Ver Detalles</button>
            </template>
        </TableComponent>
    </div>


    <FooterComponent />

</template>

<!-- Estilos & Scripts -->
<style scoped src="../styles/views/MisAlumnos.css"></style>

<script>
import FooterComponent      from        '../components/FooterComponent.vue';
import HeaderComponent      from        '../components/HeaderComponent.vue';
import MenuComponent        from        '../components/MenuComponent.vue';
import MessageComponent     from        '../components/MessageComponent.vue';
import TableComponent       from        '../components/TableComponent.vue';

export default
    {
        name: 'InfoContacto',
        components:
        {
            FooterComponent,
            HeaderComponent,
            MenuComponent,
            MessageComponent,
            TableComponent,
        },



        data() {
            return  {
                        activeTab: 'tab1',
                        descripcion: `pendiednte`,
                        tableColumns:
                            [
                                { label: 'img', field: 'img' },
                                { label: 'Nombre', field: 'nombre' },
                                { label: 'Actividad', field: 'actividad' },
                                { label: 'Estado', field: 'estado' },
                                { label: 'Detalles', field: 'misAlumnosDetalles' },
                            ],

                            tableItemsTab1: 
                            [ 
                                { id: 1, img: '', nombre: 'Ana Sofía López Ramírez', actividad: 'Cerillo', estado: 'En Progreso' },
                            ],

                            tableItemsTab2: 
                            [ 
                                { id: 1, img: '', nombre: 'Cirenia Díaz Iglesias', actividad: 'Cerillo', estado: 'Terminado' },
                            ],
                    }
        },



        methods: 
        {
            changeTab(tab) 
            {
                this.activeTab = tab;
            },
        }
    }


</script>