<template>
    <div class="material-you">
        <HeaderComponent />

        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>

        <article class="message">
            <MessageComponent title="Detalles" :message="descripcion" />
        </article>

        <div class="card-perfil">
            <CardComponent>

                <!-- Slot para el título -->
                <template v-slot:title>
                </template>


                <template v-slot:content>
                    <div class="media-content-info">
                        <div class="content-postulados">
                            <p style="text-align: left;">Información de la Vacante</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em;"> <span
                                    class="tag is-info-custom">Empresa Responsable</span> {{ vacancieDetails.company_name }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                    class="tag is-info-custom">Nombre de la Vacante</span> {{ vacancieDetails.name }}</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                    class="tag is-info-custom">Creada en </span>
                                {{ new Date(vacancieDetails.creation_log).toLocaleDateString('es-MX', {
                                    weekday: 'long',
                                    day: 'numeric', month: 'long', year: 'numeric'
                                }) }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                <span class="tag is-info-custom">Descripción</span> {{ vacancieDetails.description }}</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: justify; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Dirección</span> {{ vacancieDetails.address }}</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                <span class="tag is-info-custom">Salario</span>
                                {{ vacancieDetails.salary !== null ? '$' + vacancieDetails.salary : 'No aplica' }}
                            </p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                    class="tag is-info-custom">Notas Adicionales</span> {{ vacancieDetails.notes }}</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                    class="tag is-info-custom">Estado</span> {{ vacancieDetails.state_name }}</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                    class="tag is-info-custom">Municipio</span> {{ vacancieDetails.muni_name }}</p>

                            <md-divider></md-divider>

                            <p v-if="vacancieDetails" style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                    class="tag is-info-custom">Sector</span> {{ vacancieDetails.sector_name }}</p>
                        </div>
                    </div>
                </template>

                <template v-slot:buttons>

                    <!-- Botón Aceptar -->
                    <md-elevated-button @click="handleClick">
                        {{ buttonText }}
                    </md-elevated-button>

                    <!-- Botón Rechazar -->
                    <md-elevated-button @click="handleReject">
                        Rechazar
                    </md-elevated-button>

                </template>

            </CardComponent>
        </div>
        <FooterComponent />
</div>
</template>





<!-- <style scoped src="../styles/views/EmpresasMenuValidacionesSolicitud.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style>

<style scoped src="../styles/views/EmpresasMenuValidacionesSolicitud.css"></style>
<style scoped src="../styles/views/MaterialTheming.css"></style> -->

<script>
import CardComponent    from    '../components/CardComponent.vue';
import FooterComponent  from    '../components/FooterComponent.vue';
import HeaderComponent  from    '../components/HeaderComponent.vue';
import MenuComponent    from    '../components/MenuComponent.vue';
import MessageComponent from    '../components/MessageComponent.vue';
import ModalComponent   from    '../components/ModalComponent.vue';


export default
{

    name: 'EmpresasMenuValidacionesSolicitud',
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        ModalComponent,
    },


    //cargamos la vacante al crear el componente
    async created() {   await this.loadvacancie();  },


    data() 
    {
        return  {
                    actionOnSuccess     :   '',
                    buttonText          :   'Aceptar',
                    isClicked           :   false,
                    isFetching          :   false,
                    modalContent        :   '',
                    modalTitle          :   '',
                    motivoRechazo       :   '',
                    respuestaExitosa    :   false,
                    showModal           :   false,
                    showModalReject     :   '',
                    vacancieDetails     :   null,


                    //seccion de mensajes.
                    descripcion     :   `En este módulo, te instamos a realizar una revisión minuciosa de la vacante para asegurarte de que se ajuste a los protocolos
                                        establecidos por nuestra institución. Te recomendamos examinar cada detalle con atención. En el caso de que la vacante no cumpla con dichos
                                        protocolos, es necesario rechazarla y proporcionar una explicación clara que respalde esta decisión. Ten en cuenta que una vez que se tome
                                        la acción de aceptar o rechazar, dicha decisión será final y no podrá revertirse. Agradecemos tu dedicación y atención a este proceso.`,

                    serverErrorMessage  :   `No ha sido posible establecer conexión con el servidor. Por favor, verifique su conexión a Internet y asegúrese de que los datos ingresados sean correctos.
                                            Si el problema persiste, le recomendamos que consulte con su administrador.`,

                    apiErrorMessage :   `Se ha producido un error. Por favor, revise cuidadosamente la información ingresada e intente nuevamente. En caso de que el problema persista,
                                        le recomendamos que se comunique con su administrador para obtener asistencia adicional.`,

                    apiVacancieRejected :   `La solicitud de la vacante ha sido rechazada de manera satisfactoria. Se ha notificado al responsable correspondiente para que pueda 
                                            realizar las ediciones necesarias. Agradecemos su colaboración.`,

                    apiVacancieAccepted :   `La solicitud de vacante ha sido aceptada de forma satisfactoria. No es necesario que realices ninguna acción adicional. Agradecemos tu colaboración.`
                };
    },

    methods:
    {
        closeModal()
        {
            this.showModal          =   false;
            this.showModalReject = false;
        },


        //determina los clicks que se hacen aka double step
        handleClick() 
        {
            if (!this.isClicked) 
            {
                this.buttonText = 'Haz clic de nuevo para confirmar';
                this.isClicked = true;
            } 
            else 
            {
                this.handleAccept();
                this.isFetching = true;
                this.sendAcceptApi();
            }
        },


        //acciones a tomar segun la accion que se realiza
        handleAccept() 
        {
            if (this.actionOnSuccess === 'vacancieAccepted') 
            {
                this.$router.push({ name: 'personal-inicio' });
            }

            else if (this.actionOnSuccess === 'vacancieRejected') 
            {
                this.$router.push({ name: 'personal-inicio' });
            }

            this.showModal = false;
        },


        //para ver si rechazamos o no la vacante
        handleReject() 
        {
            this.showModalReject = true;
            this.modalTitle = 'Rechazo de la vacante';

            this.$nextTick(() => 
            {
                this.$refs.motivoRechazoRef.focus();
            });
        },


        //cargamos la informacion de la vacante a rechazar o aceptar
        async loadvacancie()
        {
            try
            {
                const vacancie      =   this.$store.getters.obtenerDetalles; //mover a created
                const token         =   localStorage.getItem('token');
                const id_vacancie   =   vacancie;

                const response      =   await fetch(process.env.VUE_APP_API_STAFF_POST_APPROVEVACANCIE,{
                    method : 'POST',
                    headers: 
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ id_vacancie: id_vacancie }),
                });


                if(!response.ok)
                {
                    throw new Error('Error al cargar la vacante');
                }

                const data              =   await response.json();
                this.vacancieDetails    =   data[0];
            }
            catch(error)
            {
                console.log(error);
            }

        },


        //aqui enviamos los datos a la api, para rechazar la vacante
        async rejectVacancieApi()
        {
            this.showModalReject    =   false;
            this.isFetching         =   true;

            try
            {
                const reason        =   this.motivoRechazo;
                const vacancie      =   this.$store.getters.obtenerDetalles;
                const token         =   localStorage.getItem('token');

                await new Promise(resolve => setTimeout(resolve, 4000));
                
                const rejectApi = process.env.VUE_APP_API_STAFF_POST_REJECTVACANCIE;

                const response = await fetch(rejectApi, 
                {
                    method  : 'POST',
                    headers: 
                            {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            },
                            body: JSON.stringify({ id_vacancie: vacancie, reason : reason }),
                });


                this.isFetching = false;

                //ha ocurrido algun tipo de error
                if (!response.ok) 
                {
                    const errorMessage = await response.text();
                    this.showModal          =   true;
                    this.modalTitle         =   'Ha Ocurrido un Error';
                    this.modalContent       =   `${this.apiErrorMessage}   \n \n MENSAJE DE ERROR:  ${errorMessage}`
                    this.respuestaExitosa   =   false;
                }

                //si todo salio bien
                if (response.ok) 
                {
                    this.showModal          =   true;
                    this.modalTitle         =   'Proceso Exitoso';
                    this.modalContent       =   this.apiVacancieRejected;
                    this.respuestaExitosa   =   true;
                    this.actionOnSuccess    = 'vacancieRejected'
                } 

            }
            catch (error) 
            {
                this.isFetching         =       false;
                this.showModal          =       true;
                this.modalTitle         =       'ERROR';
                this.modalContent       =       `${this.serverErrorMessage} \n \n MENSAJE DE ERROR: ${error.message}. `;
                this.respuestaExitosa   =       false;
            }

        },


        //api para aceptar a la vacante
        async sendAcceptApi()
        {
            try
            {
                const vacancie  =   this.$store.getters.obtenerDetalles; //mover a created
                const token     =   localStorage.getItem('token');

                await new Promise(resolve => setTimeout(resolve, 3000));
                const api_accept_vacancie = process.env.VUE_APP_API_STAFF_POST_ACCEPTVACANCIE;

                const response = await fetch(api_accept_vacancie, 
                {
                    method  : 'POST',
                    headers: 
                            {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            },
                            body: JSON.stringify({ id_vacancie: vacancie }),
                });

                this.isFetching = false;

                //ha ocurrido algun error
                if (!response.ok) 
                {
                    this.showModal          =   true;
                    this.modalTitle         =   'Ha Ocurrido un Error';
                    this.modalContent       =   `${apiErrorMessage}   \n \n MENSAJE DE ERROR:  ${errorMessage}`
                    this.respuestaExitosa   =   false;
                    
                }

                //todo salio bien
                if (response.ok) 
                {
                    this.showModal          =   true;
                    this.modalTitle         =   'Proceso Exitoso';
                    this.modalContent       =   this.apiVacancieAccepted;
                    this.respuestaExitosa   =   true;
                    this.actionOnSuccess    =   'vacancieAccepted'
                } 

            }
            catch (error) 
            {
                this.isFetching         =       false;
                this.showModal          =       true;
                this.modalTitle         =       'ERROR';
                this.modalContent       =       `${this.serverErrorMessage} \n \n MENSAJE DE ERROR: ${error.message}. `;
            }
        },
        
        
        handleError()
        {
            this.showModal          =   false;
            this.showModalReject    =   false;
        },

    },

}
</script>