<template>
<div class="material-you light">
<HeaderComponent />

  <div class="side-menu">
    <MenuComponent class="side-menu" />
  </div>


  <div class="card-perfil">
    <CardComponent>
      <!-- Slot para el título (dejado vacío) -->
      <template v-slot:title>
        
      </template>

      <!-- Slot para el contenido -->
      <template v-slot:content>
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <div class="media-content-image has-text-centered">
                <figure class="image custom-image">
                  <img src="https://media.glassdoor.com/sqll/715/walmart-squarelogo-1408483474312.png" alt="User Avatar" class="rounded-image">
                </figure>
              </div>
            </div>
            <div class="content-perfil">
              <p class="perfil">Wallmart</p>
              <p class="perfil">Aqui se muestran los datos de la empresa que crean necesarios</p>
              <!-- Puedes mostrar otros detalles del profesor aquí -->
            </div>
          </div>
        </div>
      </template>

      <!-- Slot para los botones (dejado vacío) -->
      <template v-slot:buttons>
        <!-- Deja este slot vacío para no mostrar botones en esta parte -->
      </template>
    </CardComponent>
  </div>


  <div class="separador"></div>


  <FooterComponent />

</div>
</template>

<style scoped src="../styles/material/theme.light.css"></style> 
<style scoped src="../styles/material/theme.dark.css"></style> 
<style scoped src="../styles/views/MaterialTheming.css"></style>
<style scoped src="../styles/views/MiPerfil.css"></style>

<script>
import CardComponent      from    '../components/CardComponent.vue';
import FooterComponent    from    '../components/FooterComponent.vue';
import HeaderComponent    from    '../components/HeaderComponent.vue';
import MenuComponent      from    '../components/MenuComponent.vue';

export default
  {
    name: 'PerfilStaff',

    components: 
    {
      CardComponent,
      FooterComponent,
      HeaderComponent,
      MenuComponent,
    },

  }

</script>
