<template>
  <div class="material-you light ">
    <HeaderComponent />
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>
    <article class="message ">
      <MessageComponent title="Bolsa de Trabajo" :message="descripcion" />
    </article>
    <div class="tabs " style="width: 100%;">
      <md-tabs v-model="activeTab" :style="{ borderRadius: '15em' }">
        <md-primary-tab v-for="(tab, index) in tabs" :key="index" :name="tab.name" @click="changeTab(tab.name)">
          {{ tab.label }}
        </md-primary-tab>
      </md-tabs>
    </div>
    <div class="select-container">
      <div class="select-you SolicitudesNueva">
        <md-filled-select label="Estado" v-model="selectedEstado" @change="onEstadoChange">
          <md-select-option v-for="estado in uniqueEstados" :value="estado" :key="estado">
            <div slot="headline">{{ estado }}</div>
          </md-select-option>
        </md-filled-select>
      </div>
      <div class="select-you SolicitudesNueva">
        <md-filled-select label="Municipio" v-model="selectedMunicipio" @change="onMunicipioChange">
          <md-select-option v-for="municipio in uniqueMunicipios" :value="municipio" :key="municipio">
            <div slot="headline">{{ municipio }}</div>
          </md-select-option>
        </md-filled-select>
      </div>
      <div class="select-you SolicitudesNueva">
        <md-filled-select label="Sector" v-model="selectedPerfil" @change="onPerfilChange">
          <md-select-option v-for="perfil in uniquePerfiles" :value="perfil" :key="perfil">
            <div slot="headline">{{ perfil }}</div>
          </md-select-option>
        </md-filled-select>
      </div>
    </div>
    <div class="job-table">
      <TableComponent :columns="tableColumns" :items="filteredItems">
        <template v-slot:cell="{ item, column }">
        </template>
      </TableComponent>
    </div>
    <FooterComponent />
  </div>
</template>



<style scoped src="../styles/material/theme.light.css">     </style> 
<style scoped src="../styles/material/theme.dark.css">      </style> 
<style scoped src="../styles/views/MaterialTheming.css">    </style>
<style scoped src="../styles/views/SolicitudesNueva.css">   </style>

<script>
import FooterComponent    from    '../components/FooterComponent.vue';
import HeaderComponent    from    '../components/HeaderComponent.vue';
import jwt_decode         from    'jsonwebtoken/decode';
import MenuComponent      from    '../components/MenuComponent.vue';
import MessageComponent   from    '../components/MessageComponent.vue';
import TableComponent     from    '../components/TableComponent.vue';

export default 
{
  components: 
  {
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    MessageComponent,
    TableComponent,
  },



  data() 
  {
    return  {
              allSelectsAreDefined    :     false,

              activeTab               :     'tab1',
              selectedEstado          :     '',
              selectedMunicipio       :     '',
              selectedPerfil          :     '',

              descripcion             :     `¡Bienvenido! En esta sección, encontrarás una lista de vacantes que se ajustan al perfil de tu carrera,
                                            publicadas por empresas e instituciones asociadas a la UMNSH. Las vacantes están organizadas en categorías
                                            relevantes para tu búsqueda laboral.Recuerda que es necesario y obligatorio subir tu CV para poder enviar la
                                            solicitud. Además, ten en cuenta que el proceso de selección no está monitoreado por la universidad,
                                            por lo que te recomendamos leer detenidamente las descripciones de las vacantes antes de aplicar.`,

              servicioSocial          :     `Descubre estas oportunidades especialmente diseñadas para estudiantes que buscan adquirir experiencia práctica
                                            en diversos ámbitos profesionales, al mismo tiempo que ofrecen su tiempo para servir a la comunidad.
                                            Estas experiencias proporcionan una valiosa combinación de aprendizaje en el mundo real
                                            y contribuciones significativas a la sociedad, permitiendo a los estudiantes expandir sus habilidades
                                            mientras impactan positivamente en su entorno`,

              practicas               :     `Explora estas oportunidades diseñadas para estudiantes que desean sumergirse en el mundo laboral y
                                            aplicar sus conocimientos en situaciones reales. Las ofertas de Prácticas Profesionales te permiten colaborar
                                            con empresas e instituciones, brindándote la experiencia práctica necesaria para desarrollarte en tu área de estudio.
                                            Es una forma única de adquirir habilidades prácticas, establecer contactos profesionales y prepararte para tu futura carrera.`,
    


              tabs: 
              [
                { name: 'tab1', label: 'Vacantes Disponibles'   },
                { name: 'tab2', label: 'Solicitudes enviadas '  },
                // { name: 'tab3', label: 'Bolsa de Trabajo'    },
              ],

              tableItemsTab1: [],
              tableItemsTab2: [],
              tableItemsTab3: [],

              tableColumns: 
              [
                { label   :   'Company',          field   :     'empresa'           },
                { label   :   'Actividad',        field   :     'actividad'         },
                { label   :   'Estado',           field   :     'estado'            },
                { label   :   'Municipio',        field   :     'municipio'         },
                { label   :   'Vacantes',         field   :     'vacantes'          },
                { label   :   'Disponibles ',     field   :     'disponibles'       },
                { label   :   'Perfil',           field   :     'perfil'            },
                { label   :   'Fecha',            field   :     'fecha'             },
                { label   :   'Ver',              field   :     'Ver'               },
              ],
    
            };
  },



  created()
  {
    this.loadVacancies();
  },



  computed: 
  {
    filteredItems() 
    {
      if (this.selectedEstado === '' && this.selectedMunicipio === '' && this.selectedPerfil === '') 
      {
        // No se ha seleccionado ningún filtro, mostrar todos los datos
        return this.activeTab === 'tab1' ? this.tableItemsTab1 : this.tableItemsTab2;
      } 
      else 
      {
        // Filtrar los elementos por estado, municipio y perfil empresarial
        const items = this.activeTab === 'tab1' ? this.tableItemsTab1 : this.tableItemsTab2;
    
        return items.filter(item => {
          const matchEstado = this.selectedEstado === '' || item.estado === this.selectedEstado;
          const matchMunicipio = this.selectedMunicipio === '' || item.municipio === this.selectedMunicipio;
          const matchPerfil = this.selectedPerfil === '' || item.perfil === this.selectedPerfil;
          return matchEstado && matchMunicipio && matchPerfil;
        });
      }
    },


    uniqueEstados() 
    {
      const items = this.activeTab === 'tab1' ? this.tableItemsTab1 : this.tableItemsTab2;
      return [...new Set(items.map(item => item.estado))];
    },


    uniqueMunicipios() 
    {
      const items = this.activeTab === 'tab1' ? this.tableItemsTab1 : this.tableItemsTab2;
      return [...new Set(items.map(item => item.municipio))];
    },


    uniquePerfiles() 
    {
      const items = this.activeTab === 'tab1' ? this.tableItemsTab1 : this.tableItemsTab2;
      return [...new Set(items.map(item => item.perfil))];
    },
  },



  methods: 
  {
    async loadVacancies()
    {
      console.log('se cargo la vacante');
      try
      {
        const token         =   localStorage.getItem('token');
        const decodedToken  =   jwt_decode(token);
        const id_student = decodedToken.id_student;

        console.log("id del estudiante",id_student);

        const response = await  fetch(process.env.VUE_APP_API_STUDENT_POST_LOADVACANCIES,
        {
          method : 'POST',
          headers: 
          {
              'Content-Type'  : 'application/json',
              'Authorization' : `Bearer ${token}`,
          },
          body: JSON.stringify({ id_student: id_student }),
        });
    
        if (!response.ok) 
        {
            throw new Error('Error loading vacancies');
        }
    
        const data = await response.json();
        console.log('datos',data);

        // Asignar los datos según el tipo de vacante
        this.tableItemsTab1 = data.vacancies
        .filter(item => item.vacancie_type === 'Servicio Social')
        .map(item => 
        ({
                id            :   item.vacancie_id,
                empresa       :   item.company_name,
                estado        :   item.state_name,
                municipio     :   item.muni_name,
                perfil        :   item.business_name,
                actividad     :   item.description,
                vacantes      :   item.w_hiring_capacity,
                disponibles   :   (item.w_hiring_capacity - item.filled_hiring),
                fecha         :   new Date(item.creation_log).
                                  toLocaleDateString('es-MX',{ weekday:'long', day:'numeric', month:'long', year:'numeric' }), 
        }));
    
        this.tableItemsTab2 = data.vacancies
            .filter(item => item.vacancie_type === 'Prácticas Profesionales')
            .map(item => 
            ({
                id            :   item.vacancie_id,
                empresa       :   item.company_name,
                estado        :   item.state_name,
                municipio     :   item.muni_name,
                perfil        :   item.business_name,
                actividad     :   item.description,
                vacantes      :   item.w_hiring_capacity,
                disponibles   :   (item.w_hiring_capacity - item.filled_hiring),
                fecha         :   new Date(item.creation_log).
                                  toLocaleDateString('es-MX',{ weekday:'long', day:'numeric', month:'long', year:'numeric' }), 
            }));
    
        this.tableItemsTab3 = data.vacancies
            .filter(item => item.vacancie_type === 'Bolsa de Trabajo')
            .map(item => 
            ({
                id            :   item.vacancie_id,
                empresa       :   item.company_name,
                estado        :   item.state_name,
                municipio     :   item.muni_name,
                perfil        :   item.business_name,
                actividad     :   item.description,
                vacantes      :   item.w_hiring_capacity,
                disponibles   :   (item.w_hiring_capacity - item.filled_hiring),
                fecha         :   new Date(item.creation_log).
                                  toLocaleDateString('es-MX',{ weekday:'long', day:'numeric', month:'long', year:'numeric' }), 
            }
            ));
      }
      catch(error) { }
    },


    changeTab(tab) 
    {
      this.activeTab = tab;
    },


    onEstadoChange() 
    {
      this.allSelectsAreDefined = !!this.selectedEstado && !!this.selectedMunicipio && !!this.selectedPerfil;
    },


    onMunicipioChange() 
    {
      this.allSelectsAreDefined = !!this.selectedEstado && !!this.selectedMunicipio && !!this.selectedPerfil;
    },


    onPerfilChange() 
    {
      this.allSelectsAreDefined = !!this.selectedEstado && !!this.selectedMunicipio && !!this.selectedPerfil;
    },
    
  },
    
};
</script>