<template>
  <div class="material-you light ">
    <HeaderComponent />
    <div class="side-menu">
      <MenuComponent class="side-menu" />
    </div>
    <article class="message">
      <MessageComponent title="Tu perfil" :message="descripcion" />
    </article>
    <div class="container">
      <form v-if="skillLoad.length > 0 && expLoad.length > 0" @submit.prevent="submitForm">
        <FormComponent :formFields="formFields" :apiUrl="apiUrl" />
      </form>
    </div>
    <FooterComponent />
  </div>
</template>



<style scoped src = "../styles/material/theme.light.css">       </style> 
<style scoped src = "../styles/material/theme.dark.css">        </style> 
<style scoped src = "../styles/views/MaterialTheming.css">      </style>
<style scoped src = "../styles/views/PerfilEditarperfil.css">   </style>

<script>
import CardComponent        from    '../components/CardComponent.vue';
import FooterComponent      from    '../components/FooterComponent.vue';
import FormComponent        from    '../components/FormComponent.vue';
import HeaderComponent      from    '../components/HeaderComponent.vue';
import jwt_decode           from        'jsonwebtoken/decode';
import MenuComponent        from    '../components/MenuComponent.vue';
import MessageComponent     from    '../components/MessageComponent.vue';

export default 
{
  name: 'PerfilEditarperfil',

  components: 
  {
    CardComponent,
    FooterComponent,
    FormComponent,
    HeaderComponent,
    MenuComponent,
    MessageComponent,
  },



  data() 
  {
    return  {
              descripcion : `hola`,
              formData: {},
              apiUrl: process.env.VUE_APP_API_STUDENT_POST_EDITPROFILE,
              skills: this.$store.state.skills,
              exp: this.$store.state.exp,
            };
  },



  created() 
  {
    console.log('Before Mount: calling load_profile');
    this.load_profile();
    console.log('After load_profile:',this.$store.getters.obtenerSkills);
  },



  computed: 
  {
    skillLoad() 
    {
      return this.$store.getters.obtenerSkills || [];
    },

    expLoad() 
    {
      return this.$store.getters.obtenerExp || [];
    },

    formFields() 
    {
      return  [
                { label : "Habilidades", type : "text",       name  : "skills", value :   this.skillLoad  },
                { label : "Experiencia", type : "textarea",   name  : "exp",    value :   this.expLoad    },
              ];
    },
  },



  watch: 
  {
    skills() 
    {
      this.$store.commit('setSkills', this.skills);
    },


    exp() 
    {
      this.$store.commit('setExp', this.exp);
    },
  },



  methods :
  {
    async load_profile()
      {
        try
        {
          const token = localStorage.getItem('token');
          const decodedToken = jwt_decode(token);
          const id_student = decodedToken.id_student;
          console.log(token);
          console.log('API URL:',id_student); // Verifica este valor
          const response = await fetch(process.env.VUE_APP_API_STUDENT_POST_LOADPROFILE, {
          method: 'POST',

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            id_student :  id_student
          }),
        }); 

          if (!response.ok) 
          {
            throw new Error('Error loading profile');
          }

          if(response.ok)
          {
            const data = await response.json();
          console.log('estos son los datos de la api: ',data );

          this.$store.commit('setSkills', data.profile.skills);
          this.$store.commit('setExp', data.profile.exp);

          console.log('Vuex state after commit:', this.$store.state);

            this.$forceUpdate();
          }
        }
        catch (error) 
        {
          console.error('Server Error: ', error);
        }

      },
  },

  
}
</script>
