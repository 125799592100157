<template>
  <div :class="[mainTheme]">
    <nav class="navbar" id="custom-navbar">
      <div class="navbar-brand">
        <a class="navbar-item"></a>
      </div>

      <!-- Botón en la esquina derecha -->
      <div class="navbar-end">
        <div class="navbar-item">
          <md-filled-icon-button class="menu-button" @click="toggleMenu">
            <span class="material-symbols-outlined">notifications</span>
          </md-filled-icon-button>
        </div>
      </div>
    </nav>

    <!-- Notificaciones temporales -->
    <Transition :duration="550" name="nested">
      <div class="panelNoti" v-if="nuevaNotificacion.length > 0">
        <md-list>
          <!-- Muestra todas las notificaciones temporales -->
          <template v-for="(noti, index) in nuevaNotificacion" :key="noti.id">
            <Transition name="slide-fade" :duration="300">
              <md-list-item v-if=true>
                {{ noti.body }}
              </md-list-item>
            </Transition>
          </template>

        </md-list>
      </div>
    </Transition>

    <!-- Notificaciones permanentes -->
    <Transition :duration="550" name="nested">
      <div class="panelNoti" v-if="mostrarLista">
        <md-list>
          <!-- Verifica si hay notificaciones -->
          <template v-if="notifications.length > 0">
            <template v-for="(message, index) in notifications" :key="index">
              <md-list-item
                class="itemsList custom-item"
                :href="'#'"
                @click.prevent="goToRoute(message.url)"
              >
                {{ message.name }}
              </md-list-item>
              <md-divider v-if="index < notifications.length - 1"></md-divider>
            </template>
          </template>
          <!-- Mensaje cuando no hay notificaciones -->
          <template v-else>
            <md-list-item class="itemsList custom-item">
              Sin notificaciones nuevas
            </md-list-item>
          </template>
        </md-list>
      </div>
    </Transition>

    <transition name="fade">
      <ModalGlobalLogout ref="modal" @openPopup="openModal" @closePopup="closeModal" />
    </transition>
  </div>
</template>

<style scoped src="../styles/components/HeaderComponent.css"></style>
<style scoped src="../styles/material/theme.light.css"></style>
<style scoped src="../styles/material/theme.dark.css"></style>

<script>
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { ref, defineComponent, onMounted, onBeforeUnmount } from 'vue';
import ModalGlobalLogout from '../../GLOBAL/components/ModalGlobalLogout.vue';
import { sendNotiTokenApi, loadNotificationsApi } from '../api/ApiService';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    ModalGlobalLogout,
  },
  setup() {
    const router = useRouter();
    const mostrarLista = ref(false);
    const notifications = ref([]);
    const nuevaNotificacion = ref([]);
    const modal = ref(null);
    const mainTheme = ref('light');

    async function goToRoute(routeName) {
      try {
        const routeExists = router.getRoutes().some(r => r.name === routeName);
        if (!routeExists) {
          throw new Error('Ruta no encontrada');
        }
        await router.push({ name: routeName });
      } catch (error) {
        console.error(error.message);
        // Redirige a la ruta principal si la ruta no existe
        await router.push({ name: 'personal-inicio' });
      }
    }

    const toggleMenu = () => {
      mostrarLista.value = !mostrarLista.value;
    };

    const sendNotiToken = async (notiToken) => {
      try {
        await sendNotiTokenApi(notiToken);
      } catch (error) {
        console.error('Error al enviar el token de notificación: ', error);
      }
    };

    const openModal = () => modal.value.openPopup();
    const closeModal = () => modal.value.closePopup();

    const handleThemeChange = (e) => {
      mainTheme.value = e.matches ? 'dark' : 'light';
      console.log(`El sistema está configurado para tema ${mainTheme.value}.`);
    };

    let mediaQuery;
    onMounted(async () => {
      await checkNotificationPermission();
      await loadNotifications();
      listenForMessages();

      mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      mediaQuery.addEventListener('change', handleThemeChange);
      handleThemeChange(mediaQuery);
    });

    onBeforeUnmount(() => {
      if (mediaQuery) {
        mediaQuery.removeEventListener('change', handleThemeChange);
      }
    });

    const checkNotificationPermission = async () => {
      if (!("Notification" in window)) {
        console.log("Este navegador no soporta notificaciones del sistema.");
      } else if (Notification.permission === "granted") {
        console.log("Permiso de notificaciones concedido");
        await checkNotificationToken();
      } else {
        console.log("Permiso de notificaciones no concedido. Solicitando permisos...");
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("Permiso de notificaciones concedido");
          await checkNotificationToken();
        } else {
          console.log("Permiso de notificaciones denegado");
        }
      }
    };

    const checkNotificationToken = async () => {
      const token = localStorage.getItem('tokenNotification');
      if (token) {
        console.log("Token de notificación encontrado en el almacenamiento local:", token);
      } else {
        console.log("Token de notificación no encontrado en el almacenamiento local");
        await createNotificationToken();
      }
    };

    const createNotificationToken = async () => {
      const messaging = getMessaging();
      try {
        const notificationToken = await getToken(messaging);
        if (notificationToken) {
          console.log("Token de notificación creado: ", notificationToken);
          localStorage.setItem('tokenNotification', notificationToken);
          await sendNotiToken(notificationToken);
        } else {
          console.log("No se pudo obtener el token de notificación. Autoriza o habilita las notificaciones.");
        }
      } catch (error) {
        console.error("Error obteniendo el token de notificación:", error);
      }
    };

    const loadNotifications = async () => {
      try {
        const notificationMessages = await loadNotificationsApi();
        notifications.value = notificationMessages;
        console.log('Notificaciones cargadas:', notifications.value);
      } catch (error) {
        console.error('Error loading notifications:', error);
        mostrarLista.value = false;
      }
    };

    const listenForMessages = () => {
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        console.log('Mensaje recibido. ', payload);
        const { title, body } = payload.notification;

        // Mostrar notificación
        new Notification(title, { body });

                // Añadir la nueva notificación al array
                if (nuevaNotificacion.value.length >= 10) {
          nuevaNotificacion.value.shift(); // Elimina la notificación más antigua
        }

        // Añadir la nueva notificación al array
        nuevaNotificacion.value.push({ body, id: Date.now() });

        // Quitar la notificación temporal después de unos segundos
        setTimeout(() => {
          nuevaNotificacion.value.shift(); // Quitar la notificación más antigua
        }, 5000); // Mostrar por 5 segundos

        mostrarLista.value = false; // No abrir el panel completo
      });
    };

    return {
      mostrarLista,
      toggleMenu,
      openModal,
      closeModal,
      notifications,
      nuevaNotificacion,
      mainTheme,
      sendNotiToken,
      loadNotifications,
      goToRoute
    };
  }
});
</script>
