<template>
  <div :class="[mainTheme]">
    <GoogleLogin :callback="callback" promt auto-login />
    <Transition name="bounce">
      <div v-if="isFetching" class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card">
          <section class="modal-card-body">
            <div> Iniciando Sesión... </div>
            <md-circular-progress four-color indeterminate></md-circular-progress>
          </section>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="isFetching = false"></button>
      </div>
    </Transition>
  </div>
</template>







<style scoped src="../styles/components/GoogleComponent.css"></style> 


<script>
import { decodeCredential } from 'vue3-google-login';
import { ref, onMounted, onBeforeUnmount}   from  'vue'
import { useRouter } from 'vue-router';

export default {
  setup() {
    let mediaQuery;
    const mainTheme = ref('light');
    const handleThemeChange = (e) => 
    {
        mainTheme.value = e.matches ? 'dark' : 'light';
        console.log(`El sistema está configurado para tema ${mainTheme.value}.`);
    };

    const router = useRouter();
    const isFetching = ref(false);

    const callback = async (response) => {
      // datos obtenidos de la cuenta de google
      const userData = decodeCredential(response.credential);
      const userEmail = userData.email;
      const userPicture = userData.picture;
      const userName = userData.given_name;
      const userLastName = userData.family_name;
      const apiUrl = process.env.VUE_APP_API_STUDENT_POST_SIGNIN;
      const idToken = response.credential; // Captura el id_token
      const requestBody = { mail: userEmail, idToken : idToken };

      console.log("este es el token que retorna google",idToken);

      // Activar el indicador de carga
      isFetching.value = true;

      try {
        await new Promise(resolve => setTimeout(resolve, 3000));

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestBody),
        });

        // Desactivar el indicador de carga
        isFetching.value = false;

        if (response.ok) {
          const data = await response.json();
          const token = data.token;

          if (token) {
            localStorage.setItem('token', token);
            localStorage.setItem('avatar', userPicture);
            localStorage.setItem('email_staff', userEmail);
            localStorage.setItem('userName', userName);
            localStorage.setItem('userLastName', userLastName);

            console.log('Token Guardado :', token);
            await new Promise(resolve => setTimeout(resolve, 650)); // Agrega un pequeño retraso antes de la redirección

            router.push({ name: 'estudiante-home' });
          } else {
            console.log('no se recibió el token del servidor');
          }
        } else {
          router.push({ path: '/', query: { error: 'Usuario no registrado, consulta a tu administrador' } });
        }
      } catch (error) {
        isFetching.value = false;
        console.error("ha ocurrido un error crítico", error);
        router.push({ path: '/', query: { error: 'Fallo de conexión al servidor. Revisa tu conexión a Internet. Si persiste el error, contacta al administrador' } });
      }
    };
    
    onMounted(async () => {
    mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', handleThemeChange);
    handleThemeChange(mediaQuery);
    });

    onBeforeUnmount(() => {
    if (mediaQuery) {
      mediaQuery.removeEventListener('change', handleThemeChange);
    }
    });

    return {
      isFetching,
      callback,
      mainTheme,
    };
  },
};
</script>
