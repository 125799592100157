<template>


    <!-- main theme -->
    <div :class="['material-you', mainTheme]">


        <!-- header -->
        <HeaderComponent />


        <!-- main menu -->
        <div class="side-menu">
            <MenuComponent class="side-menu" />
        </div>


        <!-- tooltips -->
        <article class="message">
            <MessageComponent title="Unidad Receptora abierta" :message="descripcion" />
        </article>


        <!-- vacancie info -->
        <div class="navbar-center">
            <div class="navbar-item">
                <div class="notiBotton">
                    <md-elevated-button class="menu-button" @click="toggleMenu">
                        Información de la Unidad Receptora
                    </md-elevated-button>
                </div>
            </div>
        </div>

        <Transition>
            <div class="detailsVacancie" v-if="mostrarLista">
                <div class="card-perfil">
                    <CardComponent>
                        <template v-slot:content>
                            <div class="media-content-info">
                                <div class="content-postulados">
                                    <p style="text-align: left;">Información de la Vacante</p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em;">
                                        <span class="tag is-info-custom">
                                            Empresa Responsable
                                        </span>
                                        {{ vacancieDetails.vacancieInfo.company_name }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">
                                            Nombre de la Vacante
                                        </span>
                                        {{ vacancieDetails.vacancieInfo.name }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">
                                            Creada en
                                        </span>
                                        {{ new
                                            Date(vacancieDetails.vacancieInfo.creation_log).
                                            toLocaleDateString('es-MX',
                                                {
                                                    weekday: 'long', day: 'numeric', month: 'long',
                                                    year: 'numeric'
                                                })
                                        }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                        <span class="tag is-info-custom">
                                            Vacantes 
                                        </span>
                                        {{ vacancieDetails.vacancieInfo.w_hiring_capacity }}
                                    </p>

                                    <p v-if="vacancieDetails"
                                        style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                        <span class="tag is-info-custom">
                                            Vacantes Disponinles
                                        </span>
                                        {{ vacancieDetails.vacancieInfo.w_hiring_capacity - vacancieDetails.vacancieInfo.filled_hiring }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: justify; margin-bottom: 1em; margin-top: 1em;">
                                        <span class="tag is-info-custom">
                                            Descripción
                                        </span>
                                        {{ vacancieDetails.vacancieInfo.description }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: justify; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">
                                            Dirección
                                        </span>
                                        {{ vacancieDetails.vacancieInfo.address }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails && vacancieDetails.vacancieInfo"
                                    style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                    <span class="tag is-info-custom">Salario</span>
                                    {{ vacancieDetails.vacancieInfo.salary !== null ? '$' + vacancieDetails.vacancieInfo.salary : 'No aplica' }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">
                                            Notas Adicionales
                                        </span> {{ vacancieDetails.vacancieInfo.notes }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em"> <span
                                            class="tag is-info-custom">Estado</span> {{
                                        vacancieDetails.vacancieInfo.state_name }}</p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">
                                            Municipio
                                        </span> {{ vacancieDetails.vacancieInfo.muni_name }}
                                    </p>

                                    <md-divider></md-divider>

                                    <p v-if="vacancieDetails"
                                        style="text-align: left; margin-bottom: 1em; margin-top: 1em">
                                        <span class="tag is-info-custom">
                                            Sector
                                        </span> {{ vacancieDetails.vacancieInfo.sector_name }}
                                    </p>
                                </div>
                            </div>
                        </template>
                    </CardComponent>
                </div>
            </div>
        </Transition>


        <!-- main tabs -->
        <div class="tabs " style="width: 100%;">
            <md-tabs v-model="activeTab" :style="{ borderRadius: '15em' }">
                <md-primary-tab v-for="(tab, index) in tabs" :key="index" :name="tab.name" @click="changeTab(tab.name)">
                    {{ tab.label }}
                </md-primary-tab>
            </md-tabs>
        </div>


        <!-- vacancie actions -->
        <div class="Cerrarcontainer">
            <md-filled-tonal-button @click="handleCloseVacancie">
                Cerrar
                
            </md-filled-tonal-button>

            <md-filled-tonal-button @click="handleEndedVacancie" class="endedButton">
                <h6> Terminar</h6>
            </md-filled-tonal-button>
        </div>


        <!-- main table -->
        <div class="job-table">
            <div class="search">
                <md-outlined-text-field label="Buscar..." value="Value" v-model="searchQuery">
                </md-outlined-text-field>
            </div>

            <TableComponent :columns="tableColumns" :searchQuery="searchQuery" :items="paginatedItems">
            </TableComponent>

            <!-- pagination list -->
            <nav class="pagination" role="navigation" aria-label="pagination">
        <!-- <button class="pagination-previous" @click="prevPage" :disabled="currentPage === 1">Anterior</button> -->

        <md-filled-tonal-icon-button @click="prevPage" :disabled="currentPage === 1">
          <span class="material-symbols-outlined">arrow_back</span>
        </md-filled-tonal-icon-button>

        <md-filled-tonal-icon-button @click="nextPage" :disabled="currentPage === totalPages">
          <span class="material-symbols-outlined">arrow_forward</span>
        </md-filled-tonal-icon-button>

        <!-- <button class="pagination-next" @click="nextPage" :disabled="currentPage === totalPages">Siguiente</button> -->
        <ul class="pagination-list">
          <li v-for="page in totalPages" :key="page">
            <a class="pagination-link" :class="{ 'is-current': page === currentPage }"
                            @click="currentPage = page">{{
                                page
                            }}</a>
          </li>
        </ul>
      </nav>
        </div>


        <!-- footer -->
        <FooterComponent />
    
<!-- modal for close vacancie -->
<Transition name="bounce">
    <ModalComponent v-if="showModalCloseVacancie" :title="modalTitle" @close="closeModal">
        <template v-slot:content>

            <p> {{ closeVacMessage }}</p>

        </template>
        <template v-slot:buttons>
            <md-elevated-button @click="handleAceptar">
                {{ acceptButton }}
            </md-elevated-button>

       <md-elevated-button @click="closeModalClose">
                Volver
            </md-elevated-button>

            <!-- <md-filled-tonal-icon-button @click="closeModalClose">
                <span class="material-symbols-outlined">cancel</span>
</md-filled-tonal-icon-button> -->

        </template>
    </ModalComponent>
</Transition>


<!-- modal for ended vacancie -->
<Transition name="bounce">
    <ModalComponent v-if="showModalEnd" :title="modalTitle" @close="closeModal">
        <template v-slot:content>

            <p> {{ endedVacancie }}</p>

        </template>
        <template v-slot:buttons>
            <md-elevated-button  @click="handleAceptar">
                {{ acceptButtonTwo }}
            </md-elevated-button>
            <md-elevated-button @click="closeModalClose">
                Volver
            </md-elevated-button>
        </template>
    </ModalComponent>
</Transition>


    </div>
</template>



<!-- TO DO: ESTE ES EL BUENO PARA LAS TABLAS SASHA GREY -->
<style scoped   src =   "../styles/material/theme.dark.css">            </style>
<style scoped   src =   "../styles/material/theme.light.css">           </style>
<style scoped   src =   "../styles/views/MaterialTheming.css">          </style>
<style scoped   src =   "../styles/views/VacantesListadoAbierta.css">   </style>



<script>
import CardComponent                    from    '../components/CardComponent.vue';
import FooterComponent                  from    '../components/FooterComponent.vue';
import HeaderComponent                  from    '../components/HeaderComponent.vue';
import MenuComponent                    from    '../components/MenuComponent.vue';
import MessageComponent                 from    '../components/MessageComponent.vue';
import TableComponent                   from    '../components/TableComponent.vue';
import ModalComponent                   from    '../components/ModalComponent.vue';

import { loadVacancie, loadRequests }   from    '../api/ApiService';

export default
{
    components:
    {
        CardComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MessageComponent,
        TableComponent,
        ModalComponent
    },



    async created() 
    {
        try 
        {
            const data              =   await loadVacancie(this.$store);
            this.vacancieDetails    =   data;
            console.log("DATOS DE LA API:", data);
        } 
        catch (error) 
        {
            console.error('Error loading vacancie:', error);
        }


        try 
        {
            const data              =   await loadRequests(this.$store);
            this.requestsList       =   data;
            this.tableItemsTab1     =   data;
            console.log("LISTADO DE REQUEST:", data);
        } 
        catch (error) 
        {
            console.error('Error loading requests:', error);
        }
    },



    data() 
    {
        return  {
                    assistance              :   null,
                    currentPage             :   1,
                    itemsPerPage            :   10,
                    mostrarLista            :   false,
                    requestsList            :   null,
                    showModal               :   false,
                    studentDetails          :   null,
                    vacancieDetails         :   null,
                    showModalCloseVacancie  :   false,
                    showModalEnd            :   false,
                    mainTheme               :   'light',
                    acceptButton            :   'Aceptar',
                    acceptButtonTwo         :   'Aceptar',

                    activeTab               :   'tab1',
                    searchQuery             :   '',

                    closeVacMessage :   `Tenga en cuenta que al cerrar la unidad receptora, ya no podrá recibir nuevas solicitudes
                                        de nuestros alumnos para unirse a su equipo. Sin embargo, las solicitudes previamente recibidas
                                        permanecerán activas para su validación. Además, los pasantes activos continuarán su curso con normalidad.
                                        Por favor, recuerde que una vez cerrada la unidad receptora, no podrá ser abierta nuevamente.
                                        Si necesita más personal en el futuro para la misma, deberá crear una nueva aparte.`,

                    descripcion     :   `Aquí puedes visualizar en detalle tu Unidad Receptora  abierta. Además,
                                        tienes la posibilidad de acceder a las solicitudes recibidas para dicha Unidad Receptora.
                                        Esto incluye la revisión de los perfiles de los candidatos, sus credenciales
                                        y la posibilidad de interactuar con ellos. También puedes ver las confirmaciones de primera asistencia,
                                        lo que te permite tener un control y seguimiento de los candidatos que han mostrado un interés inicial
                                        y han confirmado su disponibilidad. Nuestra plataforma está diseñada para hacer el proceso de contratación
                                        más eficiente y efectivo. Gracias por utilizar nuestro servicio`,
                    
                    endedVacancie       :   `¡Cuidado! Terminar una unidad receptora equivale a "borrarla".
                                            Todas las solicitudes recibidas previamente,
                                            así como todos los pasantes activos, serán dados de baja junto con la unidad receptora.
                                            Ten en cuenta que, para terminar dicha unidad receptora, no debe haber procesos activos,
                                            como incidencias o pasantías pendientes de horas por registrar.`,
                    
                    

                    closeMessage : `sfadfda`,
                    tabs:
                    [
                        { name  : 'tab1', label : 'Solicitudes de Ingreso'      },
                    ],

                    tableItemsTab1:
                    [],

                    tableItemsTab2: [],

                    tableColumns:
                    [
                        { label     :   'Nombre',               field   :   'nombre'            },
                        { label     :   'Actividad',            field   :   'actividad'         },
                        { label     :   'Solicitud ',           field   :   'requestState'      },
                        { label     :   'Respuesta',            field   :   'accepted_date'     },
                        { label     :   'Primera Asistencia',   field   :   'presenseState'     },
                        { label     :   'Acciones',             field   :   'detalles'          },
                    ],
                }
    },



    computed: 
    {
        totalPages() 
        {
            const items = this.getCurrentTabItems();
            return Math.ceil(items.length / this.itemsPerPage);
        },


        paginatedItems() 
        {
            const items   =   this.getCurrentTabItems();
            const start   =   (this.currentPage - 1) * this.itemsPerPage;
            const end     =   start + this.itemsPerPage;

            return items.slice(start, end);
        },
    },



    methods:
    {
        handleThemeChange(e) 
        {
            this.mainTheme = e.matches ? 'dark' : 'light';
            console.log(`El sistema está configurado para tema ${this.mainTheme}.`);
        },


        changeTab(tab) 
        {
            this.activeTab = tab;
            this.currentPage = 1;
        },


        toggleMenu() 
        {
            this.mostrarLista = !this.mostrarLista;
        },


        getCurrentTabItems() 
        {
            switch(this.activeTab) 
            {
                case 'tab1': return this.tableItemsTab1;
                case 'tab2': return this.tableItemsTab2;
                case 'tab3': return this.tableItemsTab3;
                case 'tab4': return this.tableItemsTab4;
                case 'tab5': return this.tableItemsTab5;
                default: return [];
            }
        },


        nextPage() 
        {
            if (this.currentPage < this.totalPages) 
            {
                this.currentPage++;
            }
        },


        prevPage() 
        {
            if (this.currentPage > 1)
            {
                this.currentPage--;
            }
        },


        totalPages() 
        {
            const items = this.getCurrentTabItems();
            return Math.ceil(items.length / this.itemsPerPage);
        },


        handleCloseVacancie()
        {
            this.showModalCloseVacancie = true;
            this.modalTitle = 'Cerrar la Unidad Receptora';
        },

        handleEndedVacancie()
        {
            this.showModalEnd = true;
            this.modalTitle = 'Terminar la Unidad Receptora';
        },


        closeModalClose()
        {
            this.showModalCloseVacancie = false;
            this.showModalEnd = false;
        },


        closeModal()
        {
            this.showModal = false;
        },

    },


    mounted() 
    {
        this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        this.mediaQuery.addEventListener('change', this.handleThemeChange);
        this.handleThemeChange(this.mediaQuery); // Llama inicialmente para establecer el estado inicial
    },



    beforeUnmount() 
    {
        if (this.mediaQuery) 
        {
            this.mediaQuery.removeEventListener('change', this.handleThemeChange);
        }
    },
}
</script>