





import    { createApp                     }   from    'vue'
import    { getAnalytics                  }   from    'firebase/analytics';
import    { getMessaging, onMessage       }   from    'firebase/messaging';
import    { initializeApp                 }   from    'firebase/app';
import    { createRouter,createWebHistory }   from    'vue-router';
import    App                                 from    './App.vue'
import    jwt_decode                          from    'jsonwebtoken/decode';
import    mainStore                           from    './GLOBAL/store/mainStore';
import    PrimeVue                            from    'primevue/config';
import    Vue3GoogleLogin                     from    'vue3-google-login';
import    VueDatePicker                       from    '@vuepic/vue-datepicker';

import    '@vuepic/vue-datepicker/dist/main.css'

const firebaseConfig = 
{
  apiKey: "AIzaSyAbOL3vtiZ6ExvDMS62XSJyPu9SX9S80-0",
  authDomain: "bolsatrabajo-c2e5e.firebaseapp.com",
  projectId: "bolsatrabajo-c2e5e",
  storageBucket: "bolsatrabajo-c2e5e.appspot.com",
  messagingSenderId: "851139924105",
  appId: "1:851139924105:web:7635c99dbad6bde3c3f658",
  measurementId: "G-JYC2LW0Y46"
};

/*****************************Vistas**********************************/
//FORMA DE NOMBRAR LAS VARIABLES DE LOS COMPONENTES DE LAS VISTAS:
// Dominio_Nombre

//GLOBAL
import InicioSesion                       from        './GLOBAL/views/InicioSesion.vue';



//STAFF
import STAFF_PaginaInicio                         from        './STAFF/views/PaginaInicio.vue' ;
import STAFF_PerfilStaff                          from        './STAFF/views/PerfilStaff.vue' ;
import STAFF_EmpresasMenu                         from        './STAFF/views/EmpresasMenu.vue' ;
import STAFF_AltaEmpresa                          from        './STAFF/views/EmpresasMenuAltas.vue' ;
import STAFF_AprobarSolicitudes                   from        './STAFF/views/EmpresasMenuValidaciones.vue' ;
import STAFF_ValidarSolicitudes                   from        './STAFF/views/EmpresasMenuValidacionesSolicitud.vue' ;
import STAFF_MisAlumnos                           from        './STAFF/views/AlumnosMisalumnos.vue' ; 
import STAFF_DetallesAlumno                       from        './STAFF/views/AlumnosMisalumnosDetalles.vue' ;    
import STAFF_InfoContacto                         from        './STAFF/views/InfoContacto.vue' ;
import STAFF_MisConversaciones                    from        './STAFF/views/MisConversaciones.vue' ;
import STAFF_MisMensajes                          from        './STAFF/views/MisMensajes.vue' ;
import STAFF_IncidenciasListado                   from        './STAFF/views/IncidenciasListado.vue' ;
import STAFF_IncidenciasListadoMensajes           from        './STAFF/views/IncidenciasListadoMensajes.vue' ;
import STAFF_IncidenciasListadoEmpresas           from        './STAFF/views/IncidenciasListadoEmpresas.vue' ;
import STAFF_IncidenciasListadoMensajesEmpresas   from        './STAFF/views/IncidenciasListadoMensajesEmpresas.vue' ;



//STUDENT
import STUDENT_SolicitudesEstadoConfirmacion          from        './STUDENT/views/SolicitudesEstadoConfirmacion.vue';
import STUDENT_EditarPerfil                           from        './STUDENT/views/PerfilEditarperfil.vue'
import STUDENT_SolicitudesEstado                      from        './STUDENT/views/SolicitudesEstado.vue';
import STUDENT_InfoContacto                           from        './STUDENT/views/InfoContacto.vue';
import STUDENT_SolicitudesDetalles                    from        './STUDENT/views/SolicitudesDetalles.vue';
import STUDENT_MiPerfil                               from        './STUDENT/views/MiPerfil.vue';
import STUDENT_MiProgreso                             from        './STUDENT/views/MiProgreso.vue';
import STUDENT_MisConversaciones                      from        './STUDENT/views/MisConversaciones.vue';
import STUDENT_MisMensajes                            from        './STUDENT/views/MisMensajes.vue';
import STUDENT_SolicitudesNueva                       from        './STUDENT/views/SolicitudesNueva.vue';
import STUDENT_PaginaInicio                           from        './STUDENT/views/PaginaInicio.vue';
import STUDENT_ProgresoDetalles                       from        './STUDENT/views/ProgresoDetalles.vue';
import STUDENT_ProgresoDetallesInc                    from        './STUDENT/views/ProgresoDetalllesIncidencias.vue';
import STUDENT_BolsaTrabajo                           from        './STUDENT/views/BolsaTrabajo.vue' 
import STUDENT_ProgresoDetalllesIncidenciasMensajes   from        './STUDENT/views/ProgresoDetalllesIncidenciasMensajes.vue' 



//COMPANY
import COMPANY_VacantesNueva              from        './COMPANY/views/VacantesNuevaCrear.vue' 
import COMPANY_SolicitudesEstado          from        './COMPANY/views/VacantesListadoEstado.vue';
import COMPANY_VacantesInformacion        from        './COMPANY/views/VacantesListadoRevision.vue'
import COMPANY_VacantesAbierta            from        './COMPANY/views/VacantesListadoAbierta.vue'
import COMPANY_VacantesCerrada            from        './COMPANY/views/VacantesListadoCerrada.vue'
import COMPANY_VacantesTerminada          from        './COMPANY/views/VacantesListadoTerminada.vue'
import COMPANY_EditarVacante              from        './COMPANY/views/VacantesListadoRechazada.vue'
import COMPANY_EmpledosAsistencias        from        './COMPANY/views/EmpleadosActualesDetallesAsistencias.vue' //tambien se agregan horas aqui
import COMPANY_EmpleadosIncidencias       from        './COMPANY/views/EmpleadosActualesDetallesIncidencias.vue'
import COMPANY_EmpleadosActuales          from        './COMPANY/views/EmpleadosActuales.vue'
import COMPANY_EmpleadosDetalles          from        './COMPANY/views/EmpleadosActualesDetalles.vue'
import COMPANY_IngresoListado             from        './COMPANY/views/IngresoListado.vue'
import COMPANY_IngresoConfirmacion        from        './COMPANY/views/IngresoConfirmacion.vue'
import COMPANY_BuzonCandidatos            from        './COMPANY/views/SolicitudesListado.vue' 
import COMPANY_DetallesCandidatura        from        './COMPANY/views/SolicitudesListadoUnirse.vue' 
import COMPANY_BolsaTrabajo               from        './COMPANY/views/BolsaTrabajo.vue' 
import COMPANY_BolsaTrabajoNueva          from        './COMPANY/views/BolsaTrabajoNueva.vue' 
import COMPANY_BolsaTrabajoListado        from        './COMPANY/views/BolsaTrabajoListado.vue' 
import COMPANY_IncidenciasMensajes        from        './COMPANY/views/EmpleadosActualesDetallesIncidenciasMensajes';
import COMPANY_DetallesAlumno             from        './COMPANY/views/DetallesAlumno.vue' 
import COMPANY_InfoContacto               from        './COMPANY/views/InfoContacto.vue'
import COMPANY_PerfilStaff                from        './COMPANY/views/MiPerfil.vue'
import COMPANY_MisAlumnos                 from        './COMPANY/views/MisAlumnos.vue' 
import COMPANY_MisConversaciones          from        './COMPANY/views/MisConversaciones.vue'
import COMPANY_MisMensajes                from        './COMPANY/views/MisMensajes.vue'
import COMPANY_MisPostulados              from        './COMPANY/views/MisPostulados.vue'
import COMPANY_PaginaInicio               from        './COMPANY/views/PaginaInicio.vue'



//Temas & Iconos
//BULMA CSS
import 'bulma/css/bulma.css';
import "bulma-responsive-tables/bulma-responsive-tables.scss";

//PRIME VUE
import 'primevue/resources/themes/saga-purple/theme.css';

//ICONOS
import 'material-symbols/outlined.css';

//MATERIAL YOU WEB COMPONENTS
import    '@material/web/button/elevated-button';
import    '@material/web/button/filled-tonal-button';
import    '@material/web/progress/circular-progress';
import    '@material/web/progress/linear-progress';
import    '@material/web/select/filled-select';
import    '@material/web/select/outlined-select';
import    '@material/web/select/select-option.js';
import    '@material/web/tabs/primary-tab';
import    '@material/web/tabs/tabs';
import    '@material/web/textfield/filled-text-field';
import    '@material/web/textfield/outlined-text-field';
import    '@material/web/list/list-item';
import    '@material/web/list/list';
import    '@material/web/iconbutton/filled-icon-button'
import    '@material/web/iconbutton/filled-tonal-icon-button'



//RUTAS AKA PATHS LOKOS
const globalRoutes = 
[
  { path: '/', component: InicioSesion, name : 'InicioSesion'},
];



//RUTAS DEL STAFF
const staffRoutes = 
[
  { path  :  '/personal/inicio',                                  name  : 'personal-inicio',                                     component : STAFF_PaginaInicio                          },
  { path  :  '/personal/perfil/mi-perfil',                        name  : 'personal-perfil-mi-perfil',                           component : STAFF_PerfilStaff                           },
  { path  :  '/personal/empresas/menu',                           name  : 'personal-empresas-menu',                              component : STAFF_EmpresasMenu                          },
  { path  :  '/personal/empresas/menu/altas',                     name  : 'personal-empresas-menu-altas',                        component : STAFF_AltaEmpresa                           },
  { path  :  '/personal/empresas/menu/validaciones',              name  : 'personal-empresas-menu-validaciones',                 component : STAFF_AprobarSolicitudes                    },
  { path  :  '/personal/empresas/menu/validaciones/solicitud',    name  : 'personal-empresas-menu-validaciones-solicitud',       component : STAFF_ValidarSolicitudes                    },
  { path  :  '/personal/alumnos/mis-alumnos',                     name  : 'personal-alumnos-mis-alumnos',                        component : STAFF_MisAlumnos                            },
  { path  :  '/personal/alumnos/mis-alumnos/detalles',            name  : 'personal-alumnos-mis-alumnos-detalles',               component : STAFF_DetallesAlumno                        },
  { path  :  '/personal/contacto',                                name  : 'personal-contacto',                                   component : STAFF_InfoContacto                          },
  { path  :  '/personal/lista-mensajes',                          name  : 'personal-lista-personas',                             component : STAFF_MisConversaciones                     },
  { path  :  '/personal/mensajes',                                name  : 'personal-mensajes',                                   component : STAFF_MisMensajes                           },
  { path  :  '/personal/incidencias',                             name  : 'personal-incidencias',                                component : STAFF_IncidenciasListado                    },  
  { path  :  '/personal/incidencias/mensajes',                    name  : 'personal-incidencias-mensajes',                       component : STAFF_IncidenciasListadoMensajes            },  
  { path  :  '/personal/incidencias/listado/empresas',            name  : 'personal-incidencias-listado-empresas',               component : STAFF_IncidenciasListadoEmpresas            }, 
  { path  :  '/personal/incidencias/listado/empresas/mensajes',   name  : 'personal-incidencias-listado-empresas-mensajes',      component : STAFF_IncidenciasListadoMensajesEmpresas    }, 
];



//RUTAS DE EMPRESA
const companyRoutes = 
[
  { path  :  '/empresa/inicio',                                         name  : 'empresa-inicio',                                              component  :   COMPANY_PaginaInicio                 },
  { path  :  '/empresa/perfil/perfil-empresarial',                      name  : 'empresa-perfil-perfil-empresarial',                           component  :   COMPANY_PerfilStaff                  },
  { path  :  '/empresa/bolsaTrabajo/inicio',                            name  : 'empresa-bolsa-trabajo',                                       component  :   COMPANY_BolsaTrabajo                 },
  { path  :  '/empresa/bolsaTrabajo/nueva',                             name  : 'empresa-bolsa-trabajo-nueva',                                 component  :   COMPANY_BolsaTrabajoNueva            },
  { path  :  '/empresa/bolsaTrabajo/listado',                           name  : 'empresa-bolsa-trabajo-listado',                               component  :   COMPANY_BolsaTrabajoListado          },
  { path  :  '/empresa/vacantes/nueva/crear',                           name  : 'empresa-vacantes-nueva-crear',                                component  :   COMPANY_VacantesNueva                },
  { path  :  '/empresa/vacantes/listado/estado',                        name  : 'empresa-vacantes-listado-estado',                             component  :   COMPANY_SolicitudesEstado            },
  { path  :  '/empresa/vacantes/listado/estado/revision',               name  : 'empresa-vacantes-listado-estado-revision',                    component  :   COMPANY_VacantesInformacion          },
  { path  :  '/empresa/vacantes/listado/estado/rechazada',              name  : 'empresa-vacantes-listado-estado-rechazada',                   component  :   COMPANY_EditarVacante                },
  { path  :  '/empresa/vacantes/listado/estado/abierta',                name  : 'empresa-vacantes-listado-estado-abierta',                     component  :   COMPANY_VacantesAbierta              },
  { path  :  '/empresa/vacantes/listado/estado/cerrada',                name  : 'empresa-vacantes-listado-estado-cerrada',                     component  :   COMPANY_VacantesCerrada              },
  { path  :  '/empresa/vacantes/listado/estado/terminada',              name  : 'empresa-vacantes-listado-estado-terminada',                   component  :   COMPANY_VacantesTerminada            },
  { path  :  '/empresa/empleados/actuales',                             name  : 'empresa-empleados-actuales',                                  component  :   COMPANY_EmpleadosActuales            },
  { path  :  '/empresa/empleados/actuales/detalles',                    name  : 'empresa-empleados-actuales-detalles',                         component  :   COMPANY_EmpleadosDetalles            },
  { path  :  '/empresa/empleados/actuales/detalles/asistencia-horas',   name  : 'empresa-empleados-actuales-detalles-asistencia-horas',        component  :   COMPANY_EmpledosAsistencias          },
  { path  :  '/empresa/empleados/actuales/detalles/incidencias',        name  : 'empresa-empleados-actuales-detalles-incidencias',             component  :   COMPANY_EmpleadosIncidencias         },
  { path  :  '/empresa/ingreso/listado',                                name  : 'empresa-ingreso-listado',                                     component  :   COMPANY_IngresoListado               },
  { path  :  '/empresa/ingreso/confirmacion',                           name  : 'empresa-ingreso-listado-confirmacion',                        component  :   COMPANY_IngresoConfirmacion          },
  { path  :  '/empresa/solicitudes/listado/unirse',                     name  : 'empresa-solicitudes-listado-unirse',                          component  :   COMPANY_DetallesCandidatura          },
  { path  :  '/empresa/solicitudes/listado',                            name  : 'empresa-solicitudes-listado',                                 component  :   COMPANY_BuzonCandidatos              },
  { path  :  '/empresa/empleados/actuales/detalles/incidencias',        name  : 'empresa-empleados-actuales-detalles-incidencias-messages',    component  :   COMPANY_IncidenciasMensajes          },
  { path  :  '/empresa/empresa-detalles-alumno',                        name  : 'empresa-detalles-alumno',                                     component  :   COMPANY_DetallesAlumno               },
  { path  :  '/empresa/lista-mensajes',                                 name  : 'empresa-lista-mensajes',                                      component  :   COMPANY_MisConversaciones            },
  { path  :  '/empresa/mensajes-contacto',                              name  : 'empresa-contacto',                                            component  :   COMPANY_InfoContacto                 },
  { path  :  '/empresa/mensajes-empresa',                               name  : 'empresa-mensajes',                                            component  :   COMPANY_MisMensajes                  },
  { path  :  '/empresa/mis-alumnos',                                    name  : 'empresa-mis-alumnos',                                         component  :   COMPANY_MisAlumnos                   },
  { path  :  '/empresa/mis-postulados',                                 name  : 'mis-postulados',                                              component  :   COMPANY_MisPostulados                },
];



const studentRoutes = 
[
  { path  :  '/estudiante/contacto',                                  name  : 'contacto',                                            component  :   STUDENT_InfoContacto                                 },
  { path  :  '/estudiante/detallesProgreso',                          name  : 'detallesProgreso',                                    component  :   STUDENT_ProgresoDetalles                             },
  { path  :  '/estudiante/perfil/editar-perfil',                      name  : 'estudiante-perfil-editar-perfil',                     component  :   STUDENT_EditarPerfil                                 }, 
  { path  :  '/estudiante/solicitudes/estado',                        name  : 'estudiante-solicitudes-estado',                       component  :   STUDENT_SolicitudesEstado                            },
  { path  :  '/estudiante/home',                                      name  : 'estudiante-home',                                     component  :   STUDENT_PaginaInicio                                 },
  { path  :  '/estudiante/lista-mensajes',                            name  : 'lista-mensajes',                                      component  :   STUDENT_MisConversaciones                            },
  { path  :  '/estudiante/messages',                                  name  : 'ListaMensajes',                                       component  :   STUDENT_MisMensajes                                  },
  { path  :  '/estudiante/solicitudes/nueva',                         name  : 'solicitudes/nueva',                                   component  :   STUDENT_SolicitudesNueva                             },
  { path  :  '/estudiante/perfil',                                    name  : 'perfil-estudiante',                                   component  :   STUDENT_MiPerfil                                     },
  { path  :  '/estudiante/progreso',                                  name  : 'progreso',                                            component  :   STUDENT_MiProgreso                                   },
  { path  :  '/estudiante/solicitudes/estado/confirmacion',           name  : 'estudiante-solicitudes-estado-confirmacion',          component  :   STUDENT_SolicitudesEstadoConfirmacion                },
  { path  :  '/estudiante/solicitudes/detalles-vacante',              name  : 'STUDENT_SolicitudesDetalles',                         component  :   STUDENT_SolicitudesDetalles                          },
  { path  :  '/estudiante/progreso/detalles/incidencias',             name  : 'estudiante-progreso-detalles-incidencias',            component  :   STUDENT_ProgresoDetallesInc                          },
  { path  :  '/estudiante/bolsa-trabajo/inicio',                      name  : 'bolsa-trabajo-inicio',                                component  :   STUDENT_BolsaTrabajo                                 },
  { path  :  '/estudiante/progreso/detalles/incidencias/mensajes',    name  : 'estudiante-progreso-detalles-incidencias-mensajes',   component  :   STUDENT_ProgresoDetalllesIncidenciasMensajes         },
];



//GUARDIANES DE LA GALAXIA AKA GUARDS
const studentGuard = (to, from, next) => 
{
  const token         =   localStorage.getItem('token');
  const decodedToken  =   jwt_decode(token);
  const domain        =   decodedToken.rolStudent;

  if(domain === 'STUDENT')  { next(); }
  else  { next({ path: '/' });  }
};


const companyGuard = (to, from, next) => 
{
  const token         =   localStorage.getItem('token');
  const decodedToken  =   jwt_decode(token);
  const domain        =   decodedToken.rol_company;

  if(domain === 'COMPANY') { next(); }
  else { next({ path: '/' }); }
};


const staffGuard = (to, from, next) => 
{
  const token         = localStorage.getItem('token');
  const decodedToken  = jwt_decode(token);
  const domain        = decodedToken.rol_staff;

  if(domain === 'STAFF') { next(); }
  else { next({ path: '/' }); }
};


studentRoutes.forEach(  route   =>  { route.beforeEnter = studentGuard; });
companyRoutes.forEach(  route   =>  { route.beforeEnter = companyGuard; });
staffRoutes.forEach(    route   =>  { route.beforeEnter = staffGuard;   });


const router = createRouter
({
  history: createWebHistory(),
  routes: 
  [
    ...globalRoutes,
    ...studentRoutes,
    ...companyRoutes,
    ...staffRoutes,
  ],
});



const disableBack = () => {
  const token = localStorage.getItem('token');
  if (token) 
  {
    const decodedToken = jwt_decode(token);
    if (decodedToken.rol_staff === 'STAFF') 
    {
      router.push({ name: 'personal-inicio' });
    } 
    else if (decodedToken.rol_company === 'COMPANY') 
    {
      router.push({ name: 'empresa-inicio' });
    } 
    else if (decodedToken.rolStudent === 'STUDENT') 
    {
      router.push({ name: 'estudiante-home' });
    } 
    else 
    {
      router.push({ name: 'InicioSesion' });
    }
  }
  else 
  {
    router.push({ name: 'InicioSesion' });
  }
};
disableBack();
// Capturar el evento de popstate y ejecutar la función para deshabilitar el botón de retroceso
window.addEventListener('popstate', disableBack);



// Función para redirigir al usuario según su rol
const redirectUser = () => {
  const token = localStorage.getItem('token');
  if (token) 
  {
    const decodedToken = jwt_decode(token);
    if (decodedToken.rol_staff === 'STAFF') 
    {
      router.push({ name: 'personal-inicio' });
    } 
    else if (decodedToken.rol_company === 'COMPANY') 
    {
      router.push({ name: 'empresa-inicio' });
    } 
    else if (decodedToken.rolStudent === 'STUDENT') 
    {
      router.push({ name: 'estudiante-home' });
    } 
    else 
    {
      router.push({ name: 'InicioSesion' });
    }
  } 
  else 
  {
    router.push({ name: 'InicioSesion' });
  }
};
// Capturar el evento de Vue.js para detectar cuando la aplicación está lista
router.isReady().then(() => {
  // Ejecutar la función para redirigir al usuario
  redirectUser();

  // Capturar el evento de popstate y ejecutar la función para redirigir al usuario
  window.addEventListener('popstate', redirectUser);
});



// Función para verificar si el token ha expirado
const checkTokenExpiration = () => {
  const token = localStorage.getItem('token');
  if (token) 
  {
    try 
    {
      const decodedToken = jwt_decode(token);
      const expirationTime = decodedToken.exp * 1000;
      const currentTime = new Date().getTime();
      if (currentTime > expirationTime) 
      {
        localStorage.clear();
        return true; // Token expirado
      }
      return false; // Token válido
    } 
    catch (error) 
    {
      console.error('Error decoding token:', error);
      return true; // Considerar expirado en caso de error al decodificar
    }
  }
  return true; // No hay token, consideramos como expirado
};

// Guard de ruta para verificar token expirado
const expiredTokenGuard = (to, from, next) => {
  const tokenExpired = checkTokenExpiration();
  if (tokenExpired && to.name !== 'InicioSesion') 
  {
    next({ name: 'InicioSesion' }); // Redirigir a la página de inicio de sesión si el token ha expirado
  } 
  else 
  {
    next(); // Continuar con la navegación normal
  }
};

// Aplica el guard de token expirado a todas las rutas
router.beforeEach(expiredTokenGuard);



const firebaseApp = initializeApp(firebaseConfig);

// Habilita Google Analytics
// const analytics = getAnalytics(firebaseApp);


//  const messaging = getMessaging();

//  onMessage(messaging, (payload) => 
//  {
//    console.log('Message received. ', payload);
// //   // Aquí manejar la notificación y mostrarla como prefieras
// });



const app  =  createApp(App);
app.use(Vue3GoogleLogin,{ clientId : process.env.VUE_APP_GOOGLE_CLIENT_ID });
app.use(router);
app.use(PrimeVue);
app.use(mainStore);
app.use(VueDatePicker);
// app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');


